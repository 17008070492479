import {Page} from '../../components/Page/Page'
import React from 'react'
import {Container} from '../../components/Container/Container'
import {
	Box,
	CheckBox,
	Heading,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableHeader,
	TableRow,
	Tabs,
	Text,
	TextInput,
} from 'grommet'
import {Link, useHistory, useLocation, useParams} from 'react-router-dom'
import {useRoutes} from '../../hooks/useRoutes'
import {PageTitle} from '../../components/TopNavigationBar/PageTitle'
import {Separator} from '../../components/TopNavigationBar/Separator'
import {BreadcrumbItem} from '../../components/TopNavigationBar/BreadcrumbItem'
import {getFullName, User} from '../../features/User/entities/user'
import _ from 'lodash'
import {StatusWarning} from 'grommet-icons'
import {useCompanyUsers} from '../../features/User/hooks/useCompanyUsers'
import {useCurrentUser} from '../../features/User/hooks/useCurrentUser'
import {
	canManageStage,
	ReclassificationStageEntity,
	ReclassificationTaskEntity,
} from '../../features/Reclassification/entities/reclassification-task'
import {useReclassificationTask} from '../../features/Reclassification/hooks/reclassificationTask/useReclassificationTask'
import {useReclassificationTaskUsers} from '../../features/Reclassification/hooks/reclassificationTask/useReclassificationTaskUsers'
import {useReclassificationTaskMutations} from '../../features/Reclassification/hooks/reclassificationTask/useReclassificationTaskMutations'
import {ReclassificationOverviewPageHeader} from '../ReclassificationOverview/ReclassificationOverviewPage'
import {UserProject} from '../../features/User/entities/userProject'
import {useCurrentUserHasPermissionOnResource} from '../../features/User/hooks/useCurrentUserHasPermission'
import {PermissionsEnum} from '../../features/User/entities/auth'
import {useCurrentProject} from '../../hooks/useCurrentProject'
import {SRPrimaryButton, SRSecondaryButton} from 'sr-react-commons'

export type ReclassificationUsersRouteParams = {
	clientSlug: string
	projectSlug: string
	reclassificationId: string
}

export function ReclassificationUsersPageTitle() {
	const {reclassificationId} = useParams<ReclassificationUsersRouteParams>()
	const project = useCurrentProject()
	const {data: reclassification} = useReclassificationTask(project?._id, reclassificationId)

	return project && reclassification ? (
		<PageTitle>
			<Separator />
			<BreadcrumbItem>{reclassification.name}</BreadcrumbItem>
			<Separator />
			<BreadcrumbItem>Assigned Users</BreadcrumbItem>
		</PageTitle>
	) : null
}

function ReclassificationUsersPageContent(props: {
	project: UserProject
	reclassification: ReclassificationTaskEntity
	reclassificationUsers: User[]
	currentUser: User
	hasSrAdminPermission: boolean
	actions: {
		assignUsersToStage: (stageId: string, userIds: string[]) => Promise<ReclassificationTaskEntity | undefined>
		unassignUsersFromStage: (stageId: string, userIds: string[]) => Promise<ReclassificationTaskEntity | undefined>
	}
}) {
	const location = useLocation()
	const history = useHistory()
	const searchParams = new URLSearchParams(location.search)
	const activeStageIndex = searchParams.has('stage') ? parseInt(searchParams.get('stage')!) : 0

	const reclassificationManager = props.reclassificationUsers.find(
		user => user._id === props.reclassification.reclassificationCompanyManager,
	)!
	const reviewManager = props.reclassificationUsers.find(
		user => user._id === props.reclassification.reviewCompanyManager,
	)!

	return (
		<Box gap={'small'}>
			<ReclassificationOverviewPageHeader
				project={props.project}
				reclassification={props.reclassification}
				heading={'Assign users to stages'}
			/>
			<Tabs alignControls={'start'} activeIndex={activeStageIndex} onActive={index => history.push(`?stage=${index}`)}>
				{props.reclassification.stages.map((stage, index) => {
					const userCanManageStage = canManageStage(
						props.currentUser,
						stage,
						props.reclassification,
						props.hasSrAdminPermission,
					)
					const managerName =
						stage.assignedCompany === props.reclassification.reviewCompany
							? getFullName(reviewManager)
							: getFullName(reclassificationManager)
					return (
						<Tab key={stage._id} title={`${index + 1}. ${stage.name}`}>
							<Box gap={'small'} flex={false}>
								<Heading level={'3'}>
									Stage {index + 1}: {stage.name}: Assigned users
								</Heading>
								<Text>Managed by {managerName}</Text>
								<ReclassificationUserList
									key={stage._id}
									projectId={props.project._id}
									reclassification={props.reclassification}
									stage={stage}
									canManage={userCanManageStage}
									reclassificationUsers={props.reclassificationUsers}
									assignUsers={userIds => props.actions.assignUsersToStage(stage._id, userIds)}
									unassignUsers={userIds => props.actions.unassignUsersFromStage(stage._id, userIds)}
								/>
							</Box>
						</Tab>
					)
				})}
			</Tabs>
		</Box>
	)
}

export function ReclassificationUsersPage() {
	const {reclassificationId} = useParams<ReclassificationUsersRouteParams>()
	const project = useCurrentProject()
	const {data: reclassification} = useReclassificationTask(project?._id, reclassificationId)
	const {userDetails, authDetails} = useCurrentUser()
	const {users: reclassificationUsers} = useReclassificationTaskUsers(project?._id, reclassificationId)
	const {assignUsersToStage, unassignUsersFromStage} = useReclassificationTaskMutations()
	const {data: hasSrAdminPermission} = useCurrentUserHasPermissionOnResource(PermissionsEnum.SR_ADMIN, project?._id)
	// @ts-ignore
	return (
		<Page>
			<Container width={'xlarge'} overflow={{vertical: 'auto'}}>
				{reclassification &&
				project &&
				reclassificationUsers &&
				userDetails &&
				authDetails &&
				hasSrAdminPermission !== undefined ? (
					<ReclassificationUsersPageContent
						project={project}
						reclassification={reclassification}
						currentUser={userDetails}
						reclassificationUsers={reclassificationUsers}
						hasSrAdminPermission={hasSrAdminPermission}
						actions={{
							assignUsersToStage: (stageId: string, userIds: string[]) =>
								assignUsersToStage({
									projectId: project._id,
									reclassificationTaskId: reclassification._id,
									stageId: stageId,
									userIds: userIds,
								}),
							unassignUsersFromStage: (stageId: string, userIds: string[]) =>
								unassignUsersFromStage({
									projectId: project._id,
									reclassificationTaskId: reclassification._id,
									stageId: stageId,
									userIds: userIds,
								}),
						}}
					/>
				) : null}
			</Container>
		</Page>
	)
}

function ReclassificationUserList({
	projectId,
	reclassification,
	stage,
	assignUsers,
	unassignUsers,
	reclassificationUsers,
	canManage,
}: {
	canManage: boolean
	projectId: string
	reclassification: ReclassificationTaskEntity
	stage: ReclassificationStageEntity
	assignUsers: (userIds: string[]) => Promise<any>
	unassignUsers: (userIds: string[]) => Promise<any>
	reclassificationUsers: User[]
}) {
	const [uiState, setUiState] = React.useState<'list' | 'assigning'>(
		stage.assignedUsers.length === 0 && canManage ? 'assigning' : 'list',
	)
	const routes = useRoutes()
	return (
		<Box gap="small">
			{stage.assignedUsers.length === 0 && (
				<Box gap={'small'} flex={false}>
					<Box
						alignSelf={'center'}
						pad={'small'}
						direction={'row'}
						gap={'small'}
						background={'status-warning'}
						round="xsmall"
					>
						<StatusWarning color={'dark-1'} />
						<Text>This stage has no users assigned yet.</Text>
					</Box>
				</Box>
			)}
			{uiState === 'list' && (
				<>
					<SRPrimaryButton
						disabled={!canManage}
						alignSelf={'end'}
						onClick={() => setUiState('assigning')}
						label={'Assign users'}
					/>
					{stage.assignedUsers.length === 0 ? (
						<Text>No users assigned.</Text>
					) : (
						<Table>
							<TableHeader>
								<TableRow>
									<TableCell>Name</TableCell>
									<TableCell>Email</TableCell>
									<TableCell>Actions</TableCell>
								</TableRow>
							</TableHeader>
							<TableBody>
								{reclassificationUsers
									.filter(user => stage.assignedUsers.includes(user._id))
									.map(user => (
										<UserRow
											userActivityRoute={routes.userActivity.linkTo({userId: user._id})}
											key={user._id}
											reclassificationId={reclassification._id}
											projectId={projectId}
											user={user}
											onUnassign={() => unassignUsers([user._id])}
										/>
									))}
							</TableBody>
						</Table>
					)}
				</>
			)}
			{uiState === 'assigning' && (
				<Box>
					<AssignUsers
						stage={stage}
						onCancel={() => setUiState('list')}
						onAssignUsers={async userIds => assignUsers(userIds).then(() => setUiState('list'))}
					/>
				</Box>
			)}
		</Box>
	)
}

function filterUsers(searchTerm: string, users: User[]) {
	// This replace all the special characters like -,$
	// TODO use commons search function when available
	const exp = searchTerm !== '' && new RegExp(searchTerm.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&'), 'i')
	return users.filter(user => !exp || exp.test(getFullName(user)))
}

export function AssignUsers({
	onCancel,
	onAssignUsers,
	stage,
}: {
	stage: ReclassificationStageEntity
	onCancel?: () => void
	onAssignUsers: (userIds: string[]) => void
}) {
	const [nameFilter, setNameFilter] = React.useState('')
	const {data: possibleStageUsersPagination} = useCompanyUsers(stage.assignedCompany, {perPage: 10000, page: 0})
	const [selectedUsers, setSelectedUsers] = React.useState<string[]>([])

	if (possibleStageUsersPagination) {
		const users = possibleStageUsersPagination.result.filter(user => !stage.assignedUsers.includes(user._id))
		return (
			<Box gap={'xsmall'}>
				<Box flex={false}>
					<Box justify={'end'} gap={'small'} direction="row">
						{onCancel && <SRSecondaryButton onClick={onCancel} alignSelf={'start'} label={'Cancel'} />}
						<SRPrimaryButton
							onClick={() => onAssignUsers(selectedUsers)}
							disabled={selectedUsers.length === 0}
							alignSelf={'start'}
							label={'Add selected users'}
						/>
					</Box>
					<Text>Displaying all users that are managed by your company</Text>
					<Box gap="small" direction="row">
						<SRSecondaryButton
							size="small"
							label={'Select all'}
							onClick={() => setSelectedUsers(users.map(user => user._id))}
						/>
						<SRSecondaryButton size="small" label={'Select none'} onClick={() => setSelectedUsers([])} />
					</Box>
				</Box>

				<Box overflow={{vertical: 'auto'}}>
					<Table>
						<TableHeader>
							<TableRow>
								<TableCell alignContent={'start'}>Selected</TableCell>
								<TableCell>
									<Box alignSelf={'start'}>
										<Text>Name</Text>
										<TextInput
											value={nameFilter}
											onChange={({target}) => setNameFilter(target.value)}
											placeholder={'Type to search'}
										/>
									</Box>
								</TableCell>
								<TableCell>Email</TableCell>
							</TableRow>
						</TableHeader>
						<TableBody>
							{users &&
								_.sortBy(filterUsers(nameFilter, users), ['firstName', 'lastName']).map(user => (
									<TableRow key={user._id}>
										<TableCell>
											<CheckBox
												checked={selectedUsers.includes(user._id)}
												onChange={event =>
													event.target.checked
														? setSelectedUsers(selectedUsers => [...selectedUsers, user._id])
														: setSelectedUsers(selectedUsers => selectedUsers.filter(id => id !== user._id))
												}
											/>
										</TableCell>
										<TableCell>{getFullName(user)}</TableCell>
										<TableCell>{user.email}</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</Box>
			</Box>
		)
	} else {
		return null
	}
}

function UserRow(props: {
	userActivityRoute: string
	user: User
	projectId: string
	reclassificationId: string
	onUnassign: () => void
}) {
	return (
		<TableRow>
			<TableCell>{getFullName(props.user)}</TableCell>
			<TableCell>{props.user.email}</TableCell>
			<TableCell>
				<SRSecondaryButton alignSelf={'start'} size="small" label={'Remove'} onClick={props.onUnassign} />
				<Link to={props.userActivityRoute}>View activity</Link>
			</TableCell>
		</TableRow>
	)
}
