import React, {Reducer} from 'react'
import {Filter, Sorting} from './useData'

type SetPageAction = {
	type: 'SET_PAGE'
	payload: {
		page: number
	}
}

type SetFilterAction = {
	type: 'SET_FILTER'
	payload: {
		filterKey: string
		filterValue: string | string[] | undefined
	}
}

type ToggleSortAction<T> = {
	type: 'TOGGLE_SORT'
	payload: {
		columnKey: keyof T
	}
}

type Actions<T> = SetPageAction | SetFilterAction | ToggleSortAction<T>

interface PaginationFilterSortState<T> {
	filter: Filter<T>
	sorting: Sorting<T>
	pagination: {page: number}
}

function reducer<T>(state: PaginationFilterSortState<T>, action: Actions<T>): PaginationFilterSortState<T> {
	switch (action.type) {
		case 'SET_FILTER':
			return {
				...state,
				pagination: {page: 0},
				filter: {...state.filter, [action.payload.filterKey]: action.payload.filterValue},
			}
		case 'SET_PAGE':
			return {...state, pagination: {page: action.payload.page}}
		case 'TOGGLE_SORT':
			return {
				...state,
				sorting: {
					field: action.payload.columnKey,
					ascending: state.sorting.field === action.payload.columnKey ? !state.sorting.ascending : false,
				},
			}
	}
}

export function usePaginationFilterSort<T>(
	initialPagination: {page: number} = {page: 0},
	initialFilters: Filter<T> = {},
	initialSortBy: Sorting<T> = {field: null, ascending: true},
) {
	const [state, dispatch] = React.useReducer<Reducer<PaginationFilterSortState<T>, Actions<T>>>(reducer, {
		filter: initialFilters,
		pagination: initialPagination,
		sorting: initialSortBy,
	})

	const setPage = (page: number) => dispatch({type: 'SET_PAGE', payload: {page}})

	const setFilter = (filterKey: string, filterValue: string | string[] | undefined) =>
		dispatch({
			type: 'SET_FILTER',
			payload: {
				filterKey,
				filterValue,
			},
		})

	const toggleSort = (columnKey: keyof T) => dispatch({type: 'TOGGLE_SORT', payload: {columnKey}})

	return {state, actions: {setPage, setFilter, toggleSort}}
}
