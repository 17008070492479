import {config} from '../config/config'
import {buildRequestInstance} from 'sr-request-commons'
import {getIdToken} from './getIdToken'
import {getMaintenanceMode} from './maintenance-mode.token'
import {setMaintenanceMode, store} from '../redux/slice'

function buildSRApi(version: 'v2' | 'v3') {
	return buildRequestInstance(config.sr.backendUrl + version + '/', getIdToken, getMaintenanceMode, () => {
		store.dispatch(setMaintenanceMode(true))
	})
}

export const srApi = buildSRApi('v2')
export const srApiV3 = buildSRApi('v3')

export type Sort = Record<string, 1 | -1>

export type Pagination<T> = {
	count: number
	result: T[]
	skip: number
	limit: number
	sort: Sort
}
