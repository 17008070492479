import {Text, TableBody, TableCell, TableHeader, TableRow} from 'grommet'
import React from 'react'
import {ClassificationWithAnalysisView} from '../../../entities/classification'
import {SmallTable} from '../../../components/SmallTable/SmallTable'
import {format} from 'date-fns'
import {config} from '../../../config/config'
import {formatMagnitude} from '../../../utilities/formatUtilities'

export function ElementHistoryTable({
	classificationsWithAnalysisView,
	showAnalysisLink,
}: {
	classificationsWithAnalysisView: ClassificationWithAnalysisView[]
	showAnalysisLink: boolean
}) {
	return (
		<SmallTable>
			<TableHeader>
				<TableRow>
					<TableCell scope="col" border={{color: '#bdc0c5', size: '1px', side: 'all'}}>
						<Text weight="bold" size="xsmall">
							Analysis Name
						</Text>
					</TableCell>
					<TableCell scope="col" border={{color: '#bdc0c5', size: '1px', side: 'all'}}>
						<Text weight="bold" size="xsmall">
							Analysis Date
						</Text>
					</TableCell>
					<TableCell scope="col" border={{color: '#bdc0c5', size: '1px', side: 'all'}}>
						<Text weight="bold" size="xsmall">
							Status
						</Text>
					</TableCell>
					<TableCell scope="col" border={{color: '#bdc0c5', size: '1px', side: 'all'}}>
						<Text weight="bold" size="xsmall">
							M
						</Text>
					</TableCell>
					<TableCell scope="col" border={{color: '#bdc0c5', size: '1px', side: 'all'}}>
						<Text weight="bold" size="xsmall">
							T
						</Text>
					</TableCell>
					<TableCell scope="col" border={{color: '#bdc0c5', size: '1px', side: 'all'}}>
						<Text weight="bold" size="xsmall">
							C
						</Text>
					</TableCell>
				</TableRow>
			</TableHeader>
			<TableBody>
				{classificationsWithAnalysisView.map((classification, index) => {
					const [companySlug, projectSlug] = classification.tenantId.split('|')
					return (
						<TableRow key={index}>
							<TableCell border={{color: '#bdc0c5', size: '1px', side: 'all'}} width={'70px'}>
								<Text size="xsmall" title={classification.analysisViewEntity.name} truncate>
									{showAnalysisLink ? (
										<a
											style={{color: '1C62DC'}}
											target="_blank"
											rel="noopener noreferrer"
											title="Open in viewer in a new tab"
											href={`${config.sr.reactViewerUrl}/${companySlug}-${projectSlug}/${classification.analysisViewEntity.id}`}
										>
											{classification.analysisViewEntity.name}
										</a>
									) : (
										classification.analysisViewEntity.name
									)}
								</Text>
							</TableCell>
							<TableCell border={{color: '#bdc0c5', size: '1px', side: 'all'}} width={'70px'}>
								<Text
									size="xsmall"
									title={format(new Date(classification.analysisViewEntity.scanDate), 'dd/MM/yyyy')}
									truncate
								>
									{format(new Date(classification.analysisViewEntity.scanDate), 'dd/MM/yyyy')}
								</Text>
							</TableCell>
							<TableCell border={{color: '#bdc0c5', size: '1px', side: 'all'}}>
								<Text size="xsmall" title={classification.status} truncate>
									{classification.status}
								</Text>
							</TableCell>
							<TableCell border={{color: '#bdc0c5', size: '1px', side: 'all'}} width={'40px'}>
								<Text size="xsmall" title={formatMagnitude(parseFloat(classification.magnitude))} truncate>
									{formatMagnitude(parseFloat(classification.magnitude))}
								</Text>
							</TableCell>
							<TableCell border={{color: '#bdc0c5', size: '1px', side: 'all'}} width={'40px'}>
								<Text size="xsmall" title={formatMagnitude(classification.tolerance)} truncate>
									{formatMagnitude(classification.tolerance)}
								</Text>
							</TableCell>
							<TableCell border={{color: '#bdc0c5', size: '1px', side: 'all'}} width={'40px'}>
								<Text size="xsmall" title={classification.confidence.toString()} truncate>
									{classification.confidence}
								</Text>
							</TableCell>
						</TableRow>
					)
				})}
			</TableBody>
		</SmallTable>
	)
}
