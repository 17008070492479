import {User} from '../../User/entities/user'
import {ReclassificationWorkPackageStageTaskEntityWithWorkPackage} from './reclassification-work-package-stage-task'

export type ReclassificationStageType =
	| 'reclassification'
	| 'review'
	| 'resolve requested expert review'
	| 'final review'

export type ReclassificationTaskStatus = 'not started' | 'in progress' | 'finished'

export type ReclassificationStageEntity = {
	_id: string
	type: ReclassificationStageType
	name: string
	percentageToBeProcessed: number
	assignedCompany: string
	assignedUsers: string[]
	isCompanyFinal: boolean
	isFinal: boolean
}

export type ReclassificationTaskEntity = {
	_id: string
	tenantId: string
	createdDate: Date
	createdBy: string
	enabled: boolean
	name: string
	model: {
		_id: string
		modelUrn: string
		modelVersion: number
	}
	version: string
	status: ReclassificationTaskStatus
	reclassificationCompany: string
	reviewCompany: string
	reviewCompanyManager: string
	reclassificationCompanyManager: string
	confidenceThreshold: number
	packageSize: number
	classifications: string[]
	stages: ReclassificationStageEntity[]
}

export type ReclassificationTaskEntityWithStageTasksWithWorkPackage = ReclassificationTaskEntity & {
	stageTasks: ReclassificationWorkPackageStageTaskEntityWithWorkPackage[]
	availableWPByStage: Record<string, number>
	workPackagesCount: number
}

export function canManageStage(
	currentUser: User,
	stage: ReclassificationStageEntity,
	reclassification: ReclassificationTaskEntity,
	hasSrAdminPermission: boolean,
) {
	return (
		currentUser._id ===
			(stage.assignedCompany === reclassification.reclassificationCompany
				? reclassification.reclassificationCompanyManager
				: reclassification.reviewCompanyManager) || hasSrAdminPermission
	)
}
