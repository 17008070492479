import React, {useEffect} from 'react'
import {forgeAuthenticate} from '../../../api/forgeApi'
import {hideTexture, initLayers, loadModel, waitForViewerEvent} from '../utilities/viewerUtilities'
import {useViewer} from '../hooks/useViewer'
import {Box} from 'grommet'
import {UserProject} from '../../User/entities/userProject'

export function Viewer({
	documentUrn,
	viewerOptions,
	minHeight,
}: {
	documentUrn: string
	viewerOptions: {
		forceNearRadius?: number | null
		initialState: UserProject['viewerState']
		useSVF2ViewerOptions: boolean
	}
	minHeight?: number
}) {
	const viewerContainerRef = React.useRef<HTMLDivElement>(null)
	const {viewer, setViewer, status, setViewerStatus} = useViewer()

	useEffect(() => {
		if (status === 'initialized' && viewer.current) {
			setViewerStatus('loading')
			loadModel(viewer.current, documentUrn, viewerOptions.initialState, viewerOptions.forceNearRadius).then(
				async modelTreeLoaded => {
					const waitEvents = [
						...(modelTreeLoaded ? [] : [Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT]),
						Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
					]
					await Promise.all(waitEvents.map(e => waitForViewerEvent(viewer.current!, e)))
					// If the model has 3D lines there might be precision issues in the forge viewer that could cause
					// that the wrong element gets selected when clicking on them. Forge is working in a solution his
					// internal ticket code is LMV-6888 we can ask about the status of this reaching forge.help@autodesk.com
					// This is a workaround, and should be removed if forge fixes the issue.
					viewer.current!.hideLines(true)
					hideTexture(viewer.current!)
					setViewerStatus('model_loaded')
				},
			)
		}
		return () => {}
	}, [status, viewer, setViewerStatus, documentUrn, viewerOptions])

	useEffect(() => {
		let viewer: Autodesk.Viewing.GuiViewer3D
		const options = {
			env: viewerOptions.useSVF2ViewerOptions ? 'AutodeskProduction2' : 'AutodeskProduction',
			api: viewerOptions.useSVF2ViewerOptions ? 'streamingV2' : 'derivativeV2',
			getAccessToken: async function (onSuccess: (token: string) => void) {
				const data = await forgeAuthenticate()
				onSuccess(data.access_token)
			},
		}
		Autodesk.Viewing.Initializer(options, () => {
			if (viewerContainerRef.current) {
				viewer = new Autodesk.Viewing.GuiViewer3D(viewerContainerRef.current)
				viewer.setTheme('light-theme')
				viewer.initialize()
				initLayers(viewer)
				setViewer(viewer)
				setViewerStatus('initialized')
			}
		})

		return () => {
			if (viewer) {
				setViewerStatus('not_loaded')
				viewer.tearDown()
			}
		}
	}, [setViewer, setViewerStatus, viewerOptions.useSVF2ViewerOptions])

	return (
		<Box height={minHeight ? {min: minHeight + 'px'} : undefined} fill={true}>
			<div
				style={{position: 'relative', height: '100%', width: '100%'}}
				ref={viewerContainerRef}
				id="viewer-container"
			/>
		</Box>
	)
}
