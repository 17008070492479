import {SrRoute} from '../../routing'
import {
	ReclassificationUsersPage,
	ReclassificationUsersPageTitle,
	ReclassificationUsersRouteParams,
} from './ReclassificationUsersPage'

export const reclassificationUsersRoute = new SrRoute<ReclassificationUsersRouteParams>(
	'/reclassifications/manage/:clientSlug/:projectSlug/:reclassificationId/users',
	ReclassificationUsersPage,
	ReclassificationUsersPageTitle,
)
