import {
	extendTheme,
	SR_COLOR_ACCENT_2,
	SR_COLOR_ACCENT_2_HOVER,
	SR_COLOR_NEUTRAL_2,
	SR_COLOR_STATUS_OK_BACKGROUND,
	SR_COLOR_STATUS_OK_BACKGROUND_HOVER,
} from 'sr-react-commons'
import {ThemeType} from 'grommet'

export const theme = extendTheme({
	global: {
		colors: {
			text: {
				light: 'rgba(0, 0, 0, 0.87)',
			},
			cta: '#fcd116',
			'neutral-1': '#6B717B', //Gray 2 Secondary
			white: '#FFFFFF',
			primary: '#303f9f',
			brand: '#666',
			focus: 'rgba(0,0,0,0.2)',
			'status-ok': SR_COLOR_STATUS_OK_BACKGROUND,
			'status-ok-hover': SR_COLOR_STATUS_OK_BACKGROUND_HOVER,
			'status-error': SR_COLOR_ACCENT_2,
			'status-error-hover': SR_COLOR_ACCENT_2_HOVER,
		},
		animation: {
			duration: '0.2s',
		},
		edgeSize: {
			small: '14px',
		},
		font: {
			size: '14px',

			family: '"Inter", sans-serif',
		},
	},
	text: {
		xsmall: {
			size: '10px',
			height: '14px',
		},
		small: {
			size: '12px',
			height: '16px',
		},
		medium: {
			size: '14px',
			height: '22px',
		},
		large: {
			size: '18px',
			height: '26px',
		},
	} as ThemeType['text'], // this is necessary due to a bug in the type definitions of grommet 2.16.3
	heading: {
		extend: 'margin-top: 8px; margin-bottom: 8px;',
	},
	button: {
		size: {
			small: {
				pad: {
					horizontal: '8px',
					vertical: '1px',
				},
				border: {
					radius: '4px',
				},
			},
		},
		border: {
			color: 'dark-4',
			radius: '4px',
		},
	},
	table: {
		body: {
			extend: 'font-size: 14px; padding-top: 3px; padding-bottom: 3px;',
		},
		header: {
			extend: `font-size: 14px; padding-top: 3px; padding-bottom: 3px; border-bottom: solid ${SR_COLOR_NEUTRAL_2} 1px`,
		},
	},
	formField: {
		margin: 'xxsmall',
		label: {
			margin: 'xsmall',
			size: 'small',
			weight: 'bold',
		},
		content: {
			pad: 'xxsmall',
		},
	},
	textInput: {
		extend: 'padding: 6px; font-size: 10px; line-height: 12px;',
	},
	tabs: {
		panel: {
			extend: 'height: 100%;',
		},
	},
	tab: {
		border: {
			color: 'light-4',
		},
	},
	tip: {
		content: {background: 'light-1'},
	},
})
