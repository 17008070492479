import {useQuery} from 'react-query'
import {fetchCurrentAuths, fetchCurrentUser} from '../api/users'

export function useCurrentUserDetails(enabled: boolean) {
	const {data, status} = useQuery(['current-user-details'], fetchCurrentUser, {enabled, staleTime: 5 * 60 * 1000})
	return {userDetails: data, status}
}

export function useCurrentAuthsDetails(userId: string | undefined) {
	const enabled = !!userId
	const {data, status} = useQuery(['current-auth-details'], () => fetchCurrentAuths(userId!), {
		enabled,
		staleTime: 5 * 60 * 1000,
	})
	return {authDetails: data, status}
}
