import {User} from '../entities/user'
import {queryCache, useMutation} from 'react-query'
import {createCompanyUser, disableUser} from '../api/users'
import {AxiosError} from 'axios'

export function useCompanyUserMutations(companyId: string) {
	const entity = `companies/${companyId}/users`

	const [addUserMutation] = useMutation<
		User,
		AxiosError,
		{firstName: string; lastName: string; email: string; username: string}
	>(userData => createCompanyUser(companyId, userData), {
		onSuccess: () => queryCache.invalidateQueries([entity]),
		throwOnError: true,
	})

	const [disableUserMutation] = useMutation<User, AxiosError, {userId: string}>(
		({userId}: {userId: string}) => disableUser(companyId, userId),
		{
			onSuccess: () => queryCache.invalidateQueries([entity]),
			throwOnError: true,
		},
	)

	return {addUserMutation, disableUserMutation}
}
