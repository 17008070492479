import {Filter, Sorting, useData} from '../../../hooks/useData'
import {User} from '../entities/user'
import {Reclassification} from '../../Reclassification/entities/reclassification'
import {QueryConfig} from 'react-query'
import {Pagination} from '../../../api/baseApi'

export function useCompanyUsers(
	companyId: string,
	{page, perPage}: {page: number; perPage: number} = {page: 0, perPage: 25},
	filters: Filter<Reclassification> = {},
	sorting: Sorting<User> = {field: null, ascending: true},
	options: QueryConfig<Pagination<User>> = {},
) {
	const entity = `companies/${companyId}/users`
	return useData<User>(entity, {page, perPage}, filters, sorting, options)
}
