export type Role =
	| 'REGISTERED_USER'
	| 'READER'
	| 'WRITER'
	| 'RECLASSIFIER'
	| 'ADMIN'
	| 'RECLASSIFICATION_ADMIN'
	| 'SR_READER'
	| 'SR_ADMIN'

export type GrantRole = {
	entity: string | null
	role: Role
}

export type AuthEntity = {
	_id: string
	member: string
	memberType: 'user' | 'team'
	memberName: string
	email: string | null
	entity: string | null
	entityName: string
	entityType: 'user' | 'team' | 'company' | 'root' | 'project' | 'workspace' | 'reclassification-task'
	role: Role
	grants: GrantRole[]
}

export type User = {
	_id: string
	email: string
	firstName?: string
	lastName?: string
	viewerFeatureFlags?: {
		progressMonitoring: boolean
	}
	createdDate: string
	enabled?: boolean
}

export function isReclassifier(auths: AuthEntity[]) {
	return auths.some(role => role.role === 'RECLASSIFIER')
}

export function isManager(auths: AuthEntity[]) {
	return isRootSrAdmin(auths) || auths.some(role => role.role === 'RECLASSIFICATION_ADMIN')
}

export function isAdminOnAnyCompany(auths: AuthEntity[]) {
	return (
		isRootSrAdmin(auths) ||
		hasRootRole(auths, 'ADMIN') ||
		auths.some(auth => auth.entityType === 'company' && ['SR_ADMIN', 'ADMIN'].includes(auth.role))
	)
}

export function isRootSrAdmin(auths: AuthEntity[]) {
	return hasRootRole(auths, 'SR_ADMIN')
}

function hasRootRole(auths: AuthEntity[], role: Role) {
	return auths.some(auth => auth.role === role && auth.entity === null)
}

export function isSrAdminAnyResource(auths: AuthEntity[]) {
	return auths.some(role => role.role === 'SR_ADMIN')
}

export function getFullName(user: Pick<User, 'firstName' | 'lastName'>) {
	return `${user.firstName} ${user.lastName}`
}
