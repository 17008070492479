import {QueryConfig} from 'react-query'
import {ReclassificationTaskEntity} from '../../entities/reclassification-task'
import {Filter, Sorting, useData} from '../../../../hooks/useData'
import {Pagination} from '../../../../api/baseApi'

export function useAllReclassificationTasks(
	{page, perPage}: {page: number; perPage: number},
	filters: Filter<ReclassificationTaskEntity> = {},
	sorting: Sorting<ReclassificationTaskEntity> = {field: null, ascending: true},
	options: QueryConfig<Pagination<ReclassificationTaskEntity>> = {},
) {
	return useData<ReclassificationTaskEntity>(
		'reclassification/reclassification-tasks',
		{page, perPage},
		filters,
		sorting,
		options,
	)
}
