import {Router} from 'react-router-dom'
import React, {ReactNode} from 'react'
import {routes} from '../routes'
import {history} from '../routing/history'

export const RouterContext = React.createContext<typeof routes>(routes)

export function AppRouter({children}: {children: ReactNode}) {
	return (
		<RouterContext.Provider value={routes}>
			<Router history={history}>{children}</Router>
		</RouterContext.Provider>
	)
}
