import {queryCache, useQuery} from 'react-query'
import {ReclassificationWorkPackageTaskEntityWithCurrentStageTask} from '../../entities/reclassification-work-package-task'
import {fetchWorkPackagesForFinalReview} from '../../api/workPackageTasks'

export function useReclassificationWorkPackagesForFinalReview(
	projectId: string | undefined,
	reclassificationId: string,
) {
	const queryKey = ['reclassification-work-package-tasks', 'final-review', reclassificationId]
	return {
		...useQuery<ReclassificationWorkPackageTaskEntityWithCurrentStageTask[]>(
			queryKey,
			() => fetchWorkPackagesForFinalReview(projectId!, reclassificationId),
			{enabled: projectId, staleTime: 5 * 60 * 1000},
		),
		invalidate: () => queryCache.invalidateQueries(queryKey),
	}
}
