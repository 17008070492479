import {useViewer} from './useViewer'
import React, {useEffect, useMemo, useState} from 'react'
import {ClassificationRedux} from '../../../entities/classification'

export type LinkedViewerElementsSelectionState = {
	selectionLinked: boolean
	setSelectionLinked: (isLinked: boolean) => void
	selectedClassifications: string[]
	setSelectedClassifications: (ids: string[]) => void
}
export function useLinkedViewerElementsSelection(classifications: ClassificationRedux[]) {
	const [selectedClassifications, setSelectedClassifications] = React.useState<string[]>([])
	const [selectionLinked, setSelectionLinked] = React.useState<boolean>(true)
	const {
		status,
		selectedElements,
		actions: {selectElements},
	} = useViewer()
	const [prevValues, setPrevValues] = useState<{
		selectedElements: number[] | undefined
		selectedClassifications: string[]
		selectionLinked: boolean
	}>()
	const {toClassificationIds, toForgeIds} = useMemo(() => {
		const forgeIdByCl: Record<string, number> = {}
		const clIdByForgeId: Record<number, string> = {}
		classifications.forEach(cl => {
			forgeIdByCl[cl._id] = cl.forgeObjectId
			clIdByForgeId[cl.forgeObjectId] = cl._id
		})
		return {
			toClassificationIds: (forgeIds: number[]) => forgeIds.map(fid => clIdByForgeId[fid]),
			toForgeIds: (clIds: string[]) => clIds.map(clid => forgeIdByCl[clid]),
		}
	}, [classifications])

	useEffect(() => {
		if (status === 'model_loaded') {
			let changes = true
			if (prevValues?.selectionLinked !== selectionLinked) {
				if (selectionLinked) {
					selectElements(toForgeIds(selectedClassifications))
				}
			} else if (
				selectionLinked &&
				JSON.stringify(prevValues?.selectedElements?.sort()) !== JSON.stringify(selectedElements?.sort())
			) {
				setSelectedClassifications(toClassificationIds(selectedElements || []))
			} else if (
				selectionLinked &&
				JSON.stringify(prevValues?.selectedClassifications?.sort()) !== JSON.stringify(selectedClassifications?.sort())
			) {
				selectElements(toForgeIds(selectedClassifications))
			} else {
				changes = false
			}
			changes && setPrevValues({selectedElements, selectedClassifications, selectionLinked})
		}
	}, [
		selectedElements,
		status,
		selectionLinked,
		prevValues,
		selectedClassifications,
		selectElements,
		toForgeIds,
		toClassificationIds,
	])
	return useMemo<LinkedViewerElementsSelectionState>(() => {
		return {selectionLinked, setSelectionLinked, selectedClassifications, setSelectedClassifications}
	}, [selectedClassifications, selectionLinked])
}
