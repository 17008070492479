import {Accordion, AccordionPanel, Box, Text} from 'grommet'
import {StatusWarning} from 'grommet-icons'
import {ActivityWorkPackageTable} from './ActivityWorkPackageTable'
import React from 'react'
import {ReclassificationTaskUserActivityDTO} from '../../../features/Reclassification/api/reclassificationTasks'

export function OverlappingWorkPackagesWarning(props: {data: ReclassificationTaskUserActivityDTO}) {
	return (
		<Box
			flex={false}
			alignSelf={'center'}
			pad={'small'}
			direction={'row'}
			gap={'small'}
			background={'status-warning'}
			fill={'horizontal'}
		>
			<StatusWarning color={'dark-1'} />
			<Box>
				<Accordion>
					<AccordionPanel label={<Text>This user has worked on different work packages in parallel:</Text>}>
						<Box gap={'small'}>
							{props.data.overlaps.map(([first, second]) => (
								<Box key={`${first}-${second}`}>
									<ActivityWorkPackageTable
										reclassificationTasks={props.data.reclassificationTasks}
										stageTasks={[props.data.stageTasks[first], props.data.stageTasks[second]]}
									/>
								</Box>
							))}
						</Box>
					</AccordionPanel>
				</Accordion>
			</Box>
		</Box>
	)
}
