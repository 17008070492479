import {UserProject} from '../../features/User/entities/userProject'
import {
	ReclassificationWorkPackageStageTaskEntity,
	ReclassificationWorkPackageStageTaskEntityWithWorkPackage,
} from '../../features/Reclassification/entities/reclassification-work-package-stage-task'
import {
	ReclassificationAnnotationEntity,
	ReclassificationDTO,
} from '../../features/Reclassification/entities/reclassification-annotation'
import {
	ReclassificationActions,
	ReclassifyDTO,
	ReclassifyWithMessageDTO,
} from '../../features/Reclassification/api/reclassificationAnnotations'
import {useViewer} from '../../features/Viewer/hooks/useViewer'
import React from 'react'
import {useHeatmapExtension} from '../../features/ViewerAssets/Heatmaps/useHeatmapExtension'
import {useRoutes} from '../../hooks/useRoutes'
import {usePaintModel} from '../../features/Viewer/hooks/UsePaintModel'
import {ElementsCompletedMessageContainer} from './ElementsCompletedMessageContainer'
import {Container} from '../../components/Container/Container'
import {Box, Tab, Tabs} from 'grommet'
import {Spinner} from '../../components/Spinner/Spinner'
import {ReclassificationContainer} from '../../features/Reclassification/components/ReclassificationContainer'
import {ReclassificationHistory} from '../../features/Reclassification/components/ReclassificationHistory'
import {LockedViewsContainer} from './LockedViewsContainer'
import {ElementSections} from '../../features/ViewerAssets/SectionsViewer/ElementSections'
import {useDrawSectionPlaneIntersection} from '../../features/Viewer/hooks/UseDrawSectionPlaneIntersection'
import {ReclassificationTaskEntity} from '../../features/Reclassification/entities/reclassification-task'
import {useClassificationByIdForStageTask} from '../../features/Reclassification/hooks/stageTask/useReclassificationElements'

export function StageTaskReclassification(props: {
	project: UserProject
	stageTask: ReclassificationWorkPackageStageTaskEntityWithWorkPackage
	reclassification: ReclassificationTaskEntity
	reclassificationElements: ReclassificationDTO[]
	actions: {
		reclassification: ReclassificationActions
		stageTask: {
			startStageTask: ({
				stageTaskId,
			}: {
				stageTaskId: string
			}) => Promise<ReclassificationWorkPackageStageTaskEntity | undefined>
			markStageTaskAsCompleted: ({
				stageTaskId,
			}: {
				stageTaskId: string
			}) => Promise<ReclassificationWorkPackageStageTaskEntity | undefined>
		}
	}
}) {
	const {status} = useViewer()
	const [selectedClassificationId, setSelectedClassificationId] = React.useState<string | undefined>(undefined)
	const [skippedClassificationIds, setSkippedClassificationIds] = React.useState<string[]>([])

	const reclassificationActions = React.useMemo(() => {
		const postReclassification = (data: ReclassificationAnnotationEntity | undefined) => {
			setSelectedClassificationId(undefined)
			if (data) {
				setSkippedClassificationIds(skippedClassificationIds => skippedClassificationIds.filter(id => data._id !== id))
			}
			return data
		}
		return {
			approve: (classificationId: string) =>
				props.actions.reclassification.approve(classificationId).then(postReclassification),
			reclassify: (classificationId: string, params: ReclassifyDTO) =>
				props.actions.reclassification.reclassify(classificationId, params).then(postReclassification),
			requestExpertReview: (classificationId: string, params: ReclassifyWithMessageDTO) =>
				props.actions.reclassification.requestExpertReview(classificationId, params).then(postReclassification),
			skip: (classificationId: string) =>
				setSkippedClassificationIds(skippedClassificationIds => skippedClassificationIds.concat([classificationId])),
		}
	}, [props.actions.reclassification])

	const reclassificationElementsWithoutAnnotation = React.useMemo(
		() =>
			props.reclassificationElements
				.filter(el => el.annotation === null)
				.filter(el => !skippedClassificationIds.includes(el.classification._id)),
		[skippedClassificationIds, props.reclassificationElements],
	)

	const currentElement = React.useMemo(() => {
		if (selectedClassificationId) {
			return props.reclassificationElements.find(el => el.classification._id === selectedClassificationId)
		}
		return reclassificationElementsWithoutAnnotation.length > 0
			? reclassificationElementsWithoutAnnotation[0]
			: undefined
	}, [reclassificationElementsWithoutAnnotation, props.reclassificationElements, selectedClassificationId])

	const {data: classification} = useClassificationByIdForStageTask(
		props.project._id,
		props.stageTask._id,
		currentElement?.classification?._id,
	)
	useHeatmapExtension(props.project, classification)

	const routes = useRoutes()
	const elementsColorMapping = React.useMemo(
		() =>
			props.reclassificationElements.map(cl => ({
				forgeObjectId: cl.classification.forgeObjectId,
				status: cl.previousValue ? cl.previousValue.classification : cl.classification.status,
			})),
		[props.reclassificationElements],
	)
	usePaintModel(elementsColorMapping, currentElement?.classification.forgeObjectId)
	useDrawSectionPlaneIntersection(currentElement?.classification.forgeObjectId)

	if (!currentElement) {
		return (
			<ElementsCompletedMessageContainer
				clearSkipped={() => setSkippedClassificationIds([])}
				skippedAnnotations={skippedClassificationIds}
				stageTask={props.stageTask}
				myReclassificationsRoute={routes.myReclassifications}
				actions={props.actions}
			/>
		)
	}

	return (
		<Container pad={'xxsmall'} fill>
			{status !== 'model_loaded' && (
				<Box
					style={{top: 0, left: 0, position: 'absolute', zIndex: 100, backgroundColor: 'rgba(255,255,255,0.5)'}}
					fill={true}
					justify={'center'}
					align={'center'}
				>
					<Spinner />
				</Box>
			)}
			<Box fill>
				<LockedViewsContainer
					classification={classification || null}
					project={props.project}
					reclassification={props.reclassification}
				/>
				<Box direction={'row'} fill>
					<Box fill pad={'xxsmall'}>
						{classification ? <ElementSections classification={classification} project={props.project} /> : null}
					</Box>
					<Box fill pad={'xxsmall'}>
						<Tabs flex justify={'start'}>
							<Tab title="Reclassification">
								<ReclassificationContainer
									currentElement={currentElement}
									remainingElementCount={reclassificationElementsWithoutAnnotation.length}
									totalElementCount={props.reclassificationElements.length}
									actions={{
										reclassification: reclassificationActions,
									}}
								/>
							</Tab>
							<Tab title="History">
								<ReclassificationHistory
									setSelectedClassificationId={setSelectedClassificationId}
									selectedClassificationId={selectedClassificationId}
									annotations={props.reclassificationElements.filter(el => el.annotation).map(el => el.annotation!)}
								/>
							</Tab>
						</Tabs>
					</Box>
				</Box>
			</Box>
		</Container>
	)
}
