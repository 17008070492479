import {LineProps} from '../DistanceMeasureLine'
import {v4 as uuidv4} from 'uuid'
import React, {Dispatch, ReactNode} from 'react'

interface MeasurementsState {
	[key: string]: LineProps[]
}

const MeasurementsContext = React.createContext<{state: MeasurementsState; dispatch: Dispatch<any>}>({
	state: {},
	dispatch: () => ({}),
})

function measurementsStateReducer(state: MeasurementsState, action: any): MeasurementsState {
	switch (action.type) {
		case 'add_line': {
			const newLine = {
				...action.payload.line,
				id: uuidv4(),
			}
			return {
				...state,
				[action.payload.sectionId]: state[action.payload.sectionId]
					? [...state[action.payload.sectionId], newLine]
					: [newLine],
			}
		}
		case 'remove_line': {
			return {
				...state,
				[action.payload.sectionId]: state[action.payload.sectionId]
					? state[action.payload.sectionId].filter(line => line.id !== action.payload.id)
					: [],
			}
		}
		case 'clear_lines': {
			return {
				...state,
				[action.payload.sectionId]: [],
			}
		}
		case 'clear_all': {
			return {}
		}
		default: {
			throw new Error(`Unknown action type: ${action.type}`)
		}
	}
}

export function MeasurementsProvider({children}: {children: ReactNode}) {
	const [state, dispatch] = React.useReducer(measurementsStateReducer, {})
	return <MeasurementsContext.Provider value={{state, dispatch}}>{children}</MeasurementsContext.Provider>
}

export function useMeasurements() {
	const {state, dispatch} = React.useContext(MeasurementsContext)

	const addLine = (sectionId: string, line: LineProps) => {
		dispatch({
			type: 'add_line',
			payload: {
				sectionId: sectionId,
				line: line,
			},
		})
	}

	const removeLine = (sectionId: string, lineId: string) => {
		dispatch({
			type: 'remove_line',
			payload: {
				sectionId: sectionId,
				id: lineId,
			},
		})
	}

	const clearLines = (sectionId: string) => {
		dispatch({
			type: 'clear_lines',
			payload: {
				sectionId: sectionId,
			},
		})
	}

	const clearAll = React.useCallback(() => {
		dispatch({type: 'clear_all'})
	}, [dispatch])

	return {state, addLine, removeLine, clearLines, clearAll}
}

export function useSectionMeasurements(sectionId: string) {
	const {state, addLine, removeLine, clearLines} = useMeasurements()

	const addSectionLine = (line: LineProps) => {
		addLine(sectionId, line)
	}

	const removeSectionLine = (lineId: string) => {
		removeLine(sectionId, lineId)
	}

	const clearSectionLines = () => {
		clearLines(sectionId)
	}

	const measurements = state[sectionId] || []
	return {measurements, addSectionLine, removeSectionLine, clearSectionLines}
}
