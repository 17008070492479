import React, {useState} from 'react'
import {AnnotationClassificationType} from '../entities/classificationAnnotation'
import {Box, Form, FormField, RadioButtonGroup, Text, TextInput, ThemeContext} from 'grommet'
import {FormRefresh} from 'grommet-icons'
import _ from 'lodash'
import {useUser} from '../../User/hooks/useUser'
import {getFullName} from '../../User/entities/user'
import {ReclassificationAnnotationEntity} from '../entities/reclassification-annotation'
import {
	SR_COLOR_ACCENT_2,
	SR_COLOR_ACCENT_2_HOVER,
	SR_COLOR_WHITE,
	SRButton,
	SRGreenButton,
	SRIconButton,
	SRPrimaryButton,
	SRSecondaryButton,
} from 'sr-react-commons'

function InitialControls(props: {
	annotation: ReclassificationAnnotationEntity | null
	onApprove: () => Promise<void>
	onStartReclassify: () => void
}) {
	const [approving, setApproving] = useState<boolean>(false)
	const onApprove = async () => {
		setApproving(true)
		try {
			await props.onApprove()
		} finally {
			setApproving(false)
		}
	}
	const lastReview = props.annotation
	const {user} = useUser(lastReview?.createdBy)
	return (
		<Box gap={'xsmall'}>
			{lastReview && (
				<Box>
					<Box direction={'row'} justify={'between'}>
						<Text size="small">
							{lastReview && `Reviewed and ${lastReview.action} by ${user ? getFullName(user) : lastReview.createdBy}`}
						</Text>
					</Box>
				</Box>
			)}
			<Box direction={'column'} gap={'small'} justify={'between'}>
				<SRGreenButton onClick={onApprove} disabled={approving} size="small" alignSelf="start" label="Approve" />
				<SRButton
					bgColor={SR_COLOR_ACCENT_2}
					hoverBgColor={SR_COLOR_ACCENT_2_HOVER}
					textColor={SR_COLOR_WHITE}
					onClick={props.onStartReclassify}
					size="small"
					alignSelf="start"
					label="Reclassify"
				/>
			</Box>
		</Box>
	)
}

type ErrorsObject = {magnitudeError?: string; formError?: string}

type ReclassificationFormType = {
	status: AnnotationClassificationType | null
	magnitude: string
}

function ReclassifyControls(props: {
	onSubmit: (event: any) => Promise<void>
	onBack: () => void
	initialValues?: ReclassificationFormType
}) {
	const [formValue, setFormValue] = React.useState<ReclassificationFormType>(
		props.initialValues || {
			status: null,
			magnitude: '',
		},
	)
	const [errors, setErrors] = React.useState<ErrorsObject>({})

	const validateForm = (formValue: ReclassificationFormType) => {
		const errors: ErrorsObject = {}
		if (!formValue.status && !formValue.magnitude) {
			errors.formError = 'You must at least set the status or the magnitude.'
		}
		if (formValue.magnitude.length > 0 && parseFloat(formValue.magnitude) < 0) {
			errors.magnitudeError = 'Magnitude must be a positive value.'
		}
		return {validated: _.isEmpty(errors), errors: errors}
	}

	return (
		<Box>
			<ThemeContext.Extend value={{formField: {border: false}}}>
				<Form
					value={formValue}
					onSubmit={(event: any) => {
						setErrors({})
						const {validated, errors} = validateForm(event.value)
						if (!validated) {
							setErrors(errors)
							return false
						}
						props.onSubmit(event)
					}}
					onChange={(nextValue: any) => setFormValue(nextValue)}
				>
					<Box direction="row" justify={'between'} gap={'xsmall'}>
						<SRSecondaryButton size="small" label={'Back'} onClick={props.onBack} />
						<SRPrimaryButton size="small" type={'submit'} label={'Save'} />
					</Box>
					<Box direction="column" gap="xsmall">
						<Box>
							<FormField
								label={
									<Box direction={'row'} gap={'xsmall'} align={'center'}>
										<Text>Status</Text>
										<SRIconButton
											style={{visibility: formValue.status === null ? 'hidden' : 'visible'}}
											size={'small'}
											alignSelf={'end'}
											title={'Unset status value'}
											onClick={() => setFormValue(value => ({...value, status: null}))}
											icon={<FormRefresh size="small" />}
										/>
									</Box>
								}
							>
								<RadioButtonGroup
									name={'status'}
									gap={'xsmall'}
									pad={'xsmall'}
									options={[
										{label: 'Verified', value: 'verified'},
										{label: 'Deviated', value: 'deviated'},
										{label: 'Missing', value: 'missing'},
										{label: 'No data', value: 'no_data'},
										{label: 'Under construction', value: 'under_construction'},
									]}
								/>
							</FormField>
						</Box>
						<Box pad={{end: 'medium'}}>
							<FormField
								width="xsmall"
								label={
									<Box direction={'row'} gap={'xsmall'} align={'center'}>
										<Text>Magnitude</Text>
										<SRIconButton
											style={{visibility: formValue.magnitude === '' ? 'hidden' : 'visible'}}
											size={'small'}
											alignSelf={'end'}
											title={'Unset magnitude value'}
											onClick={() => {
												setFormValue(value => ({...value, magnitude: ''}))
												setErrors({...errors, magnitudeError: undefined})
											}}
											icon={<FormRefresh size="small" />}
										/>
									</Box>
								}
							>
								<TextInput name={'magnitude'} type={'number'} />
							</FormField>
						</Box>
					</Box>
					<Box>
						{errors.formError && (
							<Text color={'status-error'} size={'small'}>
								{errors.formError}
							</Text>
						)}
						{errors.magnitudeError && (
							<Text size={'small'} color={'status-error'}>
								{errors.magnitudeError}
							</Text>
						)}
					</Box>
				</Form>
			</ThemeContext.Extend>
		</Box>
	)
}

export function ReviewControls({
	annotation,
	actions: {approve, reclassify},
}: {
	annotation: ReclassificationAnnotationEntity | null
	actions: {
		approve: () => Promise<any>
		reclassify: (formData: any) => Promise<any>
	}
}) {
	const [state, setState] = React.useState<'initial' | 'reclassification' | 'request_review'>('initial')

	const renderState = () => {
		switch (state) {
			case 'initial':
				return (
					<InitialControls
						annotation={annotation}
						onApprove={approve}
						onStartReclassify={() => setState('reclassification')}
					/>
				)
			case 'reclassification': {
				const lastReview = annotation
				return (
					<ReclassifyControls
						onSubmit={async (event: any) => {
							const params = {
								status: event.value.status,
								magnitude: event.value.magnitude === '' ? null : parseInt(event.value.magnitude) / 1000,
							}
							return reclassify(params).then(() => {
								setState('initial')
							})
						}}
						onBack={() => setState('initial')}
						initialValues={
							lastReview && lastReview?.action === 'reclassified'
								? {
										magnitude: (lastReview.magnitude * 1000).toFixed(0).toString(),
										status: lastReview.classification,
								  }
								: undefined
						}
					/>
				)
			}
		}
	}

	return (
		<Box gap={'small'} fill={true}>
			{renderState()}
		</Box>
	)
}
