import React, {MutableRefObject, ReactNode} from 'react'
import {
	drawSectionPlaneIntersection as drawSectionPlaneIntersectionImpl,
	paintModel,
	selectionChanged,
	translateViewportFromRealWordToViewerCoordinates,
} from '../utilities/viewerUtilities'
import {Section} from '../../../entities/classification'
import {NonUndefined} from 'grommet/utils'
import {UserProject} from '../../User/entities/userProject'

export type ViewerStatus = 'not_loaded' | 'initialized' | 'loading' | 'model_loaded'

type ContextValue =
	| {
			status: ViewerStatus
			selectedDbId?: number
			selectedElements?: number[]
			setSelection: (id: number | undefined) => void
			viewer: MutableRefObject<Autodesk.Viewing.GuiViewer3D | null>
			setViewer: (viewer: Autodesk.Viewing.GuiViewer3D) => void
			setViewerStatus: (newStatus: ViewerStatus) => void
			actions: {
				isolateElement: (dbId: number) => void
				selectElements: (dbIds: number[]) => void
				isolateAndZoomToClassifiedElement: (dbId: number) => void
				colorizeModel: (
					dbIdStatusMappings: {dbId: number; status: string}[],
					selectedDbId?: number,
					clearElementColors?: boolean,
				) => void
				drawSectionPlaneIntersection: (
					selectedDbId?: number,
					sectionPlaneCoefficients?: Section['planeCoefficients'],
				) => void
				isolateElements: (dbIds: number[]) => void
				restoreViewerState: (viewerState: any) => void
				fitToView: (dbId: number) => void
			}
	  }
	| undefined

export const ViewerContext = React.createContext<ContextValue>(undefined)

export function ViewerProvider({children}: {children: ReactNode}) {
	const [selectedDbId, setSelectedDbId] = React.useState<number | undefined>(undefined)
	const [status, setViewerStatus] = React.useState<ViewerStatus>('not_loaded')
	const viewerRef = React.useRef<Autodesk.Viewing.GuiViewer3D>(null)
	const [selectedElements, setSelectedElements] = React.useState<number[]>([])

	const setSelection = React.useCallback((id: number | undefined) => {
		setSelectedDbId(id)
	}, [])

	const setViewer = React.useCallback((viewer: Autodesk.Viewing.GuiViewer3D) => {
		;(viewerRef as MutableRefObject<Autodesk.Viewing.GuiViewer3D>).current = viewer
	}, [])

	const isolateElement = React.useCallback(
		(dbId: number) => {
			if (status === 'model_loaded' && viewerRef.current) {
				viewerRef.current.isolate([dbId])
			}
		},
		[status],
	)

	const selectElements = React.useCallback(
		(dbIds: number[]) => {
			if (status === 'model_loaded' && viewerRef.current) {
				if (selectionChanged(selectedElements, dbIds)) {
					viewerRef.current.select(dbIds)
					setSelectedElements(dbIds)
				}
			}
		},
		[status, selectedElements, setSelectedElements],
	)

	const isolateElements = React.useCallback((dbIds: number[]) => {
		if (viewerRef.current) {
			viewerRef.current.isolate(dbIds)
		}
	}, [])

	const isolateAndZoomToClassifiedElement = React.useCallback(
		function isolateAndZoom(dbId: number) {
			if (status === 'model_loaded' && viewerRef.current) {
				isolateElements([dbId])
				viewerRef.current.fitToView([dbId])
			}
		},
		[isolateElements, status],
	)

	const fitToView = React.useCallback(
		function fitToView(dbId: number) {
			if (status === 'model_loaded' && viewerRef.current) {
				viewerRef.current.fitToView([dbId])
			}
		},
		[viewerRef, status],
	)

	const colorizeModel: NonUndefined<ContextValue>['actions']['colorizeModel'] = React.useCallback(
		(dbIdStatusMappings, selectedDbId?) => {
			if (status === 'model_loaded' && viewerRef.current && viewerRef.current.model) {
				paintModel(viewerRef.current, dbIdStatusMappings, selectedDbId)
			}
		},
		[status],
	)

	const drawSectionPlaneIntersection: NonUndefined<
		ContextValue
	>['actions']['drawSectionPlaneIntersection'] = React.useCallback(
		(selectedDbId?, sectionPlaneCoefficients?) => {
			if (status === 'model_loaded' && viewerRef.current && viewerRef.current.model) {
				drawSectionPlaneIntersectionImpl(viewerRef.current, selectedDbId, sectionPlaneCoefficients)
			}
		},
		[status],
	)

	const restoreViewerState = React.useCallback((viewerState: UserProject['viewerState']) => {
		if (viewerRef.current) {
			const viewer = viewerRef.current
			viewer.restoreState(
				viewerState && viewerState?.viewport
					? {
							viewport: translateViewportFromRealWordToViewerCoordinates(
								viewerState?.viewport,
								viewer.model.getGlobalOffset(),
								viewer.model.getUnitScale(),
							),
					  }
					: undefined,
			)
		}
	}, [])

	// initial color reset of the model
	React.useEffect(() => {
		if (status === 'model_loaded') {
			colorizeModel([], undefined, true)
		}
	}, [status, colorizeModel])

	const value = React.useMemo<ContextValue>(
		() => ({
			selectedDbId,
			setSelection,
			viewer: viewerRef,
			setViewer,
			status,
			setViewerStatus,
			selectedElements,
			actions: {
				isolateElement,
				selectElements,
				isolateAndZoomToClassifiedElement,
				colorizeModel,
				drawSectionPlaneIntersection,
				isolateElements,
				restoreViewerState,
				fitToView,
			},
		}),
		[
			selectedDbId,
			setSelection,
			setViewer,
			status,
			selectedElements,
			isolateElement,
			selectElements,
			isolateAndZoomToClassifiedElement,
			colorizeModel,
			drawSectionPlaneIntersection,
			isolateElements,
			restoreViewerState,
			fitToView,
		],
	)

	return <ViewerContext.Provider value={value}>{children}</ViewerContext.Provider>
}
