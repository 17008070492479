import {colors, threshold_percentages, tolerance_color} from './ColorPalette.json'
import {params} from './ParamsHeatmap.json'
import debounce from 'lodash/debounce'
import './Viewing.Extension.Heatmap.scss'
import _ from 'lodash'
import {coordinateFromIFCtoViewer, disposeThreeNode, scaleFromIFCtoViewer} from '../../utilities/viewerUtilities'
import {fetchS3File, getS3KeyForPath} from '../../../../utilities/storageUtilities'

export const ExtensionId = 'Viewing.Extension.Heatmap'
const Autodesk = window.Autodesk
const THREE = window.THREE

const max_deviation_initial = params.max_deviation_initial
const tolerance_initial = params.tolerance_initial
const param = new Map()
param.set('max_deviation', max_deviation_initial)
param.set('tolerance', tolerance_initial)
export const HEATMAP_SCENE_ID = 'heatmapSceneId'

export default class HeatmapExtension extends Autodesk.Viewing.Extension {
	constructor(viewer, options) {
		super(viewer, options)

		this.heatmapData = []
		this.callbacksInProgressCounter = 0

		this.active = false
		this.heatmapScaleActive = false
		this.heatmapSettingsFormActive = false

		this.previousTopOpacity = viewer.impl.selectionMaterialTop.opacity

		this.deviation_colors = colors
		this.tolerance_color = tolerance_color
		this.deviation_percent = threshold_percentages
		this.deviation_steps = this.deviation_percent.map(x => x * param.get('max_deviation'))
		this.scale = params.scale_out_pipeline_to_mm
		this.files_path = params.files_path
		this.points_size = params.points_size
		this.fields_of_pointcloud = params.fields_of_pointcloud
		this.percent_sample_color_slider = params.percent_sample_color_slider
	}

	async setClassifications(classifications) {
		this.classifications = classifications
		await this.selectionChanged()
		if (this.classifications.length === 0) {
			if (this.active) {
				this.setActive(false)
			}
			this.button.setState(Autodesk.Viewing.UI.Button.State.DISABLED)
		} else {
			this.button.setState(Autodesk.Viewing.UI.Button.State.INACTIVE)
		}
	}

	setProject(project) {
		this.project = project
	}

	load() {
		// Prepare raycaster for heatmap tooltip value update
		this.raycaster = new THREE.Raycaster()
		this.raycaster.params.PointCloud.threshold = 0.05 * scaleFromIFCtoViewer(this.viewer.model) // 5cm threshold

		// getGLOBALIDdbIdMappings(this.viewer).then(res => (this.globalIdForgeIdMapping = res))
		this.createUI(this.viewer)

		// Register and activate extension itself as a tool in order to handle mouse hover via overriden method
		this.viewer.toolController.registerTool(this)
		this.viewer.toolController.activateTool(ExtensionId)
		return true
	}

	unload() {
		const verticalToolBar = this.viewer.getExtension('Viewing.Extension.VerticalToolbar')
		if (verticalToolBar && verticalToolBar.SRVerticalToolbar) {
			verticalToolBar.SRVerticalToolbar.removeControl(this.subToolbar)
		}

		this.deleteHeatmapData()

		this.viewer.toolController.deactivateTool(ExtensionId)
		this.viewer.toolController.deregisterTool(this)

		return true
	}

	getNames() {
		return [ExtensionId]
	}

	setActive(active) {
		this.active = active
		this.toggleHeatmapScale(active)
		this.toggleSettingsForm(active)
		if (active === true) {
			this.computeSliderColors()
			this.button.removeClass('heatmapsToolbarButton')
			this.button.addClass('heatmapsToolbarButtonBlue')
			this.selectionChanged()
			this.viewer.disableHighlight(true)
			//this.viewer.disableSelection(true) => cancelled behaviour for the moment, to allow element selection when heatmap is on, keeping code for easy rollback
		} else {
			this.viewer.disableHighlight(false)
			// this.viewer.disableSelection(false) => cancelled behaviour for the moment, to allow element selection when heatmap is on, keeping code for easy rollback
			this.button.removeClass('heatmapsToolbarButtonBlue')
			this.button.addClass('heatmapsToolbarButton')
			this.deleteHeatmapData()
			this.viewer.impl.sceneUpdated(true)
		}
	}

	createUI(viewer) {
		// prepare to execute the button action
		const heatmapToolbarButton = new Autodesk.Viewing.UI.Button('runHeatmapCode')
		heatmapToolbarButton.addClass('heatmapsToolbarButton')

		heatmapToolbarButton.onClick = () => {
			this.setActive(!this.active)
		}
		// heatmapToolbarButton CSS class should be defined on your .css file
		// you may include icons, below is a sample class:
		heatmapToolbarButton.setToolTip('Display deviation heatmap for the selected elements')

		this.button = heatmapToolbarButton

		this.button.setState(Autodesk.Viewing.UI.Button.State.INACTIVE)

		// Prepare floating magnitude tooltip (hidden by default on creation)
		const heatmapMagnitudeLabel = document.createElement('div')
		heatmapMagnitudeLabel.setAttribute('id', 'heatmapMagnitudeTooltip')
		document.getElementById('toolbar-TtIf').appendChild(heatmapMagnitudeLabel)

		// SubToolbar
		const subToolbar = viewer.loadedExtensions['Viewing.Extension.VerticalToolbar'].SRVerticalToolbar.getControl(
			'MyAppToolbar',
		)
			? viewer.loadedExtensions['Viewing.Extension.VerticalToolbar'].SRVerticalToolbar.getControl('MyAppToolbar')
			: new Autodesk.Viewing.UI.ControlGroup('MyAppToolbar')
		subToolbar.addControl(heatmapToolbarButton)

		viewer.loadedExtensions['Viewing.Extension.VerticalToolbar'].SRVerticalToolbar.addControl(subToolbar)
	}

	computeSliderColors() {
		//compute color each 5 %
		const perc = this.percent_sample_color_slider
		const min = -param.get('max_deviation')
		const max = param.get('max_deviation')
		const perc_scale = perc * (max - min)
		let background_string = 'linear-gradient('

		for (let i = parseInt(1.0 / perc); i >= 0; i--) {
			const color = this.computeDeviationColor((min + perc_scale * i) / this.scale)
			background_string = background_string + color + ','
		}

		background_string = background_string.slice(0, background_string.length - 1)
		background_string = background_string + ')'
		const slider = document.querySelector('#heatmapScale')
		slider.style.backgroundImage = background_string
	}

	interpolateDeviationColor(deviation, limit_up, limit_down, color_up, color_down) {
		const color = []
		const percent = Math.abs((deviation - limit_down) / (limit_up - limit_down))

		let rgb_up = color_up.match(/\d+/g)
		let rgb_down = color_down.match(/\d+/g)
		rgb_up = rgb_up.map(x => parseInt(x))
		rgb_down = rgb_down.map(x => parseInt(x))

		for (let i = 0; i < rgb_up.length; i++) {
			color[i] = rgb_down[i] + (rgb_up[i] - rgb_down[i]) * percent
		}
		return (
			'rgb(' +
			parseInt(color[0]).toString() +
			',' +
			parseInt(color[1]).toString() +
			',' +
			parseInt(color[2]).toString() +
			')'
		).toString()
	}

	computeDeviationColor(deviation) {
		//from meters to mm
		deviation = deviation * this.scale
		if (Math.abs(deviation) <= param.get('tolerance')) {
			return this.tolerance_color
		}

		for (let j = 1; j < this.deviation_steps.length - 1; j++) {
			if (deviation >= this.deviation_steps[j] && deviation <= this.deviation_steps[j - 1]) {
				if (deviation > 0.0) {
					return this.interpolateDeviationColor(
						deviation,
						this.deviation_steps[j - 1],
						this.deviation_steps[j],
						this.deviation_colors[j - 1],
						this.deviation_colors[j],
					)
				} else {
					return this.interpolateDeviationColor(
						deviation,
						this.deviation_steps[j],
						this.deviation_steps[j - 1],
						this.deviation_colors[j],
						this.deviation_colors[j - 1],
					)
				}
			}
		}
		if (deviation >= this.deviation_steps[0]) {
			return this.deviation_colors[0]
		} else {
			return this.deviation_colors[this.deviation_colors.length - 1]
		}
	}

	toggleHeatmapScale(active) {
		this.heatmapScaleActive = active

		if (this.heatmapScaleActive) {
			let scaleContainer = document.createElement('DIV')
			let scale = document.createElement('DIV')
			let scaleValue = document.createElement('DIV')
			scaleContainer.appendChild(scale)
			scale.appendChild(scaleValue)
			scaleContainer.setAttribute('id', 'heatmapScaleContainer')
			scaleValue.setAttribute('id', 'heatmapScaleValue')
			scale.setAttribute('id', 'heatmapScale')
			scale.setAttribute('class', 'adsk-control adsk-control-group toolbar-vertical-group')
			scale.addEventListener('mousemove', function (e) {
				const y = this.offsetHeight - e.layerY
				const percent = y / this.offsetHeight
				const min = -param.get('max_deviation')
				const max = param.get('max_deviation')
				const deviation = Math.max(min, Math.min(max, percent * (max - min) + min))

				scaleValue.style.display = 'block'
				scaleValue.textContent = deviation.toFixed(0) + ' mm'
				scaleValue.style.marginTop =
					Math.max(5, Math.min(this.offsetHeight - 20, (1 - percent) * this.offsetHeight)) + 'px'
			})
			this.viewer.container.appendChild(scaleContainer)
		} else {
			document.getElementById('heatmapScaleContainer').remove()
		}
	}

	toggleSettingsForm(active) {
		this.heatmapSettingsFormActive = active

		if (this.heatmapSettingsFormActive) {
			const changeColorBinded = function () {
				this.updateCloudAndSliderColors()
			}.bind(this)

			const heatmapSettingsForm = document.createElement('form')
			heatmapSettingsForm.setAttribute('id', 'heatmapSetterContainer')
			heatmapSettingsForm.setAttribute('class', 'adsk-control adsk-control-group css-class-name')

			const toleranceLabel = document.createElement('div')
			toleranceLabel.setAttribute('id', 'heatmapToleranceLabel')
			const toleranceLabelText = document.createElement('span')
			toleranceLabelText.setAttribute('class', 'tooltiptext')
			toleranceLabelText.innerHTML = 'Tolerance (mm)'
			toleranceLabel.appendChild(toleranceLabelText)
			heatmapSettingsForm.appendChild(toleranceLabel)

			const toleranceInput = document.createElement('input')
			toleranceInput.type = 'text'
			toleranceInput.autocomplete = 'off'
			toleranceInput.setAttribute('id', 'heatmapToleranceInput')
			toleranceInput.setAttribute('class', 'adsk-control adsk-control-group css-class-name')
			heatmapSettingsForm.appendChild(toleranceInput)

			const maxDeviationLabel = document.createElement('div')
			maxDeviationLabel.setAttribute('id', 'heatmapMaxDeviationLabel')
			const maxDeviationLabelText = document.createElement('span')
			maxDeviationLabelText.setAttribute('class', 'tooltiptext')
			maxDeviationLabelText.innerHTML = 'Maximum deviation range (mm)'
			maxDeviationLabel.appendChild(maxDeviationLabelText)
			heatmapSettingsForm.appendChild(maxDeviationLabel)

			const maxDeviationInput = document.createElement('input')
			maxDeviationInput.type = 'text'
			maxDeviationInput.autocomplete = 'off'
			maxDeviationInput.setAttribute('id', 'heatmapMaxDeviationInput')
			maxDeviationInput.setAttribute('class', 'adsk-control adsk-control-group css-class-name')
			heatmapSettingsForm.appendChild(maxDeviationInput)

			const resetButton = document.createElement('input')
			resetButton.type = 'button'
			resetButton.size = '1'
			resetButton.setAttribute('id', 'heatmapResetButton')
			resetButton.setAttribute('class', 'adsk-control adsk-control-group css-class-name')
			heatmapSettingsForm.appendChild(resetButton)

			const applyNewValues = () => {
				param.set('tolerance', parseFloat(toleranceInput.value).toFixed(0))
				param.set('max_deviation', parseFloat(maxDeviationInput.value).toFixed(0))
				changeColorBinded()
			}

			heatmapSettingsForm.addEventListener('change', applyNewValues)
			heatmapSettingsForm.addEventListener('keypress', event => {
				if (event.key === 'Enter') {
					applyNewValues()
				}
			})

			resetButton.addEventListener('click', () => {
				this.updateSettingsFromSelectionAndRepaint()
			})

			this.viewer.container.appendChild(heatmapSettingsForm)
		} else {
			document.getElementById('heatmapSetterContainer').remove()
		}
	}

	showLoadingSpinner() {
		if (this.callbacksInProgressCounter === 0) {
			let spinner = document.createElement('div')
			spinner.setAttribute('class', 'lds-ring')
			spinner.id = 'heatmapLoadingSpinner'
			spinner.innerHTML = '<div></div>'
			document.getElementsByClassName('adsk-viewing-viewer')[0].appendChild(spinner)
		}
		this.callbacksInProgressCounter++
	}

	hideLoadingSpinner() {
		this.callbacksInProgressCounter--
		if (this.callbacksInProgressCounter === 0) {
			document.getElementById('heatmapLoadingSpinner').remove()
		}
	}

	prepareShaderMaterial(pointSize) {
		const vertexShader = `
		  uniform float size;
		  varying vec3 vColor;
		  void main() {
			vColor = color;
			// After point projection, subtract a small magnitude in z to reduce "glitches" on shader-rendered pixels
			// above other scene elements
			gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 ) - vec4(0.0, 0.0, 0.01, 0.0);
			gl_PointSize = size;
		  }
		`

		const fragmentShader = `
		  varying vec3 vColor;
		  void main() {
			gl_FragColor = vec4(vColor, 1.0);
		  }
		`

		return new THREE.ShaderMaterial({
			vertexColors: THREE.VertexColors,
			fragmentShader: fragmentShader,
			vertexShader: vertexShader,
			depthWrite: true,
			depthTest: true,
			uniforms: {
				size: {type: 'f', value: pointSize},
			},
		})
	}

	createPointCloud(heatmap) {
		this.deviation_steps = this.deviation_percent.map(x => x * param.get('max_deviation'))

		const geometry = this.createBufferGeometryForHeatmap(heatmap)
		const material = this.prepareShaderMaterial(parseFloat(this.points_size))
		return new THREE.PointCloud(geometry, material)
	}

	createBufferGeometryForHeatmap(heatmap) {
		const geometry = new THREE.BufferGeometry()
		const positions = new Float32Array(heatmap.length * 3)
		const colors = new Float32Array(heatmap.length * 3)
		for (let i = 0; i < heatmap.length; ++i) {
			const coordinates = coordinateFromIFCtoViewer(this.viewer.model, heatmap[i][0], heatmap[i][1], heatmap[i][2])
			const color = new THREE.Color(this.computeDeviationColor(heatmap[i][3]))
			positions[3 * i] = coordinates[0]
			positions[3 * i + 1] = coordinates[1]
			positions[3 * i + 2] = coordinates[2]
			colors[3 * i] = color.r
			colors[3 * i + 1] = color.g
			colors[3 * i + 2] = color.b
		}
		geometry.addAttribute('position', new THREE.BufferAttribute(positions, 3))
		geometry.addAttribute('color', new THREE.BufferAttribute(colors, 3))
		geometry.computeBoundingBox()
		geometry.isPoints = true
		return geometry
	}

	updatePointCloudColors(cloud, magnitudes) {
		const colors = new Float32Array(magnitudes.length * 3)
		for (let i = 0; i < magnitudes.length; ++i) {
			const color = new THREE.Color(this.computeDeviationColor(magnitudes[i] / this.scale))
			colors[3 * i] = color.r
			colors[3 * i + 1] = color.g
			colors[3 * i + 2] = color.b
		}
		cloud.geometry.attributes['color'].set(colors)
		cloud.geometry.attributes['color'].needsUpdate = true
	}

	getHeatmapMagnitudes(heatmap) {
		const magnitudes = []
		for (let i = 0; i < heatmap.length; ++i) {
			magnitudes.push(heatmap[i][3] * this.scale)
		}
		return magnitudes
	}

	deleteHeatmapData() {
		Object.keys(this.heatmapData).forEach(function (elementId) {
			this.viewer.overlays.removeMesh(this.heatmapData[elementId].pointCloud, HEATMAP_SCENE_ID)
			disposeThreeNode(this.heatmapData[elementId].pointCloud)
		}, this)

		this.heatmapData = []
	}

	async readHeatmapFile(file_name) {
		let current_array = []
		let content = await fetchS3File(file_name)
		if (content !== undefined) {
			content = content.replace(/\[/g, '')
			content = content.replace(/]/g, '')
			current_array = content.split(',')
		}

		const heatmap_array = []
		let index = 0
		while (index < current_array.length) {
			const sub_array = []
			for (let i = 0; i < this.fields_of_pointcloud; i++) {
				sub_array.push(parseFloat(current_array[index]))
				index++
			}
			heatmap_array.push(sub_array)
		}

		return heatmap_array
	}

	handleMouseMove = debounce(function (event) {
		if (Object.keys(this.heatmapData).length === 0) {
			return false
		}

		// Capture pointer position
		const pointer = event.pointers ? event.pointers[0] : event

		// Backproject with render camera
		const rect = this.viewer.impl.canvas.getBoundingClientRect()
		const x = ((pointer.clientX - rect.left) / rect.width) * 2 - 1
		const y = -((pointer.clientY - rect.top) / rect.height) * 2 + 1
		const viewVector = new THREE.Vector3(x, y, 0.5).unproject(this.viewer.impl.camera)

		// Check intersection against all enabled heatmaps at the moment
		this.raycaster.set(this.viewer.impl.camera.position, viewVector.sub(this.viewer.impl.camera.position).normalize())
		let intersectedElementMagnitude = null
		let intersectedPoint

		for (const heatmapData of Object.values(this.heatmapData)) {
			const intersectedPointAux = this.raycaster.intersectObject(heatmapData.pointCloud)
			if (intersectedPointAux.length > 0) {
				// Store intersected heatmap magnitude value and point for later model occlusion check
				intersectedElementMagnitude = heatmapData.magnitudes[intersectedPointAux[0].index]
				intersectedPoint = intersectedPointAux[0]
			}
		}

		// Raycast also against model to check for self occlusions
		const modelHitTest = this.viewer.model.rayIntersect(this.raycaster, false)

		const magnitudeLabel = document.getElementById('heatmapMagnitudeTooltip')

		// A heatmap is intersected if the model is also there but no other element causes any occlusion
		if (
			this.active &&
			modelHitTest !== null &&
			intersectedElementMagnitude !== null &&
			intersectedPoint.distance <= modelHitTest.distance + this.raycaster.params.PointCloud.threshold
		) {
			// Update tooltip value and make it visible
			magnitudeLabel.style.display = 'table'
			magnitudeLabel.style.left = pointer.clientX + 15 + 'px'
			magnitudeLabel.style.top = pointer.clientY + 15 + 'px'

			// Display magnitude value if it's beyond tolerance
			let magnitudeText = '+/- ' + Math.round(param.get('tolerance'))
			if (Math.abs(intersectedElementMagnitude) >= param.get('tolerance')) {
				magnitudeText = Math.round(intersectedElementMagnitude)
			}
			magnitudeLabel.innerHTML = '<p>' + magnitudeText + ' mm</p>'

			return true
		} else {
			magnitudeLabel.style.display = 'none'
			return false
		}
	}, 10)

	async selectionChanged() {
		if (this.active && this.viewer?.model) {
			// Overlay loading spinner while work is being done
			this.showLoadingSpinner()

			// Create new heatmaps of the clicked elements
			for (let classification of this.classifications) {
				if (classification.deviationHeatmap) {
					// Process only non-cached heatmap files
					if (!(classification._id in this.heatmapData)) {
						const heatmapRawData = await this.readHeatmapFile(
							getS3KeyForPath(this.project, classification.deviationHeatmap.filePath),
						)

						if (heatmapRawData !== undefined && heatmapRawData.length >= 0) {
							this.heatmapData[classification._id] = {
								pointCloud: this.createPointCloud(heatmapRawData),
								magnitudes: this.getHeatmapMagnitudes(heatmapRawData),
							}

							this.viewer.overlays.addMesh(this.heatmapData[classification._id].pointCloud, HEATMAP_SCENE_ID)
						}
					}
				}
			}

			// Flush cached elements that were not in the callback
			const classificationIds = this.classifications.map(c => c._id)
			Object.keys(this.heatmapData).forEach(function (cachedElementId) {
				if (!classificationIds.includes(cachedElementId)) {
					this.viewer.overlays.removeMesh(this.heatmapData[cachedElementId].pointCloud, HEATMAP_SCENE_ID)
					disposeThreeNode(this.heatmapData[cachedElementId].pointCloud)
					delete this.heatmapData[cachedElementId]
				}
			}, this)

			await this.updateSettingsFromSelectionAndRepaint()

			// Detach preloading spinner after all work is done
			this.hideLoadingSpinner()
			this.viewer.impl.invalidate(true, true, true)
		}
	}

	updateSettingsFromSelectionAndRepaint() {
		const tolerances = _.uniq(this.classifications.map(cl => cl.tolerance))
		if (tolerances.length === 1) {
			const tolerance = Number(tolerances[0] * 1000).toFixed(0)
			const maxDeviation = _.min([
				_.max(this.heatmapData[Object.keys(this.heatmapData)[0]].magnitudes.map(m => Math.abs(m))).toFixed(0),
				1000,
			])
			this.setSettingsAndRepaint(tolerance, maxDeviation)
		} else {
			if (
				param.get('tolerance') !== params.tolerance_initial ||
				param.get('max_deviation') !== params.max_deviation_initial
			) {
				this.setSettingsAndRepaint(
					parseFloat(params.tolerance_initial).toFixed(0),
					parseFloat(params.max_deviation_initial).toFixed(0),
				)
			}
		}
	}

	setSettingsAndRepaint(tolerance, maxDeviation) {
		param.set('tolerance', tolerance)
		param.set('max_deviation', maxDeviation)

		const toleranceInput = document.getElementById('heatmapToleranceInput')
		if (toleranceInput) toleranceInput.value = tolerance

		const maxDeviationInput = document.getElementById('heatmapMaxDeviationInput')
		if (maxDeviationInput) maxDeviationInput.value = maxDeviation

		this.updateCloudAndSliderColors()
	}

	updateCloudAndSliderColors() {
		// Update deviation steps
		this.deviation_steps = this.deviation_percent.map(x => x * param.get('max_deviation'))

		// Updates heatmap pointcloud colors after tolerance and/or range have ben changed
		Object.keys(this.heatmapData).forEach(function (it) {
			this.updatePointCloudColors(this.heatmapData[it].pointCloud, this.heatmapData[it].magnitudes)
		}, this)

		// Make scale value invisible to force update via user mouse interaction, and update range colors
		document.getElementById('heatmapScaleValue').style.display = 'none'
		this.computeSliderColors()

		this.viewer.impl.sceneUpdated(true)
	}
}

Autodesk.Viewing.theExtensionManager.registerExtension(ExtensionId, HeatmapExtension)
