import {Box, Text} from 'grommet'
import React from 'react'
import {MenuLink} from './MenuLink'
import {useRoutes} from '../../hooks/useRoutes'
import {Route, Switch, useParams} from 'react-router-dom'
import {useProject} from '../../features/User/hooks/useProject'
import {useReclassificationTask} from '../../features/Reclassification/hooks/reclassificationTask/useReclassificationTask'

export function ReclassificationSubMenu() {
	const {clientSlug, reclassificationId, projectSlug} = useParams<{
		clientSlug: string
		projectSlug: string
		reclassificationId: string
	}>()
	const {project} = useProject(clientSlug, projectSlug)
	const {data: reclassification} = useReclassificationTask(project?._id, reclassificationId)
	const routes = useRoutes()
	return project && reclassification ? (
		<Box pad={{left: 'small', top: 'xsmall'}}>
			<Text>
				{project.company.name} {'>'} {project.name}
			</Text>
			<Text size={'large'}>{reclassification.name}</Text>
			<Box pad={{left: 'xsmall', top: 'xsmall'}}>
				<MenuLink to={routes.reclassificationOverview.linkTo({clientSlug, projectSlug, reclassificationId})}>
					<Text>Overview</Text>
				</MenuLink>
				<MenuLink to={routes.reclassificationWorkPackages.linkTo({clientSlug, projectSlug, reclassificationId})}>
					<Text>Work Packages</Text>
				</MenuLink>
				<MenuLink to={routes.reclassificationUsers.linkTo({clientSlug, projectSlug, reclassificationId})}>
					<Text>Assigned Users</Text>
				</MenuLink>
				{reclassification.status === 'finished' && (
					<MenuLink
						to={routes.reclassificationReport.linkTo({
							clientSlug,
							projectSlug,
							reclassificationId,
							subRoute: 'overall',
						})}
						exact={false}
						overrideRouteMatch={routes.reclassificationReport.path}
					>
						<Text>Reclassification Report</Text>
					</MenuLink>
				)}
			</Box>
		</Box>
	) : null
}

export function ReclassificationsSubMenu() {
	return (
		<Switch>
			<Route path={'/reclassifications/manage/:clientSlug/:projectSlug/:reclassificationId'}>
				<ReclassificationSubMenu />
			</Route>
		</Switch>
	)
}

export function SidebarNavigation() {
	return (
		<Box pad={'small'}>
			<ReclassificationsSubMenu />
		</Box>
	)
}
