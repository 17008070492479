import {useLocation} from 'react-router-dom'

export const usePaginationQueryParams = () => {
	const location = useLocation()
	const qs = new URLSearchParams(location.search)
	const page = parseInt(qs.get('page') || '1', 10)
	const filter = qs.has('filter') ? JSON.parse(qs.get('filter')!) : {}
	const sorting = qs.has('sorting') ? JSON.parse(qs.get('sorting')!) : {field: 'createdDate', ascending: false}
	return {page, filter, sorting}
}
