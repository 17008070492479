import {Filter, useData} from '../../../hooks/useData'
import {UserProject} from '../entities/userProject'
import {QueryConfig} from 'react-query'
import {Pagination} from '../../../api/baseApi'

export function useProjects(
	{page, perPage}: {page: number; perPage: number},
	filters: Filter<UserProject>,
	sorting: any = {},
	options: QueryConfig<Pagination<UserProject>> = {},
) {
	return useData<UserProject>('projects', {perPage, page}, filters, sorting, options)
}
