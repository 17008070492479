import {useQuery} from 'react-query'
import {AxiosError} from 'axios'
import {fetchReclassificationReport, ReclassificationReport} from '../api/reclassificationTasks'

export function useReclassificationReport(projectId: string | undefined, reclassificationId: string | undefined) {
	return useQuery<ReclassificationReport, AxiosError>(
		['reclassification-report', projectId, reclassificationId],
		() => fetchReclassificationReport(projectId!, reclassificationId!),
		{
			enabled: projectId && reclassificationId,
		},
	)
}
