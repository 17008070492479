import {useParams} from 'react-router-dom'
import {useProject} from '../features/User/hooks/useProject'
import {useFeatureFlagsImport} from '../features/FeatureFlags/FeatureFlagsProvider'
import {useEffect} from 'react'

export function useCurrentProject() {
	const {clientSlug, projectSlug} = useParams<{clientSlug: string; projectSlug: string}>()
	if (!clientSlug || !projectSlug) {
		throw new Error('useCurrentProject can only be used with routes that define clientSlug and projectSlug')
	}
	const {project} = useProject(clientSlug, projectSlug)
	const importDataToLayer = useFeatureFlagsImport()

	useEffect(() => {
		importDataToLayer(
			Object.fromEntries(
				Object.entries(project?.viewerFeatureFlags ?? {})
					.filter(([, value]) => typeof value === 'boolean')
					.map(([id, value]) => {
						return [id, !!value]
					}),
			),
			'server',
		)
	}, [project, importDataToLayer])
	return project
}
