import {generatePath} from 'react-router-dom'
import React, {FunctionComponent} from 'react'
import _ from 'lodash'

export type RouteParams = {[paramName: string]: string | number | boolean | undefined}

function generateSearchParamsString(searchParams: Record<string, string>): string {
	return (
		'?' +
		Object.entries(searchParams)
			.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
			.join('&')
	)
}

export class SrRoute<R extends RouteParams = {}> {
	constructor(
		public readonly path: string,
		protected readonly mainComponent: FunctionComponent,
		protected readonly topNavigationComponent: FunctionComponent,
		public readonly exact: boolean = true,
	) {}
	linkTo(params: R, searchParams: Record<string, string> = {}): string {
		return generatePath(this.path, params) + (!_.isEmpty(searchParams) ? generateSearchParamsString(searchParams) : '')
	}
	renderMainComponent() {
		return React.createElement(this.mainComponent)
	}
	renderTopNavigationComponent() {
		return React.createElement(this.topNavigationComponent)
	}
}
