import {createSlice, configureStore, PayloadAction} from '@reduxjs/toolkit'
const maintenanceSlice = createSlice({
	name: 'maintenance',
	initialState: {
		maintenance: {
			value: false,
		},
	},
	reducers: {
		setMaintenanceMode: (state: MaintenanceState, action: PayloadAction<boolean>) => {
			state.maintenance.value = action.payload
		},
	},
})

type MaintenanceState = {
	maintenance: {
		value: boolean
	}
}

export const {setMaintenanceMode} = maintenanceSlice.actions

export const store = configureStore({
	reducer: maintenanceSlice.reducer,
})

export type RootState = ReturnType<typeof store.getState>
