import {useViewer} from './useViewer'
import {useEffect} from 'react'
import {ReclassificationAnnotationEntity} from '../../Reclassification/entities/reclassification-annotation'

export function usePaintModel(
	reclassificationElements:
		| {forgeObjectId: number; status: ReclassificationAnnotationEntity['classification']}[]
		| null,
	selectedElementId: number | undefined,
) {
	const {
		status,
		actions: {colorizeModel},
	} = useViewer()
	useEffect(() => {
		if (status === 'model_loaded' && reclassificationElements) {
			colorizeModel(
				reclassificationElements.map(cl => ({
					dbId: cl.forgeObjectId,
					status: cl.status,
				})),
				selectedElementId,
				false,
			)
		}
	}, [colorizeModel, reclassificationElements, selectedElementId, status])
}
