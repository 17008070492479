import {ReclassificationTaskStatus} from '../features/Reclassification/entities/reclassification-task'
import {
	SR_RGBA_VIEWER_APPROVED,
	SR_RGBA_VIEWER_DEFAULT,
	SR_RGBA_VIEWER_DEVIATED,
	SR_RGBA_VIEWER_MISSING,
	SR_RGBA_VIEWER_NO_DATA,
	SR_RGBA_VIEWER_UNDER_CONSTRUCTION,
	SR_RGBA_VIEWER_VERIFIED,
} from 'sr-react-commons'

export const RECLASSIFICATION_STATUS_COLORS: Record<ReclassificationTaskStatus, string> = {
	'in progress': 'rgb(222, 235, 255)',
	'not started': 'rgb(223, 225, 230)',
	finished: 'rgb(227, 252, 239)',
}

export const COLOR_BY_STATUS = {
	approved: SR_RGBA_VIEWER_APPROVED,
	verified: SR_RGBA_VIEWER_VERIFIED,
	deviated: SR_RGBA_VIEWER_DEVIATED,
	under_construction: SR_RGBA_VIEWER_UNDER_CONSTRUCTION,
	missing: SR_RGBA_VIEWER_MISSING,
	no_data: SR_RGBA_VIEWER_NO_DATA,
	default: SR_RGBA_VIEWER_DEFAULT,
}

export const rgbaObjToCssColor = (rgba: {r: number; g: number; b: number; a: number}) =>
	`rgba(${rgba.r},${rgba.g},${rgba.b},${rgba.a})`
