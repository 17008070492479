import React from 'react'
import PhotoSphereRenderer from './PhotoSphereRenderer'
import {WebGLRenderer} from 'three'
import {Box, Heading} from 'grommet'
import {Classification, Viewsphere} from '../../../entities/classification'
import {UserProject} from '../../User/entities/userProject'

const THREE = window.THREE

interface IProps {
	classification: Classification
	project: UserProject
	viewsphere: Viewsphere
	highlightElement: boolean
	cameraOrientation: [number, number]
	setCameraOrientation: React.Dispatch<React.SetStateAction<[number, number]>>
	cameraFov: number
	setCameraFov: React.Dispatch<React.SetStateAction<number>>
}

const renderer = new THREE.WebGLRenderer()
renderer.domElement.id = 'photoSphereCanvas'
renderer.setSize(100, 100, false)
renderer.setPixelRatio(window.devicePixelRatio)

export default function PhotoSpherePanelContent({
	classification,
	project,
	viewsphere,
	highlightElement,
	cameraOrientation,
	setCameraOrientation,
	cameraFov,
	setCameraFov,
}: IProps) {
	const rendererRef = React.useRef<WebGLRenderer>(renderer)

	return (
		<Box fill={true}>
			{classification.viewspheres.length > 0 ? (
				viewsphere && (
					<Box gap={'xsmall'} fill={true}>
						<Box id="photosphere-asset-container" overflow={'hidden'} fill={true}>
							<PhotoSphereRenderer
								highlightElement={highlightElement}
								viewsphere={viewsphere}
								classification={classification}
								renderer={rendererRef.current}
								project={project}
								cameraOrientation={cameraOrientation}
								setCameraOrientation={setCameraOrientation}
								cameraFov={cameraFov}
								setCameraFov={setCameraFov}
							/>
						</Box>
					</Box>
				)
			) : (
				<Box>
					<Heading level={4}>This element has no 360 photos.</Heading>
				</Box>
			)}
		</Box>
	)
}
