import {srApiV3} from '../../../api/baseApi'
import {AuthEntity, User} from '../entities/user'
import {PermissionsEnum} from '../entities/auth'

export function fetchUserById(userId: string): Promise<User> {
	return srApiV3.get(`/users/${userId}`).then(response => response.data)
}

export function fetchCurrentUser(): Promise<User> {
	return srApiV3.get('/users/current').then(response => response.data)
}

export function fetchCurrentAuths(userId: string): Promise<AuthEntity[]> {
	return srApiV3.get('/auths/all', {params: {user: userId}}).then(response => response.data)
}

export function createCompanyUser(
	companyId: string,
	userData: {firstName: string; lastName: string; email: string; username: string},
): Promise<User> {
	return srApiV3.post(`/companies/${companyId}/users/`, userData).then(response => response.data)
}

export function disableUser(companyId: string, userId: string): Promise<User> {
	return srApiV3.delete(`/companies/${companyId}/users/${userId}`).then(response => response.data)
}

export async function fetchCurrentUserHasPermissionOnResource(
	id: string,
	permission: PermissionsEnum,
): Promise<boolean> {
	return srApiV3.get(`users/current/resources/${id}/permissions/${permission}`).then(response => response.data)
}
