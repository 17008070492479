import {useMutation} from 'react-query'
import {ReclassificationAnnotationEntity} from '../../entities/reclassification-annotation'
import {AxiosError} from 'axios'
import {
	approveByClassificationsIds,
	approveInStageTask,
	bulkReclassifyByClassificationsIds,
	ReclassifyDTO,
	reclassifyInStageTask,
} from '../../api/reclassificationAnnotations'
import {AnnotationClassificationType} from '../../entities/classificationAnnotation'

export function useReclassificationAnnotationMutations(
	projectId: string | undefined,
	onSuccess: () => Promise<unknown> | undefined,
) {
	const [approveMutation] = useMutation<
		ReclassificationAnnotationEntity,
		AxiosError,
		{classificationId: string; stageTaskId: string}
	>(({classificationId, stageTaskId}) => approveInStageTask(projectId || '', stageTaskId, classificationId), {
		throwOnError: true,
		onSuccess,
	})

	const [approveByClassificationsIdsMutation] = useMutation<
		ReclassificationAnnotationEntity[],
		AxiosError,
		{classificationsIds: string[]; reclassificationId: string}
	>(
		({classificationsIds, reclassificationId}) =>
			approveByClassificationsIds(projectId || '', reclassificationId, classificationsIds),
		{
			throwOnError: true,
			onSuccess,
		},
	)

	const [reclassifyMutation] = useMutation<
		ReclassificationAnnotationEntity,
		AxiosError,
		{classificationId: string; stageTaskId: string; formData: ReclassifyDTO}
	>(
		({classificationId, stageTaskId, formData}) =>
			reclassifyInStageTask(projectId || '', stageTaskId, classificationId, formData),
		{
			throwOnError: true,
			onSuccess,
		},
	)

	const [bulkReclassifyByClassificationsIdsMutation] = useMutation<
		ReclassificationAnnotationEntity[],
		AxiosError,
		{
			classificationsIds: string[]
			reclassificationId: string
			status: AnnotationClassificationType
			magnitude: number | null
		}
	>(
		({classificationsIds, reclassificationId, status, magnitude}) =>
			bulkReclassifyByClassificationsIds(projectId || '', reclassificationId, classificationsIds, status, magnitude),
		{
			throwOnError: true,
			onSuccess,
		},
	)

	return {
		reclassifyMutation,
		approveMutation,
		approveByClassificationsIdsMutation,
		bulkReclassifyByClassificationsIdsMutation,
	}
}
