import {Blank, IconProps} from 'grommet-icons'
import React from 'react'

export const Eraser = (props: IconProps) => (
	<Blank {...props}>
		<svg x="0px" y="0px" viewBox="0 0 512 512">
			<g>
				<path d="M111.6,426.7c4.7,4.4,11,6.9,17.4,6.9h146.8c6.7,0,13.2-2.7,18-7.4l208.8-208.8c9.9-9.9,9.9-26,0-35.9L330.4,9.3   c-9.5-9.5-26.4-9.5-35.9,0L9.4,294.5C4.6,299.4,1.9,306,2,312.9c0.1,6.9,3,13.4,8,18.1L111.6,426.7z M187.9,187.8L324.1,324   l-58.9,58.9H139.1L63.9,312L187.9,187.8z" />
				<path d="M499.8,459H12.2C6.6,459,2,463.6,2,469.2v30.5c0,5.6,4.5,10.2,10.2,10.2h487.7c5.6,0,10.2-4.5,10.2-10.2v-30.5   C510,463.6,505.5,459,499.8,459z" />
			</g>
		</svg>
	</Blank>
)
