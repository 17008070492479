import {useQuery} from 'react-query'
import {fetchReclassificationActivityForUser} from '../api/reclassificationTasks'

export function useUserReclassificationActivity(
	userId: string,
	{startDate, endDate}: {startDate: Date; endDate: Date},
) {
	return useQuery(['reclassification-activity', userId, {startDate, endDate}], () =>
		fetchReclassificationActivityForUser(userId, {startDate, endDate}),
	)
}
