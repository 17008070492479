import React, {useState} from 'react'
import {AnnotationClassificationType, AnnotationConfidenceType} from '../entities/classificationAnnotation'
import {Box, Form, FormField, RadioButtonGroup, Text, TextInput, ThemeContext} from 'grommet'
import {FormRefresh} from 'grommet-icons'
import {useLocation} from 'react-router-dom'
import {TextArea} from 'grommet/es6'
import _ from 'lodash'
import {ReclassificationDTO} from '../entities/reclassification-annotation'
import {ReclassificationActions} from '../api/reclassificationAnnotations'
import {SRIconButton, SRPrimaryButton, SRSecondaryButton} from 'sr-react-commons'

function InitialControls(props: {
	onApprove: () => Promise<any>
	onStartReclassify: () => void
	onStartRequestReview: () => void
	hideSkipped: boolean
	hideApprove: boolean
	hideRequestReview: boolean
	onSkip: () => void
}) {
	const [approving, setApproving] = useState<boolean>(false)
	const onApprove = async () => {
		setApproving(true)
		try {
			await props.onApprove()
		} finally {
			setApproving(false)
		}
	}
	return (
		<Box justify={'between'} gap={'small'}>
			<Box gap={'small'} justify={props.hideApprove ? 'end' : 'between'} direction="column">
				{!props.hideApprove && (
					<SRPrimaryButton alignSelf={'start'} onClick={onApprove} disabled={approving} label={'Approve'} />
				)}
				<SRSecondaryButton alignSelf={'start'} onClick={props.onStartReclassify} label={'Reclassify'} />
			</Box>
			<Box direction="row" justify="start" gap={'xsmall'}>
				{!props.hideSkipped && <SRSecondaryButton alignSelf={'start'} onClick={props.onSkip} label={'Skip'} />}
			</Box>
		</Box>
	)
}

type ErrorsObject = {magnitudeError?: string; formError?: string}

type ReclassificationFormType = {
	status: AnnotationClassificationType | null
	confidence: AnnotationConfidenceType | null
	magnitude: string
}

function ReclassifyControls(props: {
	onReclassify: (event: any) => Promise<void>
	onRequestExpertReview: (event: any) => Promise<void>
	onBack: () => void
	initialValues?: ReclassificationFormType
	requireMagnitudeAndStatus?: boolean
}) {
	const [formValue, setFormValue] = React.useState<ReclassificationFormType>(
		props.initialValues || {
			status: null,
			confidence: null,
			magnitude: '',
		},
	)
	const [errors, setErrors] = React.useState<ErrorsObject>({})

	const validateForm = (formValue: ReclassificationFormType) => {
		const errors: ErrorsObject = {}
		if (props.requireMagnitudeAndStatus) {
			if (!formValue.status || (!['no_data', 'missing'].includes(formValue.status) && !formValue.magnitude)) {
				errors.formError = 'You must set both status and magnitude unless status is no_data or missing.'
			}
		} else {
			if (!formValue.status && !formValue.magnitude) {
				errors.formError = 'You must at least set the status or the magnitude.'
			}
		}
		if (formValue.magnitude.length > 0 && parseFloat(formValue.magnitude) < 0) {
			errors.magnitudeError = 'Magnitude must be a positive value.'
		}
		return {validated: _.isEmpty(errors), errors: errors}
	}

	const formRef = React.useRef(null)
	const [formStatus, setFormStatus] = React.useState<'start' | 'expert_review'>('start')

	return (
		<Box>
			<ThemeContext.Extend value={{formField: {border: false}}}>
				<Form
					ref={formRef}
					value={formValue}
					onSubmit={(event: any) => {
						setErrors({})
						const {validated, errors} = validateForm(event.value)
						if (!validated) {
							setErrors(errors)
							return false
						}
						const action = event.nativeEvent.submitter.name
						if (action === 'request_expert_review') {
							return props.onRequestExpertReview(event)
						} else {
							return props.onReclassify(event)
						}
					}}
					onChange={(nextValue: any) => setFormValue(nextValue)}
				>
					<Box gap={'xsmall'}>
						<Box
							style={formStatus === 'expert_review' ? {display: 'none'} : undefined}
							direction="column"
							justify={'between'}
							gap="xsmall"
						>
							<FormField
								label={
									<Box direction={'row'} gap={'xsmall'} align={'center'}>
										<Text>Status</Text>
										<SRIconButton
											style={{visibility: formValue.status === null ? 'hidden' : 'visible'}}
											size={'small'}
											alignSelf={'end'}
											title={'Unset status value'}
											onClick={() => setFormValue(value => ({...value, status: null}))}
											icon={<FormRefresh size="small" />}
										/>
									</Box>
								}
							>
								<RadioButtonGroup
									name={'status'}
									gap={'xsmall'}
									pad={'xsmall'}
									options={[
										{label: 'Verified', value: 'verified'},
										{label: 'Deviated', value: 'deviated'},
										{label: 'Missing', value: 'missing'},
										{label: 'No data', value: 'no_data'},
										{label: 'Under construction', value: 'under_construction'},
									]}
								/>
							</FormField>
							<Box pad={{right: 'small'}}>
								<FormField
									width="xsmall"
									label={
										<Box direction={'row'} gap={'xsmall'} align={'center'}>
											<Text>Magnitude</Text>
											<SRIconButton
												style={{visibility: formValue.magnitude === '' ? 'hidden' : 'visible'}}
												size={'small'}
												alignSelf={'end'}
												title={'Unset magnitude value'}
												onClick={() => {
													setFormValue(value => ({...value, magnitude: ''}))
													setErrors({...errors, magnitudeError: undefined})
												}}
												icon={<FormRefresh size="small" />}
											/>
										</Box>
									}
								>
									<TextInput name={'magnitude'} type={'number'} size={'xsmall'} />
								</FormField>
							</Box>
						</Box>
						<Box style={formStatus === 'start' ? {display: 'none'} : undefined}>
							<FormField label={'Message for expert review'}>
								<Box margin={{bottom: 'xsmall'}} height="150px">
									<TextArea fill={true} resize={false} name={'message'} />
								</Box>
							</FormField>
						</Box>
						<Box
							style={formStatus === 'expert_review' ? {display: 'none'} : undefined}
							direction="row"
							justify={'between'}
							gap={'xsmall'}
						>
							<SRSecondaryButton alignSelf={'end'} label={'Back'} size={'small'} onClick={props.onBack} />
							<Box direction={'row'} gap={'xsmall'}>
								<SRSecondaryButton
									alignSelf={'end'}
									size={'small'}
									onClick={() => {
										const formData = Object.fromEntries(new FormData(formRef.current!))
										const {validated, errors} = validateForm(formData as ReclassificationFormType)
										setErrors(errors)
										if (validated) {
											setFormStatus('expert_review')
										}
									}}
									label={'Review'}
									title={'Request review'}
								/>
								<SRPrimaryButton size={'small'} alignSelf={'end'} type={'submit'} name={'reclassify'} label={'Save'} />
							</Box>
						</Box>
						<Box style={formStatus === 'start' ? {display: 'none'} : undefined} gap={'small'}>
							<Box direction="row" justify={'between'} gap={'xsmall'}>
								<SRSecondaryButton
									size={'small'}
									label={'Back'}
									onClick={() => {
										setFormStatus('start')
									}}
								/>
								<SRPrimaryButton type={'submit'} name={'request_expert_review'} size={'small'} label={'Submit'} />
							</Box>
						</Box>
						<Box>
							{errors.formError && (
								<Text color={'status-error'} size={'small'}>
									{errors.formError}
								</Text>
							)}
							{errors.magnitudeError && (
								<Text size={'small'} color={'status-error'}>
									{errors.magnitudeError}
								</Text>
							)}
						</Box>
					</Box>
				</Form>
			</ThemeContext.Extend>
		</Box>
	)
}

export function ReclassificationControls({
	currentElement,
	actions: {approve, reclassify, requestExpertReview, skip},
}: {
	currentElement: ReclassificationDTO
	actions: ReclassificationActions & {skip: (classificationId: string) => void}
}) {
	const [state, setState] = React.useState<'initial' | 'reclassification' | 'request_review'>('initial')

	const location = useLocation()
	const searchParams = new URLSearchParams(location.search)
	const skipped = searchParams.get('skipped') === '1'

	if (!currentElement) return null

	const annotation = currentElement.annotation

	const renderState = () => {
		switch (state) {
			case 'initial':
				return (
					<InitialControls
						onApprove={() => approve(currentElement.classification._id)}
						onStartReclassify={() => setState('reclassification')}
						hideSkipped={skipped}
						hideRequestReview={false}
						hideApprove={currentElement.classification.magnitude === undefined}
						onSkip={() => skip(currentElement.classification._id)}
						onStartRequestReview={() => setState('request_review')}
					/>
				)
			case 'reclassification':
				return (
					<ReclassifyControls
						onReclassify={async (event: any) => {
							const params = {
								status: event.value.status,
								confidence: event.value.confidence,
								magnitude: event.value.magnitude === '' ? null : parseInt(event.value.magnitude) / 1000.0,
							}
							return reclassify(currentElement.classification._id, params).then(() => {
								setState('initial')
							})
						}}
						onRequestExpertReview={async (event: any) => {
							const params = {
								status: event.value.status,
								confidence: event.value.confidence,
								magnitude: event.value.magnitude === '' ? null : parseInt(event.value.magnitude) / 1000.0,
								message: event.value.message,
							}
							return requestExpertReview(currentElement.classification._id, params).then(annotation => {
								if (annotation) {
									setState('initial')
								}
							})
						}}
						onBack={() => setState('initial')}
						requireMagnitudeAndStatus={currentElement.classification.status === undefined}
						initialValues={
							annotation
								? {
										magnitude: annotation.magnitude ? (annotation.magnitude * 1000).toFixed(0).toString() : '',
										confidence: null,
										status: annotation.classification,
								  }
								: undefined
						}
					/>
				)
		}
	}

	return (
		<Box gap={'small'} fill={true}>
			{renderState()}
		</Box>
	)
}
