import {
	ReclassificationOverviewPage,
	ReclassificationOverviewPageParams,
	ReclassificationOverviewPageTitle,
} from './ReclassificationOverviewPage'
import {SrRoute} from '../../routing'

export const reclassificationOverviewRoute = new SrRoute<ReclassificationOverviewPageParams>(
	'/reclassifications/manage/:clientSlug/:projectSlug/:reclassificationId/overview',
	ReclassificationOverviewPage,
	ReclassificationOverviewPageTitle,
)
