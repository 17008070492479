import {queryCache, useMutation} from 'react-query'
import {ReclassificationTaskEntity} from '../../entities/reclassification-task'
import {AxiosError} from 'axios'
import {
	postAssignStageTaskToCurrentUser,
	postAssignUsersToReclassificationStage,
	postFinishReclassificationTask,
	postStartReclassificationTask,
	postUnassignUsersToReclassificationStage,
} from '../../api/reclassificationTasks'

export function useReclassificationTaskMutations() {
	const onSuccess = (reclassificationTask: ReclassificationTaskEntity) => {
		if (reclassificationTask) {
			queryCache.invalidateQueries(['reclassification-task', reclassificationTask._id])
			queryCache.invalidateQueries(['reclassification-task-users', reclassificationTask._id])
		}
		queryCache.invalidateQueries('reclassification/reclassification-work-package-stage-tasks/by-current-user')
	}
	const [assignStageTaskToCurrentUserMutation] = useMutation<
		ReclassificationTaskEntity,
		AxiosError,
		{projectId: string; reclassificationTaskId: string; stageId: string}
	>(
		({projectId, reclassificationTaskId, stageId}) =>
			postAssignStageTaskToCurrentUser(projectId, reclassificationTaskId, stageId),
		{
			onSuccess: onSuccess,
		},
	)
	const [startReclassificationMutation] = useMutation<
		ReclassificationTaskEntity,
		AxiosError,
		{projectId: string; reclassificationTaskId: string}
	>(({projectId, reclassificationTaskId}) => postStartReclassificationTask(projectId, reclassificationTaskId), {
		onSuccess: onSuccess,
	})

	const [finishReclassificationMutation] = useMutation<
		ReclassificationTaskEntity,
		AxiosError,
		{projectId: string; reclassificationTaskId: string}
	>(({projectId, reclassificationTaskId}) => postFinishReclassificationTask(projectId, reclassificationTaskId), {
		onSuccess: onSuccess,
	})

	const [assignUsersToStageMutation] = useMutation<
		ReclassificationTaskEntity,
		AxiosError,
		{projectId: string; reclassificationTaskId: string; stageId: string; userIds: string[]}
	>(
		props =>
			postAssignUsersToReclassificationStage(
				props.projectId,
				props.reclassificationTaskId,
				props.stageId,
				props.userIds,
			),
		{onSuccess},
	)

	const [unassignUsersFromStageMutation] = useMutation<
		ReclassificationTaskEntity,
		AxiosError,
		{projectId: string; reclassificationTaskId: string; stageId: string; userIds: string[]}
	>(
		props =>
			postUnassignUsersToReclassificationStage(
				props.projectId,
				props.reclassificationTaskId,
				props.stageId,
				props.userIds,
			),
		{onSuccess},
	)

	return {
		assignStageTaskToCurrentUser: assignStageTaskToCurrentUserMutation,
		startReclassification: startReclassificationMutation,
		markAsCompleted: finishReclassificationMutation,
		assignUsersToStage: assignUsersToStageMutation,
		unassignUsersFromStage: unassignUsersFromStageMutation,
	}
}
