import {srApiV3} from '../api/baseApi'
import {buildFilterQuery, buildPaginationQuery} from '../api/utils'
import _ from 'lodash'
import {Filter, Sorting} from '../hooks/useData'

export function fetchWithQuery(
	entity: string,
	{page, perPage}: {page: number; perPage: number},
	filters: Filter<Record<string, any>> = {},
	sorting: Sorting<Record<string, any>> = {field: null, ascending: true},
) {
	let url = `/${entity}/?${buildPaginationQuery(page, perPage)}`
	if (!_.isEmpty(filters)) {
		url += `&${buildFilterQuery(filters)}`
	}
	if (sorting.field !== null) {
		url += `&sort=${!sorting.ascending ? '-' : ''}${sorting.field}`
	}
	return srApiV3.get(url).then(response => response.data)
}
