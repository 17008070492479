import React from 'react'
import {Box} from 'grommet'
import {MainApp} from './MainApp'
import {useCurrentUser} from '../features/User/hooks/useCurrentUser'
import {LoginPage} from '../pages/LoginPage'

export function MainAppWithAuth() {
	const {user} = useCurrentUser()

	return (
		<Box overflow="hidden" fill={true}>
			{user ? <MainApp /> : <LoginPage />}
		</Box>
	)
}
