import {useViewer} from './useViewer'
import {useEffect} from 'react'

export function useIsolateViewerElements(forgeObjectIds: number[] | undefined) {
	const {
		status,
		actions: {isolateElements},
	} = useViewer()
	useEffect(() => {
		if (status === 'model_loaded') {
			isolateElements(forgeObjectIds || [])
		}
	}, [forgeObjectIds, isolateElements, status])
}
