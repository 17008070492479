import {Classification, Viewsphere} from '../../../../entities/classification'
import {
	coordinateFromIFCtoViewer,
	raycastMouseEvent,
	removeMeshAndDisposeMaterial,
	scaleFromIFCtoViewer,
} from '../../utilities/viewerUtilities'

export const PhotoSphereExtensionId = 'Viewing.Extension.ThreeSixtyPhotos'

const Autodesk = window.Autodesk
const THREE = window.THREE

const VIEWSPHERE_RADIUS = 0.22
const VIEWSPHERE_MATERIAL_DEFAULT = new THREE.MeshBasicMaterial({
	color: 0x491734,
})
const VIEWSPHERE_MATERIAL_SELECTED = new THREE.MeshBasicMaterial({
	color: 0x00c781,
})
const VIEWSPHERE_MATERIAL_HOVERED = new THREE.MeshBasicMaterial({
	color: 0xff4040,
})
const VIEWSPHERE_SCENE_ID = 'viewSphereScene'

export type ViewSphereMeshWrapper = {imagePath: string; mesh: THREE.Mesh}

export default class PhotoSphereExtension extends Autodesk.Viewing.Extension {
	private classification: Classification | null = null
	private renderedViewSpheres: ViewSphereMeshWrapper[]
	private selectedViewSphere: Viewsphere | undefined
	private hoveredViewSphere: Viewsphere | undefined
	private readonly setActiveViewSphere: (index: number) => void
	private readonly setCameraFov: React.Dispatch<React.SetStateAction<number>>
	private readonly setCameraOrientation: React.Dispatch<React.SetStateAction<[number, number]>>
	private viewsLocked: boolean = false
	private cameraOrientation: [number, number] | undefined

	constructor(
		viewer: Autodesk.Viewing.GuiViewer3D,
		options: {
			setActiveViewSphere: (index: number) => void
			setCameraFov: React.Dispatch<React.SetStateAction<number>>
			setCameraOrientation: React.Dispatch<React.SetStateAction<[number, number]>>
		},
	) {
		super(viewer, options)
		this.setActiveViewSphere = options.setActiveViewSphere
		this.setCameraFov = options.setCameraFov
		this.setCameraOrientation = options.setCameraOrientation
		this.renderedViewSpheres = []
	}

	setViewsLocked = (newValue: boolean) => {
		this.viewsLocked = newValue
	}

	setSelectedClassification(classification: Classification | null) {
		this.classification = classification
		this.renderViewSpheres()
	}

	setSelectedViewSphere(viewSphere: Viewsphere | undefined) {
		this.selectedViewSphere = viewSphere
		this.renderViewSpheres()
	}

	getCameraOrientation(cameraOrientation: [number, number]) {
		this.cameraOrientation = cameraOrientation
	}

	clearViewSpheres() {
		for (const viewSphere of this.renderedViewSpheres) {
			removeMeshAndDisposeMaterial(this.viewer, VIEWSPHERE_SCENE_ID, viewSphere.mesh)
		}
		this.viewer.overlays.clearScene(VIEWSPHERE_SCENE_ID)
	}

	private renderViewSpheres() {
		if (this.classification) {
			this.renderedViewSpheres = this.displayViewSpheresPoses()
		}
		this.viewer.impl.invalidate(false, false, true)
	}

	private displayViewSpheresPoses() {
		if (!this.viewer.model || !this.classification) {
			return []
		}
		const viewSphereMeshes = []
		for (const viewSphere of this.classification.viewspheres) {
			let renderedViewSphere = this.renderedViewSpheres.find(v => v.imagePath === viewSphere.imagePath)
			if (!renderedViewSphere) {
				renderedViewSphere = this.addViewSphereToScene(viewSphere)
			}

			renderedViewSphere.mesh.material =
				viewSphere.imagePath === this.hoveredViewSphere?.imagePath
					? VIEWSPHERE_MATERIAL_HOVERED
					: viewSphere.imagePath === this.selectedViewSphere?.imagePath
					? VIEWSPHERE_MATERIAL_SELECTED
					: VIEWSPHERE_MATERIAL_DEFAULT
			viewSphereMeshes.push(renderedViewSphere)
		}

		// Remove viewspheres that no longer should be displayed
		for (const renderedViewSphere of this.renderedViewSpheres) {
			const shouldBeRendered = this.classification.viewspheres.some(
				viewSphere => viewSphere.imagePath === renderedViewSphere.imagePath,
			)
			if (!shouldBeRendered) {
				removeMeshAndDisposeMaterial(this.viewer, VIEWSPHERE_SCENE_ID, renderedViewSphere.mesh)
			}
		}

		return viewSphereMeshes
	}

	private addViewSphereToScene(viewSphere: Viewsphere) {
		const coordinates = coordinateFromIFCtoViewer(
			this.viewer.model,
			viewSphere.poseCoordinates[0],
			viewSphere.poseCoordinates[1],
			viewSphere.poseCoordinates[2],
		)

		const viewSpherePosePosition = new THREE.Vector3(coordinates[0], coordinates[1], coordinates[2])

		// Orientation is not needed at the moment but we attach it for completeness
		const viewSpherePoseOrientation = new THREE.Quaternion(
			viewSphere.poseOrientation[0],
			viewSphere.poseOrientation[1],
			viewSphere.poseOrientation[2],
			viewSphere.poseOrientation[3],
		).normalize()

		const geometry = new THREE.SphereGeometry(VIEWSPHERE_RADIUS * scaleFromIFCtoViewer(this.viewer.model), 16, 16)
		const viewSpherePoseIcon = new THREE.Mesh(geometry, VIEWSPHERE_MATERIAL_DEFAULT)
		const viewSpherePoseIconMatrix = new THREE.Matrix4()
		viewSpherePoseIconMatrix.compose(viewSpherePosePosition, viewSpherePoseOrientation, new THREE.Vector3(1, 1, 1))
		viewSpherePoseIcon.applyMatrix(viewSpherePoseIconMatrix)
		viewSpherePoseIcon.visible = true
		viewSpherePoseIcon.userData['viewSphereImagePath'] = viewSphere.imagePath
		this.viewer.overlays.addMesh(viewSpherePoseIcon, VIEWSPHERE_SCENE_ID)
		return {imagePath: viewSphere.imagePath, mesh: viewSpherePoseIcon}
	}

	// Overrides single click event when hovering a view sphere
	handleSingleClick() {
		let eventInterruption = false

		if (this.viewsLocked && false) {
			// cancelled behaviour for the moment, to allow element selection in locked views mode, keeping code for easy rollback
			eventInterruption = true
		}
		if (this.hoveredViewSphere) {
			const imagePathOfHoveredItem = this.hoveredViewSphere.imagePath
			const viewSphereIndex = this.classification!.viewspheres.findIndex(
				viewSphere => viewSphere.imagePath === imagePathOfHoveredItem,
			)
			this.setActiveViewSphere(viewSphereIndex)

			eventInterruption = true
		}

		return eventInterruption
	}

	// Avoids any interaction on double click
	handleDoubleClick() {
		let eventInterruption = false

		if (this.hoveredViewSphere) {
			eventInterruption = true
		}

		if (this.viewsLocked && false) {
			// cancelled behaviour for the moment, to allow element selection in locked views mode, keeping code for easy rollback
			eventInterruption = true
		}

		return eventInterruption
	}

	handleKeyDown() {
		return false
	}

	handleWheelInput(delta: number) {
		if (this.viewsLocked) {
			this.setCameraFov(current => Math.min(Math.max(current + delta * -1, 5), 120))
			return true
		} else {
			return false
		}
	}

	// Handles mouse hover effects on viewSphere pose icons
	handleMouseMove(event: MouseEvent) {
		let eventInterruption = false
		const hoveredItems = raycastMouseEvent(
			event,
			this.viewer,
			this.renderedViewSpheres.map(v => v.mesh),
		)
		let newHoveredViewSphere: Viewsphere | undefined
		if (hoveredItems.length) {
			eventInterruption = true
			newHoveredViewSphere = this.classification?.viewspheres.find(
				viewsphere => viewsphere.imagePath === hoveredItems[0].object.userData['viewSphereImagePath'],
			)
		} else {
			newHoveredViewSphere = undefined
		}

		if (newHoveredViewSphere?.imagePath !== this.hoveredViewSphere?.imagePath) {
			this.hoveredViewSphere = newHoveredViewSphere
			this.renderViewSpheres()
		}

		if (this.viewsLocked && event.buttons === 1) {
			//Calculate changes from new camera position
			const [currentLat, currentLon] = this.cameraOrientation!
			let lat = event.movementY * 0.1 + currentLat
			lat = Math.max(-85, Math.min(85, lat))
			const lon = (event.movementX * -0.1 + currentLon) % 360
			this.setCameraOrientation([lat, lon])
			eventInterruption = true
		}
		return eventInterruption
	}

	getNames() {
		return [PhotoSphereExtensionId]
	}

	load() {
		this.setupViewSphereScene()

		// this seems to be necessary because it seems that when the viewer is torn down the materials are disposed
		this.viewer.impl.matman().addMaterial('SR_VIEWSPHERE_MATERIAL_DEFAULT', VIEWSPHERE_MATERIAL_DEFAULT, true)
		this.viewer.impl.matman().addMaterial('SR_VIEWSPHERE_MATERIAL_HOVER', VIEWSPHERE_MATERIAL_HOVERED, true)
		this.viewer.impl.matman().addMaterial('SR_VIEWSPHERE_MATERIAL_SELECTED', VIEWSPHERE_MATERIAL_SELECTED, true)

		// Register and activate extension itself as a tool in order to handle mouse events
		this.viewer.toolController.registerTool(this)
		this.viewer.toolController.activateTool(PhotoSphereExtensionId)

		return true
	}

	// This is important because otherwise it seems to happen that other custom tools (I assume the bimwalk extension) have
	// a higher priority and then the handleWheelInput() of this extension might not be called
	getPriority() {
		return 10000
	}

	unload() {
		this.teardownViewSphereScene()
		this.viewer.toolController.deactivateTool(PhotoSphereExtensionId)
		this.viewer.toolController.deregisterTool(this)
		return true
	}

	private setupViewSphereScene() {
		if (!this.viewer.overlays.hasScene(VIEWSPHERE_SCENE_ID)) {
			this.viewer.overlays.addScene(VIEWSPHERE_SCENE_ID)
		}
	}

	private teardownViewSphereScene() {
		if (!this.viewer.overlays.hasScene(VIEWSPHERE_SCENE_ID)) {
			this.clearViewSpheres()
			this.viewer.overlays.removeScene(VIEWSPHERE_SCENE_ID)
		}
	}
}

Autodesk.Viewing.theExtensionManager.registerExtension(PhotoSphereExtensionId, PhotoSphereExtension)
