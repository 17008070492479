import React from 'react'
import {SRChip} from 'sr-react-commons'
import {COLOR_BY_STATUS, rgbaObjToCssColor} from '../../../utilities/colors'
import {Box} from 'grommet'

export const ABBREVIATIONS = {
	approved: 'Ap',
	verified: 'Vr',
	deviated: 'Dv',
	under_construction: 'UC',
	missing: 'Mi',
	no_data: 'No',
}

export const SRClassificationStatusChip = ({status}: {status: string | undefined | null}) => {
	return (
		<Box flex={false}>
			{!status ? (
				'-'
			) : (
				<SRChip
					text={ABBREVIATIONS[status as keyof typeof ABBREVIATIONS]}
					title={status}
					background={rgbaObjToCssColor(COLOR_BY_STATUS[status as keyof typeof COLOR_BY_STATUS])}
				/>
			)}
		</Box>
	)
}
