import {Filter, PageParams, useData} from '../../../../hooks/useData'
import {
	ReclassificationTaskEntity,
	ReclassificationTaskEntityWithStageTasksWithWorkPackage,
} from '../../entities/reclassification-task'

export function useCurrentUserReclassificationTasks(
	pagination: PageParams = {page: 0, perPage: 100},
	filter: Filter<ReclassificationTaskEntity> = {},
	options: {enabled?: boolean} = {},
) {
	return useData<ReclassificationTaskEntityWithStageTasksWithWorkPackage>(
		'reclassification/reclassification-tasks/assigned-to-current-user',
		pagination,
		filter,
		{ascending: false, field: 'createdDate'},
		options,
	)
}
