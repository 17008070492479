import {UserProject} from '../../features/User/entities/userProject'
import {
	ReclassificationWorkPackageStageTaskEntity,
	ReclassificationWorkPackageStageTaskEntityWithWorkPackage,
} from '../../features/Reclassification/entities/reclassification-work-package-stage-task'
import {ReclassificationActions} from '../../features/Reclassification/api/reclassificationAnnotations'
import {useViewer} from '../../features/Viewer/hooks/useViewer'
import React, {useEffect, useMemo} from 'react'
import _ from 'lodash'
import {useReclassificationTaskUsers} from '../../features/Reclassification/hooks/reclassificationTask/useReclassificationTaskUsers'
import {useRoutes} from '../../hooks/useRoutes'
import {useHistory} from 'react-router-dom'
import {useQualityControlReviewTable} from '../../features/Reclassification/components/Table/QualityControlReviewTable/useQualityControlReviewTable'
import Papa from 'papaparse'
import {format} from 'date-fns'
import {saveAs} from 'file-saver'
import {useNotifications} from '../../features/Notifications/hooks/useNotifications'
import {useHeatmapExtension} from '../../features/ViewerAssets/Heatmaps/useHeatmapExtension'
import {Container} from '../../components/Container/Container'
import {Box, Grid} from 'grommet'
import {ReviewControlsContainer} from '../../features/Reclassification/components/ReviewControlsContainer'
import {ElementSections} from '../../features/ViewerAssets/SectionsViewer/ElementSections'
import {LockedViewsContainer} from './LockedViewsContainer'
import {useDrawSectionPlaneIntersection} from '../../features/Viewer/hooks/UseDrawSectionPlaneIntersection'
import {ReclassificationTaskEntity} from '../../features/Reclassification/entities/reclassification-task'
import {useIsolateViewerElements} from '../../features/Viewer/hooks/UseIsolateViewerElements'
import {ReclassificationDTO} from '../../features/Reclassification/entities/reclassification-annotation'
import {useClassificationByIdForStageTask} from '../../features/Reclassification/hooks/stageTask/useReclassificationElements'

export function StageTaskReview(props: {
	project: UserProject
	reclassification: ReclassificationTaskEntity
	stageTask: ReclassificationWorkPackageStageTaskEntityWithWorkPackage
	reclassificationElements: ReclassificationDTO[]
	actions: {
		reclassification: ReclassificationActions
		stageTask: {
			startStageTask: ({
				stageTaskId,
			}: {
				stageTaskId: string
			}) => Promise<ReclassificationWorkPackageStageTaskEntity | undefined>
			markStageTaskAsCompleted: ({
				stageTaskId,
			}: {
				stageTaskId: string
			}) => Promise<ReclassificationWorkPackageStageTaskEntity | undefined>
		}
	}
}) {
	const {
		status,
		actions: {isolateElements, colorizeModel},
	} = useViewer()
	const [selectedClassificationId, setSelectedClassificationId] = React.useState<string | undefined>(undefined)
	const elementsByClassificationId = _.keyBy(props.reclassificationElements, el => el.classification._id)
	const selectedElement = selectedClassificationId ? elementsByClassificationId[selectedClassificationId] : undefined

	const {users} = useReclassificationTaskUsers(props.project._id, props.stageTask.reclassification)

	const routes = useRoutes()
	const history = useHistory()

	const tableInstance = useQualityControlReviewTable(props.reclassificationElements, users || [])

	const onExportCSV = () => {
		const newData = tableInstance.rows
		const header = Object.keys(newData[0].original)
		const body = newData.map(row => {
			return Object.values(row.original).map(field => {
				return field
			})
		})

		let tableCsv = Papa.unparse({data: body, fields: header})
		if (tableCsv == null) return

		const blob = new Blob([tableCsv], {type: 'text/plain'})

		const downloadDateAndTime = format(new Date(), 'yyyyMMdd-HHmm')
		saveAs(blob, `reclassification-export-${downloadDateAndTime}.csv`, {autoBom: true})
	}

	useEffect(() => {
		if (status === 'model_loaded') {
			colorizeModel(
				props.reclassificationElements.map(cl => ({
					dbId: cl.classification.forgeObjectId,
					status: cl.previousValue.classification || 'default',
				})),
				selectedElement?.classification?.forgeObjectId,
			)
		}
	}, [colorizeModel, props.reclassificationElements, selectedElement, status])
	useDrawSectionPlaneIntersection(selectedElement?.classification.forgeObjectId)

	const {pushNotification} = useNotifications()

	const {data: classification} = useClassificationByIdForStageTask(
		props.project._id,
		props.stageTask._id,
		selectedElement?.classification?._id,
	)
	useHeatmapExtension(props.project, classification)

	useIsolateViewerElements(
		useMemo(() => props.reclassificationElements.map(element => element.classification.forgeObjectId), [
			props.reclassificationElements,
		]),
	)

	return !users ? null : (
		<Container pad={{top: 'medium'}} fill={true} background={'white'}>
			<Grid
				rows={['auto', '420px']}
				columns={['100%']}
				fill={true}
				areas={[
					{name: 'top', start: [0, 0], end: [0, 0]},
					{name: 'bottom', start: [0, 1], end: [0, 1]},
				]}
			>
				<Box pad="xsmall" gridArea={'top'}>
					<LockedViewsContainer
						classification={classification || null}
						project={props.project}
						reclassification={props.reclassification}
					/>
				</Box>
				<Box gridArea={'bottom'}>
					<Grid
						rows={['100%']}
						fill={true}
						columns={['1/2', '1/2']}
						areas={[
							{name: 'left', start: [0, 0], end: [0, 0]},
							{name: 'right', start: [1, 0], end: [1, 0]},
						]}
					>
						<Box gridArea={'left'} pad={'xxsmall'}>
							{classification ? <ElementSections classification={classification} project={props.project} /> : null}
						</Box>
						<Box gridArea={'right'} pad={'xxsmall'}>
							<ReviewControlsContainer
								canFinish={true}
								onFinish={() =>
									props.actions.stageTask
										.markStageTaskAsCompleted({stageTaskId: props.stageTask._id})
										.then(() => {
											history.push(routes.myReclassifications.linkTo({}))
										})
										.catch(error => {
											pushNotification(error.message, 'error')
										})
								}
								reviewedElementsCount={props.reclassificationElements.filter(el => el.annotation !== null).length}
								totalElementsCount={props.reclassificationElements.length}
								selectedElement={selectedElement || null}
								reviewActions={{
									approve: props.actions.reclassification.approve,
									reclassify: props.actions.reclassification.reclassify,
								}}
								users={users}
								tableInstance={tableInstance}
								onSelect={classificationId => setSelectedClassificationId(classificationId)}
								onReview={classificationId => setSelectedClassificationId(classificationId)}
								onIsolateFiltered={() =>
									isolateElements(
										props.reclassificationElements.map(({classification}) => classification.forgeObjectId),
									)
								}
								onExport={onExportCSV}
								projectId={props.project._id}
							/>
						</Box>
					</Grid>
				</Box>
			</Grid>
		</Container>
	)
}
