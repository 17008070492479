import {useNotifications} from '../../features/Notifications/hooks/useNotifications'
import {Link, Route, Switch, useRouteMatch} from 'react-router-dom'
import React from 'react'
import {Box, Form, Heading, Text} from 'grommet'
import {FormClose, StatusGood, UserAdd} from 'grommet-icons'
import {User} from '../../features/User/entities/user'
import {SR_COLOR_LIGHT_1, SRCard, SRIconButton, SRPrimaryButton, SRTextInput} from 'sr-react-commons'

export function UserManagement(props: {
	onInviteUser: (newUserPayload: {email: string; firstName: string; lastName: string}) => Promise<User>
}) {
	const {pushNotification} = useNotifications()

	let {path, url} = useRouteMatch()
	const [value, setValue] = React.useState<{newUserFirstName: string; newUserLastName: string; newUserEmail: string}>({
		newUserFirstName: '',
		newUserLastName: '',
		newUserEmail: '',
	})
	const [status, setStatus] = React.useState<'idle' | 'success' | 'error'>('idle')
	return (
		<Box>
			<Switch>
				<Route path={`${path}/create`}>
					<SRCard pad={'small'} background={SR_COLOR_LIGHT_1}>
						<Box direction={'row'} flex justify={'between'}>
							<Heading level={'4'}>Add new user</Heading>
							<Link to={url}>
								<SRIconButton title={'Cancel'} icon={<FormClose />} />
							</Link>
						</Box>
						<Form
							value={value}
							onChange={(nextValue: any) => setValue(nextValue)}
							onSubmit={() => {
								props
									.onInviteUser({
										firstName: value.newUserFirstName,
										lastName: value.newUserLastName,
										email: value.newUserEmail,
									})
									.then(() => {
										setStatus('success')
										setValue({
											newUserFirstName: '',
											newUserLastName: '',
											newUserEmail: '',
										})
										setTimeout(() => {
											setStatus('idle')
										}, 5000)
									})
									.catch((errorMessage: string) => {
										pushNotification(errorMessage, 'error')
										setStatus('error')
									})
							}}
						>
							<Box gap={'medium'}>
								<Box gap={'medium'} direction={'row'} justify={'center'} align={'center'}>
									<SRTextInput
										name={'newUserFirstName'}
										id={'newUserFirstName'}
										autoComplete={'off'}
										placeholder={'First name'}
										required
									/>
									<SRTextInput
										name={'newUserLastName'}
										id={'newUserLastName'}
										autoComplete={'off'}
										placeholder={'Last name'}
										required
									/>
									<SRTextInput
										name={'newUserEmail'}
										id={'newUserEmail'}
										autoComplete={'off'}
										placeholder={'Email'}
										required
									/>
									<SRPrimaryButton alignSelf={'end'} label={'Invite user'} primary type={'submit'} />
								</Box>
								{status === 'success' && (
									<SRCard pad={'small'} direction={'row'} gap={'small'} background={'status-ok'}>
										<StatusGood color={'dark-1'} />
										<Text>User was added successfully.</Text>
									</SRCard>
								)}
							</Box>
						</Form>
					</SRCard>
				</Route>
				<Route path={path}>
					<Box alignSelf={'start'}>
						<Link to={url + '/create'}>
							<SRPrimaryButton alignSelf={'start'} primary label={'Add user'} icon={<UserAdd />} />
						</Link>
					</Box>
				</Route>
			</Switch>
		</Box>
	)
}
