import {PageTitle} from '../../components/TopNavigationBar/PageTitle'
import {Separator} from '../../components/TopNavigationBar/Separator'
import {BreadcrumbItem} from '../../components/TopNavigationBar/BreadcrumbItem'
import React from 'react'
import {Page} from '../../components/Page/Page'
import {PageHeader} from '../../components/Page/PageHeader'
import {Box, Heading, Text} from 'grommet'
import {User} from '../../features/User/entities/user'
import {useParams} from 'react-router-dom'
import {useCompany} from '../../features/User/hooks/useCompany'
import {Spinner} from '../../components/Spinner/Spinner'
import {UserManagement} from './UserManagement'
import {CompanyUsers} from './CompanyUsers'
import {useCompanyUserMutations} from '../../features/User/hooks/useCompanyUserMutations'
import {useCompanyUsers} from '../../features/User/hooks/useCompanyUsers'
import {usePaginationFilterSort} from '../../hooks/usePaginationFilterSort'
import slugify from 'slugify'
import {SR_COLOR_LIGHT_1, SRCard, SREmptyView} from 'sr-react-commons'

export type ManagedUsersPageRouteParams = {
	companyId: string
}

export function ManagedUsersPageTitle() {
	return (
		<PageTitle>
			<Separator />
			<BreadcrumbItem>My managed users</BreadcrumbItem>
		</PageTitle>
	)
}

export function ManagedUsersPage() {
	const {companyId} = useParams<ManagedUsersPageRouteParams>()
	const {data: company, status, error} = useCompany(companyId)
	const {addUserMutation} = useCompanyUserMutations(companyId)
	const {disableUserMutation} = useCompanyUserMutations(companyId)

	const {
		state: {sorting, filter, pagination},
	} = usePaginationFilterSort<User>()
	const {data: users} = useCompanyUsers(companyId, {page: pagination.page, perPage: 10000}, filter, sorting)
	if (status === 'loading') {
		return (
			<Page>
				<Spinner />
			</Page>
		)
	}
	const addUser = (body: {email: string; firstName: string; lastName: string}): Promise<User> => {
		return new Promise((resolve, reject) => {
			addUserMutation({...body, username: slugify(`${body.firstName} ${body.lastName}`, {lower: true})})
				.then(user => resolve(user!))
				.catch(error => reject(error.message))
		})
	}

	const disableUser = (body: {userId: string}): Promise<User> => {
		return new Promise((resolve, reject) => {
			disableUserMutation(body)
				.then(user => resolve(user!))
				.catch(error => reject(error.message))
		})
	}

	return status === 'success' || status === 'error' ? (
		<Page>
			<Box gap={'small'} fill={'vertical'} width={'xlarge'}>
				{company && (
					<Box gap={'small'}>
						<PageHeader>
							<Heading level={'2'}>Manage users for {company.name}</Heading>
						</PageHeader>
						<Box gap={'small'}>
							<UserManagement onInviteUser={addUser} />
							{users?.count ? (
								<Box flex fill>
									<SRCard background={SR_COLOR_LIGHT_1} height={'100%'}>
										<CompanyUsers userPagination={users} onDisableUser={disableUser} />
									</SRCard>
								</Box>
							) : (
								<Box flex fill>
									<SREmptyView
										title={'There are not users for this company'}
										message={'Please add new users to start working on it.'}
									/>
								</Box>
							)}
						</Box>
					</Box>
				)}
				{error && <Text>You are not managing this company.</Text>}
			</Box>
		</Page>
	) : null
}
