import {useCurrentUserReclassificationTasks} from './useCurrentUserReclassificationTasks'

export function useMyReclassificationTask(reclassificationId?: string) {
	const {data: myReclassificationTasks} = useCurrentUserReclassificationTasks(
		{page: 0, perPage: 1},
		{_id: reclassificationId},
		{enabled: reclassificationId !== undefined},
	)
	return myReclassificationTasks?.result?.[0]
}
