import React from 'react'
import {Box, Image, Text} from 'grommet'
import {useCurrentUser} from '../../features/User/hooks/useCurrentUser'
import {Route, Switch} from 'react-router-dom'
import {useRoutes} from '../../hooks/useRoutes'
import {TopNavigationLink} from './TopNavigationLink'
import {isAdminOnAnyCompany} from '../../features/User/entities/user'
import {SRSecondaryButton} from 'sr-react-commons'

export function TopNavigationBar() {
	const routes = useRoutes()
	const {logout, user, authDetails} = useCurrentUser()
	return (
		<Switch>
			{Object.values(routes).map(route => (
				<Route key={route.path} exact={route.exact} path={route.path}>
					<Box
						fill
						background={'light-3'}
						border={'bottom'}
						direction={'row'}
						align={'center'}
						justify={'between'}
						pad={{horizontal: 'small', vertical: 'xsmall'}}
					>
						<Box width={'65%'}>
							<Box direction={'row'} gap={'small'} align={'center'}>
								<Image src={'/images/logo-horizontal-small.svg'} />
								<TopNavigationLink to={routes.home.linkTo({})}>Reclassification</TopNavigationLink>
								{route.renderTopNavigationComponent()}
							</Box>
						</Box>
						<Box width={'35%'} align={'center'} justify={'end'} direction={'row'} gap={'small'}>
							{authDetails && isAdminOnAnyCompany(authDetails) && (
								<TopNavigationLink to={routes.myCompanies.linkTo({})} isSelected={routes.myCompanies === route}>
									Manage companies
								</TopNavigationLink>
							)}
							<TopNavigationLink to={routes.home.linkTo({})} isSelected={routes.home === route}>
								My work
							</TopNavigationLink>
							<Text title={user.attributes.email} truncate>
								{user.attributes.email}
							</Text>
							<SRSecondaryButton size="small" label="Logout" onClick={() => logout()} />
						</Box>
					</Box>
				</Route>
			))}
		</Switch>
	)
}
