import {useMutation} from 'react-query'
import {ReclassificationTaskEntity} from '../../../entities/reclassification-task'
import {AxiosError} from 'axios'
import {postUndoFinishFinalReview} from '../../../api/reclassificationTasks'

export function useUndoFinishFinalReviewMutation(onSuccess: () => any) {
	return useMutation<ReclassificationTaskEntity, AxiosError, {projectId: string; reclassificationTaskId: string}>(
		({projectId, reclassificationTaskId}) => postUndoFinishFinalReview(projectId, reclassificationTaskId),
		{
			onSuccess,
		},
	)
}
