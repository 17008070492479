import React, {ReactNode} from 'react'
import {Box, BoxProps, Text} from 'grommet'

export function BreadcrumbItem({
	truncate = true,
	width = {max: 'small'},
	children,
}: {
	truncate?: boolean
	width?: BoxProps['width']
	children: ReactNode
}) {
	return (
		<Box width={width} pad={{vertical: 'xxsmall', horizontal: 'small'}}>
			<Text truncate={truncate}>{children}</Text>
		</Box>
	)
}
