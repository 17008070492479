import {Page} from '../../components/Page/Page'
import {Container} from '../../components/Container/Container'
import React from 'react'
import {Box, Tab, Table, TableBody, TableCell, TableHeader, TableRow, Tabs, Text} from 'grommet'
import {PageTitle} from '../../components/TopNavigationBar/PageTitle'
import {Separator} from '../../components/TopNavigationBar/Separator'
import {BreadcrumbItem} from '../../components/TopNavigationBar/BreadcrumbItem'
import {Link, useHistory, useParams} from 'react-router-dom'
import {Spinner} from '../../components/Spinner/Spinner'
import {useReclassificationTask} from '../../features/Reclassification/hooks/reclassificationTask/useReclassificationTask'
import {ReclassificationOverviewPageHeader} from '../ReclassificationOverview/ReclassificationOverviewPage'
import {formatDistance, formatISO9075} from 'date-fns'
import {ReclassificationTaskEntity} from '../../features/Reclassification/entities/reclassification-task'
import {useReclassificationTaskUsers} from '../../features/Reclassification/hooks/reclassificationTask/useReclassificationTaskUsers'
import {getFullName} from '../../features/User/entities/user'
import {useRoutes} from '../../hooks/useRoutes'
import {downloadReclassificationExport} from '../../features/Reclassification/api/reclassificationTasks'
import {useNotifications} from '../../features/Notifications/hooks/useNotifications'
import FileSaver from 'file-saver'
import {AxiosError} from 'axios'
import slugify from 'slugify'
import {useReclassificationUserReport} from '../../features/Reclassification/hooks/useReclassificationUserReport'
import {useReclassificationReport} from '../../features/Reclassification/hooks/useReclassificationReport'
import {useCurrentProject} from '../../hooks/useCurrentProject'
import {SRSecondaryButton} from 'sr-react-commons'

export type ReclassificationReportRouteParams = {
	clientSlug: string
	projectSlug: string
	reclassificationId: string
	subRoute: 'overall'
}

export type ReclassificationUserReportRouteParams = {
	clientSlug: string
	projectSlug: string
	reclassificationId: string
	subRoute: 'users'
	selectedUser: string
}

type RouteParams = ReclassificationReportRouteParams | ReclassificationUserReportRouteParams

export function ReclassificationReportPageTitle() {
	const {reclassificationId} = useParams<RouteParams>()
	const project = useCurrentProject()
	const {data: reclassification} = useReclassificationTask(project?._id, reclassificationId)

	return project && reclassification ? (
		<PageTitle>
			<Separator />
			<BreadcrumbItem>{reclassification.name}</BreadcrumbItem>
			<Separator />
			<BreadcrumbItem truncate={false}>Overview</BreadcrumbItem>
		</PageTitle>
	) : null
}

export function ReportField(props: {label: string; value: string | number}) {
	return (
		<TableRow>
			<TableCell>
				<Text>{props.label}</Text>
			</TableCell>
			<TableCell>
				<Text textAlign={'end'}>{props.value}</Text>
			</TableCell>
		</TableRow>
	)
}

export function SeparatorRow(props: {label: string}) {
	return (
		<TableRow>
			<TableCell border={'bottom'} colSpan={2}>
				<Text weight={'bold'}>{props.label}</Text>
			</TableCell>
		</TableRow>
	)
}

function ReclassificationReportTable({
	reclassificationTask,
	projectId,
}: {
	reclassificationTask: ReclassificationTaskEntity
	projectId: string
}) {
	const reclassification = reclassificationTask
	const {data: report, status} = useReclassificationReport(projectId, reclassificationTask._id)

	if (status === 'loading') {
		return <Spinner />
	}

	if (!report) {
		return null
	}

	return (
		<Box width={'500px'}>
			<Table>
				<TableBody>
					<SeparatorRow label={'Reclassification'} />
					<ReportField label={'Elements reclassified'} value={reclassification.classifications.length} />
					<ReportField label={'Requested expert review'} value={report.expertReviewCount} />
					<ReportField
						label={'First element reclassified'}
						value={report.firstAnnotationDate ? formatISO9075(new Date(report.firstAnnotationDate)) : '-'}
					/>
					<ReportField
						label={'Last element reclassified'}
						value={report.lastAnnotationDate ? formatISO9075(new Date(report.lastAnnotationDate)) : '-'}
					/>
					<ReportField
						label={'Duration'}
						value={
							report.firstAnnotationDate && report.lastAnnotationDate
								? formatDistance(new Date(report.firstAnnotationDate), new Date(report.lastAnnotationDate))
								: '-'
						}
					/>
					<SeparatorRow label={'Review (including expert review requested)'} />
					<ReportField label={'Elements reviewed'} value={report.reviewedCount} />
					<ReportField label={'Reviewed elements with mistakes'} value={report.errorCount} />
					<ReportField label={'Accuracy'} value={report.accuracy.toFixed(2)} />
					<SeparatorRow label={'Review (excluding expert review requested)'} />
					<ReportField label={'Elements reviewed'} value={report.reviewsWithoutExpertReviewsCount} />
					<ReportField label={'Reviewed elements with mistakes'} value={report.errorsWithoutExpertReviewsCount} />
					<ReportField label={'Accuracy'} value={report.accuracyWithoutExpertReviews.toFixed(2)} />
				</TableBody>
			</Table>
		</Box>
	)
}

function ReclassificationUserReports({
	reclassificationTask,
	projectId,
}: {
	reclassificationTask: ReclassificationTaskEntity
	projectId: string
}) {
	const {selectedUser} = useParams<ReclassificationUserReportRouteParams>()
	const selectedUserId = selectedUser === 'all' ? undefined : selectedUser
	const reclassification = reclassificationTask
	const [clientSlug, projectSlug] = reclassification.tenantId.split('|')
	const {users} = useReclassificationTaskUsers(projectId, reclassificationTask._id)
	const {data: report, status} = useReclassificationUserReport(projectId, reclassificationTask._id, selectedUserId)

	const routes = useRoutes()

	if (!users) {
		return null
	}

	return (
		<Box direction={'row'} gap={'medium'}>
			<Box>
				<Table>
					<TableHeader>
						<TableRow>
							<TableCell>Name</TableCell>
						</TableRow>
					</TableHeader>
					<TableBody>
						{users
							.filter(user => reclassification.stages[0].assignedUsers.includes(user._id))
							.map(user => (
								<TableRow style={{background: user._id === selectedUserId ? 'lightgray' : undefined}} key={user._id}>
									<TableCell>{getFullName(user)}</TableCell>
									<TableCell>
										{user._id !== selectedUserId && (
											<Link
												to={routes.reclassificationUserReport.linkTo({
													clientSlug,
													projectSlug,
													reclassificationId: reclassification._id,
													selectedUser: user._id,
													subRoute: 'users',
												})}
											>
												<SRSecondaryButton size="small" label={'View report'} />
											</Link>
										)}
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</Box>
			<Box width={'500px'}>
				{status === 'loading' && <Spinner />}
				{report ? (
					<Table alignSelf={'start'}>
						<TableBody>
							<SeparatorRow label={'Reclassification'} />
							<ReportField label={'Elements reclassified'} value={report.reclassifiedCount} />
							<ReportField label={'Requested expert review'} value={report.expertReviewCount} />
							<ReportField
								label={'First element reclassified'}
								value={report.firstAnnotationDate ? formatISO9075(new Date(report.firstAnnotationDate)) : '-'}
							/>
							<ReportField
								label={'Last element reclassified'}
								value={report.lastAnnotationDate ? formatISO9075(new Date(report.lastAnnotationDate)) : '-'}
							/>
							<ReportField
								label={'Duration'}
								value={
									report.firstAnnotationDate && report.lastAnnotationDate
										? formatDistance(new Date(report.firstAnnotationDate), new Date(report.lastAnnotationDate))
										: '-'
								}
							/>
							<ReportField
								label={'Average time per element'}
								value={report.timePerAnnotation ? `${Math.round(report.timePerAnnotation / 1000)} seconds` : '-'}
							/>
							<SeparatorRow label={'Review (including expert review requested)'} />
							<ReportField label={'Elements reviewed'} value={report.reviewedCount} />
							<ReportField label={'Reviewed elements with mistakes'} value={report.errorCount} />
							<ReportField label={'Accuracy'} value={report.accuracy.toFixed(2)} />
							<SeparatorRow label={'Review (excluding expert review requested)'} />
							<ReportField label={'Elements reviewed'} value={report.reviewsWithoutExpertReviewsCount} />
							<ReportField label={'Reviewed elements with mistakes'} value={report.errorsWithoutExpertReviewsCount} />
							<ReportField label={'Accuracy'} value={report.accuracyWithoutExpertReviews.toFixed(2)} />
						</TableBody>
					</Table>
				) : null}
			</Box>
		</Box>
	)
}

function ReclassificationExport({
	projectId,
	reclassificationTaskId,
	fileName,
}: {
	reclassificationTaskId: string
	projectId: string
	fileName: string
}) {
	const [loadingStatus, setLoadingStatus] = React.useState<'idle' | 'loading'>('idle')

	const {pushNotification} = useNotifications()

	const onClick = () => {
		setLoadingStatus('loading')
		downloadReclassificationExport(projectId, reclassificationTaskId)
			.then(response => {
				FileSaver.saveAs(response.data, `${fileName}.csv`)
				setLoadingStatus('idle')
			})
			.catch((error: AxiosError) => {
				if (error.response) {
					pushNotification(error.response.data.message, 'error')
				} else {
					pushNotification(error.message, 'error')
				}
			})
	}

	return (
		<SRSecondaryButton
			icon={loadingStatus === 'loading' ? <Spinner size={'14px'} /> : undefined}
			reverse
			alignSelf={'start'}
			label={'Download CSV export'}
			disabled={loadingStatus !== 'idle'}
			onClick={onClick}
		/>
	)
}

export function ReclassificationReportPage() {
	const {clientSlug, projectSlug, reclassificationId, subRoute} = useParams<RouteParams>()
	const project = useCurrentProject()
	const {data: reclassification} = useReclassificationTask(project?._id, reclassificationId)
	const history = useHistory()
	const routes = useRoutes()

	if (!(project && reclassification)) {
		return (
			<Page>
				<Spinner />
			</Page>
		)
	}

	return (
		<Page>
			<Container fill={'vertical'} gap={'small'} overflow={'auto'}>
				<ReclassificationOverviewPageHeader
					project={project}
					reclassification={reclassification}
					heading={'Reclassification Report'}
				/>
				<Box fill={'vertical'}>
					<Tabs
						alignControls={'start'}
						activeIndex={subRoute === 'users' ? 1 : 0}
						onActive={index =>
							history.push(
								index === 1
									? routes.reclassificationUserReport.linkTo({
											clientSlug,
											projectSlug,
											reclassificationId,
											subRoute: 'users',
											selectedUser: 'all',
									  })
									: routes.reclassificationReport.linkTo({
											clientSlug,
											projectSlug,
											reclassificationId,
											subRoute: 'overall',
									  }),
							)
						}
					>
						<Tab title={'Overall'}>
							<Box gap={'small'}>
								<ReclassificationReportTable reclassificationTask={reclassification} projectId={project._id} />
								<ReclassificationExport
									fileName={slugify(`${clientSlug} ${projectSlug} ${reclassification.name} export`, {lower: true})}
									projectId={project._id}
									reclassificationTaskId={reclassificationId}
								/>
							</Box>
						</Tab>
						<Tab title={'Individual user reports'}>
							<ReclassificationUserReports reclassificationTask={reclassification} projectId={project._id} />
						</Tab>
					</Tabs>
				</Box>
			</Container>
		</Page>
	)
}
