import React, {ReactNode} from 'react'
import {Text} from 'grommet'
import {Link} from 'react-router-dom'
import {SR_COLOR_NEUTRAL_2, SRButton} from 'sr-react-commons'

export function TopNavigationLink({
	to,
	children,
	isSelected = false,
}: {
	to: string
	children: ReactNode
	isSelected?: boolean
}) {
	return (
		<Link to={to}>
			<SRButton bgColor={SR_COLOR_NEUTRAL_2} size={'small'} isSelected={isSelected}>
				<Text truncate={true}>{children}</Text>
			</SRButton>
		</Link>
	)
}
