import {ReclassificationStageEntity} from '../../features/Reclassification/entities/reclassification-task'
import {
	ReclassificationWorkPackageStageTaskEntity,
	ReclassificationWorkPackageStageTaskEntityWithWorkPackage,
} from '../../features/Reclassification/entities/reclassification-work-package-stage-task'
import {useRoutes} from '../../hooks/useRoutes'
import {Link, useHistory} from 'react-router-dom'
import {Box, Table, TableBody, TableCell, TableHeader, TableRow, Text} from 'grommet'
import React from 'react'
import {
	SR_COLOR_LIGHT_1,
	SR_COLOR_LIGHT_2,
	SRAccordion,
	SRCard,
	SRHeading,
	SRPrimaryButton,
	SRSecondaryButton,
} from 'sr-react-commons'

export function ReclassificationTaskStageCard(props: {
	stage: ReclassificationStageEntity
	stageTasks: ReclassificationWorkPackageStageTaskEntityWithWorkPackage[]
	availableWP: number | undefined
	onAssignStageTask: () => Promise<ReclassificationWorkPackageStageTaskEntity>
}) {
	const [assigning, setAssigning] = React.useState(false)
	const myUnfinishedStageTasks = props.stageTasks.filter(stageTask => stageTask.status !== 'finished')
	const myFinishedStageTasks = props.stageTasks.filter(stageTask => stageTask.status === 'finished')
	const routes = useRoutes()
	const history = useHistory()
	const onStartWork = async () => {
		setAssigning(true)
		const stageTask = await props.onAssignStageTask()
		setAssigning(false)
		if (stageTask !== null) {
			history.push(
				routes.stageTask.linkTo({
					clientSlug: stageTask.tenantId.split('|')[0],
					projectSlug: stageTask.tenantId.split('|')[1],
					reclassificationId: stageTask.reclassification,
					stageTaskId: stageTask._id,
				}),
			)
		}
	}
	const hasAssignedStageTasksInStage = myUnfinishedStageTasks.length > 0
	return (
		<SRCard direction={'row'} background={SR_COLOR_LIGHT_1}>
			<Box gap={'small'} flex={'grow'}>
				<Box direction={'row'} justify={'between'}>
					<SRHeading level={5} fill>
						{props.stage.name}
					</SRHeading>
					{hasAssignedStageTasksInStage ? (
						<Link
							to={routes.stageTask.linkTo({
								clientSlug: myUnfinishedStageTasks[0].tenantId.split('|')[0],
								projectSlug: myUnfinishedStageTasks[0].tenantId.split('|')[1],
								reclassificationId: myUnfinishedStageTasks[0].reclassification,
								stageTaskId: myUnfinishedStageTasks[0]._id,
							})}
						>
							<SRPrimaryButton
								label={props.stage.type === 'reclassification' ? 'Continue reclassification' : 'Continue review'}
							/>
						</Link>
					) : (
						<SRPrimaryButton
							onClick={onStartWork}
							disabled={assigning}
							label={props.stage.type === 'reclassification' ? 'Start reclassification' : 'Start review'}
						/>
					)}
				</Box>
				<Box fill={'horizontal'} background={SR_COLOR_LIGHT_2} pad={{horizontal: 'xsmall'}} round="xsmall">
					<SRAccordion
						option={
							<Text weight={'bold'}>
								{props.availableWP} Available Work Packages - {myFinishedStageTasks.length} completed
							</Text>
						}
						optionContent={
							<Box flex={false}>
								{hasAssignedStageTasksInStage ? (
									<Table>
										<StageTasksTableHeader />
										<TableBody>
											{myUnfinishedStageTasks.map(stageTask => (
												<StageTasksTableRow key={stageTask._id} stageTask={stageTask} />
											))}
										</TableBody>
									</Table>
								) : (
									<Box pad={{vertical: 'small'}} align={'center'} gap="small">
										<Text>You do not have any uncompleted tasks assigned for this stage.</Text>
									</Box>
								)}
							</Box>
						}
					/>
				</Box>
			</Box>
		</SRCard>
	)
}

function StageTasksTableHeader() {
	return (
		<TableHeader>
			<TableRow>
				<TableCell width={'medium'}>Work Package</TableCell>
				<TableCell>Elements</TableCell>
				<TableCell>Status</TableCell>
				<TableCell>Actions</TableCell>
			</TableRow>
		</TableHeader>
	)
}

function StageTasksTableRow(props: {stageTask: ReclassificationWorkPackageStageTaskEntityWithWorkPackage}) {
	const routes = useRoutes()
	return (
		<TableRow>
			<TableCell>{props.stageTask.workPackage.description}</TableCell>
			<TableCell>
				{props.stageTask.classificationIds?.length || props.stageTask.workPackage.classificationIds.length}
			</TableCell>
			<TableCell>{props.stageTask.status}</TableCell>
			<TableCell>
				<Link
					to={routes.stageTask.linkTo({
						clientSlug: props.stageTask.tenantId.split('|')[0],
						projectSlug: props.stageTask.tenantId.split('|')[1],
						reclassificationId: props.stageTask.reclassification,
						stageTaskId: props.stageTask._id,
					})}
				>
					<SRSecondaryButton
						size={'small'}
						label={props.stageTask.stageType === 'reclassification' ? 'Reclassify' : 'Review'}
					/>
				</Link>
			</TableCell>
		</TableRow>
	)
}
