import {Page} from '../../components/Page/Page'
import React from 'react'
import {Container} from '../../components/Container/Container'
import {Box, Heading, Select, Table, TableBody, TableCell, TableHeader, TableRow, Text, TextInput} from 'grommet'
import {useParams} from 'react-router-dom'
import {Spinner} from '../../components/Spinner/Spinner'
import {ReclassificationWorkPackageStatus} from '../../features/Reclassification/entities/reclassificationWorkPackage'
import {getFullName, User} from '../../features/User/entities/user'
import {PageTitle} from '../../components/TopNavigationBar/PageTitle'
import {Separator} from '../../components/TopNavigationBar/Separator'
import {BreadcrumbItem} from '../../components/TopNavigationBar/BreadcrumbItem'
import {Edit} from 'grommet-icons'
import {LabelBadge} from '../../components/LabelBadge/LabelBadge'
import {useCurrentUser} from '../../features/User/hooks/useCurrentUser'
import {useReclassificationTaskWorkPackageTasks} from '../../features/Reclassification/hooks/workPackageTask/useReclassificationTaskWorkPackageTasks'
import {useReclassificationTaskUsers} from '../../features/Reclassification/hooks/reclassificationTask/useReclassificationTaskUsers'
import {useReclassificationTask} from '../../features/Reclassification/hooks/reclassificationTask/useReclassificationTask'
import {
	ReclassificationWorkPackageTaskEntityWithStageTasks,
	ReclassificationWorkPackageTaskStatus,
} from '../../features/Reclassification/entities/reclassification-work-package-task'
import {
	canManageStage,
	ReclassificationStageEntity,
	ReclassificationTaskEntity,
} from '../../features/Reclassification/entities/reclassification-task'
import {ReclassificationWorkPackageStageTaskEntity} from '../../features/Reclassification/entities/reclassification-work-package-stage-task'
import {
	ReclassificationOverviewPageHeader,
	ReclassificationOverviewPageParams,
} from '../ReclassificationOverview/ReclassificationOverviewPage'
import {useStageTaskMutations} from '../../features/Reclassification/hooks/stageTask/useStageTaskMutations'
import {useCurrentUserHasPermissionOnResource} from '../../features/User/hooks/useCurrentUserHasPermission'
import {PermissionsEnum} from '../../features/User/entities/auth'
import {useCurrentProject} from '../../hooks/useCurrentProject'
import {SRSecondaryButton} from 'sr-react-commons'

export type ReclassificationWorkPackagesPageRouteParams = {
	clientSlug: string
	projectSlug: string
	reclassificationId: string
}

const workPackageStatusColors: Record<ReclassificationWorkPackageTaskStatus, string> = {
	'in progress': 'rgb(222, 235, 255)',
	'not started': 'rgb(223, 225, 230)',
	finished: 'rgb(227, 252, 239)',
}

export function ReclassificationWorkPackagesPageTitle() {
	const {reclassificationId} = useParams<ReclassificationOverviewPageParams>()
	const project = useCurrentProject()
	const {data: reclassification} = useReclassificationTask(project?._id, reclassificationId)

	return project && reclassification ? (
		<PageTitle>
			<Separator />
			<BreadcrumbItem>{reclassification.name}</BreadcrumbItem>
			<Separator />
			<BreadcrumbItem>Work Packages</BreadcrumbItem>
		</PageTitle>
	) : null
}

interface WorkPackageFilters {
	status: ReclassificationWorkPackageTaskStatus | ''
	assignedUser: string
	description: string
	currentStage: string
}

interface WorkPackagesTableState {
	filters: WorkPackageFilters
}

interface SetFilterAction {
	type: 'SET_FILTER'
	payload: {filterName: keyof WorkPackageFilters; value: string}
}

interface ClearFiltersAction {
	type: 'CLEAR_FILTERS'
}

type ReducerActions = SetFilterAction | ClearFiltersAction

function WorkPackagesTableReducer(state: WorkPackagesTableState, action: ReducerActions): WorkPackagesTableState {
	switch (action.type) {
		case 'SET_FILTER': {
			return {
				filters: {...state.filters, [action.payload.filterName]: action.payload.value},
			}
		}
		case 'CLEAR_FILTERS': {
			return {
				filters: {
					status: '',
					assignedUser: '',
					description: '',
					currentStage: '',
				},
			}
		}
	}
}

export function ReclassificationWorkPackagesPage() {
	const {userDetails, authDetails} = useCurrentUser()

	const [{filters}, dispatch] = React.useReducer(WorkPackagesTableReducer, {
		filters: {
			status: '',
			assignedUser: '',
			description: '',
			currentStage: '',
		},
	})

	const setFilters = (filterName: keyof WorkPackageFilters, value: string) => {
		dispatch({type: 'SET_FILTER', payload: {filterName, value}})
	}

	const {reclassificationId} = useParams<ReclassificationWorkPackagesPageRouteParams>()
	const project = useCurrentProject()
	const {data: reclassification} = useReclassificationTask(project?._id, reclassificationId)
	const {data: workPackagePagination, status, error} = useReclassificationTaskWorkPackageTasks(
		project?._id,
		reclassificationId,
		{
			page: 0,
			// until we implement pagination, just to make sure to get all on one page
			perPage: Number.MAX_SAFE_INTEGER,
		},
		undefined,
		undefined,
		{enabled: reclassification},
	)

	const {users: reclassificationUsers} = useReclassificationTaskUsers(project?._id, reclassificationId)
	const actions = useStageTaskMutations(project?._id)
	const {data: hasSrAdminPermission} = useCurrentUserHasPermissionOnResource(PermissionsEnum.SR_ADMIN, project?._id)

	if (status === 'error') {
		return (
			<Page>
				<Heading level={'2'}>Error loading:</Heading>
				<Text>{error?.response?.data.message || 'Unknown error'}</Text>
			</Page>
		)
	}

	const filteredWorkPackages = workPackagePagination
		? workPackagePagination.result.filter(wp =>
				Object.entries(filters).every(([key, value]) => {
					if (value === '') return true
					switch (key) {
						case 'status':
							return wp.status === value
						case 'description':
							return new RegExp(value.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&'), 'i').test(wp.description)
						case 'assignedUser':
							return value === wp.stageTasks.find(stageTask => stageTask.stage === wp.currentStage)?.assignedUser
						case 'currentStage':
							return value === wp.currentStage
						default:
							throw new Error('Unknown filter property: ' + key)
					}
				}),
		  )
		: undefined

	return (
		<Page>
			<Container width={'xlarge'} gap={'small'} fill={'vertical'} overflow={{vertical: 'auto'}}>
				{reclassificationUsers &&
				userDetails &&
				authDetails &&
				reclassification &&
				project &&
				filteredWorkPackages &&
				hasSrAdminPermission !== undefined ? (
					<>
						<ReclassificationOverviewPageHeader
							project={project}
							reclassification={reclassification}
							heading={'Work Package Tasks'}
						/>
						<Box flex={'grow'} pad={{top: 'small'}} overflow={{vertical: 'auto'}}>
							<Box gap={'small'}>
								<Table>
									<TableHeader>
										<TableRow>
											<TableCell>
												<Box>
													Description
													<Box alignSelf={'start'}>
														<TextInput
															placeholder={'Search by description'}
															value={filters.description}
															onChange={event => {
																event.persist()
																setFilters('description', event.target.value)
															}}
														/>
													</Box>
												</Box>
											</TableCell>
											<TableCell>
												<Box>
													Assigned to
													<AssigneeFilter
														users={reclassificationUsers}
														value={filters.assignedUser}
														onChange={value => {
															setFilters('assignedUser', value)
														}}
													/>
												</Box>
											</TableCell>
											<TableCell>
												<Box>
													Current stage
													<StagesFilter
														stages={reclassification.stages}
														value={filters.currentStage}
														onChange={value => setFilters('currentStage', value)}
													/>
												</Box>
											</TableCell>
											<TableCell>
												<Box>
													Status
													<StatusFilter value={filters.status} onChange={value => setFilters('status', value)} />
												</Box>
											</TableCell>
										</TableRow>
									</TableHeader>
									<TableBody>
										{filteredWorkPackages.map(workPackage => (
											<WorkPackageRow
												reclassificationTask={reclassification}
												currentUser={userDetails}
												onAssignUser={async userId =>
													actions.assignUserToStageTask({
														stageTaskId: workPackage.stageTasks.find(
															stageTask => stageTask.stage === workPackage.currentStage,
														)!._id,
														userId: userId,
													})
												}
												key={workPackage._id}
												users={reclassificationUsers}
												workPackage={workPackage}
												hasSrAdminPermission={hasSrAdminPermission}
											/>
										))}
									</TableBody>
								</Table>
							</Box>
						</Box>
					</>
				) : null}
			</Container>
		</Page>
	)
}

function StagesFilter({
	value,
	onChange,
	stages,
}: {
	stages: ReclassificationStageEntity[]
	value: string
	onChange: (value: string) => void
}) {
	const options = [{value: '', label: 'All'}, ...stages.map(stage => ({value: stage._id, label: stage.name}))]
	return (
		<Select
			alignSelf={'start'}
			size={'small'}
			labelKey={'label'}
			valueKey={'value'}
			options={options}
			value={options.find(option => option.value === value)}
			onChange={event => onChange(event.value.value)}
		/>
	)
}

function StatusFilter({
	value,
	onChange,
}: {
	value: string
	onChange: (value: ReclassificationWorkPackageStatus | '') => void
}) {
	const options = [
		{value: '', label: 'All'},
		{value: 'in progress', label: 'In progress'},
		{value: 'not started', label: 'Not started'},
		{value: 'finished', label: 'Finished'},
	]
	return (
		<Select
			alignSelf={'start'}
			size={'small'}
			labelKey={'label'}
			valueKey={'value'}
			options={options}
			value={options.find(option => option.value === value)}
			onChange={event => onChange(event.value.value)}
		/>
	)
}

function AssigneeFilter({value, onChange, users}: {value: string; onChange: (value: string) => void; users: User[]}) {
	const options = [
		{value: '', label: 'All'},
		{value: 'null', label: 'Unassigned'},
	].concat(
		users.map(user => ({
			value: user._id,
			label: getFullName(user),
		})),
	)
	return (
		<Select
			alignSelf={'start'}
			valueKey="value"
			labelKey="label"
			size={'small'}
			onChange={event => {
				onChange(event.value.value)
			}}
			value={options.find(option => option.value === value)}
			options={options}
		/>
	)
}

function WorkPackageRow({
	workPackage,
	reclassificationTask,
	users,
	currentUser,
	onAssignUser,
	hasSrAdminPermission,
}: {
	currentUser: User
	reclassificationTask: ReclassificationTaskEntity
	workPackage: ReclassificationWorkPackageTaskEntityWithStageTasks
	users: User[]
	onAssignUser: (userId: string | null) => Promise<ReclassificationWorkPackageStageTaskEntity | undefined>
	hasSrAdminPermission: boolean
}) {
	const currentStageTask = workPackage.stageTasks.find(stageTask => workPackage.currentStage === stageTask.stage)!
	const user = currentStageTask.assignedUser
		? users.find(user => currentStageTask.assignedUser === user._id)
		: undefined
	const currentStage = reclassificationTask.stages.find(stage => stage._id === workPackage.currentStage)!
	const canManage = canManageStage(currentUser, currentStage, reclassificationTask, hasSrAdminPermission)
	const onSelect = (userId: string | null) => onAssignUser(userId)

	return (
		<TableRow key={workPackage._id}>
			<TableCell>{workPackage.description}</TableCell>
			<TableCell>
				<AssignUserToWorkPackage
					currentStageTask={currentStageTask}
					onAssignUser={onSelect}
					user={user}
					users={users.filter(user => currentStage.assignedUsers.includes(user._id))}
					disabled={workPackage.status === 'finished' || currentStage.type === 'final review' || !canManage}
				/>
			</TableCell>
			<TableCell>{currentStage.name}</TableCell>
			<TableCell>
				<LabelBadge color={workPackageStatusColors[workPackage.status]} label={workPackage.status} />
			</TableCell>
		</TableRow>
	)
}

function AssignUserToWorkPackage({
	user,
	onAssignUser,
	currentStageTask,
	disabled,
	users,
}: {
	user: User | undefined
	onAssignUser: (userId: string | null) => Promise<ReclassificationWorkPackageStageTaskEntity | undefined>
	currentStageTask: ReclassificationWorkPackageStageTaskEntity
	users: User[]
	disabled: boolean
}) {
	const [userAssignState, setUserAssignState] = React.useState<'idle' | 'assigning'>('idle')
	return (
		<Box direction="row" gap="small">
			{userAssignState === 'idle' ? (
				<Box direction="row" gap="small">
					{user ? (
						<SRSecondaryButton
							size={'small'}
							alignSelf={'start'}
							reverse
							disabled={disabled}
							label={user ? getFullName(user) : currentStageTask.assignedUser}
							icon={<Edit size={'small'} />}
							onClick={() => setUserAssignState('assigning')}
						/>
					) : (
						<SRSecondaryButton
							disabled={disabled}
							size={'small'}
							alignSelf={'start'}
							label="Assign"
							onClick={() => setUserAssignState('assigning')}
						/>
					)}
				</Box>
			) : users ? (
				<Box direction="row" gap="small">
					<Box width={'small'}>
						<Select
							alignSelf={'start'}
							valueKey="value"
							labelKey="label"
							size={'small'}
							onChange={({option}) =>
								onAssignUser(option.value === '' ? null : option.value).then(() => setUserAssignState('idle'))
							}
							value={user ? user._id : ''}
							options={[{value: '', label: '-- Unassigned --'}].concat(
								users.map(user => ({value: user._id, label: getFullName(user)})),
							)}
						/>
					</Box>
					<SRSecondaryButton size={'small'} label={'Cancel'} onClick={() => setUserAssignState('idle')} />
				</Box>
			) : (
				<Spinner />
			)}
		</Box>
	)
}
