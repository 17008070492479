import React, {useEffect} from 'react'
import HeatmapExtension, {
	ExtensionId as HeatmapExtensionId,
} from '../../Viewer/Extensions/Heatmap/Viewing.Extension.Heatmap'
import {ExtensionId as ToolbarExtensionId} from '../../Viewer/Extensions/VerticalToolbar/Viewing.Extension.VerticalToolbar'
import {UserProject} from '../../User/entities/userProject'
import {Classification} from '../../../entities/classification'
import {useViewer} from '../../Viewer/hooks/useViewer'

export function useHeatmapExtension(project: UserProject, classification?: Classification) {
	const extensionRef = React.useRef<HeatmapExtension>()
	const [isLoaded, setIsLoaded] = React.useState(false)

	const {viewer, status} = useViewer()

	React.useEffect(() => {
		if (status === 'model_loaded' && viewer.current?.model) {
			viewer.current!.loadExtension(ToolbarExtensionId, {}).then(() => {
				if (viewer.current?.model) {
					viewer.current!.loadExtension(HeatmapExtensionId, {}).then(extension => {
						extensionRef.current = extension as HeatmapExtension
						setIsLoaded(true)
					})
				}
			})
		}
	}, [status, viewer, setIsLoaded])

	useEffect(() => {
		if (isLoaded && project) {
			extensionRef.current!.setProject(project)
		}
	}, [project, isLoaded])

	useEffect(() => {
		if (classification && isLoaded) {
			extensionRef.current!.setClassifications([classification])
		}
	}, [classification, isLoaded])

	return {isLoaded: isLoaded}
}
