import {TableBody, TableCell, TableRow, Text} from 'grommet'
import React from 'react'
import {ClassificationRedux} from '../../../entities/classification'
import {SmallTable} from '../../../components/SmallTable/SmallTable'

export function ElementDetailTable({classification}: {classification: ClassificationRedux}) {
	return (
		<SmallTable>
			<TableBody>
				<TableRow>
					<TableCell>
						<strong>Global Id</strong>
					</TableCell>
					<TableCell>{classification.externalId}</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>
						<strong>Floor</strong>
					</TableCell>
					<TableCell>{classification.element.mappedFloors.join(', ')}</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>
						<strong>Type</strong>
					</TableCell>
					<TableCell>{classification.element.mappedElementType}</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>
						<strong>Name</strong>
					</TableCell>
					<TableCell>
						<Text size={'small'} wordBreak={'break-all'}>
							{classification.element.name}
						</Text>
					</TableCell>
				</TableRow>
			</TableBody>
		</SmallTable>
	)
}
