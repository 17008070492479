import {Box, Text} from 'grommet'
import {Radial, StatusGood} from 'grommet-icons'
import React, {ReactNode} from 'react'
import {Tip} from 'grommet/components/Tip'
import {formatRelative} from 'date-fns'

export function Step({
	label,
	date,
	status,
	first = false,
	button = null,
}: {
	label: string
	date?: Date
	status: 'completed' | 'current' | 'pending'
	first?: boolean
	button?: ReactNode
}) {
	return (
		<Box style={{position: 'relative', flex: 1}} align={'center'}>
			{!first && (
				<Box
					background={status === 'pending' ? 'light-4' : 'status-ok'}
					height={'5px'}
					style={{
						position: 'absolute',
						top: '36px',
						left: 'calc(-50%  + 5px)',
						width: 'calc(100% - 15px)',
						zIndex: 0,
					}}
				/>
			)}
			{date ? (
				<Tip content={formatRelative(date, new Date())}>
					<Text size={'large'} weight={status === 'current' ? 'bold' : undefined}>
						{label}
					</Text>
				</Tip>
			) : (
				<Text size={'large'} weight={status === 'current' ? 'bold' : undefined}>
					{label}
				</Text>
			)}

			<StepIcon status={status} />
			{button}
		</Box>
	)
}

export function StepIcon({status}: {status: 'completed' | 'current' | 'pending'}) {
	return (
		<Box style={{zIndex: 1}} justify={'center'} height={'20px'}>
			{status === 'completed' ? <StatusGood size={'medium'} color={'status-ok'} /> : <Radial />}
		</Box>
	)
}
