import React from 'react'
import {Box, Tab, Tabs} from 'grommet'
import {Page} from '../../components/Page/Page'
import {useCurrentUser} from '../../features/User/hooks/useCurrentUser'
import {MyManagedReclassifications} from '../MyManagedReclassifications/MyManagedReclassificationsPage'
import {isManager, isReclassifier, isSrAdminAnyResource} from '../../features/User/entities/user'
import {Spinner} from '../../components/Spinner/Spinner'
import {Separator} from '../../components/TopNavigationBar/Separator'
import {PageTitle} from '../../components/TopNavigationBar/PageTitle'
import {BreadcrumbItem} from '../../components/TopNavigationBar/BreadcrumbItem'
import {useRoutes} from '../../hooks/useRoutes'
import {Link} from 'react-router-dom'
import {MyAssignedReclassificationTasks} from '../MyReclassifications/MyReclassificationTasksPage'
import {SRHeading, SRNotificationCard} from 'sr-react-commons'

export function HomeTitle() {
	return (
		<PageTitle>
			<Separator />
			<BreadcrumbItem>Home</BreadcrumbItem>
		</PageTitle>
	)
}

export function HomePage() {
	const {userDetails, authDetails} = useCurrentUser()
	const routes = useRoutes()
	if (!userDetails || !authDetails) {
		return (
			<Page>
				<Spinner />
			</Page>
		)
	}

	return (
		<Page>
			<Box fill={'vertical'} overflow={'auto'}>
				<Box direction={'row'} justify={'between'} fill={'horizontal'} align={'center'} flex={false}>
					<SRHeading level="3">My Reclassification Tasks</SRHeading>
					{isSrAdminAnyResource(authDetails) && (
						<Link to={routes.allReclassifications.linkTo({})}>All reclassifications</Link>
					)}
				</Box>
				{isManager(authDetails) ? (
					<Tabs alignControls={'start'}>
						<Tab title={'Managed by me'}>
							<MyManagedReclassifications />
						</Tab>
						<Tab title={'Assigned to me'}>
							<MyAssignedReclassificationTasks />
						</Tab>
					</Tabs>
				) : isReclassifier(authDetails) ? (
					<MyAssignedReclassificationTasks />
				) : (
					<Box fill={'horizontal'} height={'70px'}>
						<SRNotificationCard
							fill={false}
							type={'error'}
							text={<>You don't have any reclassification permissions</>}
						/>
					</Box>
				)}
			</Box>
		</Page>
	)
}
