import {SrRoute} from '../../routing'
import {
	ReclassificationWorkPackagesPage,
	ReclassificationWorkPackagesPageRouteParams,
	ReclassificationWorkPackagesPageTitle,
} from './ReclassificationWorkPackagesPage'

export const reclassificationWorkPackages = new SrRoute<ReclassificationWorkPackagesPageRouteParams>(
	'/reclassifications/manage/:clientSlug/:projectSlug/:reclassificationId/workPackages',
	ReclassificationWorkPackagesPage,
	ReclassificationWorkPackagesPageTitle,
)
