import {MeasurementsProvider} from './state/MeasurementsContext'
import {ElementSectionsPanelContent} from './ElementSectionsPanelContent'
import React from 'react'
import {Classification} from '../../../entities/classification'
import {UserProject} from '../../User/entities/userProject'

export function ElementSections({classification, project}: {classification: Classification; project: UserProject}) {
	return (
		<MeasurementsProvider>
			<ElementSectionsPanelContent key={classification._id} sections={classification.sections} project={project} />
		</MeasurementsProvider>
	)
}
