import React from 'react'
import {ClassificationWithAnalysisView, ClassificationRedux} from '../../../entities/classification'
import {Box, Tab, Tabs, Text} from 'grommet'
import {ElementDetailTable} from './ElementDetailTable'
import {ClassificationDetailTable} from './ClassificationDetailTable'
import {ElementHistoryTable} from './ElementHistoryTable'

export function ClassificationDetailBox(props: {
	classification: ClassificationRedux
	classificationsWithAnalysisView?: ClassificationWithAnalysisView[]
}) {
	return (
		<Box gap={'small'}>
			<Tabs alignControls={'start'}>
				<Tab title={'Classification'}>
					<ClassificationDetailTable classification={props.classification} />
				</Tab>
				<Tab title={'Element'}>
					<ElementDetailTable classification={props.classification} />
				</Tab>
				{props.classificationsWithAnalysisView && (
					<Tab title={'Element History'}>
						<Box fill overflow={'auto'} margin={{bottom: 'small'}}>
							<ElementHistoryTable
								classificationsWithAnalysisView={props.classificationsWithAnalysisView}
								showAnalysisLink={false}
							/>
						</Box>
					</Tab>
				)}
			</Tabs>
		</Box>
	)
}
