import React from 'react'
import {Box, Grommet, Heading, Image, Text} from 'grommet'
import {theme} from '../../theme'
import {config} from '../../config/config'

export function MaintenanceLanding() {
	return (
		<Grommet theme={theme} full>
			<Box background="light-1" fill>
				<Box
					width="500px"
					height="250px"
					background="white"
					alignSelf="center"
					border={{
						color: 'light-3',
						size: 'xsmall',
						style: 'solid',
					}}
					round="xsmall"
					margin={{top: '15%'}}
				>
					<Box margin={'large'} alignContent={'center'} gap={'xsmall'}>
						<Box margin={'none'}>
							<Image
								height={'100%'}
								fill={false}
								alignSelf={'start'}
								src={'/images/logo-horizontal-small.svg'}
								alt={`${config.sr.companyName} Logo`}
							/>
						</Box>
						<Box margin={{left: 'small'}} gap={'xsmall'}>
							<Heading level={'3'} size={'20px'}>
								We are down for scheduled maintenance
							</Heading>
							<Box gap={'xsmall'}>
								<Text size={'16px'} font-weight={'400'} color={'neutral-1'}>
									We expect to be back in a couple of hours.
								</Text>
								<Text size={'16px'} font-weight={'400'} color={'neutral-1'}>
									Thank you for your patience.
								</Text>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Grommet>
	)
}
