import React from 'react'
import {Text} from 'grommet'
import {SRCard} from 'sr-react-commons'

export function ReclassificationHeader(props: {
	skipped: boolean
	remainingElementCount: number
	totalElementCount: number
}) {
	return (
		<SRCard>
			{props.skipped ? (
				<Text>Remaining skipped elements: {props.remainingElementCount}</Text>
			) : (
				<Text>
					Remaining elements: {props.remainingElementCount} / {props.totalElementCount}
				</Text>
			)}
		</SRCard>
	)
}
