import {Link, useParams} from 'react-router-dom'
import React, {useCallback, useEffect, useMemo} from 'react'
import {queryCache, useQuery} from 'react-query'
import {
	fetchFinalStatesForFinalReview,
	ReclassificationActions,
	ReclassifyDTO,
} from '../../features/Reclassification/api/reclassificationAnnotations'
import {
	ReclassificationAnnotationEntity,
	SimpleReclassificationValueWithUserName,
} from '../../features/Reclassification/entities/reclassification-annotation'
import {ClassificationRedux} from '../../entities/classification'
import {Box, Grid, Heading, Text} from 'grommet'
import {ViewerProvider} from '../../features/Viewer/components/ViewerProvider'
import {Page} from '../../components/Page/Page'
import {Spinner} from '../../components/Spinner/Spinner'
import {useViewer} from '../../features/Viewer/hooks/useViewer'
import _ from 'lodash'
import {useReclassificationTaskUsers} from '../../features/Reclassification/hooks/reclassificationTask/useReclassificationTaskUsers'
import Papa from 'papaparse'
import {format} from 'date-fns'
import {saveAs} from 'file-saver'
import {Container} from '../../components/Container/Container'
import {UserProject} from '../../features/User/entities/userProject'
import {
	ReclassificationTaskEntity,
	ReclassificationTaskEntityWithStageTasksWithWorkPackage,
} from '../../features/Reclassification/entities/reclassification-task'
import {useReclassificationAnnotationMutations} from '../../features/Reclassification/hooks/reclassificationAnnotations/useReclassificationAnnotationMutations'
import {PageTitle} from '../../components/TopNavigationBar/PageTitle'
import {Separator} from '../../components/TopNavigationBar/Separator'
import {BreadcrumbItem} from '../../components/TopNavigationBar/BreadcrumbItem'
import {Bar} from 'grommet-icons'
import {useRoutes} from '../../hooks/useRoutes'
import {FinalReviewControlsContainer} from '../../features/Reclassification/components/FinalReviewControlsContainer'
import {useFinalReviewTable} from '../../features/Reclassification/components/Table/FinalReviewTable/useFinalReviewTable'
import {useFinalReviewReclassificationElements} from '../../features/Reclassification/hooks/finalReview/useFinalReviewReclassificationElements'
import {useReclassificationWorkPackagesForFinalReview} from '../../features/Reclassification/hooks/finalReview/useReclassificationWorkPackagesForFinalReview'
import {
	ReclassificationWorkPackageTaskEntityWithCurrentStageTask,
	WPTaskEntityWithCurrentStageTaskByClassification,
} from '../../features/Reclassification/entities/reclassification-work-package-task'
import {useStartFinalReviewMutation} from '../../features/Reclassification/hooks/finalReview/mutations/useStartFinalReviewMutation'
import {useFinishFinalReviewMutation} from '../../features/Reclassification/hooks/finalReview/mutations/useFinishFinalReviewMutation'
import {useUndoFinishFinalReviewMutation} from '../../features/Reclassification/hooks/finalReview/mutations/useUndoFinishFinalReviewMutation'
import {useHeatmapExtension} from '../../features/ViewerAssets/Heatmaps/useHeatmapExtension'
import {useReviewFromContextMenu} from '../../features/Viewer/hooks/UseReviewFromContextMenu'
import {FinalReviewTableData} from '../../features/Reclassification/components/Table/FinalReviewTable/FinalReviewTable'
import {TableInstance} from 'react-table'
import {
	FinalReviewTableState,
	finalReviewTableStateActions,
	useFinalReviewTableState,
} from '../../features/Reclassification/components/Table/FinalReviewTable/useFinalReviewTableState'
import {LockedViewsContainer} from '../StageTask/LockedViewsContainer'
import {ElementSections} from '../../features/ViewerAssets/SectionsViewer/ElementSections'
import {ElementSectionStateProvider} from '../../features/ViewerAssets/SectionsViewer/state/ElementSectionStateContext'
import {useDrawSectionPlaneIntersection} from '../../features/Viewer/hooks/UseDrawSectionPlaneIntersection'
import {useMyReclassificationTask} from '../../features/Reclassification/hooks/reclassificationTask/useMyReclassificationTask'
import {useIsolateViewerElements} from '../../features/Viewer/hooks/UseIsolateViewerElements'
import {useClassificationByIdForFinalReview} from '../../features/Reclassification/hooks/finalReview/useReclassificationsForFinalReview'
import {useCurrentProject} from '../../hooks/useCurrentProject'
import {useFeatureEnabled} from '../../features/FeatureFlags/FeatureFlagsProvider'
import {SRPrimaryButton, SRSecondaryButton} from 'sr-react-commons'
import {useLinkedViewerElementsSelection} from '../../features/Viewer/hooks/UseLinkedViewerElementsSelection'
import {useViewerSelectionFix} from '../../features/Viewer/hooks/useViewerSelectionFix'

export type FinalReviewRouteParams = {
	clientSlug: string
	projectSlug: string
	reclassificationId: string
}

function useReviewUIStatus() {
	const [reviewUIStatus, setReviewUIStatus] = React.useState<'list' | 'review'>('list')
	const displayList = React.useCallback(() => {
		setReviewUIStatus('list')
	}, [])
	const displayReview = React.useCallback(() => {
		setReviewUIStatus('review')
	}, [])
	return {
		reviewUIStatus,
		displayList,
		displayReview,
	}
}

function useFinalStatesForFinalReview(
	projectId: string,
	reclassificationId: string,
	filter: FinalReviewTableState['filter'],
	reclassViewerManualRepaint: boolean,
) {
	return useQuery(
		[`final-states${reclassViewerManualRepaint ? '-manual' : ''}`, 'final-review', reclassificationId, filter],
		() => fetchFinalStatesForFinalReview(projectId, reclassificationId, filter),
		{
			keepPreviousData: true,
			...(reclassViewerManualRepaint
				? {
						// Every 5 min it the data is considered old so it refetches if needed. To be removed along with reclassViewerManualRepaint
						staleTime: 1000 * 60 * 5,
				  }
				: {}),
		},
	)
}

function getFilterFromTableFilterState(filters: TableInstance<FinalReviewTableData>['state']['filters']) {
	return {
		externalId: filters.find(filter => filter.id === 'externalId')?.value ?? null,
		reviewed: filters.find(filter => filter.id === 'reviewed')?.value ?? null,
		finalStatus: filters.find(filter => filter.id === 'finalStatus')?.value ?? null,
		elementType: filters.find(filter => filter.id === 'elementType')?.value ?? null,
		// TODO: ASSEMBLIES-V2 - workPackageNameQuery: filters.find(filter => filter.id === 'workPackageName')?.value ?? null,
	}
}

function FinalReviewPageContent(props: {
	actions: {
		reclassification: Pick<ReclassificationActions, 'approve' | 'reclassify'>
	}
	onFinished: () => any
	project: UserProject
	reclassification: ReclassificationTaskEntityWithStageTasksWithWorkPackage
	reclassificationElements: {
		annotation: ReclassificationAnnotationEntity | null
		classification: ClassificationRedux
		previousValue: SimpleReclassificationValueWithUserName
	}[]
	reclassViewerManualRepaint: boolean
	wpTaskEntityWithCurrentStageTaskByClassification: WPTaskEntityWithCurrentStageTaskByClassification
}) {
	const {
		status,
		actions: {isolateElements, colorizeModel},
	} = useViewer()
	const [finalReviewTableState, finalReviewTableDispatch] = useFinalReviewTableState()
	const {data: finalStates} = useFinalStatesForFinalReview(
		props.project._id,
		props.reclassification._id,
		finalReviewTableState.filter,
		props.reclassViewerManualRepaint,
	)
	const [finishFinalReviewMutation, finishFinalReviewResult] = useFinishFinalReviewMutation(props.onFinished)
	const [selectedClassificationId, setSelectedClassificationId] = React.useState<string | undefined>(undefined)
	const elementsByClassificationId = React.useMemo(
		() => _.keyBy(props.reclassificationElements, el => el.classification._id),
		[props.reclassificationElements],
	)
	const {elementsByDbId, allDbIds} = React.useMemo(() => {
		const elementsByDbId = _.keyBy(props.reclassificationElements, el => el.classification.forgeObjectId)
		return {
			elementsByDbId,
			allDbIds: Object.keys(elementsByDbId).map(dbId => Number(dbId)),
		}
	}, [props.reclassificationElements])
	const selectedElement = selectedClassificationId ? elementsByClassificationId[selectedClassificationId] : undefined
	const {reviewUIStatus, displayList, displayReview} = useReviewUIStatus()
	const {users} = useReclassificationTaskUsers(props.project._id, props.reclassification._id)
	const tableInstance = useFinalReviewTable(
		props.reclassificationElements,
		props.wpTaskEntityWithCurrentStageTaskByClassification,
	)

	useEffect(() => {
		finalReviewTableDispatch(finalReviewTableStateActions.setPage({page: tableInstance.state.pageIndex}))
	}, [tableInstance.state.pageIndex, finalReviewTableDispatch])

	useEffect(() => {
		finalReviewTableDispatch(
			finalReviewTableStateActions.setFilter({
				filter: getFilterFromTableFilterState(tableInstance.state.filters),
			}),
		)
	}, [tableInstance.state.filters, finalReviewTableDispatch])

	const finishStageTasks = async () => {
		return finishFinalReviewMutation({
			projectId: props.project._id,
			reclassificationTaskId: props.reclassification._id,
		})
	}

	const onReview = async <T extends unknown>(res: T): Promise<T> => {
		queryCache.invalidateQueries(['final-states', 'final-review', props.reclassification._id]).then(_.noop)
		return res
	}

	const onExportCSV = () => {
		const newData = tableInstance.rows
		const header = Object.keys(newData[0].original)
		const body = newData.map(row => {
			return Object.values(row.original).map(field => {
				return field
			})
		})

		const tableCsv = Papa.unparse({data: body, fields: header})
		if (tableCsv == null) return

		const blob = new Blob([tableCsv], {type: 'text/plain'})

		const downloadDateAndTime = format(new Date(), 'yyyyMMdd-HHmm')
		saveAs(blob, `reclassification-export-${downloadDateAndTime}.csv`, {autoBom: true})
	}

	const onGoToReview = React.useCallback(
		(classificationId: string) => {
			setSelectedClassificationId(classificationId)
			displayReview()
		},
		[displayReview],
	)
	useViewerSelectionFix(allDbIds)
	useReviewFromContextMenu(elementsByDbId, onGoToReview)
	const {
		selectedClassifications,
		setSelectedClassifications,
		selectionLinked,
		setSelectionLinked,
	} = useLinkedViewerElementsSelection(props.reclassificationElements.map(el => el.classification))

	useEffect(() => {
		if (status === 'model_loaded' && finalStates) {
			colorizeModel(
				Object.entries(finalStates).flatMap(([status, ids]) =>
					ids.map(forgeObjectId => ({
						dbId: forgeObjectId,
						status: status as ReclassificationAnnotationEntity['classification'],
					})),
				),
				selectedElement?.classification?.forgeObjectId,
				true,
			)
		}
	}, [colorizeModel, finalStates, selectedElement, status])

	const {data: classification} = useClassificationByIdForFinalReview(
		props.project._id,
		props.reclassification._id,
		selectedElement?.classification?._id,
	)
	useDrawSectionPlaneIntersection(selectedElement?.classification.forgeObjectId)
	useHeatmapExtension(props.project, classification)

	useIsolateViewerElements(
		useMemo(
			() => finalStates && Object.entries(finalStates).flatMap(([, ids]) => ids.map(forgeObjectId => forgeObjectId)),
			[finalStates],
		),
	)
	const resetFilters = useCallback(() => tableInstance.setAllFilters([]), [tableInstance])
	const filterSelected = useCallback(
		() =>
			tableInstance.setAllFilters([
				{
					id: 'externalId',
					value: selectedClassifications.map(clId => elementsByClassificationId[clId].classification.externalId),
				},
			]),
		[elementsByClassificationId, selectedClassifications, tableInstance],
	)

	return !users ? null : (
		<Container pad={'none'} fill={true} background={'white'}>
			<Grid
				rows={['auto', '410px']}
				columns={['100%']}
				fill={true}
				areas={[
					{name: 'top', start: [0, 0], end: [0, 0]},
					{name: 'bottom', start: [0, 1], end: [0, 1]},
				]}
			>
				<Box pad="xsmall" gridArea={'top'}>
					<LockedViewsContainer
						classification={classification || null}
						project={props.project}
						reclassification={props.reclassification}
						isolateElementOnSelection={false}
					/>
				</Box>
				<Box gridArea={'bottom'}>
					<Grid
						rows={['100%']}
						fill={true}
						columns={['1/2', '1/2']}
						areas={[
							{name: 'left', start: [0, 0], end: [0, 0]},
							{name: 'right', start: [1, 0], end: [1, 0]},
						]}
					>
						<Box gridArea={'left'} pad={'xxsmall'}>
							{classification ? <ElementSections classification={classification} project={props.project} /> : null}
						</Box>
						<Box gridArea={'right'} pad={'xxsmall'}>
							<FinalReviewControlsContainer
								projectId={props.project._id}
								reviewUIStatus={reviewUIStatus}
								displayList={displayList}
								displayReview={displayReview}
								onFinish={finishStageTasks}
								isFinishing={finishFinalReviewResult.isLoading}
								cannotFinishTooltip={
									'You cannot finish the final review yet because not all work packages are ready for final review'
								}
								reviewedElementsCount={props.reclassificationElements.filter(el => el.annotation !== null).length}
								totalElementsCount={props.reclassificationElements.length}
								selectedElement={selectedElement || null}
								reviewActions={{
									approve: classificationId => props.actions.reclassification.approve(classificationId).then(onReview),
									reclassify: (classificationId, formData) =>
										props.actions.reclassification.reclassify(classificationId, formData).then(onReview),
								}}
								users={users}
								tableInstance={tableInstance}
								onSelect={classificationId => setSelectedClassificationId(classificationId)}
								onReview={classificationId => setSelectedClassificationId(classificationId)}
								onIsolateFiltered={() =>
									isolateElements(
										props.reclassificationElements.map(({classification}) => classification.forgeObjectId),
									)
								}
								onExport={onExportCSV}
								selectedClassifications={selectedClassifications}
								setSelectedClassifications={setSelectedClassifications}
								selectionLinked={selectionLinked}
								setSelectionLinked={setSelectionLinked}
								resetFilters={resetFilters}
								filterSelected={filterSelected}
							/>
						</Box>
					</Grid>
				</Box>
			</Grid>
		</Container>
	)
}

function FinishedFinalReviewPage(props: {
	reclassificationTask: ReclassificationTaskEntity
	project: UserProject
	onUndoDone: () => any
}) {
	const routes = useRoutes()
	const [undoFinishFinalReviewMutation, undoFinishFinalReviewMutationResult] = useUndoFinishFinalReviewMutation(
		props.onUndoDone,
	)
	const onUndo = () =>
		undoFinishFinalReviewMutation({
			projectId: props.project._id,
			reclassificationTaskId: props.reclassificationTask._id,
		})

	return (
		<Page>
			<Box fill={true} align={'center'} justify={'center'}>
				<Box align={'center'} gap="xsmall">
					<Heading level="1">You are done!</Heading>
					<Box direction={'row'} gap={'small'}>
						<Bar size={'xlarge'} color={'black'} />
						<Bar size={'xlarge'} color={'black'} />
						<Bar size={'xlarge'} color={'black'} />
					</Box>
					<Heading textAlign={'center'} level="2">
						You finished the final review.
					</Heading>
					<Link to={routes.home.linkTo({})}>Back to my assigned reclassifications</Link>
					<SRSecondaryButton
						label={'Undo finish final review'}
						disabled={undoFinishFinalReviewMutationResult.isLoading}
						onClick={onUndo}
					/>
				</Box>
			</Box>
		</Page>
	)
}

function FinalReviewNotReadyPage(props: {
	numberOfWorkPackagesForFinalReview: number
	totalNumberOfWorkPackages: number
}) {
	const routes = useRoutes()
	return (
		<Page>
			<Container gap={'xsmall'} align={'center'} justify={'center'}>
				<Heading textAlign={'center'} level={'3'}>
					Not all work packages ready for final review.
				</Heading>
				<Text>
					Work packages ready for final review: {props.numberOfWorkPackagesForFinalReview} /{' '}
					{props.totalNumberOfWorkPackages}
				</Text>
				<Text>You will be only able to review elements from work packages that are ready for the final review.</Text>
				<Link to={routes.home.linkTo({})}>Back to my assigned reclassifications</Link>
			</Container>
		</Page>
	)
}

function FinalReviewReadyPage(props: {
	reclassificationTask: ReclassificationTaskEntity
	project: UserProject
	onStarted: () => any
}) {
	const [startFinalReviewMutation, startFinalReviewMutationResult] = useStartFinalReviewMutation(props.onStarted)
	const onStart = async () => {
		return startFinalReviewMutation({
			projectId: props.project._id,
			reclassificationTaskId: props.reclassificationTask._id,
		})
	}
	return (
		<Page justify={'center'}>
			<Heading>All work packages are ready for final review.</Heading>
			<SRPrimaryButton
				disabled={startFinalReviewMutationResult.isLoading}
				label={'Start final review'}
				onClick={onStart}
			/>
		</Page>
	)
}

function useReclassificationElementsWithActions(
	project: UserProject | undefined,
	reclassificationId: string,
	workPackageTasks: ReclassificationWorkPackageTaskEntityWithCurrentStageTask[] | undefined,
) {
	const {reclassificationElements} = useFinalReviewReclassificationElements(project, reclassificationId)
	const wpTaskEntityWithCurrentStageTaskByClassification = React.useMemo(() => {
		const map: WPTaskEntityWithCurrentStageTaskByClassification = {}
		if (workPackageTasks) {
			workPackageTasks.forEach(task => {
				task.classificationIds.forEach(id => {
					map[id] = task
				})
			})
			return map
		}
		return undefined
	}, [workPackageTasks])
	const {approveMutation, reclassifyMutation} = useReclassificationAnnotationMutations(project?._id, () =>
		queryCache.invalidateQueries(['reclassification-annotations', 'final-review', reclassificationId]),
	)

	const reclassificationActions = wpTaskEntityWithCurrentStageTaskByClassification && {
		approve: (classificationId: string) =>
			approveMutation({
				classificationId,
				stageTaskId: wpTaskEntityWithCurrentStageTaskByClassification[classificationId].currentStageTask._id,
			}),
		reclassify: (classificationId: string, formData: ReclassifyDTO) =>
			reclassifyMutation({
				classificationId,
				formData,
				stageTaskId: wpTaskEntityWithCurrentStageTaskByClassification[classificationId].currentStageTask._id,
			}),
	}

	return {reclassificationElements, reclassificationActions, wpTaskEntityWithCurrentStageTaskByClassification}
}

export function FinalReviewPage() {
	const {reclassificationId} = useParams<FinalReviewRouteParams>()
	const project = useCurrentProject()
	const reclassification = useMyReclassificationTask(reclassificationId)
	const reclassViewerManualRepaint = useFeatureEnabled('reclassViewerManualRepaint')
	const {data: workPackageTasks, invalidate: refreshWorkPackages} = useReclassificationWorkPackagesForFinalReview(
		project?._id,
		reclassificationId,
	)
	const {
		reclassificationElements,
		reclassificationActions,
		wpTaskEntityWithCurrentStageTaskByClassification,
	} = useReclassificationElementsWithActions(project, reclassificationId, workPackageTasks)

	if (project && workPackageTasks && reclassification && reclassificationElements && reclassificationActions) {
		const totalNumberOfWorkPackages = reclassification.workPackagesCount

		const numberOfWorkPackagesForFinalReview = workPackageTasks.length
		const started = workPackageTasks.some(wp => wp.currentStageTask.status === 'in progress')
		const finishedStageTasks = workPackageTasks.filter(wp => wp.currentStageTask.status === 'finished')

		if (finishedStageTasks.length === totalNumberOfWorkPackages) {
			return (
				<FinishedFinalReviewPage
					reclassificationTask={reclassification}
					project={project}
					onUndoDone={refreshWorkPackages}
				/>
			)
		}

		if (!started) {
			if (numberOfWorkPackagesForFinalReview < totalNumberOfWorkPackages) {
				return (
					<FinalReviewNotReadyPage
						numberOfWorkPackagesForFinalReview={numberOfWorkPackagesForFinalReview}
						totalNumberOfWorkPackages={totalNumberOfWorkPackages}
					/>
				)
			} else {
				return (
					<FinalReviewReadyPage
						reclassificationTask={reclassification}
						project={project}
						onStarted={refreshWorkPackages}
					/>
				)
			}
		}

		return (
			<Page pad={'none'}>
				<ViewerProvider>
					<ElementSectionStateProvider>
						<FinalReviewPageContent
							reclassificationElements={reclassificationElements}
							project={project}
							reclassification={reclassification}
							onFinished={refreshWorkPackages}
							actions={{reclassification: reclassificationActions}}
							reclassViewerManualRepaint={reclassViewerManualRepaint}
							wpTaskEntityWithCurrentStageTaskByClassification={wpTaskEntityWithCurrentStageTaskByClassification!}
						/>
					</ElementSectionStateProvider>
				</ViewerProvider>
			</Page>
		)
	} else {
		return (
			<Page>
				<Spinner />
			</Page>
		)
	}
}

export function FinalReviewPageTitle() {
	const {reclassificationId} = useParams<FinalReviewRouteParams>()
	const reclassification = useMyReclassificationTask(reclassificationId)
	return (
		<PageTitle>
			<Separator />
			<BreadcrumbItem>{reclassification ? reclassification.name : 'Reclassification'}</BreadcrumbItem>
			<Separator />
			<BreadcrumbItem>Final Review</BreadcrumbItem>
		</PageTitle>
	)
}
