import {Classification, Viewsphere} from '../../../entities/classification'
import React, {useState} from 'react'
import PhotoSphereExtension, {
	PhotoSphereExtensionId,
} from '../../Viewer/Extensions/Photosphere/PhotoSphere.viewer.extension'
import {useViewer} from '../../Viewer/hooks/useViewer'
import {coordinateFromIFCtoViewer, getCenterOfElementBounds} from '../../Viewer/utilities/viewerUtilities'
import {ReclassificationDTO} from '../../Reclassification/entities/reclassification-annotation'

const THREE = window.THREE

export function usePhotosphereExtension(classification: Classification | null) {
	const extensionRef = React.useRef<PhotoSphereExtension>()
	const {viewer, status: viewerStatus} = useViewer()
	const [extensionLoaded, setExtensionLoaded] = React.useState(false)
	const [currentImageIndex, setCurrentImageIndex] = React.useState(0)
	const [cameraOrientation, setCameraOrientation] = useState<[number, number]>([0, 0])
	const [cameraFov, setCameraFov] = React.useState(75)
	const [viewsLocked, setViewsLocked] = React.useState(false)

	const viewsphere = React.useMemo(() => {
		if (classification && classification.viewspheres) {
			return classification.viewspheres[currentImageIndex]
		} else {
			return undefined
		}
	}, [classification, currentImageIndex])

	React.useEffect(() => {
		const currentViewer = viewer.current
		if (viewerStatus === 'model_loaded' && currentViewer && currentViewer.model) {
			currentViewer.setBimWalkToolPopup(false)
			currentViewer
				.loadExtension(PhotoSphereExtensionId, {
					setActiveViewSphere: setCurrentImageIndex,
					setCameraFov: setCameraFov,
					setCameraOrientation: setCameraOrientation,
				})
				.then(extension => {
					extensionRef.current = extension as PhotoSphereExtension
					setExtensionLoaded(true)
					// remove some toolbar buttons
					if (currentViewer.toolbar) {
						currentViewer.toolbar.removeControl('navTools')
						currentViewer.toolbar.removeControl('modelTools')
						const settingsToolbar = currentViewer.toolbar.getControl(
							'settingsTools',
						) as Autodesk.Viewing.UI.ControlGroup
						settingsToolbar.removeControl('toolbar-modelStructureTool')
					}
				})
		}
		return () => {
			currentViewer?.unloadExtension(PhotoSphereExtensionId)
		}
	}, [viewerStatus, viewer])

	React.useEffect(() => {
		if (extensionLoaded) {
			extensionRef.current?.setSelectedClassification(classification)
		}
	}, [classification, extensionLoaded])

	React.useEffect(() => {
		if (extensionLoaded) {
			extensionRef.current?.setSelectedViewSphere(viewsphere)
			if (classification && viewsphere) {
				const forgeViewer = viewer.current!
				const coordinates = coordinateFromIFCtoViewer(forgeViewer.model, ...viewsphere.poseCoordinates)
				const viewSpherePosePosition = new THREE.Vector3(...coordinates)
				const elementCenter = getCenterOfElementBounds(classification.forgeObjectId, forgeViewer)
				forgeViewer.navigation.setCameraUpVector(new THREE.Vector3(0, 0, 1))
				forgeViewer.navigation.setView(viewSpherePosePosition, elementCenter)
			}
		}
	}, [classification, extensionLoaded, viewer, viewsLocked, viewsphere])

	useCameraZoomUpdate(viewer, extensionLoaded, viewsLocked, cameraFov)
	useCameraOrientationUpdate(viewsphere, classification, viewer, extensionLoaded, viewsLocked, cameraOrientation)
	extensionRef.current?.getCameraOrientation(cameraOrientation)
	React.useEffect(() => {
		if (extensionLoaded) {
			extensionRef.current?.setViewsLocked(viewsLocked)
			const forgeViewer = viewer.current!
			if (viewsLocked) {
				forgeViewer.canvas.style.cursor = 'not-allowed'
				forgeViewer.setActiveNavigationTool('bimwalk')
				forgeViewer.setFOV(75)
			} else {
				forgeViewer.canvas.style.removeProperty('cursor')
				forgeViewer.setActiveNavigationTool('orbit')
				forgeViewer.setFOV(45)
			}
			forgeViewer.navigation.setCameraUpVector(new THREE.Vector3(0, 0, 1))
		}
	}, [viewer, extensionLoaded, viewsLocked])

	React.useEffect(() => {
		setCurrentImageIndex(0)
	}, [classification])

	// TODO maybe move all these state things into a react context or redux
	// or just bundle them here inside an object like
	// return {
	// 	state: {currentImageIndex,viewsphere,camera: {orientation,fov}, viewsLocked}
	// 	actions: { // all the setters}
	//}
	return {
		currentImageIndex,
		setCurrentImageIndex,
		viewsphere,
		cameraOrientation,
		setCameraOrientation,
		cameraFov,
		setCameraFov,
		viewsLocked,
		setViewsLocked,
	}
}

function useCameraOrientationUpdate(
	viewsphere: Viewsphere | undefined,
	classification: ReclassificationDTO['classification'] | null,
	viewer: React.MutableRefObject<Autodesk.Viewing.GuiViewer3D | null>,
	extensionLoaded: boolean,
	viewsLocked: boolean,
	cameraOrientation: [number, number],
) {
	React.useEffect(() => {
		if (viewsphere && classification && viewer.current && extensionLoaded && viewsLocked) {
			const forgeViewer = viewer.current
			// @ts-ignore
			const coordinates = coordinateFromIFCtoViewer(forgeViewer.model, ...viewsphere.poseCoordinates)
			const viewSpherePosePosition = new THREE.Vector3(...coordinates)
			const elementCenter = getCenterOfElementBounds(classification.forgeObjectId, forgeViewer)

			const initialLat = Math.max(-85, Math.min(85, parseFloat(viewsphere.elementCentroid[0])))
			const initialLon = parseFloat(viewsphere.elementCentroid[1])
			const [currentLat, currentLon] = cameraOrientation
			// rotate the camera
			const camera = forgeViewer.navigation.getCamera()
			const newPosition = viewSpherePosePosition
			const directionFwd = elementCenter.clone().sub(newPosition)
			const directionRight = directionFwd.clone().cross(camera.up).normalize()

			// rotate around up vector
			const yawX = new THREE.Quaternion()
			const angleX = (2 * Math.PI * (currentLon - initialLon) * -1) / 360
			yawX.setFromAxisAngle(camera.up, angleX)

			// rotate around right vector
			const yawY = new THREE.Quaternion()
			const angleY = (2 * Math.PI * (currentLat - initialLat)) / 360
			yawY.setFromAxisAngle(directionRight, angleY)

			const yawQ = new THREE.Quaternion()
			yawQ.multiply(yawX).multiply(yawY)

			directionFwd.applyQuaternion(yawQ)
			const newTarget = newPosition.clone().add(directionFwd)
			forgeViewer.navigation.setView(newPosition, newTarget)
			forgeViewer.navigation.setCameraUpVector(new THREE.Vector3(0, 0, 1))
		}
	}, [viewer, classification, cameraOrientation, viewsphere, extensionLoaded, viewsLocked])
}

function useCameraZoomUpdate(
	viewer: React.MutableRefObject<Autodesk.Viewing.GuiViewer3D | null>,
	extensionLoaded: boolean,
	viewsLocked: boolean,
	cameraFov: number,
) {
	React.useEffect(() => {
		if (viewer.current && extensionLoaded && viewsLocked) {
			const forgeViewer = viewer.current
			// forgeViewer.setFOV() unfortunately behaves different and THREE.Camera does not have fov property
			// forgeViewer.impl.camera most likely is an extension of THREE.Camera but the types are incorrect
			// @ts-ignore
			forgeViewer.impl.camera.fov = cameraFov
		}
	}, [extensionLoaded, viewer, cameraFov, viewsLocked])
}
