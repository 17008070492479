import _ from 'lodash'
import {AnnotationClassificationType} from '../../../entities/classificationAnnotation'
import {ClassificationRedux} from '../../../../../entities/classification'
import {Box, CheckBox, Table, TableBody, TableCell, TableHeader, TableRow, Text, ThemeContext} from 'grommet'
import React, {ReactNode, useCallback, useMemo} from 'react'
import {TableInstance} from 'react-table'
import {Checkmark, Cursor, Like, Search} from 'grommet-icons'
import {PaginationControls} from '../../../../../components/PaginationControls/PaginationControls'
import {theme} from '../../../../../theme'
import {getFullName} from '../../../../User/entities/user'
import {ReclassificationActions} from '../../../api/reclassificationAnnotations'
import {Spinner} from '../../../../../components/Spinner/Spinner'
import {ReclassificationWithUserNameDTO} from '../../../entities/reclassification-annotation'
import {SRGreenIconButton, SRPrimaryIconButton, SRSecondaryIconButton} from 'sr-react-commons'
import {HeaderColumnCell} from '../core/components/HeaderColumnCell'
import {WPTaskEntityWithCurrentStageTaskByClassification} from '../../../entities/reclassification-work-package-task'

export function prepareFinalReviewTableData(
	reclassificationElements: ReclassificationWithUserNameDTO[],
	wpTaskEntityWithCurrentStageTaskByClassification: WPTaskEntityWithCurrentStageTaskByClassification,
): FinalReviewTableData[] {
	return reclassificationElements.map(({classification, previousValue, annotation}) => {
		const finalClassificationStatus = annotation?.classification || previousValue.classification
		const finalClassificationMagnitude = annotation ? annotation.magnitude : previousValue.magnitude
		return {
			approved: classification.approved,
			externalId: classification.externalId,
			elementType: classification.element.type,
			mappedElementType: classification.element.mappedElementType,
			tolerance: classification.tolerance,
			confidence: classification.confidence,
			finalClassification: finalClassificationStatus,
			userName: previousValue.createdByUserRef ? getFullName(previousValue.createdByUserRef) : 'Bypassed',
			finalMagnitude: ['missing', 'no_data'].includes(finalClassificationStatus) ? 0 : finalClassificationMagnitude,
			classificationId: classification._id,
			reviewed: annotation !== null,
			initialClassification: classification.status,
			initialMagnitude: ['missing', 'no_data'].includes(classification.magnitude)
				? 0
				: parseFloat(classification.magnitude),
			workPackageName: wpTaskEntityWithCurrentStageTaskByClassification[classification._id].description,
		}
	})
}

export type FinalReviewTableData = {
	approved: boolean
	classificationId: string
	elementType: string
	mappedElementType: string
	tolerance: number
	confidence: number
	externalId: string
	userName: string | null
	finalClassification: AnnotationClassificationType
	finalMagnitude: number | null
	reviewed: boolean
	initialClassification: AnnotationClassificationType
	initialMagnitude: number | null
	workPackageName: string
}

const HeaderSectionCell = ({colSpan, children}: {colSpan?: number; children?: ReactNode}) => (
	<TableCell scope={'col'} border={'all'} align={'center'} colSpan={colSpan} children={children} />
)

export function FinalReviewTable({
	reviewActions,
	tableInstance,
	onReview,
	onSelect,
	currentClassification,
	selectedClassifications,
	setSelectedClassifications,
}: {
	reviewActions: Pick<ReclassificationActions, 'approve'>
	tableInstance: TableInstance<FinalReviewTableData>
	onReview: (classificationId: string) => void
	onSelect: (classificationId: string) => void
	currentClassification?: ClassificationRedux
	selectedClassifications: string[]
	setSelectedClassifications: (classificationIds: string[]) => void
}) {
	const [loading, setLoading] = React.useState<boolean>(false)
	const [approvedStatus, setApprovedStatus] = React.useState<boolean>(false)
	const pageClassIds = useMemo(() => tableInstance.page.map(row => row.original.classificationId), [tableInstance.page])
	const currentPageAllSelected = useMemo(
		() => pageClassIds.length === _.intersection(selectedClassifications, pageClassIds).length,
		[pageClassIds, selectedClassifications],
	)
	const resetPageSelection = useCallback(
		() => setSelectedClassifications(_.difference(selectedClassifications, pageClassIds)),
		[setSelectedClassifications, pageClassIds, selectedClassifications],
	)
	const selectAllInPage = useCallback(
		() => setSelectedClassifications(_.uniq([...selectedClassifications, ...pageClassIds])),
		[setSelectedClassifications, pageClassIds, selectedClassifications],
	)
	const approveClassification = async (classificationId: string) => {
		setLoading(true)
		reviewActions.approve(classificationId).then(() => {
			setLoading(false)
			setApprovedStatus(true)
			setTimeout(() => setApprovedStatus(false), 2000)
		})
	}

	return (
		<Box gap="xsmall" fill>
			<Box pad={{bottom: 'xxsmall'}} overflow={'auto'} fill>
				<ThemeContext.Extend
					value={{
						...theme,
						table: {
							body: {
								extend: 'font-size: 9px; padding-left:1px; padding-right: 1px; padding-top: 1px; padding-bottom: 1px;',
							},
							header: {
								extend: 'font-size: 9px; padding-left:1px; padding-right: 1px; padding-top: 1px; padding-bottom: 1px;',
							},
						},
						checkBox: {
							size: '15px',
						},
						text: {
							small: {
								size: '9px',
								height: '14px',
							},
						},
					}}
				>
					<Table {...tableInstance.getTableProps()}>
						<TableHeader>
							<TableRow>
								<HeaderSectionCell />
								<HeaderSectionCell colSpan={5}>
									<Text size={'xsmall'}>Original Classification</Text>
								</HeaderSectionCell>
								<HeaderSectionCell colSpan={6}>
									<Text size={'xsmall'}>Reclassification</Text>
								</HeaderSectionCell>
								<HeaderSectionCell>
									<Text size={'xsmall'}>Actions</Text>
								</HeaderSectionCell>
							</TableRow>
							{tableInstance.headerGroups.map(headerGroup => (
								<TableRow {...headerGroup.getHeaderGroupProps()}>
									<TableCell scope={'col'} border={'all'} verticalAlign={'middle'} align={'center'} gap={'xsmall'}>
										<Box title={`Toggle all this page items ${currentPageAllSelected ? 'unselected' : 'selected'}`}>
											<CheckBox
												checked={currentPageAllSelected}
												onChange={currentPageAllSelected ? resetPageSelection : selectAllInPage}
											/>
										</Box>
									</TableCell>
									{headerGroup.headers.map(column => (
										<HeaderColumnCell column={column} key={column.id} />
									))}
									<TableCell scope={'col'} border={'all'} verticalAlign={'top'} align={'center'} title={'Actions'}>
										Actions
									</TableCell>
								</TableRow>
							))}
						</TableHeader>
						<TableBody {...tableInstance.getTableBodyProps()}>
							{tableInstance.page.map(row => {
								tableInstance.prepareRow(row)
								return (
									<TableRow
										{...row.getRowProps()}
										style={
											row.original.classificationId === currentClassification?._id
												? {backgroundColor: '#d3d5d8'}
												: undefined
										}
									>
										<TableCell scope={'row'} border={'all'} align={'center'}>
											<CheckBox
												checked={selectedClassifications.includes(row.original.classificationId)}
												onChange={() => {
													!selectedClassifications.includes(row.original.classificationId)
														? setSelectedClassifications([...selectedClassifications, row.original.classificationId])
														: setSelectedClassifications(
																selectedClassifications.filter(
																	classification => classification !== row.original.classificationId,
																),
														  )
												}}
											/>
										</TableCell>
										{row.cells.map(cell => (
											<TableCell size={String(cell.column.width)} scope={'row'} border={'all'} {...cell.getCellProps()}>
												<Text title={cell.value} truncate={true} size="xsmall">
													{cell.render('Cell')}
												</Text>
											</TableCell>
										))}
										<TableCell scope={'row'} border={'all'}>
											<>
												{row.original.classificationId !== currentClassification?._id ? (
													<Box direction="row" justify={'between'} gap="xxsmall">
														<SRSecondaryIconButton
															pad={'5px'}
															onClick={() => {
																onSelect(row.original.classificationId)
															}}
															title={'Focus'}
															icon={<Cursor size={'xsmall'} />}
														/>
														<SRPrimaryIconButton
															primary
															onClick={() => onReview(row.original.classificationId)}
															title={'Review'}
															icon={<Search size={'xsmall'} />}
														/>
													</Box>
												) : (
													<Box direction="row" justify={'between'} gap="xxsmall">
														<SRPrimaryIconButton
															size={'small'}
															onClick={() => onReview(row.original.classificationId)}
															icon={<Search size={'xsmall'} />}
															title={'Review'}
														/>
														<SRGreenIconButton
															onClick={() => approveClassification(row.original.classificationId)}
															size="small"
															disabled={loading}
															title={'approve'}
															icon={
																loading ? (
																	<Spinner size={'12px'} />
																) : approvedStatus ? (
																	<Like size={'xsmall'} />
																) : (
																	<Checkmark size={'xsmall'} />
																)
															}
														/>
													</Box>
												)}
											</>
										</TableCell>
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				</ThemeContext.Extend>
			</Box>
			<PaginationControls
				start={tableInstance.state.pageIndex * tableInstance.state.pageSize}
				currentPageLen={tableInstance.page.length}
				currentPageSize={tableInstance.state.pageSize}
				totalCount={tableInstance.rows.length}
				page={tableInstance.state.pageIndex + 1}
				setPage={newPage => tableInstance.gotoPage(newPage - 1)}
				pageCount={tableInstance.pageCount}
				setCurrentPageSize={tableInstance.setPageSize}
			/>
		</Box>
	)
}
