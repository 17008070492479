import {Viewsphere} from '../../../entities/classification'
import {Box, CheckBox, Text} from 'grommet'
import {Next, Previous} from 'grommet-icons'
import React from 'react'
import {SRIconButton} from 'sr-react-commons'

export function PhotoSphereControls(props: {
	checked: boolean
	onToggleHighlightElement: () => void
	onPrevious: () => void
	onNext: () => void
	currentImageIndex: number
	viewspheres: Viewsphere[]
}) {
	return (
		<Box flex={false} direction={'row'} justify={'between'}>
			<Box pad={'xxsmall'} align="center" justify="center">
				<CheckBox
					data-testid={'highlight-element-checkbox'}
					label={'Highlight element'}
					data-asset-hide={true}
					toggle
					checked={props.checked}
					onChange={props.onToggleHighlightElement}
				/>
			</Box>
			<Box pad={'xsmall'} direction="row" align="center" justify="center" gap={'xsmall'}>
				<SRIconButton
					size={'small'}
					data-asset-hide={true}
					icon={<Previous size={'small'} />}
					onClick={props.onPrevious}
				/>
				<Text>
					{props.currentImageIndex + 1}/{props.viewspheres.length}
				</Text>
				<SRIconButton
					data-testid={'photosphere-gallery-next-button'}
					size={'small'}
					data-asset-hide={true}
					icon={<Next size={'small'} />}
					onClick={props.onNext}
				/>
			</Box>
		</Box>
	)
}
