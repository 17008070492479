import {useSelector} from 'react-redux'
import {MaintenanceLanding} from './MaintenanceLanding'
import React from 'react'
import {RootState} from '../../redux/slice'

export function ShowMaintenanceMode(props: {children: any}) {
	const maintenanceMode = useSelector((state: RootState) => {
		return state.maintenance.value
	})

	if (maintenanceMode) {
		return <MaintenanceLanding />
	} else {
		return props.children
	}
}
