import {Box} from 'grommet'
import React, {ReactNode} from 'react'

export function PageHeader({children}: {children: ReactNode}) {
	return (
		<Box flex={false} pad={{bottom: 'small'}}>
			{children}
		</Box>
	)
}
