import './Viewing.Extension.VerticalToolbar.scss'
import {CustomWindow} from '../../../../custom.window'

export const ExtensionId = 'Viewing.Extension.VerticalToolbar'
declare let window: CustomWindow
const Autodesk = window.Autodesk
const AutodeskNamespace = window.AutodeskNamespace
AutodeskNamespace('Autodesk.SR.VerticalToolbar')

export default class VerticalToolbarExtension extends Autodesk.Viewing.Extension {
	public SRVerticalToolbar: Autodesk.Viewing.UI.ToolBar
	public static toolbar: Autodesk.Viewing.UI.ToolBar | null = null

	constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: any) {
		super(viewer, options)
		this.viewer = viewer
		this.SRVerticalToolbar = new Autodesk.Viewing.UI.ToolBar('toolbar-TtIf')
	}
	load() {
		this.createToolbar()
		document.getElementById('toolbar-TtIf')!.style.top = '0px'
		return true
	}

	unload() {
		const toolbar = document.getElementById('divToolbar')
		if (toolbar) toolbar.remove()
		return super.unload()
	}

	createToolbar() {
		const self = this
		const ctrlGroup = new Autodesk.Viewing.UI.ControlGroup('MyAppToolbar')
		ctrlGroup.addClass('toolbar-vertical-group')

		self.SRVerticalToolbar.addControl(ctrlGroup)
		const toolbarDiv = document.createElement('div')
		toolbarDiv.id = 'divToolbar'
		self.viewer.container.appendChild(toolbarDiv)
		VerticalToolbarExtension.toolbar = self.SRVerticalToolbar
		document.getElementById('divToolbar')!.appendChild(this.SRVerticalToolbar.container)
	}
}

Autodesk.Viewing.theExtensionManager.registerExtension(ExtensionId, VerticalToolbarExtension)
