import {ClassificationAnnotation} from '../entities/classificationAnnotation'
import {TableInstance} from 'react-table'
import {
	AnnotationsTableData,
	QualityControlReviewTable,
} from './Table/QualityControlReviewTable/QualityControlReviewTable'
import React from 'react'
import {ReviewContainer} from './ReviewContainer'
import {Box, Text} from 'grommet'
import {ReclassificationAnnotationEntity, ReclassificationDTO} from '../entities/reclassification-annotation'
import {User} from '../../User/entities/user'
import {ReclassificationActions} from '../api/reclassificationAnnotations'
import {SRIconButton, SRPrimaryButton, SRSecondaryButton} from 'sr-react-commons'
import {DocumentCsv} from 'grommet-icons'

export function ReviewControlsContainer(props: {
	selectedElement: ReclassificationDTO | null
	reviewActions: Pick<ReclassificationActions, 'approve' | 'reclassify'>
	annotation?: ClassificationAnnotation
	users: User[]
	tableInstance: TableInstance<AnnotationsTableData>
	onSelect: (classificationId: string) => void
	onReview: (classificationId: string) => void
	onIsolateFiltered: () => void
	onExport?: () => void
	reviewedElementsCount: number
	totalElementsCount: number
	onFinish: () => Promise<any>
	canFinish: boolean
	cannotFinishTooltip?: string
	projectId: string
}) {
	const [status, setStatus] = React.useState<'list' | 'review'>('list')
	const backToListView = (data: ReclassificationAnnotationEntity | undefined) => {
		setStatus('list')
		return data
	}

	return (
		<>
			{status === 'review' ? (
				props.selectedElement && (
					<ReviewContainer
						showAnalysisLink={false}
						reviewActions={{
							approve: classificationId => props.reviewActions.approve(classificationId).then(backToListView),
							reclassify: (annotationId, formData) =>
								props.reviewActions.reclassify(annotationId, formData).then(backToListView),
						}}
						onIsolateFiltered={props.onIsolateFiltered}
						onBack={() => setStatus('list')}
						selectedElement={props.selectedElement}
						users={props.users}
						projectId={props.projectId}
					/>
				)
			) : (
				<Box gap="xsmall" flex>
					<Box direction={'row'} justify={'between'} gap={'xsmall'} flex={false}>
						<Box direction={'row'} align={'end'} gap={'xsmall'}>
							<SRIconButton
								icon={<DocumentCsv size={'small'} />}
								size={'small'}
								title={'Export all as CSV'}
								onClick={props.onExport}
							/>
							<SRSecondaryButton
								size={'small'}
								onClick={props.onIsolateFiltered}
								label="Isolate elements in work package"
							/>
						</Box>
						<Box>
							{!props.canFinish && <Text size={'small'}>{props.cannotFinishTooltip}</Text>}
							<Box direction={'row'} gap={'small'} justify={'end'}>
								<Box justify={'end'}>
									Elements reviewed: {props.reviewedElementsCount} / {props.totalElementsCount}
								</Box>
								<SRPrimaryButton
									alignSelf="start"
									size="small"
									disabled={!props.canFinish}
									label={'Finish review'}
									onClick={props.onFinish}
								/>
							</Box>
						</Box>
					</Box>
					<QualityControlReviewTable
						tableInstance={props.tableInstance}
						currentClassification={props.selectedElement?.classification || null}
						onSelect={props.onSelect}
						onReview={classificationId => {
							props.onReview(classificationId)
							setStatus('review')
						}}
					/>
				</Box>
			)}
		</>
	)
}
