import {QueryConfig, useQuery} from 'react-query'
import {Pagination} from '../api/baseApi'
import {fetchWithQuery} from '../repositories/base.repository'
import _ from 'lodash'
import {AxiosError} from 'axios'

type LikeFilter = {
	like: string
}

export type FilterValue = string | string[] | LikeFilter

export type PageParams = {page: number; perPage: number}

export type Filter<T extends Record<string, any>> = {
	[P in keyof T]?: FilterValue
} & {
	_ids?: string[]
}

export type Sorting<T extends Record<string, any>> = {
	field: keyof T | null
	ascending: boolean
}

export function useData<T>(
	entity: string,
	{page, perPage}: PageParams,
	filters: Filter<T> = {},
	sorting: Sorting<T> = {field: null, ascending: true},
	options: QueryConfig<Pagination<T>> = {},
) {
	const sortedFilters: Filter<Record<string, any>> = {}
	Object.entries(filters).forEach(([key, value]) => {
		sortedFilters[key] = _.isArray(value) ? [...value].sort() : value
	})
	const _sorting: Sorting<Record<string, any>> = {field: String(sorting.field), ascending: sorting.ascending}
	return useQuery<Pagination<T>, AxiosError>(
		[entity, {page, perPage}, sortedFilters, sorting],
		() => fetchWithQuery(entity, {page, perPage}, sortedFilters, _sorting),
		options,
	)
}
