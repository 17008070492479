import React from 'react'
import {MainAppWithAuth} from './components/MainAppWithAuth'
import {UserProvider} from './features/User/components/UserProvider'
import {Grommet} from 'grommet'
import {NotificationProvider} from './features/Notifications/components/NotificationProvider'
import {amplifyConfig} from './config/amplify'
import Amplify from '@aws-amplify/core'
import {theme} from './theme'
import {Provider} from 'react-redux'
import {store} from './redux/slice'
import {ShowMaintenanceMode} from './components/MaintenanceMode/ShowMaintenanceMode'
import {FeatureFlagsProvider} from './features/FeatureFlags/FeatureFlagsProvider'

Amplify.configure(amplifyConfig)

function App() {
	return (
		<Provider store={store}>
			<FeatureFlagsProvider>
				<UserProvider>
					<Grommet theme={theme} full>
						<ShowMaintenanceMode>
							<NotificationProvider>
								<MainAppWithAuth />
							</NotificationProvider>
						</ShowMaintenanceMode>
					</Grommet>
				</UserProvider>
			</FeatureFlagsProvider>
		</Provider>
	)
}

export default App
