import React from 'react'
import {Redirect, Route, Switch, useLocation} from 'react-router-dom'
import {NotFoundPage} from '../pages/NotFoundPage'
import {useRoutes} from '../hooks/useRoutes'
import {Box, Sidebar} from 'grommet'
import {SidebarNavigation} from './SidebarNavigation/SidebarNavigation'
import {FormNext, FormPrevious} from 'grommet-icons'
import {SRIconButton} from 'sr-react-commons'

export function AppContent() {
	const [sidebarState, setSidebarState] = React.useState<'collapsed' | 'opened'>('opened')
	const routes = useRoutes()
	const {pathname} = useLocation()
	return (
		<Box fill={true} direction={'row'}>
			{pathname.startsWith('/reclassifications/manage/') && (
				<Box fill={'vertical'} direction={'row'}>
					<Sidebar
						pad={'none'}
						width={sidebarState === 'collapsed' ? 'xxsmall' : 'medium'}
						background="light-3"
						border={'right'}
					>
						{sidebarState === 'opened' && <SidebarNavigation />}
					</Sidebar>
					<Box
						flex={false}
						style={{position: 'relative'}}
						alignSelf={'start'}
						margin={{top: 'medium', left: '-20px'}}
						background={'light-1'}
					>
						{sidebarState === 'collapsed' ? (
							<SRIconButton hasBorder icon={<FormNext />} onClick={() => setSidebarState('opened')} />
						) : (
							<SRIconButton hasBorder icon={<FormPrevious />} onClick={() => setSidebarState('collapsed')} />
						)}
					</Box>
				</Box>
			)}
			<Switch>
				<Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
				{Object.values(routes).map(route => (
					<Route key={route.path} exact={route.exact} path={route.path}>
						{route.renderMainComponent()}
					</Route>
				))}
				<Route>
					<NotFoundPage />
				</Route>
			</Switch>
		</Box>
	)
}
