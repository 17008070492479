import {Filter, useData} from '../../../../hooks/useData'
import {
	ReclassificationWorkPackageStageTaskEntity,
	ReclassificationWorkPackageStageTaskEntityWithWorkPackage,
} from '../../entities/reclassification-work-package-stage-task'

export function useCurrentUserStageTasks(filter: Filter<ReclassificationWorkPackageStageTaskEntity> = {}) {
	return useData<ReclassificationWorkPackageStageTaskEntityWithWorkPackage>(
		'reclassification/reclassification-work-package-stage-tasks/by-current-user',
		{page: 0, perPage: 10000},
		filter,
		{ascending: true, field: 'status'},
		{staleTime: 60 * 1000},
	)
}
