import {Box, Heading} from 'grommet'
import React from 'react'
import {Link} from 'react-router-dom'
import {Page} from '../components/Page/Page'
import {useRoutes} from '../hooks/useRoutes'

export function NotFoundPage() {
	const routes = useRoutes()
	return (
		<Page>
			<Box align={'center'} fill>
				<Heading level={1}>Page not found.</Heading>
				<Link to={routes.home.linkTo({})}>Back to home</Link>
			</Box>
		</Page>
	)
}
