import {UserProject} from '../../features/User/entities/userProject'
import React, {useEffect, useState} from 'react'
import {Box, Heading} from 'grommet'
import {Viewer} from '../../features/Viewer/components/Viewer'
import PhotoSpherePanelContent from '../../features/ViewerAssets/Photosphere/PhotoSpherePanelContent'
import {usePhotosphereExtension} from '../../features/ViewerAssets/Photosphere/usePhotosphereExtension'
import {PhotoSphereControls} from '../../features/ViewerAssets/Photosphere/PhotoSphereControls'
import {useViewer} from '../../features/Viewer/hooks/useViewer'
import {Lock, Unlock} from 'grommet-icons'
import {ReclassificationTaskEntity} from '../../features/Reclassification/entities/reclassification-task'
import {Classification} from '../../entities/classification'
import {useFeatureEnabled} from '../../features/FeatureFlags/FeatureFlagsProvider'
import {SRBrandSelectableButton} from 'sr-react-commons'

export function LockedViewsContainer({
	classification,
	project,
	reclassification,
	isolateElementOnSelection = true,
}: {
	classification: Classification | null
	project: UserProject
	reclassification: ReclassificationTaskEntity
	isolateElementOnSelection?: boolean
}) {
	const useSVF2ViewerOptions = useFeatureEnabled('useSVF2ViewerOptions')
	const viewerOptions = React.useMemo(() => {
		return {
			forceNearRadius: project.viewerFeatureFlags.forceNearRadius,
			initialState: project.viewerState,
			useSVF2ViewerOptions,
		}
	}, [project.viewerFeatureFlags.forceNearRadius, project.viewerState, useSVF2ViewerOptions])

	const {
		status: viewerStatus,
		actions: {isolateAndZoomToClassifiedElement, isolateElement, fitToView},
	} = useViewer()

	const {
		currentImageIndex,
		setCurrentImageIndex,
		viewsphere,
		cameraOrientation,
		setCameraOrientation,
		cameraFov,
		setCameraFov,
		viewsLocked,
		setViewsLocked,
	} = usePhotosphereExtension(classification)

	const [highlightElement, setHighlightElement] = useState(false)

	useEffect(() => {
		if (viewerStatus === 'model_loaded' && classification) {
			if (viewsLocked) {
				if (isolateElementOnSelection) {
					isolateElement(classification.forgeObjectId)
				}
			} else {
				if (isolateElementOnSelection) {
					isolateAndZoomToClassifiedElement(classification.forgeObjectId)
				} else {
					fitToView(classification.forgeObjectId)
				}
			}
		}
	}, [
		classification,
		isolateAndZoomToClassifiedElement,
		viewsLocked,
		viewerStatus,
		isolateElement,
		isolateElementOnSelection,
		fitToView,
	])

	return (
		<Box fill direction={'column'}>
			<Box fill="horizontal">
				{classification ? (
					<Box direction={'row'}>
						<Box width={'25%'}>
							<PhotoSphereControls
								checked={highlightElement}
								onToggleHighlightElement={() => setHighlightElement(!highlightElement)}
								onPrevious={() =>
									setCurrentImageIndex(current => {
										let newIndex = (current - 1) % classification!.viewspheres.length
										if (newIndex < 0) {
											newIndex = classification!.viewspheres.length + newIndex
										}
										return newIndex
									})
								}
								onNext={() => setCurrentImageIndex(current => (current + 1) % classification!.viewspheres.length)}
								currentImageIndex={currentImageIndex}
								viewspheres={classification!.viewspheres}
							/>
						</Box>
						<Box width={'50%'} justify={'center'} align={'center'}>
							<Box width={'180px'}>
								<SRBrandSelectableButton
									size={'small'}
									isSelected={viewsLocked}
									icon={viewsLocked ? <Lock size={'small'} /> : <Unlock size={'small'} />}
									reverse
									label={viewsLocked ? 'Unlock movement' : 'Lock movement'}
									onClick={() => setViewsLocked(prevState => !prevState)}
								/>
							</Box>
						</Box>
					</Box>
				) : null}
			</Box>
			<Box fill direction={'row'}>
				<Box fill={'vertical'} width={'50%'} pad={'xxsmall'}>
					{classification && viewsphere ? (
						<Box fill>
							<PhotoSpherePanelContent
								highlightElement={highlightElement}
								viewsphere={viewsphere}
								classification={classification}
								project={project}
								cameraOrientation={cameraOrientation}
								setCameraOrientation={setCameraOrientation}
								cameraFov={cameraFov}
								setCameraFov={setCameraFov}
							/>
						</Box>
					) : (
						<Heading level={'3'}>No item selected</Heading>
					)}
				</Box>
				<Box fill={'vertical'} width={'50%'} pad={'xxsmall'}>
					<Viewer documentUrn={reclassification.model.modelUrn} viewerOptions={viewerOptions} />
				</Box>
			</Box>
		</Box>
	)
}
