import React, {Dispatch, ReactNode} from 'react'
import {Section} from '../../../../entities/classification'
import {noop} from 'lodash'

interface ElementSectionState {
	sections: Section[] | null
	currentSection: Section | null
	currentSectionIndex: number
}

const INITIAL_STATE: ElementSectionState = {sections: null, currentSection: null, currentSectionIndex: 0}

const ElementSectionStateContext = React.createContext<{
	state: ElementSectionState
	dispatch: Dispatch<ElementSectionStateAction>
}>({state: INITIAL_STATE, dispatch: noop})

type ElementSectionStateAction =
	| {type: 'setSections'; payload: Section[] | null}
	| {type: 'setCurrentSectionIndex'; payload: number}

const elementSectionStateReducer = (
	state: ElementSectionState,
	action: ElementSectionStateAction,
): ElementSectionState => {
	switch (action.type) {
		case 'setSections': {
			return {...state, sections: action.payload, currentSection: action.payload?.[0] || null, currentSectionIndex: 0}
		}
		case 'setCurrentSectionIndex': {
			return {
				...state,
				currentSection: state.sections?.[action.payload] || null,
				currentSectionIndex: action.payload,
			}
		}
	}
}

export const ElementSectionStateProvider = ({children}: {children: ReactNode}) => {
	const [state, dispatch] = React.useReducer(elementSectionStateReducer, INITIAL_STATE)
	return <ElementSectionStateContext.Provider value={{state, dispatch}}>{children}</ElementSectionStateContext.Provider>
}

export const useElementSectionState = () => {
	const {state, dispatch} = React.useContext(ElementSectionStateContext)
	const {currentSectionIndex, sections} = state
	const incrIndex = React.useCallback(
		(amount: number) => (!sections ? 0 : (sections.length + currentSectionIndex + amount) % sections.length),
		[sections, currentSectionIndex],
	)
	return {
		state,
		actions: {
			setSections: React.useCallback(
				(sections: Section[] | null) => dispatch({type: 'setSections', payload: sections}),
				[dispatch],
			),
			nextSection: React.useCallback(() => dispatch({type: 'setCurrentSectionIndex', payload: incrIndex(1)}), [
				dispatch,
				incrIndex,
			]),
			prevSection: React.useCallback(() => dispatch({type: 'setCurrentSectionIndex', payload: incrIndex(-1)}), [
				dispatch,
				incrIndex,
			]),
		},
	}
}
