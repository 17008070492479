import {Column, Row} from 'react-table'
import {Box} from 'grommet'
import React from 'react'
import {SRClassificationStatusChip} from '../../SRClassificationStatusChip'

export type ReclassificationTableColumn<D extends object = {}> = Column<D> & {
	labelGetter?: (row: Row<D>) => string
	columns?: ReclassificationTableColumn<D>[]
	Title?: string
}
export const StatusTableCell = ({value}: {value: string | null}) => (
	<Box justify={'center'} fill align={'center'}>
		<SRClassificationStatusChip status={value} />
	</Box>
)
