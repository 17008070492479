import {TextMetrics, TextStyle} from 'pixi.js'
import {Container, Sprite, Text} from '@inlet/react-pixi'
import Line from './Line'
// @ts-ignore
import closeIcon from './images/close.svg'
import React from 'react'
// @ts-ignore
import convert from 'convert-units'
import {getDistance} from './ScaleUtils'
import {SR_SECTION_ANNOTATION} from 'sr-react-commons'
import Rectangle from './Rectangle'

const textStyle: TextStyle = new TextStyle({fill: SR_SECTION_ANNOTATION, fontFamily: 'Arial', fontSize: '14px'})
const CLOSE_SIZE = 10
const LINE_CLOSE_GAP = 5
const TEXT_CLOSE_GAP = 10
const LINE_TEXT_GAP = 5
const BACKGROUND_PAD = 4

export interface LineProps {
	id?: string
	startX: number
	startY: number
	endX: number
	endY: number
}

interface Props {
	lineProps: LineProps
	transformToGlobal: (point: [number, number]) => [number, number]
	zoom: number
	removeLine?: (id: string) => void
	scale: number
}

export function DistanceMeasureLine({lineProps, transformToGlobal, zoom, removeLine, scale}: Props) {
	const [startX, startY] = transformToGlobal([lineProps.startX, lineProps.startY])
	const [endX, endY] = transformToGlobal([lineProps.endX, lineProps.endY])
	let angle = Math.atan2(lineProps.endY - lineProps.startY, lineProps.endX - lineProps.startX) * (180 / Math.PI)
	let distance = getDistance([lineProps.startX, lineProps.startY], [lineProps.endX, lineProps.endY])
	let vectorLength = distance * zoom
	const normVector = [(endX - startX) / vectorLength, (endY - startY) / vectorLength]
	const normVectorPerp = [-normVector[1], normVector[0]]
	const offsetTextX = normVectorPerp[0] * LINE_TEXT_GAP
	const offsetTextY = normVectorPerp[1] * LINE_TEXT_GAP
	const distanceUnitObject = convert(distance * scale)
		.from('m')
		.to('mm')

	const text = Math.round(distanceUnitObject) + '  mm'
	let textMetrics = TextMetrics.measureText(text, textStyle)
	const textDistanceFromStart = vectorLength / 2 - textMetrics.width / 2
	const textOffsetX = offsetTextX + normVector[0] * textDistanceFromStart
	const textOffsetY = offsetTextY + normVector[1] * textDistanceFromStart
	let textX = startX + textOffsetX
	let textY = startY + textOffsetY
	const closeOffsetX = (textMetrics.width + TEXT_CLOSE_GAP) * normVector[0] + normVectorPerp[0] * LINE_CLOSE_GAP
	const closeOffsetY = (textMetrics.width + TEXT_CLOSE_GAP) * normVector[1] + normVectorPerp[1] * LINE_CLOSE_GAP
	let closeX = textX + closeOffsetX
	let closeY = textY + closeOffsetY
	let rectangleOffsetX = -(normVector[0] * (BACKGROUND_PAD / 2) + normVectorPerp[0] * (BACKGROUND_PAD / 2))
	let rectangleOffsetY = -(normVector[1] * (BACKGROUND_PAD / 2) + normVectorPerp[1] * (BACKGROUND_PAD / 2))
	if (angle < -90 || angle > 90) {
		rectangleOffsetX = normVector[0] * (BACKGROUND_PAD / 2) + normVectorPerp[0] * (BACKGROUND_PAD / 2)
		rectangleOffsetY = normVector[1] * (BACKGROUND_PAD / 2) + normVectorPerp[1] * (BACKGROUND_PAD / 2)
		const textDistanceFromStart = vectorLength / 2 + textMetrics.width / 2
		angle = angle + 180
		textX = startX - offsetTextX + normVector[0] * textDistanceFromStart
		textY = startY - offsetTextY + normVector[1] * textDistanceFromStart
		closeX = textX - closeOffsetX
		closeY = textY - closeOffsetY
	}
	return (
		<Container key={lineProps.id}>
			<Line startX={startX} startY={startY} endX={endX} endY={endY} />
			<Rectangle
				x={textX + rectangleOffsetX}
				y={textY + rectangleOffsetY}
				width={textMetrics.width + TEXT_CLOSE_GAP + CLOSE_SIZE + BACKGROUND_PAD}
				height={textMetrics.height + BACKGROUND_PAD}
				angle={angle}
				color={0xffffff}
				alpha={0.7}
			/>
			<Text x={textX} y={textY} text={text} angle={angle} style={textStyle} />
			{removeLine && lineProps.id !== undefined && (
				<Sprite
					tint={parseInt(SR_SECTION_ANNOTATION.slice(1), 16)}
					anchor={0}
					cursor="pointer"
					interactive={true}
					click={() => removeLine(lineProps.id || '')}
					x={closeX}
					y={closeY}
					image={closeIcon}
					angle={angle}
					width={CLOSE_SIZE}
					height={CLOSE_SIZE}
				/>
			)}
		</Container>
	)
}
