import React, {Dispatch, MutableRefObject, SetStateAction, useCallback, useEffect, useRef, useState} from 'react'
import {
	createBoundingBox,
	disposeThreeNode,
	getCameraTargetFromOrientation,
} from '../../Viewer/utilities/viewerUtilities'
import {Line, PerspectiveCamera, Scene, WebGLRenderer} from 'three'
import {Box} from 'grommet'
import {getS3KeyForPath, loadS3Image} from '../../../utilities/storageUtilities'
import {UserProject} from '../../User/entities/userProject'
import {useViewer} from '../../Viewer/hooks/useViewer'
import {Spinner} from '../../../components/Spinner/Spinner'
import {Stack} from 'grommet/es6'
import {Viewsphere} from '../../../entities/classification'
import {useMounted} from '../../../hooks/useMounted'
import {ReclassificationDTO} from '../../Reclassification/entities/reclassification-annotation'
const THREE = window.THREE

interface IProps {
	project: UserProject
	viewsphere: Viewsphere
	renderer: WebGLRenderer
	highlightElement: boolean
	classification: ReclassificationDTO['classification']
	cameraOrientation: [number, number]
	setCameraOrientation: React.Dispatch<React.SetStateAction<[number, number]>>
	cameraFov: number
	setCameraFov: React.Dispatch<React.SetStateAction<number>>
}

const sphereRadius = 500

const fov = 75
const near = 0.1
const far = 1000

const camera = new THREE.PerspectiveCamera(fov, undefined, near, far)

export default function PhotoSphereRenderer({
	project,
	viewsphere,
	renderer,
	highlightElement,
	classification,
	cameraOrientation,
	setCameraOrientation,
	cameraFov,
	setCameraFov,
}: IProps) {
	const mount = useRef<HTMLDivElement>(null)
	const [grabPosition, setGrabPosition] = useState<[number, number] | null>(null)
	const [grabOrientation, setGrabOrientation] = useState<[number, number] | null>(null)
	const sceneRef = useRef<Scene>(new THREE.Scene())
	const boundingBoxRef: MutableRefObject<Line | null> = useRef<Line>(null)
	const cameraRef: MutableRefObject<PerspectiveCamera> = useRef<PerspectiveCamera>(camera)

	const {viewer} = useViewer()

	const {isLoaded} = useImageAs360Texture(
		viewsphere,
		sceneRef,
		project,
		cameraRef,
		setCameraOrientation,
		renderer,
		viewer,
	)

	useEffect(() => {
		setupRenderer(mount as MutableRefObject<HTMLDivElement>, renderer)
	}, [mount, renderer])

	useEffect(() => {
		updateCamera(cameraRef, cameraOrientation, cameraFov)
	}, [cameraOrientation, cameraFov, cameraRef, isLoaded])

	useEffect(() => {
		updateBoundingBox(sceneRef, boundingBoxRef, classification, viewsphere, highlightElement, isLoaded)
	}, [viewsphere, boundingBoxRef, highlightElement, isLoaded, classification])

	useEffect(() => {
		const stop = startAnimation(cameraRef, sceneRef, mount as MutableRefObject<HTMLDivElement>, isLoaded, renderer)
		return () => {
			stop()
		}
	}, [isLoaded, renderer, cameraRef, sceneRef, mount])

	const {onZoom, onMouseDown, onMouseMove, onMouseUp} = useMouseCallbacks(
		setGrabPosition,
		setGrabOrientation,
		cameraOrientation,
		grabPosition,
		grabOrientation,
		setCameraOrientation,
		setCameraFov,
	)

	return (
		<Box fill={true}>
			<Stack fill={true}>
				<Box
					style={{
						cursor:
							'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAt1BMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD///8AAAAzMzP6+vri4uISEhKKioqtra2dnZ2EhIR9fX10dHRkZGQdHR3t7e3Hx8e5ubm1tbWoqKhWVlZKSko4ODgICAjv7+/o6OjMzMyxsbFOTk4pKSkXFxcEBAT29vbW1tZ6enpISEgLCwvhzeX+AAAAGXRSTlMANRO0nHRJHfnskIxQRKh89syDVwTWZjEJxPFEswAAAOFJREFUKM+1j+lygkAQhIflEAJe0Rw9u4CCeKKoSTTX+z9XoMJWWeX+ssrvZ3f19DQ5zOw/0DUMQPlmQ72bE2adBp8/Rp3CQUi3ILx+bxj4fjDs9T1Bmo6bbPPN8aDU4bjJt4nb+de789kSFyxn826jW3ICLNZZKU8nWWbrBTCRVm04U8TpjquRFf1Go0d7l8aYOrUR7FGEFr1S9LGymwthgX2gE/Kl0cHPOtF2xOWZ5QpIC93RflW4InkDoPRXesd5LJIMQPzV7tCMa7f6BvhJL79AVDmYTNQ1NhnxbI/uwB8H5Bjd4zQPBAAAAABJRU5ErkJggg=="), auto',
					}}
					fill={true}
					ref={mount}
					onMouseDown={onMouseDown}
					onWheel={onZoom}
					onMouseMove={onMouseMove}
					onMouseUp={onMouseUp}
					onMouseLeave={onMouseUp}
				/>
				{!isLoaded ? (
					<Box fill={true} alignSelf={'center'} background={'light-4'} justify={'center'}>
						<Spinner />
					</Box>
				) : null}
			</Stack>
		</Box>
	)
}

function useMouseCallbacks(
	setGrabPosition: Dispatch<SetStateAction<[number, number] | null>>,
	setGrabOrientation: Dispatch<SetStateAction<[number, number] | null>>,
	cameraOrientation: [number, number],
	grabPosition: [number, number] | null,
	grabOrientation: [number, number] | null,
	setCameraOrientation: Dispatch<SetStateAction<[number, number]>>,
	setCameraFov: Dispatch<SetStateAction<number>>,
) {
	const onZoom = useCallback(
		event => {
			updateZoom(setCameraFov, event)
		},
		[setCameraFov],
	)

	const onMouseDown = useCallback(
		event => {
			startGrab(event, setGrabPosition, setGrabOrientation, cameraOrientation)
		},
		[cameraOrientation, setGrabOrientation, setGrabPosition],
	)

	const onMouseMove = useCallback(
		event => {
			updateCameraOrientation(event, grabPosition, grabOrientation, setCameraOrientation)
		},
		[grabOrientation, grabPosition, setCameraOrientation],
	)

	const onMouseUp = useCallback(() => {
		stopGrab(setGrabPosition, setGrabOrientation)
	}, [setGrabOrientation, setGrabPosition])
	return {onZoom, onMouseDown, onMouseMove, onMouseUp}
}

function useImageAs360Texture(
	viewsphere: {imagePath: string; elementCentroid: [string, string]},
	sceneRef: React.MutableRefObject<Scene>,
	project: UserProject,
	cameraRef: React.MutableRefObject<PerspectiveCamera>,
	setCameraOrientation: React.Dispatch<React.SetStateAction<[number, number]>>,
	renderer: WebGLRenderer,
	viewer: React.MutableRefObject<Autodesk.Viewing.GuiViewer3D | null>,
) {
	const {
		imagePath,
		elementCentroid: [centroidX, centroidY],
	} = viewsphere
	const isMounted = useMounted()
	const imagePathRef = useRef<string>(imagePath)
	const [isLoaded, setIsLoaded] = useState(false)
	useEffect(() => {
		const scene = sceneRef.current
		updatePhotoSphere(
			project,
			cameraRef,
			sceneRef,
			imagePathRef,
			setIsLoaded,
			imagePath,
			centroidX,
			centroidY,
			setCameraOrientation,
			isMounted,
		)
		return () => {
			cleanUpScene(scene)
		}
	}, [cameraRef, centroidX, centroidY, imagePath, isMounted, project, renderer, sceneRef, setCameraOrientation, viewer])
	return {isLoaded}
}

function setupRenderer(mount: React.MutableRefObject<HTMLDivElement>, renderer: WebGLRenderer) {
	if (mount.current) {
		mount.current.appendChild(renderer.domElement)
	}
}

function updateCamera(
	cameraRef: React.MutableRefObject<PerspectiveCamera>,
	cameraOrientation: number[],
	cameraFov: number,
) {
	//UPDATES PHOTOSPHERE TARGET
	const camera = cameraRef.current

	if (camera) {
		const [lat, lon] = cameraOrientation

		//Converts Orientation to Target for PhotoSphere Cam
		const target = getCameraTargetFromOrientation(lat, lon).multiplyScalar(sphereRadius)
		camera.lookAt(target)
		camera.fov = cameraFov
	}
}

function cleanUpScene(scene: Scene) {
	if (scene) {
		for (let i = scene.children.length - 1; i >= 0; i--) {
			disposeThreeNode(scene.children[i])
			scene.remove(scene.children[i])
		}
	}
}

function updatePhotoSphere(
	project: UserProject,
	cameraRef: React.MutableRefObject<PerspectiveCamera>,
	sceneRef: React.MutableRefObject<Scene>,
	imagePathRef: React.MutableRefObject<string>,
	setIsLoaded: Dispatch<SetStateAction<boolean>>,
	imagePath: string,
	centroidX: string,
	centroidY: string,
	setCameraOrientation: Dispatch<SetStateAction<[number, number]>>,
	isMounted: () => boolean,
) {
	if (!project) {
		return
	}

	const camera = cameraRef.current
	const scene = sceneRef.current
	const textureLoader = new THREE.TextureLoader()
	setIsLoaded(false)
	imagePathRef.current = imagePath

	textureLoader.setCrossOrigin('Anonymous')

	cleanUpScene(scene)

	// TODO move this to a service worker
	loadS3Image(getS3KeyForPath(project, imagePath)).then(imgUrl => {
		if (!isMounted() || imagePathRef.current !== imagePath) {
			return
		}
		textureLoader.load(imgUrl, texture => {
			if (!isMounted() || imagePathRef.current !== imagePath) {
				return
			}
			const sphereGeometry = new THREE.SphereGeometry(sphereRadius, 60, 40)
			sphereGeometry.applyMatrix(new THREE.Matrix4().makeScale(-1, 1, 1))

			const viewsphereTexture = new THREE.MeshBasicMaterial({
				map: texture,
			})

			const viewSphereMesh = new THREE.Mesh(sphereGeometry, viewsphereTexture)
			viewSphereMesh.name = 'photoSphereMesh'

			const lat = Math.max(-85, Math.min(85, parseFloat(centroidX)))
			const lon = parseFloat(centroidY)
			setCameraOrientation([lat, lon])
			if (scene && camera) {
				scene.add(camera)
				scene.add(viewSphereMesh)
			}

			setIsLoaded(true)
		})
	})
}

function updateBoundingBox(
	sceneRef: React.MutableRefObject<Scene>,
	boundingBoxRef: React.MutableRefObject<Line | null>,
	classification: ReclassificationDTO['classification'],
	viewsphere: Viewsphere,
	highlightElement: boolean,
	isLoaded: boolean,
) {
	const scene = sceneRef.current

	if (scene) {
		if (boundingBoxRef.current) {
			scene.remove(boundingBoxRef.current)
		}
		const boundingBox = createBoundingBox(viewsphere.elementBoundaries)
		boundingBox.visible = highlightElement && isLoaded
		boundingBox.name = 'boundingBoxLine'
		scene.add(boundingBox)
		boundingBoxRef.current = boundingBox
	}
}

function updateZoom(setCameraFov: Dispatch<SetStateAction<number>>, event: any) {
	// WebKit
	const nativeEvent = event.nativeEvent
	let update = 0
	if (nativeEvent.wheelDeltaY) {
		update = -(nativeEvent.wheelDeltaY * 0.05)
		// Opera / Explorer 9
	} else if (nativeEvent.wheelDelta) {
		update = -(nativeEvent.wheelDelta * 0.05)
		// Firefox
	} else if (nativeEvent.detail) {
		update = nativeEvent.detail
	}

	// Clamp FOV values to avoid overflows
	setCameraFov(current => Math.min(Math.max(current + update, 5), 120))
}

function startGrab(
	event: any,
	setGrabPosition: Dispatch<SetStateAction<[number, number] | null>>,
	setGrabOrientation: Dispatch<SetStateAction<[number, number] | null>>,
	cameraOrientation: [number, number] | null,
) {
	const nativeEvent = event.nativeEvent
	setGrabPosition([nativeEvent.clientX, nativeEvent.clientY])
	setGrabOrientation(cameraOrientation)
}

function updateCameraOrientation(
	event: any,
	grabPosition: [number, number] | null,
	grabOrientation: [number, number] | null,
	setCameraOrientation: Dispatch<SetStateAction<[number, number]>>,
) {
	const nativeEvent = event.nativeEvent
	if (grabPosition && grabOrientation) {
		const [grabX, grabY] = grabPosition
		const [grabLat, grabLon] = grabOrientation
		let lat = (nativeEvent.clientY - grabY) * 0.1 + grabLat
		lat = Math.max(-85, Math.min(85, lat))
		const lon = ((grabX - nativeEvent.clientX) * 0.1 + grabLon) % 360
		setCameraOrientation([lat, lon])
	}
}

function stopGrab(
	setGrabPosition: (value: ((prevState: any) => undefined) | any) => void,
	setGrabOrientation: (value: ((prevState: any) => undefined) | any) => void,
) {
	setGrabPosition(null)
	setGrabOrientation(null)
}

function startAnimation(
	cameraRef: React.MutableRefObject<PerspectiveCamera>,
	sceneRef: React.MutableRefObject<Scene>,
	mount: React.MutableRefObject<HTMLDivElement>,
	isLoaded: boolean,
	renderer: WebGLRenderer,
) {
	let frameId: number
	const camera = cameraRef.current
	const scene = sceneRef.current

	const animate = () => {
		const canvas = mount.current
		if (isLoaded && canvas && !renderer.context.isContextLost()) {
			const mountWidth = canvas.offsetWidth
			const mountHeight = canvas.offsetHeight
			camera.aspect = mountWidth / mountHeight
			camera.updateProjectionMatrix()

			renderer.setSize(mountWidth, mountHeight, false)
			renderer.render(scene, camera)
			frameId = requestAnimationFrame(animate)
		}
	}

	const start = () => {
		if (!frameId) {
			frameId = requestAnimationFrame(animate)
		}
	}

	const stop = () => {
		if (frameId) {
			cancelAnimationFrame(frameId)
			frameId = -1
		}
	}

	if (isLoaded) {
		start()
	} else {
		stop()
	}
	return stop
}
