import {PageTitle} from '../../components/TopNavigationBar/PageTitle'
import React from 'react'
import {Separator} from '../../components/TopNavigationBar/Separator'
import {BreadcrumbItem} from '../../components/TopNavigationBar/BreadcrumbItem'
import {Container} from '../../components/Container/Container'
import {Page} from '../../components/Page/Page'
import {Box, Select, Table, TableBody, TableCell, TableHeader, TableRow, Text} from 'grommet'
import {PaginationControls} from '../../components/PaginationControls/PaginationControls'
import {Ascending, Descending} from 'grommet-icons'
import _ from 'lodash'
import {useProjects} from '../../features/User/hooks/useProjects'
import {UserProject} from '../../features/User/entities/userProject'
import {format} from 'date-fns'
import {Link} from 'react-router-dom'
import {useRoutes} from '../../hooks/useRoutes'
import {ReclassificationTaskEntity} from '../../features/Reclassification/entities/reclassification-task'
import {useAllReclassificationTasks} from '../../features/Reclassification/hooks/reclassificationTask/useAllReclassificationTasks'
import {useProject} from '../../features/User/hooks/useProject'
import {Spinner} from '../../components/Spinner/Spinner'
import {useProjectAnalysis} from '../../features/Reclassification/hooks/useAnalysis'
import {useProjectAnalysisVersion} from '../../features/Reclassification/hooks/useProjectAnalysisVersion'
import {goTo} from '../../routing/history'
import {Sorting} from '../../hooks/useData'
import {usePaginationQueryParams} from '../../hooks/usePaginationQueryParams'
import {SRChip, SREmptyView, SRHeading, SRPlainButton, SRSecondaryButton} from 'sr-react-commons'
import {RECLASSIFICATION_STATUS_COLORS} from '../../utilities/colors'

export function AllReclassificationsPageTitle() {
	return (
		<PageTitle>
			<Separator />
			<BreadcrumbItem>All reclassifications</BreadcrumbItem>
		</PageTitle>
	)
}

export function SortTableTitle<T>(props: {
	tableColumnName: keyof T
	label?: string
	toggleSort: (columnKey: keyof T) => void
	sorting: {field: string | null; ascending: boolean}
}) {
	return (
		<SRPlainButton onClick={() => props.toggleSort(props.tableColumnName)}>
			<Box direction={'row'} align={'center'}>
				<Text weight={'bold'}>{props.label || _.capitalize(String(props.tableColumnName))}</Text>
				{props.sorting.field === props.tableColumnName && (props.sorting.ascending ? <Ascending /> : <Descending />)}
			</Box>
		</SRPlainButton>
	)
}

function ProjectFilter(props: {
	value: string | undefined
	allProjects: UserProject[]
	onChange: (nextValue: string) => void
}) {
	const allOptions = [
		{label: 'All', value: 'All'},
		...props.allProjects.map(project => ({label: project.name, value: project.tenantId})),
	]
	const [options, setOptions] = React.useState(allOptions)

	const value = props.value || 'All'

	return (
		<Select
			size={'small'}
			valueKey={'value'}
			labelKey={'label'}
			value={value}
			valueLabel={
				<Box width={{max: 'small'}} pad={{vertical: 'xxsmall', horizontal: 'xsmall'}}>
					<Text truncate={true}>{allOptions.find(option => option.value === value)?.label}</Text>
				</Box>
			}
			options={options}
			onChange={({option}) => props.onChange(option.value === 'All' ? undefined : option.value)}
			onSearch={text => {
				const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')
				const exp = new RegExp(escapedText, 'i')
				setOptions(allOptions.filter(o => exp.test(o.label)))
			}}
			onClose={() => setOptions(allOptions)}
		/>
	)
}

function ReclassificationTableRow(props: {reclassification: ReclassificationTaskEntity}) {
	const routes = useRoutes()
	const [clientSlug, projectSlug] = props.reclassification.tenantId.split('|')
	const {project} = useProject(clientSlug, projectSlug)
	const {data: version} = useProjectAnalysisVersion(project?._id, props.reclassification.version)
	const {data: analysis} = useProjectAnalysis(project?._id, version?.analysis)
	return (
		<TableRow>
			<TableCell>
				<Box width={{min: '200px'}} fill={'horizontal'}>
					<Text title={project?.name} truncate={true}>
						{project?.name}
					</Text>
				</Box>
			</TableCell>
			<TableCell>
				<Box width={'200px'}>
					{analysis && version && (
						<Box direction="row">
							<Text title={analysis.name} truncate={true}>
								{analysis.name}
							</Text>
							<Text>v{version.versionNumber}</Text>
						</Box>
					)}
				</Box>
			</TableCell>
			<TableCell>
				<Box width={'200px'}>
					<Text title={props.reclassification.name} truncate={true}>
						{props.reclassification.name}
					</Text>
				</Box>
			</TableCell>
			<TableCell>
				<SRChip
					text={props.reclassification.status}
					background={RECLASSIFICATION_STATUS_COLORS[props.reclassification.status]}
				/>
			</TableCell>
			<TableCell>{format(new Date(props.reclassification.createdDate), 'MM/dd/yyyy')}</TableCell>
			<TableCell>
				<Link
					to={routes.reclassificationOverview.linkTo({
						clientSlug: props.reclassification.tenantId.split('|')[0],
						projectSlug: props.reclassification.tenantId.split('|')[1],
						reclassificationId: props.reclassification._id,
					})}
				>
					<SRSecondaryButton size={'small'} alignSelf={'end'} label={'Manage'} />
				</Link>
			</TableCell>
		</TableRow>
	)
}

export function AllReclassificationsPage() {
	const routes = useRoutes()
	const {page, filter, sorting} = usePaginationQueryParams()
	const PAGE_SIZE = 20
	const {data: reclassifications, status} = useAllReclassificationTasks(
		{page: page - 1, perPage: PAGE_SIZE},
		filter,
		sorting,
		{
			keepPreviousData: true,
		},
	)

	const goToPagination = (
		page: number,
		filter: Record<string, string>,
		sorting: Sorting<ReclassificationTaskEntity>,
	) => {
		const url = routes.allReclassifications.linkTo(
			{},
			{page: page.toString(), filter: JSON.stringify(filter), sorting: JSON.stringify(sorting)},
		)
		goTo(url)
	}

	const goToPage = (page: number) => {
		goToPagination(page, filter, sorting)
	}
	const goToFilter = (filterName: string, value: string) => {
		goToPagination(page, {...filter, [filterName]: value}, sorting)
	}
	const goToSorting = (columnKey: keyof ReclassificationTaskEntity) => {
		const newSorting = {
			field: columnKey,
			ascending: sorting.field === columnKey ? !sorting.ascending : false,
		}
		goToPagination(page, filter, newSorting)
	}
	const {data: allProjects} = useProjects({page: 0, perPage: 200}, {}, {field: 'name', ascending: true})

	return (
		<Page>
			<Container width={'1200px'} fill={'vertical'}>
				<SRHeading level={3}>All reclassifications</SRHeading>
				{reclassifications && (
					<Box fill gap={'small'}>
						<Box fill overflow={{vertical: 'auto'}}>
							<Table>
								<TableHeader>
									<TableRow>
										<TableCell>
											<Box align={'start'}>
												<Text weight={'bold'}>Project</Text>
												{allProjects && (
													<ProjectFilter
														value={filter['tenantId'] as string | undefined}
														allProjects={allProjects.result}
														onChange={nextValue => {
															goToFilter('tenantId', nextValue)
														}}
													/>
												)}
											</Box>
										</TableCell>
										<TableCell>
											<Box align={'start'}>
												<Text weight={'bold'}>Analysis</Text>
											</Box>
										</TableCell>
										<TableCell>
											<Box align={'start'}>
												<Text weight="bold">Name</Text>
											</Box>
										</TableCell>
										<TableCell>
											<Box width={'xsmall'}>
												<SortTableTitle<ReclassificationTaskEntity>
													tableColumnName={'status'}
													sorting={sorting}
													toggleSort={goToSorting}
												/>
												<Select
													size={'small'}
													alignSelf={'start'}
													multiple={true}
													value={(filter['status'] as string) || ['All']}
													options={['All', 'not started', 'in progress', 'finished']}
													onChange={event =>
														goToFilter(
															'status',
															event.option === 'All' ? undefined : event.value.filter((el: string) => el !== 'All'),
														)
													}
												/>
											</Box>
										</TableCell>
										<TableCell>
											<SortTableTitle
												tableColumnName={'createdDate'}
												label={'Created date'}
												sorting={sorting}
												toggleSort={goToSorting}
											/>
										</TableCell>
										<TableCell>Actions</TableCell>
									</TableRow>
								</TableHeader>
								<TableBody>
									{status === 'success' ? (
										reclassifications.result.length > 0 ? (
											reclassifications.result.map(reclassification => (
												<ReclassificationTableRow key={reclassification._id} reclassification={reclassification} />
											))
										) : (
											<TableRow>
												<TableCell colSpan={6}>
													<Box pad={'xlarge'}>
														<SREmptyView
															title={'No reclassification tasks found'}
															message={'Please refine the filters to get results.'}
														/>
													</Box>
												</TableCell>
											</TableRow>
										)
									) : (
										<Spinner />
									)}
								</TableBody>
							</Table>
						</Box>
						<PaginationControls
							start={reclassifications.skip}
							currentPageSize={PAGE_SIZE}
							totalCount={reclassifications.count}
							page={page}
							pageCount={Math.max(1, Math.ceil(reclassifications.count / PAGE_SIZE))}
							setPage={page => goToPage(page)}
							currentPageLen={reclassifications.result.length}
						/>
					</Box>
				)}
			</Container>
		</Page>
	)
}
