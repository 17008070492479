import {Box, Text} from 'grommet'
import React from 'react'
import _ from 'lodash'
import {SRIconButton, SRPageSelector, SRPageSizeSelector} from 'sr-react-commons'
import {CaretNext, CaretPrevious, FastForward} from 'grommet-icons'

export function PaginationControls({
	start,
	totalCount,
	page,
	setPage,
	pageCount,
	currentPageLen,
	setCurrentPageSize,
	currentPageSize,
}: {
	start: number
	totalCount: number
	page: number
	pageCount: number
	setPage: (value: number) => void
	currentPageLen: number
	setCurrentPageSize?: (value: number) => void
	currentPageSize: number
}) {
	return (
		<Box flex={false} direction={'row'} justify={'between'}>
			<Box direction={'row'} gap={'xxsmall'}>
				<SRIconButton
					size="small"
					disabled={page === 1}
					icon={<FastForward />}
					style={{transform: 'rotate(180deg)'}}
					onClick={() => setPage(1)}
				/>
				<SRIconButton size="small" disabled={page === 1} icon={<CaretPrevious />} onClick={() => setPage(page - 1)} />
			</Box>
			<Box align={'center'} direction={'row'} gap={'medium'}>
				<Text size={'xsmall'}>
					Displaying items {start + 1} - {Math.min(start + currentPageLen, totalCount)} of {totalCount}
				</Text>
				<SRPageSelector value={page} options={_.range(1, pageCount + 1)} onChange={setPage} />
				{setCurrentPageSize && (
					<SRPageSizeSelector value={currentPageSize} options={[10, 25, 50, 75, 100]} onChange={setCurrentPageSize} />
				)}
			</Box>
			<Box direction={'row'} gap={'xxsmall'}>
				<SRIconButton
					size="small"
					disabled={page === pageCount}
					icon={<CaretNext />}
					onClick={() => setPage(page + 1)}
				/>
				<SRIconButton
					size="small"
					disabled={page === pageCount}
					icon={<FastForward />}
					onClick={() => setPage(pageCount)}
				/>
			</Box>
		</Box>
	)
}
