import {Box, Layer, Text} from 'grommet'
import React, {ChangeEvent, useCallback, useState} from 'react'
import {
	SRHeading,
	SRIconButton,
	SRPlainButton,
	SRPrimaryButton,
	SRSimpleSelect,
	SRSpinnerIcon,
	SRTextInput,
} from 'sr-react-commons'
import {Checkmark, FormClose} from 'grommet-icons'
import {SRClassificationStatusChip} from './SRClassificationStatusChip'
import {useReclassificationAnnotationMutations} from '../hooks/reclassificationAnnotations/useReclassificationAnnotationMutations'
import {queryCache} from 'react-query'
import {useParams} from 'react-router-dom'
import {FinalReviewRouteParams} from '../../../routes/FinalReview/FinalReviewPage'
import {useProject} from '../../User/hooks/useProject'
import {AnnotationClassificationType} from '../entities/classificationAnnotation'

const VALID_LABELS: AnnotationClassificationType[] = [
	'deviated',
	'verified',
	'missing',
	'under_construction',
	'no_data',
]
const LABELS_WITH_MAGNITUDE: AnnotationClassificationType[] = ['deviated', 'verified']
const labelHasMagnitude = (label?: AnnotationClassificationType) => label && LABELS_WITH_MAGNITUDE.includes(label)

export type ReclassificationEditSelectedModalProps = {
	isOpen: boolean
	onClose: () => void
	selectedClassifications: string[]
}
export const ReclassificationEditSelectedModal = ({
	isOpen,
	onClose,
	selectedClassifications,
}: ReclassificationEditSelectedModalProps) => {
	const {clientSlug, projectSlug, reclassificationId} = useParams<FinalReviewRouteParams>()
	const {project} = useProject(clientSlug, projectSlug)
	const [newLabel, setNewLabel] = useState<AnnotationClassificationType>()
	const [newMagnitude, setNewMagnitude] = useState<number>()
	const {bulkReclassifyByClassificationsIdsMutation} = useReclassificationAnnotationMutations(project?._id, () =>
		queryCache.invalidateQueries(['reclassification-annotations', 'final-review', reclassificationId]),
	)
	const onLabelSelected = useCallback(
		(label: string) => {
			setNewLabel(label as AnnotationClassificationType)
			if (!labelHasMagnitude(label as AnnotationClassificationType)) setNewMagnitude(undefined)
		},
		[setNewLabel, setNewMagnitude],
	)
	const onMagnitudeSelected = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			setNewMagnitude(parseFloat(e.target.value))
		},
		[setNewMagnitude],
	)
	const [isLoading, setIsLoading] = useState(false)
	const reclassify = useCallback(async () => {
		setIsLoading(true)
		try {
			await bulkReclassifyByClassificationsIdsMutation({
				classificationsIds: selectedClassifications,
				reclassificationId,
				status: newLabel!,
				magnitude: newMagnitude !== undefined ? newMagnitude / 1000 : null,
			})
			onClose()
		} catch (e) {
			// TODO: IMPROVE THIS ERROR HANDLER
			alert('There was an error performing the bulk reclassification operation, try again please.')
		}
		setIsLoading(false)
	}, [
		bulkReclassifyByClassificationsIdsMutation,
		selectedClassifications,
		reclassificationId,
		newLabel,
		newMagnitude,
		onClose,
	])
	const canReclassify = !isLoading && newLabel && (!labelHasMagnitude(newLabel) || newMagnitude !== undefined)
	return isOpen ? (
		<Layer onClickOutside={onClose}>
			<Box gap={'medium'} width={'450px'} pad={'medium'} flex>
				<Box fill={'horizontal'} justify={'between'} align={'center'} direction={'row'}>
					<SRHeading level={'4'}>Edit selected</SRHeading>
					<SRIconButton disabled={isLoading} icon={<FormClose />} onClick={onClose} />
				</Box>
				<Box fill gap={'small'}>
					<SRHeading level={5}>{selectedClassifications.length} Elements selected</SRHeading>
					<Text>
						The selected elements will be assigned new values, and will marked as reviewed <Checkmark size={'10px'} />
					</Text>
					<Box direction={'row'} gap={'medium'}>
						<Box width={'50%'} gap={'xxsmall'}>
							<Text weight={'bold'} size={'small'}>
								Assign Label
							</Text>
							<SRSimpleSelect
								value={newLabel}
								options={VALID_LABELS.map(value => ({
									value,
									label: (
										<Box fill={'horizontal'} justify={'center'}>
											<SRClassificationStatusChip status={value} />
										</Box>
									),
								}))}
								size={'small'}
								focusIndicator={false}
								onChange={onLabelSelected}
								placeholder={'Choose a label'}
							/>
						</Box>
						{!labelHasMagnitude(newLabel) ? null : (
							<Box width={'50%'} gap={'xxsmall'}>
								<Text weight={'bold'} size={'small'}>
									Assign Magnitude
								</Text>
								<SRTextInput
									style={{padding: '9px'}}
									type={'number'}
									focusIndicator={false}
									value={newMagnitude}
									onChange={onMagnitudeSelected}
								/>
							</Box>
						)}
					</Box>
				</Box>
				<Box fill={'horizontal'} direction={'row'} align={'center'} justify={'between'}>
					<SRPlainButton label={'Cancel'} onClick={onClose} disabled={isLoading} />
					<SRPrimaryButton
						disabled={!canReclassify}
						label={'Edit selected'}
						onClick={reclassify}
						icon={isLoading ? <SRSpinnerIcon size={'small'} /> : undefined}
					/>
				</Box>
			</Box>
		</Layer>
	) : null
}
