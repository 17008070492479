import {queryCache, useMutation} from 'react-query'
import {
	approveInStageTask,
	ReclassifyDTO,
	reclassifyInStageTask,
	reclassifyWithExpertReviewInStageTask,
	ReclassifyWithMessageDTO,
} from '../../api/reclassificationAnnotations'
import React from 'react'
import {ReclassificationAnnotationEntity, ReclassificationDTO} from '../../entities/reclassification-annotation'
import {AxiosError} from 'axios'

export function useStageTaskReclassificationMutations(projectId: string | undefined, stageTaskId: string | undefined) {
	const onSuccess = (annotation: ReclassificationAnnotationEntity) => {
		queryCache.setQueryData<ReclassificationDTO[]>(['stage-task-reclassification', stageTaskId], reclassifications =>
			reclassifications!.map(r => {
				return {...r, annotation: annotation.classificationId === r.classification._id ? annotation : r.annotation}
			}),
		)
		return queryCache.invalidateQueries(['stage-task-annotations', stageTaskId])
	}

	const [approveMutation] = useMutation<ReclassificationAnnotationEntity, AxiosError, {classificationId: string}>(
		({classificationId}) => approveInStageTask(projectId || '', stageTaskId || '', classificationId),
		{onSuccess, throwOnError: true},
	)

	const approve = React.useCallback(
		(classificationId: string) => {
			if (!projectId || !stageTaskId) return Promise.reject()
			return approveMutation({classificationId})
		},
		[approveMutation, projectId, stageTaskId],
	)

	const [reclassifyMutation] = useMutation<
		ReclassificationAnnotationEntity,
		AxiosError,
		{classificationId: string; formData: ReclassifyDTO}
	>(
		({classificationId, formData}) =>
			reclassifyInStageTask(projectId || '', stageTaskId || '', classificationId, formData),
		{
			onSuccess,
			throwOnError: true,
		},
	)

	const reclassify = React.useCallback(
		(classificationId: string, formData: ReclassifyDTO) => {
			if (!projectId || !stageTaskId) return Promise.reject()
			return reclassifyMutation({classificationId, formData})
		},
		[projectId, reclassifyMutation, stageTaskId],
	)

	const [reclassifyWithExpertReviewMutation] = useMutation<
		ReclassificationAnnotationEntity,
		AxiosError,
		{classificationId: string; formData: ReclassifyWithMessageDTO}
	>(
		({classificationId, formData}) =>
			reclassifyWithExpertReviewInStageTask(projectId || '', stageTaskId || '', classificationId, formData),
		{
			onSuccess,
			throwOnError: true,
		},
	)

	const reclassifyWithExpertReview = React.useCallback(
		(classificationId: string, formData: ReclassifyWithMessageDTO) => {
			if (!projectId || !stageTaskId) return Promise.reject()
			return reclassifyWithExpertReviewMutation({classificationId, formData})
		},
		[projectId, reclassifyWithExpertReviewMutation, stageTaskId],
	)

	return {approve, reclassify, reclassifyWithExpertReview}
}
