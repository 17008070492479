import {useViewer} from './useViewer'
import {useEffect} from 'react'

export type ContextMenuItem = {
	title: string
	callback: () => void
	condition?: () => boolean
}

export function useExtendContextMenu(additionalItems: ContextMenuItem[]) {
	const {viewer, status} = useViewer()

	useEffect(() => {
		let viewerInstance: Autodesk.Viewing.GuiViewer3D | undefined = undefined
		if (status === 'model_loaded') {
			viewerInstance = viewer.current!
			viewer.current!.registerContextMenuCallback('MenuItems', (menu, status) => {
				if (status.hasSelected) {
					for (const {title, callback, condition} of additionalItems) {
						if (condition && condition()) {
							menu.push({
								title,
								target: callback,
							})
						}
					}
				}
			})
		}
		return () => {
			viewerInstance?.unregisterContextMenuCallback('MenuItems')
		}
	}, [additionalItems, status, viewer])
}
