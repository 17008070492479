import {ClassificationAnnotation} from '../entities/classificationAnnotation'
import {TableInstance} from 'react-table'
import React, {useCallback, useMemo} from 'react'
import {ReviewContainer} from './ReviewContainer'
import {Box, Text} from 'grommet'
import {
	ReclassificationAnnotationEntity,
	ReclassificationWithUserNameDTO,
} from '../entities/reclassification-annotation'
import {User} from '../../User/entities/user'
import {ReclassificationActions} from '../api/reclassificationAnnotations'
import {FinalReviewTable, FinalReviewTableData} from './Table/FinalReviewTable/FinalReviewTable'
import {
	SR_COLOR_TEXT_LIGHT,
	SRGreenIconButton,
	SRIconButton,
	SRPrimaryButton,
	SRPrimaryIconButton,
} from 'sr-react-commons'
import {Checkmark, DocumentCsv, Edit, Filter, Link, Refresh, Sync} from 'grommet-icons'
import {useReclassificationAnnotationMutations} from '../hooks/reclassificationAnnotations/useReclassificationAnnotationMutations'
import {queryCache} from 'react-query'
import {useParams} from 'react-router-dom'
import {FinalReviewRouteParams} from '../../../routes/FinalReview/FinalReviewPage'
import {useProject} from '../../User/hooks/useProject'
import {Spinner} from '../../../components/Spinner/Spinner'
import {useFeatureEnabled} from '../../FeatureFlags/FeatureFlagsProvider'
import {ReclassificationEditSelectedModal} from './ReclassificationEditSelectedModal'

const DividerPipe = () => <Box width={'1px'} fill={'vertical'} background={SR_COLOR_TEXT_LIGHT}></Box>

export function FinalReviewControlsContainer(props: {
	selectedElement: ReclassificationWithUserNameDTO | null
	reviewActions: Pick<ReclassificationActions, 'approve' | 'reclassify'>
	annotation?: ClassificationAnnotation
	users: User[]
	tableInstance: TableInstance<FinalReviewTableData>
	onSelect: (classificationId: string) => void
	onReview: (classificationId: string) => void
	onIsolateFiltered: () => void
	onExport?: () => void
	reviewedElementsCount: number
	totalElementsCount: number
	onFinish: () => Promise<any>
	isFinishing: boolean
	cannotFinishTooltip?: string
	reviewUIStatus: 'list' | 'review'
	displayList: () => void
	displayReview: () => void
	projectId: string
	selectedClassifications: string[]
	setSelectedClassifications: (ids: string[]) => void
	selectionLinked: boolean
	setSelectionLinked: (isLinked: boolean) => void
	resetFilters: () => void
	filterSelected: () => void
}) {
	const backToListView = (data: ReclassificationAnnotationEntity | undefined) => {
		props.displayList()
		return data
	}
	const {clientSlug, projectSlug, reclassificationId} = useParams<FinalReviewRouteParams>()
	const {project} = useProject(clientSlug, projectSlug)
	const [isBulkApproveLoading, setIsBulkApproveLoading] = React.useState<boolean>(false)
	const [isBulkEditModalOpened, setIsBulkEditModalOpened] = React.useState<boolean>(false)

	const {approveByClassificationsIdsMutation} = useReclassificationAnnotationMutations(project?._id, () => {
		setIsBulkApproveLoading(false)
		return queryCache.invalidateQueries(['reclassification-annotations', 'final-review', reclassificationId])
	})
	const approveSelectedClassifications = () => {
		setIsBulkApproveLoading(true)
		return approveByClassificationsIdsMutation({classificationsIds: props.selectedClassifications, reclassificationId})
	}
	const editSelectedClassifications = () => {
		setIsBulkEditModalOpened(true)
	}
	const reclassViewerManualRepaint = useFeatureEnabled('reclassViewerManualRepaint')
	const resetSelection = useCallback(() => props.setSelectedClassifications([]), [props])
	const reviewedCount = useMemo(() => props.tableInstance.data.filter((r: FinalReviewTableData) => r.reviewed).length, [
		props.tableInstance.data,
	])
	return (
		<>
			{isBulkEditModalOpened && (
				<ReclassificationEditSelectedModal
					isOpen={isBulkEditModalOpened}
					onClose={() => setIsBulkEditModalOpened(false)}
					selectedClassifications={props.selectedClassifications}
				/>
			)}
			{props.reviewUIStatus === 'review' ? (
				props.selectedElement && (
					<ReviewContainer
						showAnalysisLink={true}
						reviewActions={{
							approve: classificationId => props.reviewActions.approve(classificationId).then(backToListView),
							reclassify: (annotationId, formData) =>
								props.reviewActions.reclassify(annotationId, formData).then(backToListView),
						}}
						onIsolateFiltered={props.onIsolateFiltered}
						onBack={props.displayList}
						selectedElement={props.selectedElement}
						users={props.users}
						projectId={props.projectId}
					/>
				)
			) : (
				<Box gap="xsmall" flex>
					<Box flex={false}>
						<Box direction={'row'} justify={'between'} gap={'small'}>
							<Box direction={'row'} align={'center'} gap={'small'}>
								<Text
									size={'xsmall'}
									weight={600}
									title={`Filtered ${props.tableInstance.rows.length} / ${props.tableInstance.data.length}`}
								>
									Filtered
									<br />
									{props.tableInstance.rows.length}
								</Text>
								<SRIconButton
									title={'Reset filters'}
									icon={<Refresh size={'small'} />}
									onClick={props.resetFilters}
									disabled={props.tableInstance.rows.length === props.tableInstance.data.length}
								/>
								<SRIconButton size="small" icon={<DocumentCsv size={'16px'} />} onClick={props.onExport} />
								<DividerPipe />
								<Text size={'xsmall'} weight={600}>
									Selected
									<br />
									{props.selectedClassifications.length}
								</Text>
								<SRIconButton
									title={'Reset selection for all the pages'}
									icon={<Refresh size={'small'} />}
									onClick={resetSelection}
									disabled={!props.selectedClassifications.length}
								/>
								<SRIconButton
									title={'Filter selected elements by Global Id'}
									icon={<Filter size={'small'} />}
									onClick={props.filterSelected}
									disabled={!props.selectedClassifications.length}
								/>
								<SRIconButton
									title={'Synchronize Table / Viewer selection'}
									icon={<Link size={'small'} />}
									onClick={() => props.setSelectionLinked(!props.selectionLinked)}
									isSelected={props.selectionLinked}
								/>
								<SRGreenIconButton
									size="medium"
									title={isBulkApproveLoading ? '' : 'Approve selected'}
									icon={isBulkApproveLoading ? <Spinner size={'16px'} /> : <Checkmark size={'small'} />}
									onClick={approveSelectedClassifications}
									disabled={props.selectedClassifications.length < 1 || isBulkApproveLoading}
								/>
								<SRPrimaryIconButton
									title={'Edit selected'}
									icon={<Edit size={'small'} />}
									onClick={editSelectedClassifications}
									disabled={props.selectedClassifications.length < 1 || isBulkEditModalOpened}
								/>
							</Box>
							<Box direction={'row'} gap={'small'} justify={'end'}>
								{!reclassViewerManualRepaint ? null : (
									<Box gap={'small'} direction={'row'}>
										<SRPrimaryIconButton
											alignSelf="start"
											disabled={props.isFinishing}
											icon={<Sync size={'small'} />}
											onClick={() => queryCache.invalidateQueries(['final-states-manual', 'final-review'])}
											title={
												'Refresh Viewer\nIf you are seeing this button, this project has the automatic on change viewer re-render disabled for performance reasons. You can still use this btn to manually refresh the viewer state (re rendering can still take time to be applied)'
											}
										/>
										<DividerPipe />
									</Box>
								)}
								<Text
									size={'xsmall'}
									weight={600}
									title={`Reviewed ${reviewedCount} / ${props.tableInstance.data.length}`}
								>
									Reviewed
									<br />
									{Math.floor((100 * reviewedCount) / props.tableInstance.data.length)} %
								</Text>
								<SRPrimaryButton
									size={'small'}
									pad={'6px'}
									alignSelf="start"
									disabled={props.isFinishing}
									label={'Finish review'}
									onClick={props.onFinish}
								/>
							</Box>
						</Box>
					</Box>
					<FinalReviewTable
						reviewActions={{
							approve: (classificationId: string) => props.reviewActions.approve(classificationId).then(backToListView),
						}}
						tableInstance={props.tableInstance}
						currentClassification={props.selectedElement?.classification}
						onSelect={props.onSelect}
						onReview={(classificationId: string) => {
							props.onReview(classificationId)
							props.displayReview()
						}}
						selectedClassifications={props.selectedClassifications}
						setSelectedClassifications={props.setSelectedClassifications}
					/>
				</Box>
			)}
		</>
	)
}
