import {Classification} from '../../../entities/classification'
import {srApiV3} from '../../../api/baseApi'
import {
	ReclassificationAnnotationEntity,
	ReclassificationDTO,
	SimpleReclassificationValueWithUserName,
} from '../entities/reclassification-annotation'
import {ReclassificationWorkPackageStageTaskEntity} from '../entities/reclassification-work-package-stage-task'

export function fetchReclassificationsForStageTask(
	projectId: string,
	stageTaskId: string,
): Promise<ReclassificationDTO[]> {
	return srApiV3
		.get(
			`/projects/${projectId}/reclassification/reclassification-work-package-stage-tasks/${stageTaskId}/reclassifications`,
		)
		.then(response => response.data)
}

export function fetchClassificationByIdForStageTask(
	projectId: string,
	stageTaskId: string,
	classificationId: string,
): Promise<Classification> {
	return srApiV3
		.get(
			`/projects/${projectId}/reclassification/reclassification-work-package-stage-tasks/${stageTaskId}/classifications/${classificationId}`,
		)
		.then(response => response.data)
}

export function postStartStageTask(
	projectId: string,
	stageTaskId: string,
): Promise<ReclassificationWorkPackageStageTaskEntity> {
	return srApiV3
		.post(`/projects/${projectId}/reclassification/reclassification-work-package-stage-tasks/${stageTaskId}/start`)
		.then(response => response.data)
}

export function postFinishStageTask(
	projectId: string,
	stageTaskId: string,
): Promise<ReclassificationWorkPackageStageTaskEntity> {
	return srApiV3
		.post(`/projects/${projectId}/reclassification/reclassification-work-package-stage-tasks/${stageTaskId}/finish`)
		.then(response => response.data)
}

export function postAssignUserToStageTask(
	projectId: string,
	stageTaskId: string,
	userId: string | null,
): Promise<ReclassificationWorkPackageStageTaskEntity> {
	return srApiV3
		.post(
			`/projects/${projectId}/reclassification/reclassification-work-package-stage-tasks/${stageTaskId}/assign-user`,
			{userId},
		)
		.then(response => response.data)
}
