import {useQuery} from 'react-query'
import {fetchProjectByTenantId} from '../api/projects'

export function useProject(clientSlug: string, projectSlug: string) {
	const tenantId = `${clientSlug}|${projectSlug}`
	const {data: project, status} = useQuery(
		['project', tenantId],
		() => {
			return fetchProjectByTenantId(tenantId)
		},
		{
			staleTime: 60 * 60 * 1000,
		},
	)
	return {project, status}
}
