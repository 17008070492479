import React from 'react'
import {UserContext} from '../components/UserProvider'

export const useCurrentUser = () => {
	const context = React.useContext(UserContext)

	if (context === undefined) {
		throw new Error('`useUser` hook must be used within a `UserProvider` component')
	}
	return context
}
