import {useFilters, usePagination, useSortBy, useTable} from 'react-table'
import React, {useEffect} from 'react'
import {formatMagnitude} from '../../../../../utilities/formatUtilities'
import {AnnotationsTableData, prepareTableData} from './QualityControlReviewTable'
import {MultiSelectFileUploadColumnFilter, SelectColumnFilter} from '../core/components/SelectColumnFilter'
import {Checkmark} from 'grommet-icons'
import {Box} from 'grommet'
import {User} from '../../../../User/entities/user'
import _ from 'lodash'
import {ReclassificationDTO} from '../../../entities/reclassification-annotation'
import {ReclassificationTableColumn, StatusTableCell} from '../core/columns'
import {ReviewedSelectColumnFilter} from '../core/components/ReviewedSelectColumnFilter'

type QCColumn = ReclassificationTableColumn<AnnotationsTableData>
export function useQualityControlReviewTable(
	reclassificationElements: ReclassificationDTO[],
	users: User[],
	initialPageSize: number = 10,
) {
	const usersById = _.keyBy(users, '_id')
	const tableData = React.useMemo(() => {
		return prepareTableData(reclassificationElements, usersById)
	}, [reclassificationElements, usersById])
	const columns: QCColumn[] = React.useMemo(
		() => [
			{
				Header: 'Original Classification',
				columns: [
					{
						Header: 'Global Id',
						Title: 'Global Id',
						accessor: 'externalId',
						Filter: MultiSelectFileUploadColumnFilter,
						filter: 'includesValue',
					},
					{
						Header: 'Type',
						Title: 'Type',
						accessor: 'elementType',
						Filter: SelectColumnFilter,
						filter: 'includesValue',
						labelGetter: row => row.original.mappedElementType || row.original.elementType,
						Cell: ({row, column}) => (column as QCColumn).labelGetter?.(row),
					},
					{
						Header: 'Status',
						Title: 'Status',
						accessor: 'originalClassification',
						Filter: SelectColumnFilter,
						filter: 'includesValue',
						Cell: StatusTableCell,
					},
					{
						Header: 'M',
						Title: 'Original Magnitude',
						accessor: 'originalMagnitude',
						Cell: ({value}: {value: number}) => formatMagnitude(value, false),
						sortType: 'basic',
						disableFilters: true,
					},
				],
			},
			{
				id: 'reclassification',
				Header: 'Reclassification',
				columns: [
					{
						id: 'reclassificationAction',
						Header: 'Action',
						Title: 'Action',
						accessor: 'action',
						Filter: SelectColumnFilter,
						filter: 'includesValue',
					},
					{
						id: 'reclassificationStatus',
						Header: 'Status',
						Title: 'New Status',
						accessor: 'newClassification',
						Cell: StatusTableCell,
						Filter: SelectColumnFilter,
						filter: 'includesValue',
					},
					{
						id: 'reclassificationMagnitude',
						Header: 'M',
						Title: 'New Magnitude',
						accessor: 'newMagnitude',
						sortType: 'basic',
						Cell: ({value}: {value: number | null}) => (value !== null ? formatMagnitude(value, false) : '-'),
						disableFilters: true,
					},
					{
						id: 'reclassificationDeltaMagnitude',
						Header: 'ΔM',
						Title: 'Delta Magnitude',
						accessor: 'deltaMagnitude',
						sortType: 'basic',
						Cell: ({value}: {value: number}) => formatMagnitude(value, false),
						disableFilters: true,
					},
					{
						id: 'reclassificationUser',
						Header: 'User',
						Title: 'User',
						accessor: 'annotationUserName',
						Filter: SelectColumnFilter,
						filter: 'includesValue',
					},
				],
			},
			{
				Header: 'R',
				Title: 'Reviewed',
				id: 'reviewed',
				accessor: 'reviewed',
				Cell: ({value}: {value: boolean}) => (
					<Box align={'center'}>{value ? <Checkmark color={'brand'} size={'small'} /> : null}</Box>
				),
				filter: 'equals',
				Filter: ReviewedSelectColumnFilter,
				sortType: 'basic',
			},
		],
		[],
	)

	const table = useTable<AnnotationsTableData>(
		{
			columns,
			data: tableData,
			autoResetFilters: false,
			autoResetPage: false,
			autoResetSortBy: false,
			initialState: {
				pageSize: initialPageSize,
			},
		},
		useFilters,
		useSortBy,
		usePagination,
	)

	const {gotoPage} = table

	useEffect(() => {
		gotoPage(0)
	}, [table.state.filters, gotoPage])

	return table
}
