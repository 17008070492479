import {UserProject} from '../features/User/entities/userProject'
import {srApiV3} from '../api/baseApi'

export type SignResponse = {
	url: string
	key: string
}

export function getS3KeyForPath(project: UserProject, imagePath: string) {
	return project.tenantId.replace('|', '/') + '/' + imagePath
}

export enum S3Operation {
	GET_PRIVATE = 'sign-asset',
	GET_PUBLIC = 'sign-asset-public',
}

async function signS3Asset(key: string, operation: S3Operation): Promise<SignResponse> {
	return srApiV3.post(`auth/${operation}/${key}`).then(r => r.data)
}

export async function fetchS3FileRaw(key: string, operation = S3Operation.GET_PRIVATE): Promise<Response> {
	const signResponse = await signS3Asset(key, operation)
	return fetch(signResponse.url)
}

export async function fetchS3File(key: string): Promise<string> {
	const response = await fetchS3FileRaw(key)
	return response.text()
}

function getBase64ImageTypeByKey(key: string) {
	const fileExtensionPointIndex = key.lastIndexOf('.')
	if (fileExtensionPointIndex < 0) {
		throw new Error('Key does not contain file extension: ' + key)
	}
	const extension = key.substring(fileExtensionPointIndex + 1)
	switch (extension) {
		case 'svg':
			return 'svg+xml'
		case 'png':
			return 'png'
		case 'jpeg':
		case 'jpg':
			return 'jpg'
		default:
			throw new Error('Unsupported image extension: ' + extension)
	}
}

export async function loadS3Image(key: string, operation = S3Operation.GET_PRIVATE): Promise<string> {
	const base64ImageFileType = getBase64ImageTypeByKey(key)
	const response = await fetchS3FileRaw(key, operation)
	const buffer = await response.arrayBuffer()
	return arrayBufferToBase64(buffer, base64ImageFileType)
}

function arrayBufferToBase64(buffer: ArrayBuffer, fileType: string = 'png') {
	const base64Flag = `data:image/${fileType};base64,`
	return base64Flag + Buffer.from(buffer).toString('base64')
}
