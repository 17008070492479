import {srApiV3} from '../../../api/baseApi'
import {ClassificationWithAnalysisView} from '../../../entities/classification'

export function fetchClassificationWithAnalysisView(
	externalId: string,
	projectId: string,
): Promise<ClassificationWithAnalysisView[]> {
	return srApiV3
		.get(`/projects/${projectId}/classifications/by-external-id/${externalId}/last-stable`)
		.then(response => response.data)
}
