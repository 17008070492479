import {Box} from 'grommet'
import React from 'react'
import {TopNavigationBar} from './TopNavigationBar/TopNavigationBar'
import {AppContent} from './AppContent'
import {AppRouter} from './AppRouter'

export function MainApp() {
	return (
		<Box fill>
			<AppRouter>
				<Box height={'xxsmall'}>
					<TopNavigationBar />
				</Box>
				<Box fill direction={'row'}>
					<Box fill={true}>
						<AppContent />
					</Box>
				</Box>
			</AppRouter>
		</Box>
	)
}
