import {ClassificationDetailBox} from './ClassificationDetailBox'
import React from 'react'
import {Box} from 'grommet'
import {ReclassificationDTO} from '../entities/reclassification-annotation'
import {useParams} from 'react-router-dom'
import {useProject} from '../../User/hooks/useProject'
import {FinalReviewRouteParams} from '../../../routes/FinalReview/FinalReviewPage'
import {useClassificationsWithAnalysisView} from '../hooks/useClassificationsWithAnalysisView'
import {SRSecondaryButton} from 'sr-react-commons'

export function ElementControls(props: {
	currentElement: ReclassificationDTO
	selectElement: () => void
	isolateElement: () => void
	fitToView: () => void
}) {
	const {clientSlug, projectSlug} = useParams<FinalReviewRouteParams>()
	const {project} = useProject(clientSlug, projectSlug)
	const {data} = useClassificationsWithAnalysisView(props.currentElement.classification.externalId, project!._id)
	return (
		<Box fill={true} justify={'between'}>
			<ClassificationDetailBox
				classification={props.currentElement.classification}
				classificationsWithAnalysisView={data}
			/>
			<Box direction={'row'} gap={'xsmall'}>
				<SRSecondaryButton size="small" alignSelf={'start'} onClick={props.selectElement} label="Select in viewer" />
				<SRSecondaryButton size="small" alignSelf={'start'} onClick={props.isolateElement} label="Isolate element" />
				<SRSecondaryButton size="small" alignSelf={'start'} onClick={props.fitToView} label="Fit to view" />
			</Box>
		</Box>
	)
}
