import {useQuery} from 'react-query'
import {fetchClassificationByIdForStageTask, fetchReclassificationsForStageTask} from '../../api/stageTasks'
import {ReclassificationDTO} from '../../entities/reclassification-annotation'
import {UserProject} from '../../../User/entities/userProject'
import {Classification} from '../../../../entities/classification'

export function useClassificationByIdForStageTask(
	projectId: string | undefined,
	stageTaskId: string | undefined,
	classificationId: string | undefined,
) {
	return useQuery<Classification>(
		['stage-task-classification', stageTaskId, classificationId],
		() => fetchClassificationByIdForStageTask(projectId!, stageTaskId!, classificationId!),
		{
			enabled: classificationId && stageTaskId && projectId,
			staleTime: 10 * 60 * 1000,
		},
	)
}

function useReclassificationsForStageTask(stageTaskId: string | undefined, projectId: string | undefined) {
	return useQuery<ReclassificationDTO[]>(
		['stage-task-reclassification', stageTaskId],
		() => fetchReclassificationsForStageTask(projectId!, stageTaskId!),
		{
			enabled: stageTaskId && projectId,
			staleTime: 10 * 60 * 1000,
		},
	)
}

export function useReclassificationElements(stageTaskId: string | undefined, project: UserProject | undefined) {
	const {data: reclassificationElements} = useReclassificationsForStageTask(stageTaskId, project?._id)
	return {reclassificationElements, status: reclassificationElements ? 'success' : 'loading'}
}
