import {useQuery} from 'react-query'
import {fetchOneByProject} from '../../../repositories/by-project.repository'

export function useProjectAnalysis(projectId: string | undefined, id: string | undefined) {
	return useQuery(
		['project', projectId, 'analyses', 'by-id', id],
		() => fetchOneByProject('analyses', projectId!, id!),
		{
			enabled: projectId !== undefined && id !== undefined,
		},
	)
}
