import {createSlice} from '@reduxjs/toolkit'
import {
	buildFilterStateWithPaginationReducer,
	buildInitialFilterState,
	FilterState,
} from '../../../../../reducers/base.filter.state'
import {
	buildInitialSortPaginationState,
	buildSortPaginationStateReducer,
	SortPaginationState,
} from '../../../../../reducers/base.pagination.sorting.state'
import {ReclassificationAnnotationEntity} from '../../../entities/reclassification-annotation'
import {useReducer} from 'react'

type FinalReviewFilter = {
	externalId: string[] | null
	elementType: string[] | null
	reviewed: boolean | null
	finalStatus: ReclassificationAnnotationEntity['classification'] | null
	// TODO: ASSEMBLIES-V2 - workPackageNameQuery: string | null
}

type FinalReviewSortFields = 'externalId' | 'status' | 'magnitude' | 'finalStatus' | 'finalMagnitude'

export type FinalReviewTableState = FilterState<FinalReviewFilter> & SortPaginationState<FinalReviewSortFields>

function buildFinalReviewTableInitialState(): FinalReviewTableState {
	return {
		...buildInitialSortPaginationState<FinalReviewTableState>({field: 'finalMagnitude', ascending: false}),
		...buildInitialFilterState<FinalReviewTableState>({
			externalId: null,
			elementType: null,
			reviewed: null,
			finalStatus: null,
			// TODO: ASSEMBLIES-V2 - workPackageNameQuery: null,
		}),
	}
}

const initialState = buildFinalReviewTableInitialState()

const finalReviewFilterStateSlice = createSlice({
	name: 'finalReviewTable',
	initialState,
	reducers: {
		...buildSortPaginationStateReducer<FinalReviewTableState>(),
		...buildFilterStateWithPaginationReducer<FinalReviewTableState>(initialState),
	},
})

export const finalReviewTableStateActions = finalReviewFilterStateSlice.actions

export function useFinalReviewTableState() {
	return useReducer(finalReviewFilterStateSlice.reducer, initialState)
}
