import {useMutation} from 'react-query'
import {ReclassificationTaskEntity} from '../../../entities/reclassification-task'
import {AxiosError} from 'axios'
import {postStartFinalReview} from '../../../api/reclassificationTasks'

export function useStartFinalReviewMutation(
	onSuccess: (value: ReclassificationTaskEntity) => Promise<any> | undefined,
) {
	return useMutation<ReclassificationTaskEntity, AxiosError, {projectId: string; reclassificationTaskId: string}>(
		({projectId, reclassificationTaskId}) => postStartFinalReview(projectId, reclassificationTaskId),
		{
			onSuccess,
		},
	)
}
