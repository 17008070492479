import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import * as Sentry from '@sentry/react'
import {Integrations} from '@sentry/tracing'
import {config} from './config/config'
import {history} from './routing/history'
import {CaptureConsole} from '@sentry/integrations'

if (process.env.REACT_APP_MOCKS_ENABLED) {
	const {worker} = require('./mocks/browser')
	worker.start()
}

if (config.sr.activate_sentry) {
	Sentry.init({
		dsn: 'https://31d2652f6bd84ce3be30e9aac713c650@o272185.ingest.sentry.io/6143963',
		integrations: [
			new CaptureConsole({levels: ['error']}),
			new Integrations.BrowserTracing({
				routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
				tracingOrigins: [config.sr.backendUrl],
			}),
		],
		tracesSampleRate: process.env.REACT_APP_STAGE === 'production' ? 0.1 : 1.0,
		environment: process.env.REACT_APP_STAGE,
	})
}

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
