import {AnnotationClassificationType, AnnotationStatusType} from '../../../entities/classificationAnnotation'
import {ClassificationStatusType} from '../../../../../entities/classification'
import {Box, Table, TableBody, TableCell, TableHeader, TableRow, Text, ThemeContext} from 'grommet'
import React from 'react'
import {TableInstance} from 'react-table'
import {PaginationControls} from '../../../../../components/PaginationControls/PaginationControls'
import {theme} from '../../../../../theme'
import {getFullName, User} from '../../../../User/entities/user'
import {ReclassificationDTO} from '../../../entities/reclassification-annotation'
import {SRPrimaryButton, SRSecondaryButton} from 'sr-react-commons'
import {HeaderColumnCell} from '../core/components/HeaderColumnCell'

export function prepareTableData(
	reclassificationElements: ReclassificationDTO[],
	usersById: Record<string, User>,
): AnnotationsTableData[] {
	return reclassificationElements
		.map(data => {
			const user = data.previousValue.createdBy && usersById[data.previousValue.createdBy]
			return {
				...data,
				lastValueUserName: user ? getFullName(user) : 'Unknown',
			}
		})
		.map(({classification, previousValue, lastValueUserName, annotation}) => {
			return {
				externalId: classification.externalId,
				elementType: classification.element.type,
				mappedElementType: classification.element.mappedElementType,
				originalClassification: classification.status || 'deviated',
				originalMagnitude: parseFloat(classification.magnitude || ''),
				originalConfidence: classification.confidence,
				action: previousValue.action,
				newClassification: previousValue.classification,
				newMagnitude: previousValue.magnitude,
				annotationUser: previousValue.createdBy || '',
				annotationUserName: lastValueUserName,
				classificationId: classification._id,
				deltaMagnitude: previousValue.magnitude
					? Math.abs(parseFloat(classification.magnitude || '') - previousValue.magnitude)
					: 0,
				reviewed: annotation !== null,
			}
		})
}

export type AnnotationsTableData = {
	classificationId: string
	elementType: string
	mappedElementType: string
	originalClassification: ClassificationStatusType
	originalMagnitude: number
	externalId: string
	action: AnnotationStatusType
	originalConfidence: number
	newClassification: AnnotationClassificationType
	newMagnitude: number | null
	deltaMagnitude: number
	annotationUser: string
	annotationUserName: string
	reviewed: boolean
}

export function QualityControlReviewTable({
	tableInstance,
	onReview,
	onSelect,
	currentClassification,
}: {
	tableInstance: TableInstance<AnnotationsTableData>
	onReview: (classificationId: string) => void
	onSelect: (classificationId: string) => void
	currentClassification: ReclassificationDTO['classification'] | null
}) {
	return (
		<Box flex gap="xxsmall">
			<Box overflow={'auto'} fill>
				<ThemeContext.Extend
					value={{
						...theme,
						table: {
							body: {
								extend: 'font-size: 9px; padding-left:1px; padding-right: 1px; padding-top: 1px; padding-bottom: 1px;',
							},
							header: {
								extend:
									'font-size: 9px; line-height:12px; padding-left:1px; padding-right: 1px; padding-top: 1px; padding-bottom: 1px;',
							},
						},
						text: {
							small: {
								size: '9px',
								height: '14px',
							},
						},
						button: {
							size: {
								small: {
									pad: {
										horizontal: '2px',
									},
								},
							},
						},
					}}
				>
					<Table {...tableInstance.getTableProps()}>
						<TableHeader>
							{tableInstance.headerGroups.map(headerGroup => (
								<TableRow {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map(column => (
										<HeaderColumnCell column={column} />
									))}
									<TableCell scope={'col'} border={'all'} verticalAlign={'top'} align={'center'} title={'Actions'}>
										Actions
									</TableCell>
								</TableRow>
							))}
						</TableHeader>
						<TableBody {...tableInstance.getTableBodyProps()}>
							{tableInstance.page.map(row => {
								tableInstance.prepareRow(row)
								return (
									<TableRow
										{...row.getRowProps()}
										style={
											row.original.classificationId === currentClassification?._id
												? {backgroundColor: '#F8F8F8'}
												: undefined
										}
									>
										{row.cells.map(cell => (
											<TableCell scope={'row'} border={'all'} {...cell.getCellProps()}>
												<Text truncate={true} size="xsmall">
													{cell.render('Cell')}
												</Text>
											</TableCell>
										))}
										<TableCell scope={'row'} border={'all'}>
											<Box direction="row" justify={'between'} gap="xxsmall">
												<SRSecondaryButton
													size={'small'}
													style={{width: '48px', padding: '1px'}}
													onClick={() => onSelect(row.original.classificationId)}
													label={'Select'}
												/>
												<SRPrimaryButton
													size={'small'}
													style={{width: '48px', padding: '1px'}}
													onClick={() => onReview(row.original.classificationId)}
													label={'Review'}
												/>
											</Box>
										</TableCell>
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				</ThemeContext.Extend>
			</Box>
			<PaginationControls
				start={tableInstance.state.pageIndex * tableInstance.state.pageSize}
				currentPageLen={tableInstance.page.length}
				totalCount={tableInstance.rows.length}
				page={tableInstance.state.pageIndex + 1}
				setPage={newPage => tableInstance.gotoPage(newPage - 1)}
				pageCount={tableInstance.pageCount}
				setCurrentPageSize={tableInstance.setPageSize}
				currentPageSize={tableInstance.state.pageSize}
			/>
		</Box>
	)
}
