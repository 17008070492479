import {Box, Heading, Layer} from 'grommet'
import React from 'react'
import {SRButton, SRSecondaryButton} from 'sr-react-commons'

export function ConfirmationModal(props: {
	isOpen: boolean
	onClose: () => void
	onConfirmation: () => void
	text: string
	confirmationButtonLabel: string
}) {
	return props.isOpen ? (
		<Layer onClickOutside={props.onClose}>
			<Box justify={'center'} gap={'small'} align={'center'} height={'small'} pad={'small'}>
				<Heading textAlign={'center'} level={'4'}>
					{props.text}
				</Heading>
				<Box gap={'small'} direction={'row'}>
					<SRButton bgColor={'status-error'} label={props.confirmationButtonLabel} onClick={props.onConfirmation} />
					<SRSecondaryButton label={'Cancel'} onClick={props.onClose} />
				</Box>
			</Box>
		</Layer>
	) : null
}
