import {homeRoute} from './Home'
import {reclassificationOverviewRoute} from './ReclassificationOverview'
import {myManagedReclassificationsRoute} from './MyManagedReclassifications'
import {myReclassificationsRoute} from './MyReclassifications'
import {reclassificationWorkPackages} from './ReclassificationWorkPackages'
import {reclassificationUsersRoute} from './ReclassificationUsers'
import {allReclassificationsRoute} from './AllReclassifications'
import {userActivityRoute} from './UserActivity'
import {managedUsersRoute} from './ManagedUsers'
import {myCompaniesRoute} from './MyCompanies'
import {reclassificationReport, reclassificationUserReport} from './ReclassificationReport'
import {stageTaskRoute} from './StageTask'
import {finalReviewRoute} from './FinalReview'

export const routes = {
	home: homeRoute,
	reclassificationOverview: reclassificationOverviewRoute,
	myManagedReclassifications: myManagedReclassificationsRoute,
	myReclassifications: myReclassificationsRoute,
	reclassificationReport: reclassificationReport,
	reclassificationUserReport: reclassificationUserReport,
	reclassificationWorkPackages: reclassificationWorkPackages,
	reclassificationUsers: reclassificationUsersRoute,
	allReclassifications: allReclassificationsRoute,
	userActivity: userActivityRoute,
	managedUsers: managedUsersRoute,
	myCompanies: myCompaniesRoute,
	stageTask: stageTaskRoute,
	finalReview: finalReviewRoute,
}
