import {
	ReclassificationAnnotationEntity,
	ReclassificationWithUserNameDTO,
} from '../entities/reclassification-annotation'
import {srApiV3} from '../../../api/baseApi'
import {AnnotationClassificationType} from '../entities/classificationAnnotation'
import {Classification} from '../../../entities/classification'
import {FinalReviewTableState} from '../components/Table/FinalReviewTable/useFinalReviewTableState'

export type ReclassifyDTO = {
	status: AnnotationClassificationType
	magnitude: number | null
}

export type ReclassifyWithMessageDTO = ReclassifyDTO & {
	message: string
}

export function approveInStageTask(
	projectId: string,
	stageTaskId: string,
	classificationId: string,
): Promise<ReclassificationAnnotationEntity> {
	return srApiV3
		.post(
			`/projects/${projectId}/reclassification/reclassification-work-package-stage-tasks/${stageTaskId}/classifications/${classificationId}/approve`,
		)
		.then(response => response.data)
}

export function reclassifyInStageTask(
	projectId: string,
	stageTaskId: string,
	classificationId: string,
	params: ReclassifyDTO,
): Promise<ReclassificationAnnotationEntity> {
	return srApiV3
		.post(
			`/projects/${projectId}/reclassification/reclassification-work-package-stage-tasks/${stageTaskId}/classifications/${classificationId}/reclassify`,
			{
				classification: params.status,
				magnitude: params.magnitude,
			},
		)
		.then(response => response.data)
}

export function reclassifyWithExpertReviewInStageTask(
	projectId: string,
	stageTaskId: string,
	classificationId: string,
	params: ReclassifyWithMessageDTO,
): Promise<ReclassificationAnnotationEntity> {
	return srApiV3
		.post(
			`/projects/${projectId}/reclassification/reclassification-work-package-stage-tasks/${stageTaskId}/classifications/${classificationId}/reclassify-with-expert-review`,
			{
				classification: params.status,
				magnitude: params.magnitude,
				message: params.message,
			},
		)
		.then(response => response.data)
}

export type ReclassificationActions = {
	approve: (classificationId: string) => Promise<ReclassificationAnnotationEntity | undefined>
	reclassify: (
		classificationId: string,
		formData: ReclassifyDTO,
	) => Promise<ReclassificationAnnotationEntity | undefined>
	requestExpertReview: (
		classificationId: string,
		formData: ReclassifyWithMessageDTO,
	) => Promise<ReclassificationAnnotationEntity | undefined>
}

export function fetchReclassificationsForFinalReview(
	projectId: string,
	reclassificationId: string,
): Promise<ReclassificationWithUserNameDTO[]> {
	return srApiV3
		.get(
			`/projects/${projectId}/reclassification/reclassification-tasks/${reclassificationId}/final-review/reclassifications`,
		)
		.then(response => response.data)
}

export function fetchFinalStatesForFinalReview(
	projectId: string,
	reclassificationId: string,
	filter: FinalReviewTableState['filter'],
): Promise<Record<ReclassificationAnnotationEntity['classification'], number[]>> {
	return srApiV3
		.get(
			`/projects/${projectId}/reclassification/reclassification-tasks/${reclassificationId}/final-review/for-colorize-model`,
			{params: {filter}},
		)
		.then(response => response.data)
}

export function fetchClassificationByIdForFinalReview(
	projectId: string,
	reclassificationId: string,
	classificationId: string,
): Promise<Classification> {
	return srApiV3
		.get(
			`/projects/${projectId}/reclassification/reclassification-tasks/${reclassificationId}/final-review/classifications/${classificationId}`,
		)
		.then(response => response.data)
}

export function approveByClassificationsIds(
	projectId: string,
	reclassificationId: string,
	classificationIds: string[],
): Promise<ReclassificationAnnotationEntity[]> {
	return srApiV3
		.post(`/projects/${projectId}/reclassification/reclassification-tasks/${reclassificationId}/final-review/approve`, {
			classificationIds,
		})
		.then(response => response.data)
}

export function bulkReclassifyByClassificationsIds(
	projectId: string,
	reclassificationId: string,
	classificationIds: string[],
	status: AnnotationClassificationType,
	magnitude: number | null,
): Promise<ReclassificationAnnotationEntity[]> {
	return srApiV3
		.post(
			`/projects/${projectId}/reclassification/reclassification-tasks/${reclassificationId}/final-review/bulk-reclassify`,
			{
				classificationIds,
				classification: status,
				magnitude,
			},
		)
		.then(response => response.data)
}
