import {ReclassificationTaskEntity} from '../entities/reclassification-task'
import {srApiV3} from '../../../api/baseApi'
import {User} from '../../User/entities/user'
import {ReclassificationWorkPackageStageTaskEntityWithWorkPackage} from '../entities/reclassification-work-package-stage-task'

export function postAssignStageTaskToCurrentUser(
	projectId: string,
	reclassificationTaskId: string,
	stageId: string,
): Promise<ReclassificationTaskEntity> {
	return srApiV3
		.post(
			`/projects/${projectId}/reclassification/reclassification-tasks/${reclassificationTaskId}/stages/${stageId}/assign-stage-task-to-current-user`,
		)
		.then(response => response.data)
}

export function postAssignUsersToReclassificationStage(
	projectId: string,
	reclassificationTaskId: string,
	stageId: string,
	userIds: string[],
): Promise<ReclassificationTaskEntity> {
	return srApiV3
		.post(
			`/projects/${projectId}/reclassification/reclassification-tasks/${reclassificationTaskId}/stages/${stageId}/assign-users`,
			userIds,
		)
		.then(response => response.data)
}

export function postUnassignUsersToReclassificationStage(
	projectId: string,
	reclassificationTaskId: string,
	stageId: string,
	userIds: string[],
): Promise<ReclassificationTaskEntity> {
	return srApiV3
		.post(
			`/projects/${projectId}/reclassification/reclassification-tasks/${reclassificationTaskId}/stages/${stageId}/unassign-users`,
			userIds,
		)
		.then(response => response.data)
}

export function postStartReclassificationTask(
	projectId: string,
	reclassificationTaskId: string,
): Promise<ReclassificationTaskEntity> {
	return srApiV3
		.post(`/projects/${projectId}/reclassification/reclassification-tasks/${reclassificationTaskId}/start`)
		.then(response => response.data)
}

export function postFinishReclassificationTask(
	projectId: string,
	reclassificationTaskId: string,
): Promise<ReclassificationTaskEntity> {
	return srApiV3
		.post(`/projects/${projectId}/reclassification/reclassification-tasks/${reclassificationTaskId}/finish`)
		.then(response => response.data)
}

export function postStartFinalReview(
	projectId: string,
	reclassificationTaskId: string,
): Promise<ReclassificationTaskEntity> {
	return srApiV3
		.post(`/projects/${projectId}/reclassification/reclassification-tasks/${reclassificationTaskId}/final-review/start`)
		.then(response => response.data)
}

export function postFinishFinalReview(
	projectId: string,
	reclassificationTaskId: string,
): Promise<ReclassificationTaskEntity> {
	return srApiV3
		.post(
			`/projects/${projectId}/reclassification/reclassification-tasks/${reclassificationTaskId}/final-review/finish`,
		)
		.then(response => response.data)
}

export function postUndoFinishFinalReview(
	projectId: string,
	reclassificationTaskId: string,
): Promise<ReclassificationTaskEntity> {
	return srApiV3
		.post(
			`/projects/${projectId}/reclassification/reclassification-tasks/${reclassificationTaskId}/final-review/undo-finish`,
		)
		.then(response => response.data)
}

export function fetchReclassificationTaskUsers(projectId: string, reclassificationTaskId: string): Promise<User[]> {
	return srApiV3
		.get(`/projects/${projectId}/reclassification/reclassification-tasks/${reclassificationTaskId}/users/all`)
		.then(response => response.data)
}

export function fetchReclassificationTask(
	projectId: string,
	reclassificationTaskId: string,
): Promise<ReclassificationTaskEntity> {
	return srApiV3
		.get(`/projects/${projectId}/reclassification/reclassification-tasks/${reclassificationTaskId}`)
		.then(response => response.data)
}

export type ReclassificationReport = {
	reviewedCount: number
	errorCount: number
	expertReviewCount: number
	reviewsWithoutExpertReviewsCount: number
	errorsWithoutExpertReviewsCount: number
	accuracy: number
	accuracyWithoutExpertReviews: number
	firstAnnotationDate: string | null
	lastAnnotationDate: string | null
}

export type ReclassificationUserReport = ReclassificationReport & {
	timePerAnnotation: number | null
	reclassifiedCount: number
}

export function fetchReclassificationReport(
	projectId: string,
	reclassificationId: string,
): Promise<ReclassificationReport> {
	return srApiV3
		.get(`/projects/${projectId}/reclassification/reclassification-tasks/${reclassificationId}/reclassification-report`)
		.then(response => response.data)
}

export function fetchReclassificationUserReport(
	projectId: string,
	reclassificationId: string,
	userId: string,
): Promise<ReclassificationUserReport> {
	return srApiV3
		.get(
			`/projects/${projectId}/reclassification/reclassification-tasks/${reclassificationId}/users/${userId}/reclassification-report`,
		)
		.then(response => response.data)
}

export function downloadReclassificationExport(projectId: string, reclassificationId: string) {
	return srApiV3.get<string>(
		`/projects/${projectId}/reclassification/reclassification-tasks/${reclassificationId}/reclassification-export`,
		{responseType: 'blob'},
	)
}

export type StageTaskActivityDTO = {
	stageTask: ReclassificationWorkPackageStageTaskEntityWithWorkPackage
	first: Date
	last: Date
	count: number
}

export type ReclassificationTaskUserActivityDTO = {
	reclassificationTasks: ReclassificationTaskEntity[]
	stageTasks: StageTaskActivityDTO[]
	overlaps: [number, number][]
	totalTime: number
}

export function fetchReclassificationActivityForUser(
	userId: string,
	{startDate, endDate}: {startDate: Date; endDate: Date},
): Promise<ReclassificationTaskUserActivityDTO> {
	return srApiV3
		.get(
			`/reclassification/user-activity/${userId}?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
		)
		.then(response => response.data)
}
