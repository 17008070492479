import {ReclassificationWorkPackageStageTaskEntityWithWorkPackage} from '../../features/Reclassification/entities/reclassification-work-package-stage-task'
import {SrRoute} from '../../routing'
import {Anchor, Box, Heading} from 'grommet'
import {Bar} from 'grommet-icons'
import {Link} from 'react-router-dom'
import React from 'react'
import {ReclassificationActions} from '../../features/Reclassification/api/reclassificationAnnotations'
import {SRSecondaryButton} from 'sr-react-commons'

export function ElementsCompletedMessageContainer(props: {
	skippedAnnotations: string[]
	clearSkipped: () => void
	stageTask: ReclassificationWorkPackageStageTaskEntityWithWorkPackage
	myReclassificationsRoute: SrRoute<{}>
	actions: {reclassification: ReclassificationActions; stageTask: {markStageTaskAsCompleted: Function}}
}) {
	return (
		<Box fill={true} align={'center'} justify={'center'}>
			<Box align={'center'}>
				<Heading level="1">You are done!</Heading>
				<Box direction={'row'} gap={'small'}>
					<Bar size={'xlarge'} color={'black'} />
					<Bar size={'xlarge'} color={'black'} />
					<Bar size={'xlarge'} color={'black'} />
				</Box>
				{props.skippedAnnotations.length > 0 ? (
					<Heading textAlign={'center'} level={'2'}>
						Well almost.
						<br />
						<Anchor onClick={props.clearSkipped}>
							Reclassify {props.skippedAnnotations.length > 1 ? 'these' : 'this'} {props.skippedAnnotations.length}{' '}
							element
							{props.skippedAnnotations.length > 1 ? 's' : ''} that you skipped
						</Anchor>
						{' and you are all set.'}
					</Heading>
				) : (
					<>
						<Heading textAlign={'center'} level="2">
							No more elements left for reclassification for work package {props.stageTask.workPackage.description}
						</Heading>
						{props.stageTask.status === 'finished' ? (
							<Link to={props.myReclassificationsRoute.linkTo({})}>Back to my assigned reclassifications</Link>
						) : (
							<SRSecondaryButton
								onClick={() => props.actions.stageTask.markStageTaskAsCompleted({stageTaskId: props.stageTask._id})}
								label={'Mark work package as completed'}
							/>
						)}
					</>
				)}
			</Box>
		</Box>
	)
}
