import React from 'react'
import {Notification} from './NotificationProvider'
import {ErrorNotification} from './ErrorNotification'
import {InfoNotification} from './InfoNotification'

export function NotificationModal({
	notification,
	acknowledgeFn,
}: {
	notification: Notification
	acknowledgeFn: () => void
}) {
	switch (notification.level) {
		case 'success':
			return null
		case 'error':
			return <ErrorNotification message={notification.message} dismiss={acknowledgeFn} />
		case 'warning':
			return null
		case 'info':
			return <InfoNotification message={notification.message} dismiss={acknowledgeFn} />
	}
}
