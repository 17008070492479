import {useQuery} from 'react-query'
import {fetchReclassificationTaskUsers} from '../../api/reclassificationTasks'

export function useReclassificationTaskUsers(projectId: string | undefined, reclassificationTaskId: string) {
	const {data, status} = useQuery(
		['reclassification-task-users', reclassificationTaskId],
		() => fetchReclassificationTaskUsers(projectId!, reclassificationTaskId),
		{
			enabled: projectId,
		},
	)

	return {users: data, status}
}
