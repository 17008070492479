import {queryCache, useMutation} from 'react-query'
import {ReclassificationWorkPackageStageTaskEntity} from '../../entities/reclassification-work-package-stage-task'
import {AxiosError} from 'axios'
import {postAssignUserToStageTask} from '../../api/stageTasks'

export function useStageTaskMutations(projectId: string | undefined) {
	const onSuccess = (entity: ReclassificationWorkPackageStageTaskEntity) =>
		queryCache.invalidateQueries(
			`projects/${projectId || ''}/reclassification/reclassification-tasks/${
				entity.reclassification
			}/reclassification-work-package-tasks`,
		)
	const [assignUserToStageTaskMutation] = useMutation<
		ReclassificationWorkPackageStageTaskEntity,
		AxiosError,
		{stageTaskId: string; userId: string | null}
	>(({stageTaskId, userId}) => postAssignUserToStageTask(projectId!, stageTaskId, userId), {
		onSuccess,
	})
	return {assignUserToStageTask: assignUserToStageTaskMutation}
}
