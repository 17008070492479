import {PageTitle} from '../../components/TopNavigationBar/PageTitle'
import {Separator} from '../../components/TopNavigationBar/Separator'
import {BreadcrumbItem} from '../../components/TopNavigationBar/BreadcrumbItem'
import React from 'react'
import {PageHeader} from '../../components/Page/PageHeader'
import {Box, Heading} from 'grommet'
import {Page} from '../../components/Page/Page'
import {useCurrentUserCompanies} from '../../features/User/hooks/useCurrentUserCompanies'
import {Spinner} from '../../components/Spinner/Spinner'
import {Link} from 'react-router-dom'
import {managedUsersRoute} from '../ManagedUsers'
import {SR_COLOR_LIGHT_2, SRCard, SRCardContainer} from 'sr-react-commons'

export function MyCompaniesPageTitle() {
	return (
		<PageTitle>
			<Separator />
			<BreadcrumbItem>My companies</BreadcrumbItem>
		</PageTitle>
	)
}

export function MyCompaniesPage() {
	const {data: myCompanies, status} = useCurrentUserCompanies()
	if (status === 'loading') {
		return (
			<Page>
				<Spinner />
			</Page>
		)
	}
	return myCompanies ? (
		<Page>
			<Box gap={'small'} width={'large'}>
				<PageHeader>
					<Heading level={'2'}>My companies</Heading>
				</PageHeader>
				<SRCardContainer scrollable>
					{myCompanies.map(company => (
						<SRCard key={company._id} background={SR_COLOR_LIGHT_2} pad={'medium'}>
							<Box direction={'row'} justify={'between'} align={'center'}>
								<Heading level={'4'}>{company.name}</Heading>
								<Link to={managedUsersRoute.linkTo({companyId: company._id})}>Manage users</Link>
							</Box>
						</SRCard>
					))}
				</SRCardContainer>
			</Box>
		</Page>
	) : null
}
