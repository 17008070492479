import React, {ReactNode, useCallback} from 'react'
import {queryCache} from 'react-query'
import {useCurrentAuthsDetails, useCurrentUserDetails} from '../hooks/useCurrentUserDetails'
import {AuthEntity, User} from '../entities/user'
import {Auth} from '@aws-amplify/auth'

type ContextValue =
	| {
			login: (usernameOrEmail: string, password: string) => Promise<void>
			logout: () => Promise<void>
			user: any
			userDetails: User | undefined
			authDetails: AuthEntity[] | undefined
	  }
	| undefined

export const UserContext = React.createContext<ContextValue>(undefined)

export const UserProvider = ({children}: {children: ReactNode}) => {
	const [cognitoUser, setCognitoUser] = React.useState(null)
	const {userDetails} = useCurrentUserDetails(cognitoUser !== null)
	const {authDetails} = useCurrentAuthsDetails(userDetails?._id)

	React.useEffect(() => {
		// attempt to fetch the info of the user that was already logged in
		Auth.currentAuthenticatedUser()
			.then(user => setCognitoUser(user))
			.catch(() => setCognitoUser(null))
	}, [])

	// We make sure to handle the user update here, but return the resolve value in order for our components to be
	// able to chain additional `.then()` logic. Additionally, we `.catch` the error and "enhance it" by providing
	// a message that our React components can use.
	const login = useCallback(
		(usernameOrEmail: string, password: string) =>
			Auth.signIn(usernameOrEmail, password)
				.then(cognitoUser => {
					setCognitoUser(cognitoUser)
				})
				.catch(err => {
					if (err.code === 'UserNotFoundException') {
						err.message = 'Invalid username or password'
					}
					throw err
				}),
		[setCognitoUser],
	)

	const logout = useCallback(
		() =>
			Auth.signOut().then(data => {
				setCognitoUser(null)
				queryCache.clear()
				return data
			}),
		[setCognitoUser],
	)

	return (
		<UserContext.Provider value={{login, logout, user: cognitoUser, authDetails, userDetails: userDetails}}>
			{children}
		</UserContext.Provider>
	)
}
