import {useViewer} from './useViewer'
import {useEffect} from 'react'
import {useElementSectionState} from '../../ViewerAssets/SectionsViewer/state/ElementSectionStateContext'

export function useDrawSectionPlaneIntersection(selectedElementId?: number) {
	const {
		status,
		actions: {drawSectionPlaneIntersection},
	} = useViewer()
	const {
		state: {currentSection, currentSectionIndex},
	} = useElementSectionState()
	useEffect(() => {
		drawSectionPlaneIntersection(selectedElementId, currentSection?.planeCoefficients)
	}, [drawSectionPlaneIntersection, selectedElementId, currentSection, currentSectionIndex, status])
}
