import {useQuery} from 'react-query'
import {fetchUserById} from '../api/users'

export function useUser(userId: string | null | undefined) {
	const {data: user, status} = useQuery(['user', userId], () => fetchUserById(userId!), {
		enabled: userId,
		staleTime: 60 * 60 * 1000,
	})

	return {user, status}
}
