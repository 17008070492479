import React, {createContext, ReactNode, useCallback, useContext, useEffect, useRef, useState} from 'react'
import {createSrFeaturesReader, SrFeatureFlag, SRFeatureFlags} from 'sr-feature-flags'

const FEATURE_FLAG_LAYERS = ['session', 'server'] as const
type FeatureFlagLayers = typeof FEATURE_FLAG_LAYERS[number]
const Context = createContext<{
	featureEnabled: (feature: SrFeatureFlag) => boolean
	importDataToLayer: (data: Record<string, boolean>, layer: FeatureFlagLayers) => void
	featureFlags: SRFeatureFlags
} | null>(null)
export function FeatureFlagsProvider(props: {children: ReactNode}) {
	const readerRef = useRef(createSrFeaturesReader([...FEATURE_FLAG_LAYERS]))
	const [updatedCounter, setUpdatedCounter] = useState(0)
	const importDataToLayer = useCallback(
		(data: Record<string, boolean>, layer: FeatureFlagLayers) => {
			readerRef.current.importDataToLayer(data, layer)
			setUpdatedCounter(prevState => prevState++)
		},
		[readerRef],
	)
	useEffect(() => {
		const params = Object.fromEntries(new URLSearchParams(document.location.search).entries())
		importDataToLayer(
			Object.fromEntries(
				Object.entries(params)
					.filter(([id]) => id.startsWith(FEATURE_QS_PREFIX))
					.map(([id, value]) => [id.replace(FEATURE_QS_PREFIX, ''), !['false', '0'].includes(value)]),
			),
			'session',
		)
	}, [importDataToLayer])
	// This is necessary so that the value of the context is updated whenever somebody imports
	// TODO find a better solution for this
	// e.g. a react adapter for FeatureFlagReader
	const featureEnabled = useCallback(
		(feature: SrFeatureFlag) => {
			return readerRef.current.featureFlags.featureEnabled(feature)
		},
		[updatedCounter],
	)
	return (
		<Context.Provider
			children={props.children}
			value={{featureEnabled, importDataToLayer, featureFlags: readerRef.current.featureFlags}}
		></Context.Provider>
	)
}

export function useFeatureFlagContext() {
	const context = useContext(Context)
	if (!context) {
		throw new Error('`useFeatureFlagsContext()` needs to be used within a FeatureFlagsProvider')
	}
	return context
}

export function useFeatureFlags() {
	return useFeatureFlagContext().featureFlags
}

export function useFeatureEnabledFunc() {
	return useFeatureFlagContext().featureEnabled
}

export function useFeatureFlagsImport() {
	return useFeatureFlagContext().importDataToLayer
}

export function useFeatureEnabled(feature: SrFeatureFlag) {
	const {featureEnabled} = useFeatureFlagContext()
	return featureEnabled(feature)
}
export const FEATURE_QS_PREFIX = 'feature.'
