import _ from 'lodash'
import {Filter, FilterValue} from '../hooks/useData'

export function buildPaginationQuery(page: number, perPage: number) {
	return `skip=${page * perPage}&limit=${perPage}`
}

export function buildFilterQuery(filters: Filter<Record<string, FilterValue>>) {
	const filtersString = Object.entries(filters)
		.filter(([key, value]) => key !== '_ids' && value !== '' && value !== undefined)
		.map(([key, value]) => {
			if (typeof value === 'object' && 'like' in value) {
				return `${key}=~${value.like}=i`
			}
			return _.isArray(value) ? `${key}=in=(${value.map(el => `"${el}"`).join(',')})` : `${key}==${value}`
		})
		.join(';')
	return `filter=${filtersString}${
		'_ids' in filters ? `&${filters._ids?.map(id => `ids[]=${id}`).join('&') || ''}` : ''
	}`
}
