import {TableBody, TableCell, TableRow} from 'grommet'
import _ from 'lodash'
import React from 'react'
import {formatMagnitude} from '../../../utilities/formatUtilities'
import {SimpleReclassificationValue} from '../entities/reclassification-annotation'
import {getFullName, User} from '../../User/entities/user'
import {SmallTable} from '../../../components/SmallTable/SmallTable'

export function AnnotationDetailTable({
	previousValue,
	users,
}: {
	previousValue: SimpleReclassificationValue
	users: User[]
}) {
	const user = users.find(user => user._id === previousValue.createdBy)
	return (
		<SmallTable>
			<TableBody>
				<TableRow>
					<TableCell>
						<strong>New status</strong>
					</TableCell>
					<TableCell>{previousValue.classification ? _.capitalize(previousValue.classification) : '-'}</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>
						<strong>New magnitude</strong>
					</TableCell>
					<TableCell>{previousValue.magnitude ? formatMagnitude(previousValue.magnitude) : '-'}</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>
						<strong>Last User</strong>
					</TableCell>
					<TableCell>{user && getFullName(user)}</TableCell>
				</TableRow>
			</TableBody>
		</SmallTable>
	)
}
