import {TableBody, TableCell, TableRow} from 'grommet'
import _ from 'lodash'
import React from 'react'
import {ClassificationRedux} from '../../../entities/classification'
import {formatMagnitude} from '../../../utilities/formatUtilities'
import {SmallTable} from '../../../components/SmallTable/SmallTable'

export function ClassificationDetailTable({classification}: {classification: ClassificationRedux}) {
	const shouldBeHidden = classification.magnitude === undefined
	return (
		<SmallTable>
			<TableBody>
				<TableRow>
					<TableCell>
						<strong>Status</strong>
					</TableCell>
					<TableCell>{!shouldBeHidden ? _.capitalize(classification.status) : 'Hidden'}</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>
						<strong>Magnitude</strong>
					</TableCell>
					<TableCell>
						{!shouldBeHidden ? formatMagnitude(parseFloat(classification.magnitude!), true) : 'Hidden'}
					</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>
						<strong>Tolerance</strong>
					</TableCell>
					<TableCell>{formatMagnitude(classification.tolerance, true)}</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>
						<strong>Confidence</strong>
					</TableCell>
					<TableCell>{!shouldBeHidden ? classification.confidence.toFixed(2) : 'Hidden'}</TableCell>
				</TableRow>
			</TableBody>
		</SmallTable>
	)
}
