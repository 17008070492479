import {useViewer} from './useViewer'
import {ContextMenuItem, useExtendContextMenu} from './useExtendContextMenu'
import React from 'react'
import {ReclassificationWithUserNameDTO} from '../../Reclassification/entities/reclassification-annotation'

export function useReviewFromContextMenu(
	elementsByDbId: Record<string, ReclassificationWithUserNameDTO>,
	onReview: (classificationId: string) => void,
) {
	const {viewer} = useViewer()
	const additionalItems: ContextMenuItem[] = React.useMemo(
		() => [
			{
				title: 'Review',
				callback: () => {
					const selectedDbIds = viewer.current?.getSelection()
					if (!selectedDbIds || selectedDbIds?.length > 1) {
						return false
					}
					const element = elementsByDbId[selectedDbIds[0]]
					onReview(element.classification._id)
				},
				condition: () => {
					const selectedDbIds = viewer.current?.getSelection()
					if (!selectedDbIds || selectedDbIds?.length > 1) {
						return false
					}
					return elementsByDbId.hasOwnProperty(selectedDbIds[0].toString())
				},
			},
		],
		[onReview, elementsByDbId, viewer],
	)

	useExtendContextMenu(additionalItems)
}
