import React from 'react'
import {ColumnFilterProps, SRSimpleSelectWithFilterIconStatus} from './SelectColumnFilter'

export const ReviewedSelectColumnFilter = ({
	column: {filterValue, setFilter, preFilteredRows},
}: ColumnFilterProps<boolean | null>) => {
	return (
		<SRSimpleSelectWithFilterIconStatus
			size={'small'}
			value={filterValue === undefined ? undefined : filterValue?.toString()}
			options={[
				{value: '', label: 'All'},
				...(preFilteredRows.some(r => r.values.reviewed) ? [{value: 'true', label: 'Reviewed'}] : []),
				...(preFilteredRows.some(r => !r.values.reviewed) ? [{value: 'false', label: 'Not reviewed'}] : []),
			]}
			onChange={value => setFilter(value === 'true' ? true : value === 'false' ? false : null)}
			isFiltering={option => ['true', 'false'].includes(option?.value as string)}
		/>
	)
}
