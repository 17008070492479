import React, {ReactNode} from 'react'
import {Box} from 'grommet'

export function PageTitle({children}: {children: ReactNode}) {
	return (
		<Box direction={'row'} align={'center'} gap={'xsmall'}>
			{children}
		</Box>
	)
}
