import {Classification, Section} from '../../../entities/classification'
import {NonUndefined} from 'grommet/utils'
import {UserProject} from '../../User/entities/userProject'
import {isNumber} from 'lodash'
import {HEATMAP_SCENE_ID} from '../Extensions/Heatmap/Viewing.Extension.Heatmap'

type RGBAColor = {
	r: number
	g: number
	b: number
	a: number
}

export type Vector3 = [number, number, number]
export type ViewportDTO = {
	name: string
	distanceToOrbit: number
	aspectRatio: number
	projection: string
	isOrthographic: boolean
	fieldOfView: number
	eye: Vector3
	target: Vector3
	up: Vector3
	worldUpVector: Vector3
	pivotPoint: Vector3
}

const BLACK = 0x000000
const RED = 0xff0000
const RENDER_ORDER_TOP_MOST = 999

// this type definition is based on the usage in the function and is not comprehensive
export type ViewerMouseEvent =
	| {pointers: {clientX: number; clientY: number}[]}
	| {pointers: undefined; clientX: number; clientY: number}

const THREE = window.THREE
const DEBUG_OVERLAY = 'debugOverlay'
const SECTION_PLANE_OVERLAY = 'sectionPlaneOverlay'

export const initLayers = (viewer: Autodesk.Viewing.GuiViewer3D) => {
	// Order matters
	ensureOverlay(viewer, HEATMAP_SCENE_ID)
	ensureOverlay(viewer, SECTION_PLANE_OVERLAY)
}

export function coordinateFromIFCtoViewer(model: Autodesk.Viewing.Model, x: number, y: number, z: number) {
	const x_correction = model.getData().globalOffset.x
	const y_correction = model.getData().globalOffset.y
	const z_correction = model.getData().globalOffset.z
	const scale = model.getUnitScale()

	const correct = []
	correct.push(x / scale - x_correction)
	correct.push(y / scale - y_correction)
	correct.push(z / scale - z_correction)

	return correct
}

export function scaleFromIFCtoViewer(model: Autodesk.Viewing.Model) {
	const scale = model.getUnitScale()
	return 1 / scale
}

export function disposeThreeNode(node: any) {
	// https://stackoverflow.com/questions/33152132/three-js-collada-whats-the-proper-way-to-dispose-and-release-memory-garbag

	if (node instanceof THREE.Mesh) {
		if (node.geometry) {
			node.geometry.dispose()
		}

		if (node.material) {
			if (!(node.material instanceof THREE.MeshFaceMaterial)) {
				// @ts-ignore
				node.material.map?.dispose()

				// @ts-ignore
				node.material.dispose()
			}
		}
	}
}

const statusColorMap: Record<string, any> = {
	verified: getColor({r: 0, g: 199, b: 129, a: 0.4}),
	deviated: getColor({r: 255, g: 170, b: 21, a: 0.4}),
	missing: getColor({r: 255, g: 64, b: 64, a: 0.4}),
	default: getColor({r: 200, g: 200, b: 200, a: 0.4}),
	no_data: getColor({r: 100, g: 100, b: 100, a: 0.4}),
	under_construction: getColor({r: 64, g: 64, b: 255, a: 0.4}),
}

function getColor(rgbaColorObject: RGBAColor) {
	return new THREE.Vector4(rgbaColorObject.r / 255, rgbaColorObject.g / 255, rgbaColorObject.b / 255, rgbaColorObject.a)
}

export function hideTexture(viewer: Autodesk.Viewing.GuiViewer3D) {
	const THREE = window.THREE
	//get materials list
	let mats = viewer.impl.matman()._materials

	//define a grey color
	let white = new THREE.Color(1, 1, 1)

	//iterate materials
	for (let index in mats) {
		//index is the material name (unique string in the list)
		if (mats.hasOwnProperty(index)) {
			let m = mats[index]
			m.color = white
			// necessary since some materials have shaders with vertex shading and the color from the model cannot be
			// overwritten
			m.vertexColors = THREE.NoColors
			//mark the material dirty. The viewer will refresh
			m.needsUpdate = true
		}
	}

	//refresh the scene
	viewer.impl.invalidate(true, true, false)
}

export async function loadModel(
	viewer: Autodesk.Viewing.GuiViewer3D,
	documentUrn: string,
	viewerState: UserProject['viewerState'],
	forceNearRadius?: UserProject['viewerFeatureFlags']['forceNearRadius'],
) {
	const documentId = `urn:${btoa(documentUrn)}`
	return new Promise((resolve, reject) => {
		Autodesk.Viewing.Document.load(
			documentId,
			document => {
				const viewables = document.getRoot().search({type: 'geometry'})[0]
				const loadOptions: {
					isAEC: boolean
					nearRadius?: number
				} = {
					isAEC: true,
					// unfortunately the forge viewer ignores 0 as it is evaluated to false,
					// so we need to set it to a negative value to get the same result
					...(isNumber(forceNearRadius) && {nearRadius: forceNearRadius || -1}),
				}
				viewer!.loadDocumentNode(document, viewables, loadOptions).then(model => {
					viewer.setQualityLevel(false, false)
					viewer.restoreState(
						viewerState && viewerState?.viewport
							? {
									viewport: translateViewportFromRealWordToViewerCoordinates(
										viewerState?.viewport,
										viewer.model.getGlobalOffset(),
										viewer.model.getUnitScale(),
									),
							  }
							: undefined,
					)
					resolve(model.getData()?.instanceTree !== undefined)
				})
			},
			reject,
		)
	})
}

function setColor(viewer: Autodesk.Viewing.GuiViewer3D, dbId: number, color: any) {
	viewer.setThemingColor(dbId, color, undefined, true)
}

export function paintModel(
	viewer: Autodesk.Viewing.GuiViewer3D,
	dbIdColorMappings: {dbId: number; status: string}[],
	selectedDbId?: number,
	clearElementColors: boolean = false,
) {
	if (clearElementColors) {
		const allDbIds = Object.keys(viewer.model.getData().instanceTree.nodeAccess.dbIdToIndex).map(id => parseInt(id))
		allDbIds.forEach(id => setColor(viewer, id, statusColorMap['default']))
	}
	for (const {dbId, status} of dbIdColorMappings) {
		if (status in statusColorMap) {
			let color = statusColorMap[status]
			if (dbId === selectedDbId) {
				color = new THREE.Vector4(color.x, color.y, color.z, 1.0)
			}
			setColor(viewer, dbId, color)
		}
	}
}

export function drawSectionPlaneIntersection(
	viewer: Autodesk.Viewing.GuiViewer3D,
	dbId?: number,
	sectionPlaneCoefficients?: NonUndefined<Section['planeCoefficients']>,
): void {
	clearOverlay(viewer, SECTION_PLANE_OVERLAY)
	if (!dbId || !sectionPlaneCoefficients) {
		viewer.impl.invalidate(false, false, true)
		return
	}
	const pl = planeFromCoefficients(viewer, sectionPlaneCoefficients)
	const triangles = getElementTriangles(viewer, dbId)
	const DEBUG_TRIANGLES = false // Set it to true to render the element's triangle
	if (DEBUG_TRIANGLES) {
		clearOverlay(viewer, DEBUG_OVERLAY)
		drawTriangles(viewer, triangles, DEBUG_OVERLAY)
	}
	const lineOpts = {overlay: SECTION_PLANE_OVERLAY, color: BLACK, drawOnTop: true}
	triangles.forEach(t => {
		const intersections = intersectPlaneTriangle(pl, t)
		intersections && drawLine(viewer, intersections[0], intersections[1], lineOpts)
	})
}

const intersectPlaneTriangle = (pl: THREE.Plane, t: THREE.Triangle): THREE.Vector3[] | undefined => {
	const points: THREE.Vector3[] = []
	const addPoint = (point: THREE.Vector3 | undefined) => point && points.push(point)
	addPoint(pl.intersectLine(new THREE.Line3(t.a, t.b), new THREE.Vector3()))
	addPoint(pl.intersectLine(new THREE.Line3(t.b, t.c), new THREE.Vector3()))
	addPoint(pl.intersectLine(new THREE.Line3(t.c, t.a), new THREE.Vector3()))
	return points.length ? points : undefined
}

const planeFromCoefficients = (
	viewer: Autodesk.Viewing.GuiViewer3D,
	[a, b, c, d]: [number, number, number, number],
): THREE.Plane => {
	const normal = new THREE.Vector3(a, b, c)
	const projectedOffset = normal.dot(viewer.impl.model.getData().globalOffset)
	const correctedDistance = d * scaleFromIFCtoViewer(viewer.impl.model) + projectedOffset
	return new THREE.Plane(normal, correctedDistance)
}

export function fragmentIdsFromDbId(viewer: Autodesk.Viewing.GuiViewer3D, dbId: number): number[] {
	const fragIds: number[] = []
	viewer.model.getInstanceTree().enumNodeFragments(
		dbId,
		id => {
			fragIds.push(id)
		},
		true,
	)
	return fragIds
}

export function findPaintedElementByMinDepth(dbIds: number[], viewer: Autodesk.Viewing.Viewer3D): number | undefined {
	return dbIds
		.slice()
		.reverse()
		.find(dbId => {
			const fragList = viewer.model.getFragmentList()
			const colorMap = fragList.db2ThemingColor
			return [
				statusColorMap['deviated'],
				statusColorMap['verified'],
				statusColorMap['under_construction'],
				statusColorMap['missing'],
				statusColorMap['no_data'],
			].some(color => colorMap[dbId] && color.equals(colorMap[dbId]))
		})
}

export function getElementTriangles(viewer: Autodesk.Viewing.GuiViewer3D, dbId: number): THREE.Triangle[] {
	const fragIds = fragmentIdsFromDbId(viewer, dbId)
	const triangles: THREE.Triangle[] = []
	for (const fragId of fragIds) {
		const rp = viewer.impl.getRenderProxy(viewer.model, fragId)
		if (!rp.geometry) continue
		const {vb: vertices, ib: vertexIndexes, vbstride: verticesItemSize} = rp.geometry as {
			vb: number[]
			ib: number[]
			vbstride: number
		}
		// The vertices array stores the x,y,z coordinates as consecutive numbers of all the vertices that
		// composes a fragment object. Items have to be taken by verticesItemSize that is usually 4 or 3, if
		// it is 4, the 4th coordinate is skipped.
		const vertexAt = (vertexIndex: number): THREE.Vector3 => {
			const i = vertexIndex * verticesItemSize
			return rp.localToWorld(new THREE.Vector3(vertices[i], vertices[i + 1], vertices[i + 2]))
		}
		// The vertexIndexes array stores triangle vertex indexes.
		// Since triangles have 3 vertex, the iteration step is 3.
		for (let i = 0; i < vertexIndexes.length; i += 3) {
			const a = vertexAt(vertexIndexes[i])
			const b = vertexAt(vertexIndexes[i + 1])
			const c = vertexAt(vertexIndexes[i + 2])
			triangles.push(new THREE.Triangle(a, b, c))
		}
	}
	return triangles
}

export async function waitForFinalFrameRendered(viewer: Autodesk.Viewing.GuiViewer3D): Promise<void> {
	const condition = (e: {value: {finalFrame?: boolean}}) => !!e.value.finalFrame
	return waitForViewerEvent(viewer, Autodesk.Viewing.FINAL_FRAME_RENDERED_CHANGED_EVENT, condition)
}

export async function waitForViewerEvent(
	viewer: Autodesk.Viewing.GuiViewer3D,
	name: string,
	condition: (e: any) => boolean = () => true,
): Promise<void> {
	await new Promise<void>(resolve => {
		const callback = (e: any) => {
			if (condition(e)) {
				viewer.removeEventListener(name, callback)
				resolve()
			}
		}
		viewer.addEventListener(name, callback)
	})
}

export function ensureOverlay(viewer: Autodesk.Viewing.GuiViewer3D, overlay: string): void {
	if (!viewer.impl.overlayScenes.hasOwnProperty(overlay)) {
		viewer.impl.createOverlayScene(overlay)
	}
}

export function clearOverlay(viewer: Autodesk.Viewing.GuiViewer3D, overlay: string): void {
	const scene: THREE.Scene | undefined = (viewer.impl.overlayScenes[overlay] || {}).scene
	if (!scene) return
	const children = [...scene.children]
	scene.remove.apply(scene, children)
	children.forEach(node => disposeThreeNode(node))
}

export function drawTriangles(
	viewer: Autodesk.Viewing.GuiViewer3D,
	triangles: THREE.Triangle[],
	overlay: string = DEBUG_OVERLAY,
): void {
	const opts = {overlay, drawOnTop: false}
	triangles.forEach(t => {
		drawLine(viewer, t.a, t.b, opts)
		drawLine(viewer, t.b, t.c, opts)
		drawLine(viewer, t.c, t.a, opts)
	})
}

export function drawLine(
	viewer: Autodesk.Viewing.GuiViewer3D,
	start: THREE.Vector3,
	end: THREE.Vector3,
	opts: {
		overlay?: string
		color?: string | number
		linewidth?: number
		drawOnTop?: boolean
	},
) {
	const DEFAULTS = {overlay: DEBUG_OVERLAY, color: RED, linewidth: 1, drawOnTop: false}
	const {linewidth, drawOnTop, color, overlay} = {...DEFAULTS, ...opts}
	const material = new THREE.LineBasicMaterial({
		color: new THREE.Color(color),
		linewidth,
		depthTest: !drawOnTop,
		depthWrite: !drawOnTop,
	})
	const geometry = new THREE.Geometry()
	geometry.vertices.unshift(start, end)
	const line = new THREE.Line(geometry, material)
	if (drawOnTop) {
		line.renderOrder = RENDER_ORDER_TOP_MOST
		line.onBeforeRender = renderer => renderer.clearDepth()
	}
	ensureOverlay(viewer, overlay)
	viewer.impl.addOverlay(overlay, line)
	viewer.impl.invalidate(false, false, true)
}

export function isolateClassifiedElements(viewer: Autodesk.Viewing.GuiViewer3D, classifications: Classification[]) {
	viewer.isolate(classifications.map(cl => cl.forgeObjectId))
}

export function getCameraTargetFromOrientation(lat: number, lon: number) {
	const phi = THREE.Math.degToRad(90 - lat)
	const theta = THREE.Math.degToRad(lon)

	return new THREE.Vector3(Math.sin(phi) * Math.cos(theta), Math.cos(phi), Math.sin(phi) * Math.sin(theta))
}

export function createBoundingBox(elementBoundaries: string[]) {
	// Prepare bounding box material
	const bBoxMaterial = new THREE.LineBasicMaterial({
		color: 0xfa00ff,
		linewidth: 3,
	})

	// Prepare bounding box vertices
	const bboxVertices = []
	for (let vertex = 0; vertex < elementBoundaries.length / 2; ++vertex) {
		const vertexPhi = THREE.Math.degToRad(90 - parseFloat(elementBoundaries[vertex * 2]))
		const vertexTheta = THREE.Math.degToRad(parseFloat(elementBoundaries[vertex * 2 + 1]))
		const vertexCoords = new THREE.Vector3(
			499 * Math.sin(vertexPhi) * Math.cos(vertexTheta),
			499 * Math.cos(vertexPhi),
			499 * Math.sin(vertexPhi) * Math.sin(vertexTheta),
		)
		bboxVertices.push(vertexCoords)
	}

	// In order to provide a fast visual representation on top of the 360 image, bounding boxes are rendered
	// as a line-based prism handcrafted from vertices in polar coordinates.
	// We "follow the dots" a bit here in order to build the topology in the correct order
	// as vertices are shared from the backend:
	//
	//    7----3
	//   /|    |\
	//  6--------2
	//  | |    | |
	//  | 5----1 |
	//  |/      \|
	//  4--------0
	//
	const bBoxGeometry = new THREE.Geometry()
	bBoxGeometry.vertices.push(
		bboxVertices[0],
		bboxVertices[4],
		bboxVertices[5],
		bboxVertices[1],
		bboxVertices[0],
		bboxVertices[2],
		bboxVertices[6],
		bboxVertices[7],
		bboxVertices[3],
		bboxVertices[2],
		bboxVertices[0],
		bboxVertices[1],
		bboxVertices[3],
		bboxVertices[7],
		bboxVertices[5],
		bboxVertices[4],
		bboxVertices[6],
	)

	return new THREE.Line(bBoxGeometry, bBoxMaterial)
}

export function getAllDbIdsFromModel(model: Autodesk.Viewing.Model) {
	const {instanceTree} = model.getData()
	const {dbIdToIndex} = instanceTree.nodeAccess
	return Object.keys(dbIdToIndex).map(dbId => parseInt(dbId, 10))
}

export function removeMeshAndDisposeMaterial(viewer: Autodesk.Viewing.GuiViewer3D, sceneId: string, mesh: THREE.Mesh) {
	viewer.overlays.removeMesh(mesh, sceneId)
	mesh.geometry.dispose()
	if (Array.isArray(mesh.material)) {
		mesh.material.forEach(mat => mat.dispose())
	} else {
		mesh.material.dispose()
	}
}

// Project mouse event coordinates into scene camera and perform scene raycasting against custom meshes
export function raycastMouseEvent(
	event: MouseEvent,
	viewer: Autodesk.Viewing.GuiViewer3D,
	collisionObjects: THREE.Mesh[],
) {
	if (collisionObjects.length === 0) {
		return []
	}

	// Backproject with render camera
	const rect = viewer.impl.canvas.getBoundingClientRect()
	const x = ((event.clientX - rect.left) / rect.width) * 2 - 1
	const y = -((event.clientY - rect.top) / rect.height) * 2 + 1

	const pointerVector = new THREE.Vector3()
	pointerVector.set(x, y, 0.5)
	pointerVector.unproject(viewer.impl.camera)

	// Build raycaster and check for intersections
	const raycaster = new THREE.Raycaster()
	raycaster.set(viewer.impl.camera.position, pointerVector.sub(viewer.impl.camera.position).normalize())
	const intersectResults = raycaster.intersectObjects(collisionObjects, true)

	// @ts-ignore because rayIntersect is not in the types
	const hitTest = viewer.model.rayIntersect(raycaster, true, viewer.getIsolatedNodes())

	return intersectResults.filter(res => !hitTest || hitTest.distance > res.distance)
}

export function getCenterOfElementBounds(dbId: number, viewer: Autodesk.Viewing.GuiViewer3D): THREE.Vector3 {
	const model = viewer.model
	const instanceTree = model.getData().instanceTree
	const fragList = model.getFragmentList()

	let bounds = new THREE.Box3()

	instanceTree.enumNodeFragments(
		dbId,
		(fragId: any) => {
			let box = new THREE.Box3()
			fragList.getWorldBounds(fragId, box)
			bounds.union(box)
		},
		true,
	)

	// @ts-ignore
	return bounds.center()
}

export function scalarFromViewerToRealWorld(scalar: number, translation: number, unitScale: number): number {
	return (scalar + translation) * unitScale
}

export function scalarFromRealWorldToViewer(scalar: number, translation: number, unitScale: number): number {
	return scalar / unitScale - translation
}

export function vector3FromViewerToRealWorldCoordinates(
	vector: Vector3,
	globalOffset: THREE.Vector3,
	unitScale: number,
): Vector3 {
	return [
		scalarFromViewerToRealWorld(vector[0], globalOffset.x, unitScale),
		scalarFromViewerToRealWorld(vector[1], globalOffset.y, unitScale),
		scalarFromViewerToRealWorld(vector[2], globalOffset.z, unitScale),
	]
}

export function vector3FromRealWorldToViewerCoordinates(
	vector: Vector3,
	globalOffset: THREE.Vector3,
	unitScale: number,
): Vector3 {
	return [
		scalarFromRealWorldToViewer(vector[0], globalOffset.x, unitScale),
		scalarFromRealWorldToViewer(vector[1], globalOffset.y, unitScale),
		scalarFromRealWorldToViewer(vector[2], globalOffset.z, unitScale),
	]
}

const VIEWPORT_COORDINATE_ATTRS = ['eye', 'target', 'pivotPoint'] as const

export function translateViewportFromRealWordToViewerCoordinates(
	realWorldCoordinates: ViewportDTO,
	globalOffset: THREE.Vector3,
	unitScale: number,
): ViewportDTO {
	return {
		...realWorldCoordinates,
		...Object.fromEntries(
			VIEWPORT_COORDINATE_ATTRS.map(attr => [
				attr,
				vector3FromRealWorldToViewerCoordinates(realWorldCoordinates[attr], globalOffset, unitScale),
			]),
		),
	}
}

export function translateViewportFromViewerToRealWorldCoordinates(
	realWorldCoordinates: ViewportDTO,
	globalOffset: THREE.Vector3,
	unitScale: number,
): ViewportDTO {
	return {
		...realWorldCoordinates,
		...Object.fromEntries(
			VIEWPORT_COORDINATE_ATTRS.map(attr => [
				attr,
				vector3FromViewerToRealWorldCoordinates(realWorldCoordinates[attr], globalOffset, unitScale),
			]),
		),
	}
}

export function selectionChanged(oldSelection: number[], newSelection: number[]) {
	return (
		oldSelection.some((id: number) => !newSelection.includes(id)) ||
		newSelection.some((id: number) => !oldSelection.includes(id))
	)
}
