import React from 'react'
import {Box, Table, TableBody, TableCell, TableHeader, TableRow} from 'grommet'
import {format} from 'date-fns'
import {useNotifications} from '../../features/Notifications/hooks/useNotifications'
import {ConfirmationModal} from '../../components/ConfirmationModal/ConfirmationModal'
import {getFullName, User} from '../../features/User/entities/user'
import {Pagination} from '../../api/baseApi'
import {useRoutes} from '../../hooks/useRoutes'
import {Link} from 'react-router-dom'
import {SRDeletePrimaryButton, SRSecondaryButton} from 'sr-react-commons'

export function CompanyUsers(props: {
	userPagination: Pagination<User>
	onDisableUser: (payload: {userId: string}) => Promise<User>
}) {
	const {pushNotification} = useNotifications()

	const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(false)
	const [userToDisable, setUserToDisable] = React.useState<string | undefined>(undefined)

	const openDisableConfirmation = (userId: string) => {
		setUserToDisable(userId)
		setConfirmationModalOpen(true)
	}

	const routes = useRoutes()

	const confirmDisableUser = () => {
		if (userToDisable) {
			props
				.onDisableUser({
					userId: userToDisable,
				})
				.then(() => {
					setUserToDisable(undefined)
					setConfirmationModalOpen(false)
				})
				.catch((errorMessage: string) => {
					pushNotification(errorMessage, 'error')
					setUserToDisable(undefined)
					setConfirmationModalOpen(false)
				})
		}
	}
	return props.userPagination ? (
		<Box>
			<ConfirmationModal
				isOpen={confirmationModalOpen}
				onClose={() => setConfirmationModalOpen(false)}
				onConfirmation={confirmDisableUser}
				text={'Are you sure you want to disable the user?'}
				confirmationButtonLabel={'Disable'}
			/>
			<Box fill overflow={'auto'} pad={{right: 'xsmall'}}>
				<Table>
					<TableHeader>
						<TableRow>
							<TableCell>Email</TableCell>
							<TableCell>Name</TableCell>
							<TableCell>Created date</TableCell>
							<TableCell>Actions</TableCell>
						</TableRow>
					</TableHeader>
					<TableBody>
						{props.userPagination.result.map((user: User) => (
							<TableRow key={user._id}>
								<TableCell>{user.email}</TableCell>
								<TableCell>{getFullName(user)}</TableCell>
								<TableCell>{format(new Date(user.createdDate), 'yyyy-MM-dd')}</TableCell>
								<TableCell>
									<Box gap="xsmall" direction="row">
										<Link
											title="View reclassification activity of the user"
											to={routes.userActivity.linkTo({userId: user._id})}
										>
											<SRSecondaryButton size={'small'} label={'Activity'} />
										</Link>
										<SRDeletePrimaryButton
											onClick={() => openDisableConfirmation(user._id)}
											size={'small'}
											label={'Disable'}
										/>
									</Box>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Box>
		</Box>
	) : null
}
