import {SrRoute} from '../../routing'
import {
	ReclassificationReportPage,
	ReclassificationReportPageTitle,
	ReclassificationReportRouteParams,
	ReclassificationUserReportRouteParams,
} from './ReclassificationReportPage'

export const reclassificationReport = new SrRoute<ReclassificationReportRouteParams>(
	'/reclassifications/manage/:clientSlug/:projectSlug/:reclassificationId/report/:subRoute',
	ReclassificationReportPage,
	ReclassificationReportPageTitle,
	true,
)

export const reclassificationUserReport = new SrRoute<ReclassificationUserReportRouteParams>(
	'/reclassifications/manage/:clientSlug/:projectSlug/:reclassificationId/report/:subRoute/:selectedUser',
	ReclassificationReportPage,
	ReclassificationReportPageTitle,
	true,
)
