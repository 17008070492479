import {UserProject} from '../../../User/entities/userProject'
import {useReclassificationsForFinalReview} from './useReclassificationsForFinalReview'
import React from 'react'
import {ReclassificationWithUserNameDTO} from '../../entities/reclassification-annotation'

export function useFinalReviewReclassificationElements(
	project: UserProject | undefined,
	reclassificationId: string | undefined,
): {reclassificationElements: ReclassificationWithUserNameDTO[] | null; status: 'success' | 'loading'} {
	const {data: reclassificationElements} = useReclassificationsForFinalReview(project?._id, reclassificationId)

	return {
		reclassificationElements: reclassificationElements || null,
		status: !!reclassificationElements ? 'success' : 'loading',
	}
}
