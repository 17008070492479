import _ from 'lodash'
import {Row} from 'react-table'
import {AnnotationsTableData} from '../../QualityControlReviewTable/QualityControlReviewTable'
import React from 'react'
import {Box, Form} from 'grommet'
import Papa from 'papaparse'
import {DocumentCsv, Filter} from 'grommet-icons'
import {
	SR_COLOR_ACCENT_3,
	SR_COLOR_NEUTRAL_1,
	SRFileInput,
	SRIconButton,
	SRMultiSelect,
	SRMultiSelectProps,
	SRSimpleSelect,
	SRSimpleSelectOptionObject,
	SRSimpleSelectProps,
} from 'sr-react-commons'

export type ColumnFilterProps<Value> = {
	column: {
		filterValue: Value
		setFilter: (value: Value) => void
		preFilteredRows: Row<AnnotationsTableData>[]
		id: string
		labelGetter?: (row: Row<AnnotationsTableData>) => string
	}
}

export const SRSimpleSelectWithFilterIconStatus = ({
	isFiltering,
	...props
}: SRSimpleSelectProps & {isFiltering?: (value: SRSimpleSelectOptionObject | undefined) => boolean}) => (
	<SRSimpleSelect
		borderless
		hideExpandedIcon
		focusIndicator={false}
		valueFormatter={selection => (
			<SRIconButton
				size={'small'}
				icon={<Filter size={'xsmall'} />}
				textColor={(isFiltering ? isFiltering(selection) : selection) ? SR_COLOR_ACCENT_3 : SR_COLOR_NEUTRAL_1}
			/>
		)}
		size={'xsmall'}
		dropHeight={'medium'}
		{...props}
	/>
)

export const SRMultiSelectWithFilterIconStatus = (props: SRMultiSelectProps) => (
	<SRMultiSelect
		borderless
		hideExpandedIcon
		focusIndicator={false}
		valueFormatter={selections => (
			<SRIconButton
				size={'small'}
				icon={<Filter size={'xsmall'} />}
				textColor={selections?.length ? SR_COLOR_ACCENT_3 : SR_COLOR_NEUTRAL_1}
			/>
		)}
		isStretchAlign={false}
		size={'xsmall'}
		showToggleAllButtons
		dropHeight={'medium'}
		{...props}
	/>
)

export function SelectColumnFilter({
	column: {filterValue, setFilter, preFilteredRows, id, labelGetter},
}: ColumnFilterProps<string[]>) {
	// Calculate the options for filtering
	// using the preFilteredRows
	const options = React.useMemo(
		() =>
			_.sortBy(
				_.uniqBy(preFilteredRows, row => row.values[id]).map(row => ({
					value: row.values[id],
					label: labelGetter?.(row) || row.values[id],
				})),
				'label',
			),
		[id, labelGetter, preFilteredRows],
	)

	return (
		<Box width={{max: '150px'}}>
			<SRMultiSelectWithFilterIconStatus
				size={'xsmall'}
				showToggleAllButtons
				showSearchInput
				value={filterValue || []}
				options={options}
				dropHeight={'medium'}
				onChange={(value: string[]) => setFilter(value)}
			/>
		</Box>
	)
}

export function MultiSelectFileUploadColumnFilter({
	column: {filterValue, setFilter, preFilteredRows, id},
}: ColumnFilterProps<string[]>) {
	// Calculate the options for filtering
	// using the preFilteredRows
	const options = React.useMemo(() => {
		const options = new Set<string>()
		preFilteredRows.forEach(row => {
			options.add(row.values[id])
		})
		return Array.from(options).map(option => ({value: option, label: option || 'None'}))
	}, [id, preFilteredRows])

	const onFileUpload = (files: File[]) => {
		if (files.length > 0) {
			if (!files[0].name.endsWith('.csv')) {
				alert('You need to select a .csv file!')
				return
			}
			Papa.parse(files[0], {
				header: true,
				skipEmptyLines: true,
				complete: function (results) {
					if (results.errors.length > 0) {
						const errors = results.errors.filter(
							error => results.meta.fields?.length !== 1 && error.code !== 'UndetectableDelimiter',
						)
						if (errors.length > 0) {
							alert(errors.map(error => error.message).join('\n'))
						}
					}
					if (results.meta.fields?.includes('externalId')) {
						const data = results.data as {externalId: string}[]
						const externalIds = data.map(el => el.externalId).filter(id => options.some(option => option.value === id))
						setFilter(externalIds)
					} else {
						alert("Selected file does not contain column 'externalId'!")
					}
				},
			})
		}
	}

	return (
		<Box>
			<Form>
				<Box flex={'grow'} width={{max: '150px'}} direction={'row'} align={'center'} justify={'between'}>
					<SRMultiSelectWithFilterIconStatus
						options={options}
						size={'small'}
						value={filterValue || []}
						dropHeight={'medium'}
						searchPlaceholder={'Type to filter'}
						emptySearchMessage={'No matching ids found'}
						showToggleAllButtons
						showSearchInput
						onChange={(value: string[]) => setFilter(value)}
					/>
					<Box flex={false}>
						<SRFileInput
							title="Upload a .csv file with a column 'externalId' and the externalIds you want to filter for. Can contain more columns."
							accept={'.csv'}
							onChange={onFileUpload}
							size={'small'}
							icon={<DocumentCsv size={'xsmall'} />}
							buttonVariant={'plain'}
							label={''}
						/>
					</Box>
				</Box>
			</Form>
		</Box>
	)
}
