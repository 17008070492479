import {Box, Table, TableBody, TableCell, TableHeader, TableRow, Text} from 'grommet'
import {format, formatDistance} from 'date-fns'
import React from 'react'
import {StageTaskActivityDTO} from '../../../features/Reclassification/api/reclassificationTasks'
import {ReclassificationTaskEntity} from '../../../features/Reclassification/entities/reclassification-task'

export function ActivityWorkPackageTable(props: {
	reclassificationTasks: ReclassificationTaskEntity[]
	stageTasks: StageTaskActivityDTO[]
}) {
	return (
		<Table>
			<TableHeader>
				<TableRow>
					<TableCell>Work Package</TableCell>
					<TableCell>Stage</TableCell>
					<TableCell>Elements done</TableCell>
					<TableCell>Status</TableCell>
					<TableCell>First reclassification</TableCell>
					<TableCell>Last reclassification</TableCell>
					<TableCell>Duration</TableCell>
					<TableCell>Actions</TableCell>
				</TableRow>
			</TableHeader>
			<TableBody>
				{props.stageTasks.map(stageTask => (
					<TableRow key={stageTask.stageTask._id}>
						<TableCell>
							<Box width={'200px'}>
								<Text truncate={true}>{stageTask.stageTask.workPackage.description}</Text>
							</Box>
						</TableCell>
						<TableCell>
							{
								props.reclassificationTasks
									.find(task => task._id === stageTask.stageTask.reclassification)!
									.stages.find(stage => stage._id === stageTask.stageTask.stage)!.name
							}
						</TableCell>
						<TableCell>{stageTask.count}</TableCell>
						<TableCell>{stageTask.stageTask.status}</TableCell>
						<TableCell>{format(new Date(stageTask.first), 'yyyy-MM-dd HH:mm')}</TableCell>
						<TableCell>{format(new Date(stageTask.last), 'yyyy-MM-dd HH:mm')}</TableCell>
						<TableCell>{formatDistance(new Date(stageTask.last), new Date(stageTask.first))}</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	)
}
