import {useViewer} from '../../Viewer/hooks/useViewer'
import {Box, Tab, Tabs, Text} from 'grommet'
import React from 'react'
import {ClassificationWithAnalysisView} from '../../../entities/classification'
import {ElementDetailTable} from './ElementDetailTable'
import {ClassificationDetailTable} from './ClassificationDetailTable'
import {AnnotationDetailTable} from './AnnotationDetailTable'
import {ReviewControls} from './ReviewControls'
import {ReclassificationDTO, SimpleReclassificationValue} from '../entities/reclassification-annotation'
import {User} from '../../User/entities/user'
import {ReclassificationActions} from '../api/reclassificationAnnotations'
import {ElementHistoryTable} from './ElementHistoryTable'
import {useClassificationsWithAnalysisView} from '../hooks/useClassificationsWithAnalysisView'
import {SRPrimaryButton, SRSecondaryButton} from 'sr-react-commons'
import {FormPreviousLink} from 'grommet-icons'

function ReviewHeader(props: {
	isolateFiltered: () => void
	isolateElement: () => void
	fitToView: () => void
	onBack: () => void
}) {
	return (
		<Box direction={'row'} gap={'xxsmall'}>
			<SRPrimaryButton
				title={'Back to list'}
				icon={<FormPreviousLink size={'small'} />}
				onClick={props.onBack}
				size={'small'}
			/>
			{props.isolateFiltered && (
				<SRSecondaryButton
					size={'small'}
					alignSelf={'start'}
					onClick={props.isolateFiltered}
					label="Isolate elements to review"
				/>
			)}
			<SRSecondaryButton
				size="small"
				alignSelf={'start'}
				onClick={props.isolateElement}
				label="Isolate selected element"
			/>
			<SRSecondaryButton size="small" alignSelf={'start'} onClick={props.fitToView} label="Fit to view" />
		</Box>
	)
}

function ReviewElementDetails(props: {
	classification: ReclassificationDTO['classification']
	classificationsWithAnalysisView?: ClassificationWithAnalysisView[]
	previousValue: SimpleReclassificationValue
	users: User[]
	showAnalysisLink: boolean
}) {
	return (
		<Box justify={'between'}>
			<Tabs alignControls={'start'}>
				<Tab title={'Classification Details'}>
					<Box>
						<Box direction={'row'} gap={'xsmall'} justify={'between'}>
							<ClassificationDetailTable classification={props.classification} />
							<AnnotationDetailTable previousValue={props.previousValue} users={props.users} />
						</Box>
					</Box>
				</Tab>
				<Tab title={'Element Details'}>
					<Box>
						<ElementDetailTable classification={props.classification} />
					</Box>
				</Tab>
				{props.classificationsWithAnalysisView && (
					<Tab title={'Element History'}>
						<Box fill overflow={'auto'}>
							<ElementHistoryTable
								classificationsWithAnalysisView={props.classificationsWithAnalysisView}
								showAnalysisLink={props.showAnalysisLink}
							/>
						</Box>
					</Tab>
				)}
			</Tabs>
			{props.previousValue.message ? (
				<Box pad={{vertical: 'xsmall', horizontal: 'xsmall'}} gap="xsmall" direction="row">
					<Text>Message for expert review:</Text>
					<Box overflow={{vertical: 'auto'}} width={{max: '250px'}} height={{max: '150px'}}>
						<Text size={'small'}>{props.previousValue.message}</Text>
					</Box>
				</Box>
			) : null}
		</Box>
	)
}

export function ReviewContainer(props: {
	selectedElement: ReclassificationDTO
	reviewActions: Pick<ReclassificationActions, 'approve' | 'reclassify'>
	onBack: () => void
	onIsolateFiltered: () => void
	users: User[]
	projectId: string
	showAnalysisLink: boolean
}) {
	const {
		viewer,
		status,
		actions: {isolateElement},
	} = useViewer()

	const {data} = useClassificationsWithAnalysisView(props.selectedElement.classification.externalId, props.projectId)
	return (
		<Box pad={'small'} gap="small">
			<ReviewHeader
				onBack={props.onBack}
				isolateFiltered={props.onIsolateFiltered}
				isolateElement={() => {
					isolateElement(props.selectedElement.classification.forgeObjectId)
				}}
				fitToView={() => {
					if (viewer.current && props.selectedElement.classification && status === 'model_loaded') {
						viewer.current.fitToView([props.selectedElement.classification.forgeObjectId])
					}
				}}
			/>
			<Box direction="row" justify={'between'} gap={'xxsmall'}>
				<Box flex={'grow'} pad="xsmall" background={'light-4'}>
					<Box alignSelf={'start'}>
						<ReviewElementDetails
							showAnalysisLink={props.showAnalysisLink}
							classification={props.selectedElement.classification}
							classificationsWithAnalysisView={data}
							previousValue={props.selectedElement.previousValue}
							users={props.users}
						/>
					</Box>
				</Box>
				<Box width={{min: '160px', max: '180px'}} gap="xxsmall" pad="xsmall" background={'light-4'}>
					<ReviewControls
						key={props.selectedElement.classification._id}
						annotation={props.selectedElement.annotation}
						actions={{
							approve: async () => props.reviewActions.approve(props.selectedElement.classification._id),
							reclassify: async formData =>
								props.reviewActions.reclassify(props.selectedElement.classification._id, formData),
						}}
					/>
				</Box>
			</Box>
		</Box>
	)
}
