export enum PermissionsEnum {
	ADMIN = 'ADMIN',
	WRITE = 'WRITE',
	LIST = 'LIST',
	READ = 'READ',
	SR_READ = 'SR_READ',
	SR_LIST = 'SR_LIST',
	SR_WRITE = 'SR_WRITE',
	SR_ADMIN = 'SR_ADMIN',
	VIEW_ASSETS = 'VIEW_ASSETS',
	WRITE_ASSETS = 'WRITE_ASSETS',
	RECLASSIFY = 'RECLASSIFY',
	MANAGE_RECLASSIFICATION = 'MANAGE_RECLASSIFICATION',
	PROJECT_READ = 'PROJECT_READ',
	COMPANY_READ = 'COMPANY_READ',
	FORBIDDEN = 'FORBIDDEN',
	REGISTERED = 'REGISTERED',
	ALL = 'ALL',
}
