import {srApiV3} from '../../../api/baseApi'
import {Company} from '../entities/userProject'

export function fetchCompaniesManagedByCurrentUser() {
	return srApiV3.get(`/companies/by-current-user`).then(response => response.data)
}

export function fetchCompany(companyId: string): Promise<Company> {
	return srApiV3.get(`/companies/${companyId}`).then(response => response.data)
}
