import {useQuery} from 'react-query'
import {AxiosError} from 'axios'
import {fetchReclassificationUserReport, ReclassificationUserReport} from '../api/reclassificationTasks'

export function useReclassificationUserReport(
	projectId: string | undefined,
	reclassificationId: string | undefined,
	userId: string | undefined,
) {
	return useQuery<ReclassificationUserReport, AxiosError>(
		['reclassification-user-report', projectId, reclassificationId, userId],
		() => fetchReclassificationUserReport(projectId!, reclassificationId!, userId!),
		{
			enabled: projectId && reclassificationId && userId,
		},
	)
}
