import {Box} from 'grommet'
import React from 'react'

export function LabelBadge({label, color, ...props}: any) {
	return (
		<Box alignSelf={'start'} round={'xsmall'} pad={{horizontal: 'small'}} border={'all'} background={color} {...props}>
			{label}
		</Box>
	)
}
