import {useQuery} from 'react-query'
import {ReclassificationTaskEntity} from '../../entities/reclassification-task'
import {AxiosError} from 'axios'
import {fetchReclassificationTask} from '../../api/reclassificationTasks'

export function useReclassificationTask(projectId: string | undefined, reclassificationTaskId: string | undefined) {
	return useQuery<ReclassificationTaskEntity, AxiosError>(
		['reclassification-task', reclassificationTaskId],
		() => fetchReclassificationTask(projectId!, reclassificationTaskId!),
		{
			enabled: projectId !== undefined && reclassificationTaskId !== undefined,
			staleTime: 10 * 60 * 1000,
		},
	)
}
