import {useProject} from '../../../features/User/hooks/useProject'
import {useRoutes} from '../../../hooks/useRoutes'
import {Accordion, AccordionPanel, Box, Text} from 'grommet'
import {Link} from 'react-router-dom'
import {ActivityWorkPackageTable} from './ActivityWorkPackageTable'
import {Spinner} from '../../../components/Spinner/Spinner'
import React from 'react'
import {ReclassificationTaskEntity} from '../../../features/Reclassification/entities/reclassification-task'
import {StageTaskActivityDTO} from '../../../features/Reclassification/api/reclassificationTasks'

export function ReclassificationActivityCardContainer(props: {
	reclassification: ReclassificationTaskEntity
	stageTasks: StageTaskActivityDTO[]
}) {
	const [clientSlug, projectSlug] = props.reclassification.tenantId.split('|')
	const {project} = useProject(clientSlug, projectSlug)
	const routes = useRoutes()

	return (
		<Box flex={false} pad={'small'} background={'light-3'} border={'all'}>
			<Box gap={'medium'} direction={'row'} justify={'between'}>
				{project ? (
					<Box gap={'small'} flex={'grow'}>
						<Box direction={'row'} justify={'between'}>
							<Text weight={'bold'}>
								{project.company.name} {'>'} {project.name}
							</Text>
							<Box width={{max: 'medium'}}>
								<Text title={props.reclassification.name} weight={'bold'} truncate={true}>
									{props.reclassification.name}
								</Text>
								<Box alignSelf={'end'}>
									<Link
										to={routes.reclassificationOverview.linkTo({
											clientSlug: clientSlug,
											projectSlug: projectSlug,
											reclassificationId: props.reclassification._id,
										})}
									>
										view reclassification
									</Link>
								</Box>
							</Box>
						</Box>
						<Accordion>
							<AccordionPanel label={<Text>Work Packages</Text>}>
								{props.stageTasks && (
									<ActivityWorkPackageTable
										reclassificationTasks={[props.reclassification]}
										stageTasks={props.stageTasks}
									/>
								)}
							</AccordionPanel>
						</Accordion>
					</Box>
				) : (
					<Spinner />
				)}
			</Box>
		</Box>
	)
}
