import {Blank, IconProps} from 'grommet-icons'
import React from 'react'

export const Ruler = (props: IconProps) => (
	<Blank {...props}>
		<svg fill="#000000" viewBox="0 0 8.4666667 8.4666669" version="1.1" x="0px" y="0px">
			<g>
				<path d="m 6.0911003,-0.0011191 a 0.26460984,0.26460984 0 0 0 -0.1834515,0.07855 l -0.784447,0.78445 1.2820912,1.281575 a 0.26464799,0.26464799 0 1 1 -0.3741367,0.374134 l -1.2820917,-1.281576 -0.5978964,0.597898 0.8686807,0.869196 a 0.26464799,0.26464799 0 1 1 -0.3741372,0.374136 l -0.8691975,-0.869195 -0.5978964,0.598413 1.2815755,1.281575 a 0.26473748,0.26473748 0 1 1 -0.3741375,0.374653 l -1.2815755,-1.282091 -0.5968627,0.596863 0.8691975,0.86868 A 0.2646485,0.2646485 0 1 1 2.7026779,5.0202809 L 1.8334804,4.1515979 1.2355839,4.7494959 2.5176762,6.0310709 A 0.2649201,0.2649201 0 1 1 2.143022,6.4057239 L 0.86092972,5.1236329 0.07544796,5.9096299 a 0.26460984,0.26460984 0 0 0 5.1673e-4,0.373105 l 2.10891511,2.108398 a 0.26460984,0.26460984 0 0 0 0.3725873,0 L 8.389668,2.5589309 a 0.26460984,0.26460984 0 0 0 0.00155,-0.374137 L 6.2823041,0.0758799 a 0.26460984,0.26460984 0 0 0 -0.1912038,-0.076999 z" />
			</g>
		</svg>
	</Blank>
)
