import {queryCache, useMutation} from 'react-query'
import {ReclassificationWorkPackageStageTaskEntity} from '../../entities/reclassification-work-package-stage-task'
import {AxiosError} from 'axios'
import {postFinishStageTask, postStartStageTask} from '../../api/stageTasks'

export function useCurrentUserStageTaskMutations(projectId: string | undefined) {
	const onSuccess = () =>
		queryCache.invalidateQueries('reclassification/reclassification-work-package-stage-tasks/by-current-user')

	const [startStageTaskMutation] = useMutation<
		ReclassificationWorkPackageStageTaskEntity,
		AxiosError,
		{stageTaskId: string}
	>(({stageTaskId}) => postStartStageTask(projectId || '', stageTaskId), {
		onSuccess,
		throwOnError: true,
	})

	const [finishStageTaskMutation] = useMutation<
		ReclassificationWorkPackageStageTaskEntity,
		AxiosError,
		{stageTaskId: string}
	>(({stageTaskId}) => postFinishStageTask(projectId || '', stageTaskId), {
		onSuccess,
		throwOnError: true,
	})

	return {startStageTask: startStageTaskMutation, finishStageTask: finishStageTaskMutation}
}
