import {HeaderGroup} from 'react-table'
import {Box, TableCell, Text} from 'grommet'
import {SRIconButton} from 'sr-react-commons'
import {Ascend, Descend, Unsorted} from 'grommet-icons'
import {ReclassificationTableColumn} from '../columns'
import React from 'react'

export const HeaderColumnCell = ({column}: {column: HeaderGroup<any>}) => (
	<TableCell scope={'col'} border={'all'} verticalAlign={'top'} {...column.getHeaderProps()} align={'center'}>
		<Box align={'center'} direction={'row'} justify={'between'} fill={'horizontal'}>
			{!column.canSort ? null : (
				<SRIconButton
					icon={
						column.isSorted ? (
							column.isSortedDesc ? (
								<Descend size="xsmall" />
							) : (
								<Ascend size="xsmall" />
							)
						) : (
							<Box overflow={'hidden'} width={'12px'} height={'12px'} align={'center'} justify={'center'}>
								<Unsorted size="medium" />
							</Box>
						)
					}
					{...column.getSortByToggleProps()}
					size={'small'}
				/>
			)}
			<Text truncate size={'xsmall'} title={(column as ReclassificationTableColumn).Title}>
				{column.render('Header')}
			</Text>
			<Box>{column.canFilter && column.render('Filter')}</Box>
		</Box>
	</TableCell>
)
