import {Box, Paragraph, Text, Tip} from 'grommet'
import {Alert} from 'grommet-icons'
import React from 'react'

function timeToHoursFormatted(totalTimeInMilliseconds: number) {
	const hours = Math.round(totalTimeInMilliseconds / 1000 / 60 / 60)
	return `${hours} hour${hours > 1 ? 's' : ''}`
}

export function ApproximateTimeWorkedDisplay(props: {timeInMilliseconds: number}) {
	return (
		<Box gap="xsmall" align="center" direction={'row'} flex={false}>
			{props.timeInMilliseconds > 0 ? (
				<>
					<Paragraph size="large">
						<Text>Approximate time worked in this time period: </Text>
						<Text weight="bold">about {timeToHoursFormatted(props.timeInMilliseconds)} </Text>
					</Paragraph>
				</>
			) : (
				<Paragraph>
					<Text>The user has not enough activities in the filtered time period to give an approximate time.</Text>
				</Paragraph>
			)}
			<Tip
				dropProps={{align: {left: 'right'}}}
				content={
					<Box width={'medium'}>
						<Text size={'small'}>
							Please only use this approximate time for verification of the hours that contractors have sent in the
							invoice. Do not trust this number for billing only.
						</Text>
						<br />
						<Text size={'small'}>
							This time is calculated by computing the time between each two sequential reclassification activities and
							filtering breaks longer than 15 minutes.
						</Text>
					</Box>
				}
			>
				<Box style={{cursor: 'help'}}>
					<Alert color={'status-warning'} />
				</Box>
			</Tip>
		</Box>
	)
}
