import {useFilters, usePagination, useSortBy, useTable} from 'react-table'
import React, {useEffect} from 'react'
import {formatMagnitude} from '../../../../../utilities/formatUtilities'
import {MultiSelectFileUploadColumnFilter, SelectColumnFilter} from '../core/components/SelectColumnFilter'
import {Checkmark} from 'grommet-icons'
import {Box} from 'grommet'
import {FinalReviewTableData, prepareFinalReviewTableData} from './FinalReviewTable'
import {ReclassificationWithUserNameDTO} from '../../../entities/reclassification-annotation'
import {ReclassificationTableColumn, StatusTableCell} from '../core/columns'
import {ReviewedSelectColumnFilter} from '../core/components/ReviewedSelectColumnFilter'
import {WPTaskEntityWithCurrentStageTaskByClassification} from '../../../entities/reclassification-work-package-task'

type FRColumn = ReclassificationTableColumn<FinalReviewTableData>

export function useFinalReviewTable(
	reclassificationElements: ReclassificationWithUserNameDTO[],
	wpTaskEntityWithCurrentStageTaskByClassification: WPTaskEntityWithCurrentStageTaskByClassification,
	initialPageSize: number = 10,
) {
	const tableData = React.useMemo(() => {
		return prepareFinalReviewTableData(reclassificationElements, wpTaskEntityWithCurrentStageTaskByClassification)
	}, [reclassificationElements, wpTaskEntityWithCurrentStageTaskByClassification])

	const columns: FRColumn[] = React.useMemo(
		() => [
			{
				Header: 'G. Id',
				Title: 'Global Id',
				accessor: 'externalId',
				Filter: MultiSelectFileUploadColumnFilter,
				filter: 'includesValue',
				width: 'xxsmall',
			},
			{
				Header: 'WP',
				Title: 'Work Package Name',
				accessor: 'workPackageName',
				Filter: SelectColumnFilter,
				filter: 'includesValue',
				width: '130px',
			},
			{
				Header: 'Ty',
				Title: 'Type',
				accessor: 'elementType',
				Filter: SelectColumnFilter,
				filter: 'includesValue',
				labelGetter: row => row.original.mappedElementType || row.original.elementType,
				Cell: ({row, column}) => (column as FRColumn).labelGetter?.(row),
				width: 'xsmall',
			},
			{
				id: 'initialStatus',
				Header: 'S',
				Title: 'Initial Status',
				accessor: 'initialClassification',
				Cell: StatusTableCell,
				Filter: SelectColumnFilter,
				filter: 'includesValue',
			},
			{
				id: 'initialMagnitude',
				Header: 'M',
				Title: 'Initial Magnitude',
				accessor: 'initialMagnitude',
				sortType: 'basic',
				Cell: ({value}: {value: number | null}) => (value !== null ? formatMagnitude(value, false) : '-'),
				disableFilters: true,
			},
			{
				Header: 'C',
				Title: 'Final Confidence',
				accessor: 'confidence',
				sortType: 'basic',
				Cell: ({value}: {value: number}) => Math.trunc(value * 100) / 100,
				disableFilters: true,
			},
			{
				Header: 'T',
				Title: 'Final Tolerance',
				accessor: 'tolerance',
				Cell: ({value}: {value: number}) => formatMagnitude(value, false),
				disableFilters: true,
			},
			{
				id: 'finalMagnitude',
				Header: 'M',
				Title: 'Final Magnitude',
				accessor: 'finalMagnitude',
				sortType: 'basic',
				Cell: ({value}: {value: number | null}) => (value !== null ? formatMagnitude(value, false) : '-'),
				disableFilters: true,
			},
			{
				id: 'finalStatus',
				Header: 'S',
				Title: 'Final Status',
				accessor: 'finalClassification',
				Cell: StatusTableCell,
				Filter: SelectColumnFilter,
				filter: 'includesValue',
			},
			{
				id: 'user',
				Header: 'User',
				Title: 'User',
				accessor: 'userName',
				Cell: ({value}: {value: string | null}) => value || '-',
				Filter: SelectColumnFilter,
				filter: 'includesValue',
				width: 'xxsmall',
			},
			{
				Header: 'R',
				Title: 'Reviewed',
				id: 'reviewed',
				width: '50px',
				accessor: 'reviewed',
				Cell: ({value}: {value: boolean}) => (
					<Box align={'center'}>{value ? <Checkmark color={'brand'} size={'xsmall'} /> : null}</Box>
				),
				filter: 'equals',
				Filter: ReviewedSelectColumnFilter,
				sortType: 'basic',
			},
		],
		[],
	)

	const table = useTable<FinalReviewTableData>(
		{
			columns,
			data: tableData,
			autoResetFilters: false,
			autoResetPage: false,
			autoResetSortBy: false,
			initialState: {
				pageSize: initialPageSize,
				sortBy: [{id: 'finalMagnitude', desc: true}],
			},
		},
		useFilters,
		useSortBy,
		usePagination,
	)

	const {gotoPage} = table

	useEffect(() => {
		gotoPage(0)
	}, [table.state.filters, gotoPage])

	return table
}
