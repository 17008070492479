import {PageTitle} from '../../components/TopNavigationBar/PageTitle'
import React from 'react'
import {Page} from '../../components/Page/Page'
import {Container} from '../../components/Container/Container'
import {PageHeader} from '../../components/Page/PageHeader'
import {useUser} from '../../features/User/hooks/useUser'
import {useParams} from 'react-router-dom'
import {Spinner} from '../../components/Spinner/Spinner'
import {Box, DateInput, Heading, Text} from 'grommet'
import {useUserReclassificationActivity} from '../../features/Reclassification/hooks/useUserReclassificationActivity'
import {subDays} from 'date-fns'
import _ from 'lodash'
import {Edit} from 'grommet-icons'
import {ReclassificationActivityCardContainer} from './components/ReclassificationActivityCardContainer'
import {getFullName} from '../../features/User/entities/user'
import {ReclassificationTaskEntity} from '../../features/Reclassification/entities/reclassification-task'
import {StageTaskActivityDTO} from '../../features/Reclassification/api/reclassificationTasks'
import {OverlappingWorkPackagesWarning} from './components/OverlappingWorkPackagesWarning'
import {ApproximateTimeWorkedDisplay} from './ApproximateTimeWorkedDisplay'

export type UserActivityPageRouteParams = {
	userId: string
}

export function UserActivityPageTitle() {
	return <PageTitle>User Activity</PageTitle>
}

const dateFormat = new Intl.DateTimeFormat(undefined, {
	month: 'short',
	day: 'numeric',
})

export function UserActivityPage() {
	const {userId} = useParams<UserActivityPageRouteParams>()
	const {user, status} = useUser(userId)
	const [[startDate, endDate], setTimePeriod] = React.useState<[string, string]>(() => [
		subDays(new Date(), 31).toISOString(),
		new Date().toISOString(),
	])
	const {data} = useUserReclassificationActivity(userId, {startDate: new Date(startDate), endDate: new Date(endDate)})

	if (status === 'loading') {
		return (
			<Page>
				<Spinner />
			</Page>
		)
	}

	return user ? (
		<Page>
			<Container gap={'small'} fill={'vertical'} width={'xlarge'}>
				<PageHeader>
					<Heading level={'2'}>User Activity for {getFullName(user)}</Heading>
				</PageHeader>
				<Box flex={false}>
					<DateFilterBox startDate={startDate} endDate={endDate} setDates={setTimePeriod} />
				</Box>
				{data ? (
					<>
						<ApproximateTimeWorkedDisplay timeInMilliseconds={data.totalTime} />
						<Box overflow={{vertical: 'auto'}}>
							{data.overlaps.length > 0 && <OverlappingWorkPackagesWarning data={data} />}
							<UserReclassifications reclassifications={data.reclassificationTasks} stageTasks={data.stageTasks} />
						</Box>
					</>
				) : (
					<Box>
						<Spinner />
					</Box>
				)}
			</Container>
		</Page>
	) : null
}

function DateFilterBox(props: {
	startDate: string
	endDate: string
	setDates: React.Dispatch<React.SetStateAction<[string, string]>>
}) {
	return (
		<Box direction={'row'} gap={'small'}>
			<Text>
				Showing activity for {dateFormat.format(new Date(props.startDate))} -{' '}
				{dateFormat.format(new Date(props.endDate))}
			</Text>
			<DateInput
				value={[props.startDate, props.endDate]}
				buttonProps={{
					label: 'Change time period',
					icon: <Edit size={'small'} />,
					reverse: true,
					size: 'small',
				}}
				onChange={event => {
					const nextValue = event.value
					props.setDates([nextValue[0], nextValue[1]])
				}}
			/>
		</Box>
	)
}

export function UserReclassifications({
	reclassifications,
	stageTasks,
}: {
	reclassifications: ReclassificationTaskEntity[]
	stageTasks: StageTaskActivityDTO[]
}) {
	const stageTasksByReclassificationId = _.groupBy(stageTasks, wp => wp.stageTask.reclassification)

	return (
		<Box flex={false}>
			<Heading level={'3'}>Reclassifications</Heading>
			{reclassifications ? (
				<Box gap={'small'}>
					{reclassifications.map(reclassification => (
						<ReclassificationActivityCardContainer
							key={reclassification._id}
							reclassification={reclassification}
							stageTasks={stageTasksByReclassificationId[reclassification._id]}
						/>
					))}
				</Box>
			) : (
				<Spinner />
			)}
		</Box>
	)
}
