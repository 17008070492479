import _ from 'lodash'
import {MutableRefObject, useEffect, useRef} from 'react'
import {useViewer} from './useViewer'
import {findPaintedElementByMinDepth} from '../utilities/viewerUtilities'

const forceLeafObjectSelectionModeHack = (viewer: Autodesk.Viewing.GuiViewer3D) => {
	// Fix for local storage selection mode due to Autodesk bug.
	// If not config present in the local-storage for the selection mode,
	// it does not react to setting to leaf mode. So the hack is to set it
	// to something else and then setting it to the desired mode (leaf)
	viewer.setSelectionMode(Autodesk.Viewing.SelectionMode.FIRST_OBJECT)
	viewer.setSelectionMode(Autodesk.Viewing.SelectionMode.LEAF_OBJECT)
}

export function useViewerSelectionFix(dbIds: number[]) {
	const {
		viewer: viewerRef,
		status,
		actions: {selectElements},
	} = useViewer()
	const selectElementsRef = useRef(selectElements)
	useEffect(() => {
		selectElementsRef.current = selectElements
	}, [selectElements])
	useEffect(() => {
		if (status === 'model_loaded' && viewerRef.current) {
			let viewer = viewerRef.current
			forceLeafObjectSelectionModeHack(viewer)
			const selectionCallback = (event: any) =>
				selectElementsWithClassificationsOrElementIfcTypes(event, selectElementsRef)
			const debouncedSelectionCallback = _.debounce(selectionCallback, 100)
			viewer.addEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, debouncedSelectionCallback)
			return () => {
				if (viewer) {
					viewer.removeEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, debouncedSelectionCallback)
				}
			}
		}
	}, [dbIds, status, viewerRef, selectElementsRef])
}

async function getUpdatedSelection(
	// can't use viewer definitions since they are incorrect for getProperties()
	viewer: any,
	selectedDbIds: number[],
): Promise<number[]> {
	const getParentId = (id: number): number => {
		const instanceTree = viewer.model.getData().instanceTree
		return instanceTree.getNodeParentId(id)
	}
	const newSelection = await Promise.all(
		selectedDbIds.map(async (id: number) => {
			const dbIds = []
			// try to find an element in the hierarchy that is classified
			for (let dbId = id; dbId; dbId = getParentId(dbId)) {
				dbIds.push(dbId)
			}
			// if no element in the hierarchy is classified, find an element that has an
			// IfcType corresponding to an element
			return findPaintedElementByMinDepth(dbIds, viewer) || id
		}),
	)
	// if multiple elements are selected and both a child and parent of this child are selected,
	// newSelection could have dbIds duplicated in such case we remove that id so it acts as toggle selection (select/unselect).
	return Object.entries(_.countBy(newSelection))
		.filter(([, value]) => value === 1)
		.map(([key]) => parseInt(key, 10))
}

function selectElementsWithClassificationsOrElementIfcTypes(
	event: any,
	selectElementsRef: MutableRefObject<(ids: number[]) => void>,
) {
	const viewer: Autodesk.Viewing.Viewer3D = event.target
	if (event.selections.length) {
		const selectedDbIds = event.selections[0].dbIdArray
		getUpdatedSelection(viewer, selectedDbIds).then((newSelection: any[]) => {
			// if getUpdatedSelection found any elements suited for selection
			// we need to programmatically tell the viewer to select this new selection.
			// this will result in firing the selection event again and therefore
			// this method will be called again, but then it should call the store dispatch only
			selectElementsRef.current?.(newSelection)
		})
	} else {
		selectElementsRef.current?.([])
	}
}
