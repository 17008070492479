import {Box, Heading, Text} from 'grommet'
import React from 'react'
import {Link, useParams} from 'react-router-dom'
import {ViewerProvider} from '../../features/Viewer/components/ViewerProvider'
import {UserProject} from '../../features/User/entities/userProject'
import {Container} from '../../components/Container/Container'
import {useRoutes} from '../../hooks/useRoutes'
import {useCurrentUserStageTasks} from '../../features/Reclassification/hooks/stageTask/useCurrentUserStageTasks'
import {PageTitle} from '../../components/TopNavigationBar/PageTitle'
import {BreadcrumbItem} from '../../components/TopNavigationBar/BreadcrumbItem'
import {ReclassificationDTO} from '../../features/Reclassification/entities/reclassification-annotation'
import {
	ReclassificationWorkPackageStageTaskEntity,
	ReclassificationWorkPackageStageTaskEntityWithWorkPackage,
} from '../../features/Reclassification/entities/reclassification-work-package-stage-task'
import {useReclassificationElements} from '../../features/Reclassification/hooks/stageTask/useReclassificationElements'
import {useStageTaskReclassificationMutations} from '../../features/Reclassification/hooks/stageTask/useStageTaskReclassificationMutations'
import {useCurrentUserStageTaskMutations} from '../../features/Reclassification/hooks/stageTask/useCurrentUserStageTaskMutations'
import {ReclassificationActions} from '../../features/Reclassification/api/reclassificationAnnotations'
import _ from 'lodash'
import {Separator} from '../../components/TopNavigationBar/Separator'
import {useNotifications} from '../../features/Notifications/hooks/useNotifications'
import {Page} from '../../components/Page/Page'
import {StageTaskReview} from './StageTaskReview'
import {StageTaskReclassification} from './StageTaskReclassification'
import {ElementSectionStateProvider} from '../../features/ViewerAssets/SectionsViewer/state/ElementSectionStateContext'
import {ReclassificationTaskEntity} from '../../features/Reclassification/entities/reclassification-task'
import {useMyReclassificationTask} from '../../features/Reclassification/hooks/reclassificationTask/useMyReclassificationTask'
import {useCurrentProject} from '../../hooks/useCurrentProject'
import {SRPrimaryButton} from 'sr-react-commons'

export type StageTaskPageRouteParams = {
	clientSlug: string
	projectSlug: string
	stageTaskId: string
	reclassificationId: string
}

function useCurrentUserStageTask(stageTaskId: string) {
	const {data: stageTasks, status} = useCurrentUserStageTasks({_id: stageTaskId})
	return {status, stageTask: stageTasks && stageTasks.result.length > 0 ? stageTasks.result[0] : undefined}
}

export function StageTaskPageTitle() {
	const {stageTaskId} = useParams<StageTaskPageRouteParams>()
	const {stageTask} = useCurrentUserStageTask(stageTaskId)
	return (
		<PageTitle>
			<Separator />
			<BreadcrumbItem>{stageTask ? stageTask.workPackage.description : 'Work Package'}</BreadcrumbItem>
			<Separator />
			<BreadcrumbItem>{stageTask ? _.capitalize(stageTask.stageType) : '-'}</BreadcrumbItem>
		</PageTitle>
	)
}

export function StageTaskPage() {
	const {stageTaskId} = useParams<StageTaskPageRouteParams>()
	const project = useCurrentProject()
	const {status, stageTask} = useCurrentUserStageTask(stageTaskId)
	const reclassification = useMyReclassificationTask(stageTask?.reclassification)
	const {reclassificationElements} = useReclassificationElements(stageTask?._id, project)
	const {approve, reclassify, reclassifyWithExpertReview} = useStageTaskReclassificationMutations(
		project?._id,
		stageTask?._id,
	)
	const routes = useRoutes()
	const {startStageTask, finishStageTask} = useCurrentUserStageTaskMutations(project?._id)
	const {pushNotification} = useNotifications()
	if ((status === 'success' || status === 'error') && stageTask === undefined) {
		return (
			<Page>
				<Container align={'center'}>
					<Heading textAlign={'center'} level={2}>
						Stage task with id {stageTaskId} not found.
					</Heading>
					<Text textAlign={'center'}>The stage task might not be assigned to you or does not exist.</Text>
					<Link to={routes.home.linkTo({})}>Back to my work</Link>
				</Container>
			</Page>
		)
	}
	if (project && reclassification && stageTask && reclassificationElements) {
		return (
			<Box fill={true} background={'light-2'}>
				<ViewerProvider>
					<ElementSectionStateProvider>
						<StageTaskPageContent
							stageTask={stageTask}
							project={project}
							reclassification={reclassification}
							reclassificationElements={reclassificationElements}
							actions={{
								reclassification: {
									approve: (classificationId: string) =>
										approve(classificationId).catch(error => {
											pushNotification(error.message, 'error')
											return Promise.reject(error)
										}),
									reclassify: (...args) =>
										reclassify(...args).catch(error => {
											pushNotification(error.message, 'error')
											return Promise.reject(error)
										}),
									requestExpertReview: (...args) =>
										reclassifyWithExpertReview(...args).catch(error => {
											pushNotification(error.message, 'error')
											return undefined
										}),
								},
								stageTask: {
									startStageTask,
									markStageTaskAsCompleted: finishStageTask,
								},
							}}
						/>
					</ElementSectionStateProvider>
				</ViewerProvider>
			</Box>
		)
	} else {
		return null
	}
}

function StageTaskPageContent(props: {
	project: UserProject
	reclassification: ReclassificationTaskEntity
	stageTask: ReclassificationWorkPackageStageTaskEntityWithWorkPackage
	reclassificationElements: ReclassificationDTO[]
	actions: {
		reclassification: ReclassificationActions
		stageTask: {
			startStageTask: ({
				stageTaskId,
			}: {
				stageTaskId: string
			}) => Promise<ReclassificationWorkPackageStageTaskEntity | undefined>
			markStageTaskAsCompleted: ({
				stageTaskId,
			}: {
				stageTaskId: string
			}) => Promise<ReclassificationWorkPackageStageTaskEntity | undefined>
		}
	}
}) {
	if (props.stageTask.status === 'not started') {
		return (
			<Container pad={'xxsmall'} justify={'center'} fill={true}>
				<Box alignSelf={'center'}>
					<Heading textAlign={'center'}>
						{_.capitalize(props.stageTask.stageType)} of work package {props.stageTask.workPackage.description}
					</Heading>
					<SRPrimaryButton
						alignSelf={'center'}
						label={'Start'}
						onClick={() => props.actions.stageTask.startStageTask({stageTaskId: props.stageTask._id})}
					/>
				</Box>
			</Container>
		)
	}

	switch (props.stageTask.stageType) {
		case 'review':
			return (
				<StageTaskReview
					project={props.project}
					reclassification={props.reclassification}
					stageTask={props.stageTask}
					reclassificationElements={props.reclassificationElements}
					actions={props.actions}
				/>
			)
		case 'resolve requested expert review':
			return (
				<StageTaskReview
					project={props.project}
					reclassification={props.reclassification}
					stageTask={props.stageTask}
					reclassificationElements={props.reclassificationElements}
					actions={props.actions}
				/>
			)
		case 'final review':
			return (
				<StageTaskReview
					project={props.project}
					reclassification={props.reclassification}
					stageTask={props.stageTask}
					reclassificationElements={props.reclassificationElements}
					actions={props.actions}
				/>
			)
		case 'reclassification':
			return (
				<StageTaskReclassification
					project={props.project}
					reclassification={props.reclassification}
					stageTask={props.stageTask}
					reclassificationElements={props.reclassificationElements}
					actions={props.actions}
				/>
			)
		default:
			throw new Error(`Unknown stage type: ${props.stageTask.stageType}`)
	}
}
