import {Page} from '../../components/Page/Page'
import React from 'react'
import {Box, Text} from 'grommet'
import {Spinner} from '../../components/Spinner/Spinner'
import {useProject} from '../../features/User/hooks/useProject'
import {UserProject} from '../../features/User/entities/userProject'
import {useNotifications} from '../../features/Notifications/hooks/useNotifications'
import {PageTitle} from '../../components/TopNavigationBar/PageTitle'
import {Separator} from '../../components/TopNavigationBar/Separator'
import {BreadcrumbItem} from '../../components/TopNavigationBar/BreadcrumbItem'
import {useCurrentUserReclassificationTasks} from '../../features/Reclassification/hooks/reclassificationTask/useCurrentUserReclassificationTasks'
import {
	ReclassificationStageEntity,
	ReclassificationTaskEntity,
	ReclassificationTaskEntityWithStageTasksWithWorkPackage,
} from '../../features/Reclassification/entities/reclassification-task'
import {useCurrentUser} from '../../features/User/hooks/useCurrentUser'
import {useReclassificationTaskMutations} from '../../features/Reclassification/hooks/reclassificationTask/useReclassificationTaskMutations'
import {ReclassificationTaskStageCard} from './ReclassificationTaskStageCard'
import {Link} from 'react-router-dom'
import {useRoutes} from '../../hooks/useRoutes'
import {useReclassificationWorkPackagesForFinalReview} from '../../features/Reclassification/hooks/finalReview/useReclassificationWorkPackagesForFinalReview'
import {PaginationControls} from '../../components/PaginationControls/PaginationControls'
import {usePaginationFilterSort} from '../../hooks/usePaginationFilterSort'
import {
	SR_COLOR_LIGHT_1,
	SRCard,
	SRCardContainer,
	SRHeading,
	SRNotificationCard,
	SRPrimaryButton,
} from 'sr-react-commons'
import {Container} from '../../components/Container/Container'

const TASKS_PER_PAGE = 5

export function MyReclassificationsTasksPage() {
	return (
		<Page>
			<MyAssignedReclassificationTasks />
		</Page>
	)
}

export function MyReclassificationsTasksPageTitle() {
	return (
		<PageTitle>
			<Separator />
			<BreadcrumbItem width={{max: 'medium'}}>My assigned tasks</BreadcrumbItem>
		</PageTitle>
	)
}

export function MyAssignedReclassificationTasks() {
	const pagination = usePaginationFilterSort<ReclassificationTaskEntity>({page: 0}, {status: ['in progress']})
	const {data: reclassifications, status: reclassificationsLoadingStatus} = useCurrentUserReclassificationTasks(
		{page: pagination.state.pagination.page, perPage: TASKS_PER_PAGE},
		pagination.state.filter,
	)
	const {userDetails} = useCurrentUser()
	if (reclassificationsLoadingStatus === 'loading') {
		return (
			<Page>
				<Spinner />
			</Page>
		)
	}
	return reclassifications && userDetails ? (
		<Container gap={'medium'} fill={'vertical'}>
			<SRHeading level={5}>Active reclassifications assigned to me</SRHeading>
			<Box fill>
				{reclassifications.result.length > 0 ? (
					<Box gap={'small'}>
						<SRCardContainer scrollable>
							{reclassifications.result.map(reclassification => (
								<AssignedReclassificationCardContainer
									userId={userDetails._id}
									key={reclassification._id}
									reclassification={reclassification}
								/>
							))}
						</SRCardContainer>
						<PaginationControls
							start={reclassifications.skip}
							currentPageLen={reclassifications.result.length}
							totalCount={reclassifications.count}
							page={pagination.state.pagination.page + 1}
							pageCount={Math.ceil(reclassifications.count / TASKS_PER_PAGE)}
							setPage={(page: number) => pagination.actions.setPage(page - 1)}
							currentPageSize={TASKS_PER_PAGE}
						/>
					</Box>
				) : (
					<Box height={'70px'}>
						<SRNotificationCard
							fill={false}
							type={'info'}
							text={<>You are currently not assigned to any reclassification.</>}
						/>
					</Box>
				)}
			</Box>
		</Container>
	) : null
}

function FinalReviewTaskCard(props: {
	projectId: string
	reclassification: ReclassificationTaskEntityWithStageTasksWithWorkPackage
	stage: ReclassificationStageEntity
}) {
	const routes = useRoutes()
	const {data: workPackagesForFinalReview} = useReclassificationWorkPackagesForFinalReview(
		props.projectId,
		props.reclassification._id,
	)

	const isFinished =
		workPackagesForFinalReview &&
		workPackagesForFinalReview.length === props.reclassification.workPackagesCount &&
		workPackagesForFinalReview.every(wp => wp.status === 'finished')
	const isReady =
		workPackagesForFinalReview && workPackagesForFinalReview.length === props.reclassification.workPackagesCount

	return (
		<SRCard direction={'row'} background={SR_COLOR_LIGHT_1}>
			<Box gap={'small'} flex={'grow'}>
				<Box gap={'small'}>
					<Box direction={'row'} justify={'between'}>
						<SRHeading level={5} fill>
							{props.stage.name}
						</SRHeading>
						<Link
							to={routes.finalReview.linkTo({
								clientSlug: props.reclassification.tenantId.split('|')[0],
								projectSlug: props.reclassification.tenantId.split('|')[1],
								reclassificationId: props.reclassification._id,
							})}
						>
							<SRPrimaryButton disabled={!isReady} label={'Review'} />
						</Link>
					</Box>
					{isFinished && <Text>{props.stage.name} has been finished</Text>}
					{workPackagesForFinalReview !== undefined && !isReady && (
						<Text>
							{props.stage.name} is not ready yet: {workPackagesForFinalReview.length} of{' '}
							{props.reclassification.workPackagesCount} work packages are ready for {props.stage.name}.
						</Text>
					)}
				</Box>
			</Box>
		</SRCard>
	)
}

function AssignedReclassificationCard(props: {
	project: UserProject
	reclassification: ReclassificationTaskEntityWithStageTasksWithWorkPackage
	userId: string
	onAssignStageTask: (stageId: string) => Promise<any>
}) {
	const myStages = props.reclassification.stages.filter(stage => stage.assignedUsers.includes(props.userId))
	return (
		<Box gap={'medium'} flex={'grow'}>
			<Box gap={'small'} flex={'grow'}>
				<SRHeading level={4} fill>
					{props.project.company.name} {'>'} {props.project.name} {'>'} {props.reclassification.name}
				</SRHeading>
				<Box gap={'small'}>
					{myStages.map(stage =>
						stage.type === 'final review' ? (
							<FinalReviewTaskCard
								projectId={props.project._id}
								key={stage._id}
								stage={stage}
								reclassification={props.reclassification}
							/>
						) : (
							<ReclassificationTaskStageCard
								key={stage._id}
								stage={stage}
								stageTasks={props.reclassification.stageTasks.filter(stageTask => stageTask.stage === stage._id)}
								availableWP={props.reclassification.availableWPByStage[stage.name]}
								onAssignStageTask={() => props.onAssignStageTask(stage._id)}
							/>
						),
					)}
				</Box>
			</Box>
		</Box>
	)
}

function AssignedReclassificationCardContainer(props: {
	reclassification: ReclassificationTaskEntityWithStageTasksWithWorkPackage
	userId: string
}) {
	const [clientSlug, projectSlug] = props.reclassification.tenantId.split('|')
	const {project, status: projectLoadingStatus} = useProject(clientSlug, projectSlug)
	const notifications = useNotifications()
	const {assignStageTaskToCurrentUser} = useReclassificationTaskMutations()
	if (projectLoadingStatus === 'loading') {
		return <Spinner />
	}
	return project ? (
		<AssignedReclassificationCard
			userId={props.userId}
			project={project}
			reclassification={props.reclassification}
			onAssignStageTask={(stageId: string) =>
				assignStageTaskToCurrentUser({
					projectId: project?._id,
					reclassificationTaskId: props.reclassification._id,
					stageId: stageId,
				}).then(response => {
					if (response === null) notifications.pushNotification('No unassigned stage tasks left.', 'info')
					return response
				})
			}
		/>
	) : null
}
