import {useViewer} from '../../Viewer/hooks/useViewer'
import {Box} from 'grommet'
import React from 'react'
import {ReclassificationControls} from './ReclassificationControls'
import {ElementControls} from './ElementControls'
import {ReclassificationDTO} from '../entities/reclassification-annotation'
import {ReclassificationActions} from '../api/reclassificationAnnotations'
import {SRCard} from 'sr-react-commons'
import {ReclassificationHeader} from './ReclassificationHeader'

export function ReclassificationContainer({
	currentElement,
	remainingElementCount,
	totalElementCount,
	actions,
}: {
	currentElement: ReclassificationDTO
	remainingElementCount: number
	totalElementCount: number
	actions: {
		reclassification: ReclassificationActions & {skip: (classificationId: string) => void}
	}
}) {
	const {
		actions: {selectElements, isolateElement, fitToView},
	} = useViewer()

	return (
		<Box gap="small" fill flex>
			{currentElement ? (
				<Box direction="row" gap={'xsmall'} fill justify={'between'}>
					<SRCard fill>
						<ElementControls
							currentElement={currentElement}
							selectElement={() => {
								selectElements([currentElement.classification.forgeObjectId])
							}}
							isolateElement={() => {
								isolateElement(currentElement.classification.forgeObjectId)
							}}
							fitToView={() => {
								fitToView(currentElement.classification.forgeObjectId)
							}}
						/>
					</SRCard>
					<Box fill={'vertical'} direction={'column'} gap="small" width={'220px'}>
						<ReclassificationHeader
							skipped={false}
							remainingElementCount={remainingElementCount}
							totalElementCount={totalElementCount}
						/>
						<SRCard fill>
							<ReclassificationControls
								key={currentElement.classification._id}
								actions={actions.reclassification}
								currentElement={currentElement}
							/>
						</SRCard>
					</Box>
				</Box>
			) : null}
		</Box>
	)
}
