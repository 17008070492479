import React from 'react'
import {DistanceMeasureCanvas} from './DistanceMeasureCanvas'
import {LineProps} from './DistanceMeasureLine'
import {getS3KeyForPath} from '../../../utilities/storageUtilities'
import {UserProject} from '../../User/entities/userProject'
import {Section} from '../../../entities/classification'
import {useSectionMeasurements} from './state/MeasurementsContext'

type ElementSectionViewProps = {
	section: Section
	project: UserProject
}

export function ElementSectionView({section, project}: ElementSectionViewProps) {
	let imagePath = getS3KeyForPath(project, section.sectionPath)
	const {measurements, addSectionLine, removeSectionLine, clearSectionLines} = useSectionMeasurements(
		section.sectionPath,
	)

	const addMeasurementLine = (measurement: LineProps) => {
		addSectionLine(measurement)
	}

	const removeMeasurement = (id: string) => {
		removeSectionLine(id)
	}

	const clearMeasurements = () => {
		clearSectionLines()
	}

	return (
		<DistanceMeasureCanvas
			clearMeasurements={clearMeasurements}
			addMeasurement={addMeasurementLine}
			removeMeasurement={removeMeasurement}
			measurements={measurements}
			imageSource={imagePath}
			scale={section.scale}
		/>
	)
}
