import {PermissionsEnum} from '../entities/auth'
import {useQuery} from 'react-query'
import {fetchCurrentUserHasPermissionOnResource} from '../api/users'

export function useCurrentUserHasPermissionOnResource(permission: PermissionsEnum, id: string | undefined) {
	return useQuery(
		['current-user', 'resources', id, 'permissions', permission],
		() => fetchCurrentUserHasPermissionOnResource(id!, permission),
		{enabled: id !== undefined, staleTime: 5 * 60 * 1000},
	)
}
