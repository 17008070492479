import React from 'react'
import {Box, Form, Image, Text} from 'grommet'
import {useCurrentUser} from '../features/User/hooks/useCurrentUser'
import {useNotifications} from '../features/Notifications/hooks/useNotifications'
import {SR_COLOR_LIGHT_1, SR_COLOR_WHITE, SRBrandButton, SRHeading, SRTextInput} from 'sr-react-commons'

export function LoginPage() {
	const {login} = useCurrentUser()
	const {pushNotification} = useNotifications()

	return (
		<Box fill={true} justify={'center'} background={SR_COLOR_LIGHT_1}>
			<Box
				gap="large"
				background={SR_COLOR_WHITE}
				alignSelf={'center'}
				width="460px"
				pad="large"
				round="xsmall"
				border={{color: 'neutral-2', size: '1px'}}
			>
				<Image margin={{bottom: '10px'}} alignSelf={'start'} src={'/images/Logo.svg'} alt={`Naska Logo`} />
				<Form
					onSubmit={(event: any) => {
						login(event.value.email, event.value.password).catch(error => {
							pushNotification(error.message, 'error')
						})
					}}
				>
					<Box gap={'medium'}>
						<SRHeading level={5}>Sign in</SRHeading>
						<Box>
							<Text>Email</Text>
							<SRTextInput id="user_email" type={'email'} name="email" />
						</Box>
						<Box>
							<Text>Password</Text>
							<SRTextInput type={'password'} id="user_password" name="password" />
						</Box>
						<SRBrandButton type="submit" label="Sign in" />
					</Box>
				</Form>
			</Box>
		</Box>
	)
}
