import React, {ReactNode, useCallback} from 'react'
import {NotificationModal} from './NotificationModal'

type NotificationLevel = 'success' | 'error' | 'warning' | 'info'

export interface Notification {
	message: string
	level: NotificationLevel
}

type ContextValue =
	| {
			currentNotification?: Notification
			pushNotification: (message: string, level: NotificationLevel) => void
	  }
	| undefined

export const NotificationContext = React.createContext<ContextValue>(undefined)

export function NotificationProvider({children}: {children: ReactNode}) {
	const [notification, setNotification] = React.useState<Notification | undefined>(undefined)

	const pushNotification = useCallback((message: string, level: NotificationLevel) => {
		setNotification({message, level})
	}, [])

	const unsetNotification = useCallback(() => {
		setNotification(undefined)
	}, [])

	return (
		<NotificationContext.Provider value={{currentNotification: notification, pushNotification}}>
			{notification ? <NotificationModal notification={notification} acknowledgeFn={unsetNotification} /> : null}
			{children}
		</NotificationContext.Provider>
	)
}
