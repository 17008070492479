import {config} from './config'

export const amplifyConfig = {
	Auth: {
		// REQUIRED - Amazon Cognito Region
		region: config.sr.aws_cognito_region,
		// OPTIONAL - Amazon Cognito User Pool ID
		userPoolId: config.sr.aws_user_pools_id,
		// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
		userPoolWebClientId: config.sr.aws_user_pools_web_client_id,
		// OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
		mandatorySignIn: true,
	},
	Analytics: {
		disabled: true,
	},
}
