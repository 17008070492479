import React from 'react'
import {Box, Table, TableBody, TableCell, TableHeader, TableRow} from 'grommet'
import {format, formatDistanceToNow} from 'date-fns'
import {ReclassificationAnnotationEntity} from '../entities/reclassification-annotation'
import _ from 'lodash'
import {SRCard, SRSecondaryButton} from 'sr-react-commons'

export function ReclassificationHistory({
	annotations,
	selectedClassificationId,
	setSelectedClassificationId,
}: {
	annotations: ReclassificationAnnotationEntity[]
	selectedClassificationId: string | undefined
	setSelectedClassificationId: (nextId: string | undefined) => void
}) {
	return (
		<SRCard fill>
			<Box fill overflow="auto">
				<Table>
					<TableHeader>
						<TableRow>
							<TableCell>Status</TableCell>
							<TableCell>Classification</TableCell>
							<TableCell>Magnitude</TableCell>
							<TableCell>Date</TableCell>
							<TableCell>Actions</TableCell>
						</TableRow>
					</TableHeader>
					<TableBody>
						{_.sortBy(annotations, 'createdDate')
							.reverse()
							.map(annotation => (
								<TableRow
									style={{
										backgroundColor:
											annotation.classificationId === selectedClassificationId ? 'lightgray' : 'transparent',
									}}
									key={annotation._id}
								>
									<TableCell>{annotation.action}</TableCell>
									<TableCell>{annotation.classification || '-'}</TableCell>
									<TableCell>
										{annotation.magnitude ? `${Math.round(Number(annotation.magnitude * 1000))} mm` : '-'}
									</TableCell>
									<TableCell>
										<Box title={format(new Date(annotation.createdDate), 'yyyy-MM-dd HH:mm')}>
											{formatDistanceToNow(new Date(annotation.createdDate), {addSuffix: true})}
										</Box>
									</TableCell>
									<TableCell>
										{selectedClassificationId && selectedClassificationId === annotation.classificationId ? (
											<SRSecondaryButton
												size={'small'}
												label={'Cancel'}
												onClick={() => setSelectedClassificationId(undefined)}
											/>
										) : (
											<SRSecondaryButton
												size={'small'}
												label={'Edit'}
												onClick={() => setSelectedClassificationId(annotation.classificationId)}
											/>
										)}
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</Box>
		</SRCard>
	)
}
