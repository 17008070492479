import React, {useLayoutEffect} from 'react'
import {ViewerContext} from '../components/ViewerProvider'

export const useViewer = () => {
	const context = React.useContext(ViewerContext)

	const viewer = context?.viewer
	const status = context?.status

	if (context === undefined) {
		throw new Error('`useViewer` hook must be used within a `ViewerProvider` component')
	}

	useLayoutEffect(() => {
		if (status === 'model_loaded' && viewer?.current) {
			viewer.current.resize()
		}
	})

	return context
}
