import {Filter, Sorting, useData} from '../../../../hooks/useData'
import {
	ReclassificationWorkPackageTaskEntity,
	ReclassificationWorkPackageTaskEntityWithStageTasks,
} from '../../entities/reclassification-work-package-task'
import {QueryConfig} from 'react-query'
import {Pagination} from '../../../../api/baseApi'
export function useReclassificationTaskWorkPackageTasks(
	projectId: string | undefined,
	reclassificationTaskId: string,
	{page = 0, perPage = 25}: {page: number; perPage: number},
	filters: Filter<ReclassificationWorkPackageTaskEntity> = {},
	sorting: Sorting<ReclassificationWorkPackageTaskEntity> = {field: null, ascending: true},
	options: QueryConfig<Pagination<ReclassificationWorkPackageTaskEntityWithStageTasks>> = {},
) {
	return useData<ReclassificationWorkPackageTaskEntityWithStageTasks>(
		`projects/${
			projectId || ''
		}/reclassification/reclassification-tasks/${reclassificationTaskId}/reclassification-work-package-tasks`,
		{page, perPage},
		filters,
		sorting,
		{
			...options,
			enabled: projectId && (options.enabled ?? true),
		},
	)
}
