import {ElementSectionView} from './ElementSectionView'
import React, {useEffect} from 'react'
import {Box, Heading, Text} from 'grommet'
import {UserProject} from '../../User/entities/userProject'
import {Section} from '../../../entities/classification'
import './Viewing.Extension.ElementSections.scss'
import {useMeasurements} from './state/MeasurementsContext'
import {Next, Previous} from 'grommet-icons'
import {useElementSectionState} from './state/ElementSectionStateContext'
import {SRIconButton} from 'sr-react-commons'

interface ElementSectionsPanelContentProps {
	sections: Section[]
	project: UserProject
}

export const ElementSectionsPanelContent = ({sections, project}: ElementSectionsPanelContentProps) => {
	const {
		state: {currentSection, currentSectionIndex},
		actions: {setSections, nextSection, prevSection},
	} = useElementSectionState()
	const currentSectionName =
		currentSection?.name === 'WIP' ? `Section ${String.fromCharCode(65 + currentSectionIndex)}` : currentSection?.name
	const {clearAll} = useMeasurements()
	useEffect(() => {
		clearAll()
		setSections(sections)
		return () => setSections(null)
	}, [clearAll, sections, setSections])
	return (
		<Box fill>
			{sections && currentSection ? (
				<Box id="element-sections-container" fill={true} direction={'row'}>
					<Box fill pad="xsmall" gap="xsmall">
						<Box align={'end'}>
							<Box direction="row" align="center" justify="center" gap={'xsmall'}>
								<Box align="center">
									<Text>{currentSectionName}</Text>
								</Box>
								<SRIconButton
									size={'small'}
									data-asset-hide={true}
									onClick={prevSection}
									icon={<Previous size={'small'} />}
								/>
								<Box>
									<Text>
										{currentSectionIndex + 1}/{sections.length}
									</Text>
								</Box>
								<SRIconButton
									size={'small'}
									data-asset-hide={true}
									onClick={nextSection}
									icon={<Next size={'small'} />}
								/>
							</Box>
						</Box>
						<ElementSectionView section={currentSection} project={project} />
					</Box>
				</Box>
			) : (
				<Box>
					<Heading level={4}>This element has no sections drawings.</Heading>
				</Box>
			)}
		</Box>
	)
}
