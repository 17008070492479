import {rest} from 'msw'
import {config} from '../config/config'

export const companyHandlers = [
	rest.post(config.sr.backendUrl + 'v3/companies/:companyId/users/', (req, res, ctx) => {
		return res(
			ctx.status(400),
			ctx.json({
				message: 'User with that email already exists!',
			}),
		)
	}),
]
