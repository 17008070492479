import {RouterContext} from '../components/AppRouter'
import React from 'react'

export function useRoutes() {
	const context = React.useContext(RouterContext)
	if (context === undefined) {
		throw new Error('`useRoutes` hook must be used within a `AppRouter` component')
	}
	return context
}
