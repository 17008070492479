import {useQuery} from 'react-query'
import {ClassificationWithAnalysisView} from '../../../entities/classification'
import {AxiosError} from 'axios'
import {fetchClassificationWithAnalysisView} from '../api/classification'

export function useClassificationsWithAnalysisView(externalId: string, projectId: string) {
	return useQuery<ClassificationWithAnalysisView[], AxiosError>(['classifications-with-analysis', externalId], () =>
		fetchClassificationWithAnalysisView(externalId, projectId),
	)
}
