import React from 'react'
import {NotificationContext} from '../components/NotificationProvider'

export function useNotifications() {
	const context = React.useContext(NotificationContext)

	if (context === undefined) {
		throw new Error('`useNotifications` hook must be used within a `NotificationProvider` component')
	}
	return context
}
