import {useQuery} from 'react-query'
import {ReclassificationWithUserNameDTO} from '../../entities/reclassification-annotation'
import {
	fetchClassificationByIdForFinalReview,
	fetchReclassificationsForFinalReview,
} from '../../api/reclassificationAnnotations'
import {Classification} from '../../../../entities/classification'

export function useReclassificationsForFinalReview(
	projectId: string | undefined,
	reclassificationId: string | undefined,
) {
	return useQuery<ReclassificationWithUserNameDTO[]>(
		['reclassification-annotations', 'final-review', reclassificationId],
		() => fetchReclassificationsForFinalReview(projectId!, reclassificationId!),
		{enabled: projectId && reclassificationId, keepPreviousData: true},
	)
}

export function useClassificationByIdForFinalReview(
	projectId: string | undefined,
	reclassificationId: string | undefined,
	classificationId: string | undefined,
) {
	return useQuery<Classification>(
		['classification-by-id', reclassificationId, classificationId],
		() => fetchClassificationByIdForFinalReview(projectId!, reclassificationId!, classificationId!),
		{enabled: projectId && reclassificationId && classificationId},
	)
}
