import {Table, TableProps, ThemeContext} from 'grommet'
import React from 'react'

export function SmallTable(props: TableProps & JSX.IntrinsicElements['table']) {
	return (
		<ThemeContext.Extend
			value={{
				table: {
					body: {
						pad: {horizontal: 'xxsmall', vertical: 'xxsmall'},
					},
				},
			}}
		>
			<Table {...props} />
		</ThemeContext.Extend>
	)
}
