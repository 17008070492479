import React from 'react'
import {Box, Layer, Paragraph} from 'grommet'
import {StatusCritical} from 'grommet-icons'
import {SRSecondaryButton} from 'sr-react-commons'

export function ErrorNotification({message, dismiss}: {message: string; dismiss: () => void}) {
	return (
		<Layer modal={true} position="top" margin={{top: 'small'}} animate={true} animation="slide" responsive={true}>
			<Box align="stretch" justify="between" direction="row" elevation="small">
				<Box align="center" justify="center" background={{color: 'status-error'}} direction="row" pad="medium">
					<StatusCritical size="large" color="white" />
				</Box>
				<Box align="stretch" justify="center" direction="column" background={{color: 'light-3'}}>
					<Box align="center" justify="center" direction="row">
						<Paragraph fill size="medium" textAlign="start" margin="medium" color="black">
							{message}
						</Paragraph>
						<SRSecondaryButton
							label="Acknowledge"
							type="button"
							hoverIndicator={true}
							size="small"
							margin="small"
							onClick={dismiss}
						/>
					</Box>
				</Box>
			</Box>
		</Layer>
	)
}
