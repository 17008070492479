import {Page} from '../../components/Page/Page'
import React from 'react'
import {Spinner} from '../../components/Spinner/Spinner'
import {Container} from '../../components/Container/Container'
import {Box, Text} from 'grommet'
import {useProject} from '../../features/User/hooks/useProject'
import {UserProject} from '../../features/User/entities/userProject'
import {Link} from 'react-router-dom'
import {useRoutes} from '../../hooks/useRoutes'
import {PageTitle} from '../../components/TopNavigationBar/PageTitle'
import {Separator} from '../../components/TopNavigationBar/Separator'
import {BreadcrumbItem} from '../../components/TopNavigationBar/BreadcrumbItem'
import {usePaginationFilterSort} from '../../hooks/usePaginationFilterSort'
import {PaginationControls} from '../../components/PaginationControls/PaginationControls'
import {useCurrentUserManagedReclassificationTasks} from '../../features/Reclassification/hooks/reclassificationTask/useCurrentUserManageedReclassificationTasks'
import {ReclassificationTaskEntity} from '../../features/Reclassification/entities/reclassification-task'
import {useCurrentUser} from '../../features/User/hooks/useCurrentUser'
import {User} from '../../features/User/entities/user'
import {
	SR_COLOR_LIGHT_1,
	SRCard,
	SRCardContainer,
	SRChip,
	SRHeading,
	SRMultiSelect,
	SRNotificationCard,
	SRPrimaryButton,
	SRSecondaryChip,
} from 'sr-react-commons'
import {RECLASSIFICATION_STATUS_COLORS} from '../../utilities/colors'

const TASKS_PER_PAGE = 10

export function MyManagedReclassificationsPageTitle() {
	return (
		<PageTitle>
			<Separator />
			<BreadcrumbItem width={{max: 'medium'}}>My managed reclassifications</BreadcrumbItem>
		</PageTitle>
	)
}

export function MyManagedReclassificationsPage() {
	return (
		<Page>
			<MyManagedReclassifications />
		</Page>
	)
}

export function MyManagedReclassifications() {
	const pagination = usePaginationFilterSort<ReclassificationTaskEntity>(
		{page: 0},
		{status: ['not started', 'in progress']},
	)
	const {
		data: reclassificationsPagination,
		status: reclassificationsLoadingStatus,
	} = useCurrentUserManagedReclassificationTasks(
		{page: pagination.state.pagination.page, perPage: TASKS_PER_PAGE},
		pagination.state.filter,
	)
	const {userDetails} = useCurrentUser()
	const reclassifications = reclassificationsPagination?.result
	if (reclassificationsLoadingStatus === 'loading') {
		return (
			<Page>
				<Spinner />
			</Page>
		)
	}
	return reclassificationsPagination && reclassifications && userDetails ? (
		<Container gap={'medium'} fill={'vertical'}>
			<SRHeading level={5}>Reclassifications managed by me</SRHeading>
			<Box direction={'row'} gap={'small'} align={'center'}>
				<SRHeading level={6}>Filter by status: </SRHeading>
				<Box width={'medium'}>
					<SRMultiSelect
						size={'small'}
						options={[
							{label: 'Not started', value: 'not started'},
							{label: 'In Progress', value: 'in progress'},
							{label: 'Finished', value: 'finished'},
						]}
						onChange={(values: string[]) => {
							pagination.actions.setFilter('status', values)
							pagination.actions.setPage(0)
						}}
						value={(pagination.state.filter.status as string[]) || ['not started', 'in progress', 'finished']}
					/>
				</Box>
			</Box>
			<Box fill>
				{reclassifications.length > 0 ? (
					<Box gap={'small'}>
						<SRCardContainer scrollable>
							{reclassifications.map(reclassification => (
								<ManagedReclassificationCardContainer
									key={reclassification._id}
									currentUser={userDetails}
									reclassification={reclassification}
								/>
							))}
						</SRCardContainer>
						<PaginationControls
							start={reclassificationsPagination.skip}
							currentPageLen={reclassificationsPagination.result.length}
							currentPageSize={TASKS_PER_PAGE}
							totalCount={reclassificationsPagination.count}
							page={pagination.state.pagination.page + 1}
							pageCount={Math.ceil(reclassificationsPagination.count / TASKS_PER_PAGE)}
							setPage={(page: number) => pagination.actions.setPage(page - 1)}
						/>
					</Box>
				) : (
					<Box height={'70px'}>
						<SRNotificationCard fill={false} type={'info'} text={<>No reclassifications found for current filter.</>} />
					</Box>
				)}
			</Box>
		</Container>
	) : null
}

function ManagedReclassificationCard(props: {
	currentUser: User
	project: UserProject
	reclassification: ReclassificationTaskEntity
}) {
	const [clientSlug, projectSlug] = props.reclassification.tenantId.split('|')
	const routes = useRoutes()
	return (
		<SRCard direction={'row'} background={SR_COLOR_LIGHT_1}>
			<Box gap={'medium'} direction={'row'} justify={'between'} align={'center'} flex={'grow'}>
				<Box fill gap={'medium'}>
					<Box direction={'row'} align={'center'} gap={'xsmall'}>
						<Text weight={'bold'}>
							{props.project.company.name} {'>'} {props.project.name}
						</Text>
						<Text title={props.reclassification.name} truncate>
							{props.reclassification.name}
						</Text>
					</Box>
					<Box direction={'row'} gap={'xsmall'} align={'center'}>
						Managing:
						{props.currentUser._id === props.reclassification.reclassificationCompanyManager ? (
							<SRSecondaryChip text={'Reclassification'} />
						) : null}
						{props.currentUser._id === props.reclassification.reviewCompanyManager ? (
							<SRSecondaryChip text={'Review'} />
						) : null}
					</Box>
				</Box>
				<Box width={'130px'}>
					<SRChip
						text={props.reclassification.status}
						background={RECLASSIFICATION_STATUS_COLORS[props.reclassification.status]}
					/>
				</Box>
				<Link
					to={routes.reclassificationOverview.linkTo({
						clientSlug,
						projectSlug,
						reclassificationId: props.reclassification._id,
					})}
				>
					<SRPrimaryButton label={'Manage'} />
				</Link>
			</Box>
		</SRCard>
	)
}

function ManagedReclassificationCardContainer(props: {
	reclassification: ReclassificationTaskEntity
	currentUser: User
}) {
	const [clientSlug, projectSlug] = props.reclassification.tenantId.split('|')
	const {project, status: projectLoadingStatus} = useProject(clientSlug, projectSlug)

	if (projectLoadingStatus === 'loading') {
		return <Spinner />
	}
	return project ? (
		<ManagedReclassificationCard
			currentUser={props.currentUser}
			project={project}
			reclassification={props.reclassification}
		/>
	) : null
}
