import {Filter, PageParams, useData} from '../../../../hooks/useData'
import {ReclassificationTaskEntity} from '../../entities/reclassification-task'

export function useCurrentUserManagedReclassificationTasks(
	pagination: PageParams,
	filter: Filter<ReclassificationTaskEntity> = {},
) {
	return useData<ReclassificationTaskEntity>(
		'reclassification/reclassification-tasks/managed-by-current-user',
		pagination,
		filter,
		{ascending: false, field: 'createdDate'},
	)
}
