import {Link, useHistory, useParams} from 'react-router-dom'
import {Box, Heading, Meter, Text} from 'grommet'
import React, {ReactNode} from 'react'
import {Spinner} from '../../components/Spinner/Spinner'
import {Container} from '../../components/Container/Container'
import {Page} from '../../components/Page/Page'
import {useRoutes} from '../../hooks/useRoutes'
import {Separator} from '../../components/TopNavigationBar/Separator'
import {BreadcrumbItem} from '../../components/TopNavigationBar/BreadcrumbItem'
import {PageTitle} from '../../components/TopNavigationBar/PageTitle'
import {PageHeader} from '../../components/Page/PageHeader'
import {Deliver, Launch, Radial, ShareRounded, StatusGood, StatusWarning} from 'grommet-icons'
import {getFullName, User} from '../../features/User/entities/user'
import {UserProject} from '../../features/User/entities/userProject'
import {Step} from '../../components/Steps/Step'
import {useCurrentUser} from '../../features/User/hooks/useCurrentUser'
import {
	canManageStage,
	ReclassificationTaskEntity,
} from '../../features/Reclassification/entities/reclassification-task'
import {useReclassificationTask} from '../../features/Reclassification/hooks/reclassificationTask/useReclassificationTask'
import {useReclassificationTaskMutations} from '../../features/Reclassification/hooks/reclassificationTask/useReclassificationTaskMutations'
import {useReclassificationTaskUsers} from '../../features/Reclassification/hooks/reclassificationTask/useReclassificationTaskUsers'
import {useReclassificationTaskWorkPackageTasks} from '../../features/Reclassification/hooks/workPackageTask/useReclassificationTaskWorkPackageTasks'
import {ReclassificationWorkPackageTaskEntity} from '../../features/Reclassification/entities/reclassification-work-package-task'
import {useCurrentUserHasPermissionOnResource} from '../../features/User/hooks/useCurrentUserHasPermission'
import {PermissionsEnum} from '../../features/User/entities/auth'
import {useCurrentProject} from '../../hooks/useCurrentProject'
import {
	SR_COLOR_LIGHT_1,
	SR_COLOR_LIGHT_2,
	SRAccordion,
	SRCard,
	SRCardContainer,
	SRPrimaryButton,
	SRSecondaryButton,
	SRTextInput,
} from 'sr-react-commons'

export type ReclassificationOverviewPageParams = {
	clientSlug: string
	projectSlug: string
	reclassificationId: string
}

export function ReclassificationOverviewPageTitle() {
	const {reclassificationId} = useParams<ReclassificationOverviewPageParams>()
	const project = useCurrentProject()
	const {data: reclassification} = useReclassificationTask(project?._id, reclassificationId)

	return project && reclassification ? (
		<PageTitle>
			<Separator />
			<BreadcrumbItem>{reclassification.name}</BreadcrumbItem>
			<Separator />
			<BreadcrumbItem truncate={false}>Overview</BreadcrumbItem>
		</PageTitle>
	) : null
}

export function ReclassificationOverviewPageHeader(props: {
	project: UserProject
	reclassification: ReclassificationTaskEntity
	heading: string
}) {
	const locationInputRef = React.useRef<any>(null)
	const [state, setState] = React.useState<'sharing' | 'idle'>('idle')
	const [copied, setCopied] = React.useState(false)
	return (
		<PageHeader>
			<Box direction={'row'} justify={'between'}>
				<Box>
					<Text>
						{props.project.company.name} {'>'} {props.project.name}
					</Text>
					<Box direction={'row'} gap={'small'}>
						<Text size={'large'} weight={'bold'}>
							{props.reclassification.name}
						</Text>
					</Box>
					<Heading level={'3'}>{props.heading}</Heading>
				</Box>
				<Box>
					{state === 'sharing' ? (
						<Box gap={'xsmall'}>
							<Box direction={'row'} gap={'xsmall'}>
								<SRTextInput size={'xsmall'} ref={locationInputRef} readOnly value={window.location.href} />
								<SRSecondaryButton
									size={'small'}
									onClick={() => {
										locationInputRef.current.select()
										document.execCommand('copy')
										setCopied(true)
										setTimeout(() => setCopied(false), 5000)
									}}
									label={'Copy'}
								/>
								<SRSecondaryButton size={'small'} onClick={() => setState('idle')} label={'Cancel'} />
							</Box>
							{copied && (
								<Text textAlign={'end'} size={'small'}>
									Page link has been copied to your clipboard!
								</Text>
							)}
						</Box>
					) : (
						<SRSecondaryButton
							icon={<ShareRounded />}
							size={'small'}
							onClick={() => setState('sharing')}
							alignSelf={'end'}
							label={'Share'}
						/>
					)}
				</Box>
			</Box>
		</PageHeader>
	)
}

function ReclassificationPageStepContent(props: {
	reclassification: ReclassificationTaskEntity
	reclassificationUsers: User[]
	project: UserProject
	currentUser: User
}) {
	return (
		<>
			{props.reclassification.status === 'not started' && (
				<OverviewNotStarted
					reclassification={props.reclassification}
					reclassificationUsers={props.reclassificationUsers}
					currentUser={props.currentUser}
					project={props.project}
				/>
			)}
			{props.reclassification.status === 'in progress' && (
				<OverviewInProgress
					project={props.project}
					reclassification={props.reclassification}
					reclassificationUsers={props.reclassificationUsers}
					currentUser={props.currentUser}
				/>
			)}
			{props.reclassification.status === 'finished' && <OverviewFinished reclassification={props.reclassification} />}
		</>
	)
}

export function ReclassificationOverviewPage() {
	const {reclassificationId} = useParams<ReclassificationOverviewPageParams>()
	const project = useCurrentProject()
	const {data: reclassification} = useReclassificationTask(project?._id, reclassificationId)
	const {startReclassification, markAsCompleted} = useReclassificationTaskMutations()
	const {users: reclassificationUsers} = useReclassificationTaskUsers(project?._id, reclassificationId)
	const {userDetails, authDetails} = useCurrentUser()

	if (!(reclassification && reclassificationUsers && project && userDetails && authDetails)) {
		return (
			<Page>
				<Spinner />
			</Page>
		)
	} else {
		const canBeStarted =
			reclassification.status === 'not started' && reclassification.stages[0].assignedUsers.length > 0

		return (
			<Page>
				<Container gap={'small'} fill={'vertical'} width={'xlarge'} overflow={{vertical: 'auto'}}>
					<ReclassificationOverviewPageHeader
						project={project}
						reclassification={reclassification}
						heading={'Overview'}
					/>
					<Box gap="small">
						<ReclassificationTimeline
							onStart={() =>
								startReclassification({projectId: project._id, reclassificationTaskId: reclassificationId})
							}
							onComplete={() => markAsCompleted({projectId: project._id, reclassificationTaskId: reclassificationId})}
							canBeStarted={canBeStarted}
							project={project}
							reclassification={reclassification}
						/>
						<Box pad={'small'}>
							<ReclassificationPageStepContent
								reclassification={reclassification}
								reclassificationUsers={reclassificationUsers}
								project={project}
								currentUser={userDetails}
							/>
						</Box>
					</Box>
				</Container>
			</Page>
		)
	}
}

export function ReclassificationTimeline({
	reclassification,
	onStart,
	onComplete,
	canBeStarted,
	project,
}: {
	reclassification: ReclassificationTaskEntity
	onStart: () => void
	onComplete: () => void
	canBeStarted: boolean
	project: UserProject
}) {
	const {data: workPackagePagination} = useReclassificationTaskWorkPackageTasks(project._id, reclassification._id, {
		page: 0,
		perPage: Math.ceil(reclassification.classifications.length / reclassification.packageSize) * 2,
	})

	const canBeMarkedAsCompleted =
		workPackagePagination && !workPackagePagination.result.some(wp => wp.status !== 'finished')
	return (
		<Box flex={false} gap={'xsmall'}>
			<Box alignSelf={'center'} width={'large'} gap={'xsmall'} direction={'row'}>
				<Step
					first={true}
					label={'Start'}
					status={reclassification.status === 'not started' ? 'current' : 'completed'}
					button={
						reclassification.status === 'not started' ? (
							<SRPrimaryButton
								disabled={!canBeStarted}
								margin={{top: 'xsmall'}}
								size={'small'}
								label={'Start'}
								onClick={onStart}
							/>
						) : undefined
					}
				/>
				<Step
					label={'Reclassification'}
					status={
						reclassification.status === 'in progress'
							? 'current'
							: reclassification.status === 'not started'
							? 'pending'
							: 'completed'
					}
					button={
						reclassification.status === 'in progress' ? (
							<SRPrimaryButton
								disabled={!canBeMarkedAsCompleted}
								margin={{top: 'xsmall'}}
								size={'small'}
								label={'Mark as completed'}
								onClick={onComplete}
							/>
						) : undefined
					}
				/>
				<Step label={'Finish'} status={reclassification.status === 'finished' ? 'completed' : 'pending'} />
			</Box>
		</Box>
	)
}

function OverviewInProgressPresenter({
	reclassification,
	reclassificationUsers,
	currentUser,
	workPackages,
	hasSrAdminPermission,
}: {
	reclassification: ReclassificationTaskEntity
	reclassificationUsers: User[]
	currentUser: User
	workPackages: ReclassificationWorkPackageTaskEntity[]
	hasSrAdminPermission: boolean
}) {
	const history = useHistory()
	const routes = useRoutes()
	const [clientSlug, projectSlug] = reclassification.tenantId.split('|')

	const reclassificationManager = reclassificationUsers.find(
		user => user._id === reclassification.reclassificationCompanyManager,
	)!
	const reviewManager = reclassificationUsers.find(user => user._id === reclassification.reviewCompanyManager)!
	const canBeFinished = !workPackages.some(wp => wp.status !== 'finished')
	return (
		<Box flex gap={'small'}>
			<Heading level={3}>Step 2: Manage reclassification</Heading>
			<Box pad={'small'}>
				<TaskItem completed={canBeFinished}>Supervise reclassification and review of all work packages</TaskItem>
			</Box>
			<Heading level={4}>Reclassification Stages</Heading>
			<Box gap={'small'} fill={'vertical'} overflow={'auto'}>
				<SRCardContainer scrollable>
					{reclassification.stages.map((stage, index) => {
						const canManage = canManageStage(currentUser, stage, reclassification, hasSrAdminPermission)
						return (
							<SRCard key={stage._id} background={SR_COLOR_LIGHT_1}>
								<Box justify={'between'} direction={'row'} gap={'small'}>
									<Box width={{min: 'medium'}}>
										<Text weight={'bold'}>
											Stage {index + 1}: {stage.name}
										</Text>
										<Text>
											Stage manager:{' '}
											{stage.assignedCompany === reclassification.reviewCompany
												? getFullName(reviewManager)
												: getFullName(reclassificationManager)}{' '}
										</Text>
									</Box>
									<Box direction={'row'} gap={'small'}>
										<Box gap={'xsmall'}>
											<Meter
												type="bar"
												background={SR_COLOR_LIGHT_1}
												values={[
													{
														value:
															(workPackages.filter(
																wp =>
																	wp.status === 'finished' ||
																	index < reclassification.stages.findIndex(stage => wp.currentStage === stage._id),
															).length /
																workPackages.length) *
															100.0,
													},
												]}
												size="small"
												thickness="small"
											/>
											<Text textAlign={'center'}>
												{
													workPackages.filter(
														wp =>
															wp.status === 'finished' ||
															index < reclassification.stages.findIndex(stage => wp.currentStage === stage._id),
													).length
												}{' '}
												/ {workPackages.length} completed
											</Text>
										</Box>
										<Box>{stage.assignedUsers.length} user(s) assigned</Box>
										<Box gap={'xsmall'}>
											<SRSecondaryButton
												onClick={() =>
													history.push(
														routes.reclassificationUsers.linkTo(
															{
																clientSlug,
																projectSlug,
																reclassificationId: reclassification._id,
															},
															{stage: index.toString()},
														),
													)
												}
												disabled={!canManage}
												alignSelf={'end'}
												size={'small'}
												label={'Manage users'}
											/>
										</Box>
									</Box>
								</Box>
							</SRCard>
						)
					})}
				</SRCardContainer>
			</Box>
		</Box>
	)
}

export function OverviewInProgress({
	reclassification,
	reclassificationUsers,
	project,
	currentUser,
}: {
	project: UserProject
	reclassification: ReclassificationTaskEntity
	reclassificationUsers: User[]
	currentUser: User
}) {
	const {data: workPackagePagination} = useReclassificationTaskWorkPackageTasks(project._id, reclassification._id, {
		page: 0,
		perPage: Math.ceil(reclassification.classifications.length / reclassification.packageSize) * 2,
	})
	const {data: hasSrAdminPermission} = useCurrentUserHasPermissionOnResource(PermissionsEnum.SR_ADMIN, project._id)
	return workPackagePagination && hasSrAdminPermission !== undefined ? (
		<OverviewInProgressPresenter
			reclassification={reclassification}
			currentUser={currentUser}
			reclassificationUsers={reclassificationUsers}
			workPackages={workPackagePagination.result}
			hasSrAdminPermission={hasSrAdminPermission}
		/>
	) : (
		<Spinner />
	)
}

export function OverviewNotStarted({
	reclassification,
	reclassificationUsers,
	currentUser,
	project,
}: {
	reclassification: ReclassificationTaskEntity
	reclassificationUsers: User[]
	currentUser: User
	project: UserProject
}) {
	const history = useHistory()
	const routes = useRoutes()
	const [clientSlug, projectSlug] = reclassification.tenantId.split('|')
	const {data: hasSrAdminPermission} = useCurrentUserHasPermissionOnResource(PermissionsEnum.SR_ADMIN, project._id)
	const reclassificationManager = reclassificationUsers.find(
		user => user._id === reclassification.reclassificationCompanyManager,
	)!
	const reviewManager = reclassificationUsers.find(user => user._id === reclassification.reviewCompanyManager)!

	return hasSrAdminPermission !== undefined ? (
		<Box flex={false} gap={'small'}>
			<Heading level={3}>Step 1: Assign Users</Heading>
			<Box pad={'small'}>
				<TaskItem completed={reclassification.stages[0].assignedUsers.length > 0}>
					<Link to={'./users?stage=0'}>
						Assign users to the first stage of the reclassification task{' '}
						{reclassification.stages[0].assignedUsers.length > 0 &&
							`(You have already assigned ${reclassification.stages[0].assignedUsers.length} users)`}
					</Link>
				</TaskItem>
			</Box>
			<Heading level={4}>Reclassification Stages</Heading>
			<SRCardContainer scrollable>
				{reclassification.stages.map((stage, index) => {
					const canManage = canManageStage(currentUser, stage, reclassification, hasSrAdminPermission)
					return (
						<SRCard key={stage._id} background={SR_COLOR_LIGHT_1}>
							<Box gap={'small'} direction={'row'} justify={'between'}>
								<Box width={{min: 'medium'}} gap={'small'}>
									<Text>
										Stage {index + 1}: {stage.name} - {stage.type}
									</Text>
									{stage.assignedUsers.length > 0 ? (
										<Box fill={'horizontal'} background={SR_COLOR_LIGHT_2} pad={{horizontal: 'xsmall'}} round="xsmall">
											<SRAccordion
												option={`${stage.assignedUsers.length} assigned user(s)`}
												optionContent={stage.assignedUsers.map(userId => {
													const user = reclassificationUsers.find(user => user._id === userId)
													if (!user) return null
													return (
														<Box key={userId} direction={'row'} justify={'between'} pad={'xsmall'} border={'bottom'}>
															<Text>{getFullName(user)}</Text>
															<SRSecondaryButton disabled={!canManage} size={'small'} label={'unassign'} />
														</Box>
													)
												})}
											/>
										</Box>
									) : (
										<Box gap={'small'} flex={false}>
											<Box
												alignSelf={'start'}
												pad={{horizontal: 'medium', vertical: 'xsmall'}}
												direction={'row'}
												gap={'small'}
												background={'status-warning'}
												align={'center'}
												round="xsmall"
											>
												<StatusWarning color={'dark-1'} />
												<Text>No users assigned yet.</Text>
											</Box>
										</Box>
									)}
								</Box>
								<Box justify={'between'}>
									<SRPrimaryButton
										onClick={() =>
											history.push(
												routes.reclassificationUsers.linkTo(
													{
														clientSlug,
														projectSlug,
														reclassificationId: reclassification._id,
													},
													{stage: index.toString()},
												),
											)
										}
										disabled={!canManage}
										alignSelf={'end'}
										label={'Assign users'}
									/>
									<Text>
										Stage manager:{' '}
										{stage.assignedCompany === reclassification.reviewCompany
											? getFullName(reviewManager)
											: getFullName(reclassificationManager)}{' '}
									</Text>
								</Box>
							</Box>
						</SRCard>
					)
				})}
			</SRCardContainer>
		</Box>
	) : null
}

export function TaskItem({completed, children}: {completed: boolean; children: ReactNode}) {
	return (
		<Box direction={'row'} gap={'small'}>
			{completed ? <StatusGood color={'status-ok'} /> : <Radial />}
			{children}
		</Box>
	)
}

export function OverviewFinished({reclassification}: {reclassification: ReclassificationTaskEntity}) {
	const routes = useRoutes()
	const [clientSlug, projectSlug] = reclassification.tenantId.split('|')
	return (
		<Box gap={'xsmall'} align={'center'}>
			<Heading level={2} textAlign={'center'}>
				Reclassification finished!
			</Heading>
			<Text textAlign={'center'}>
				You can now create a new analysis version with the reclassified classifications and publish it to the client.
			</Text>
			<Box margin={{top: 'small'}} direction={'row'} gap={'small'} justify={'center'}>
				<StatusGood size={'large'} />
				<Deliver size={'large'} />
				<Launch size={'large'} />
			</Box>
			<Link
				to={routes.reclassificationReport.linkTo({
					clientSlug,
					projectSlug,
					reclassificationId: reclassification._id,
					subRoute: 'overall',
				})}
			>
				See reclassification report
			</Link>
			<Link to={routes.myManagedReclassifications.linkTo({})}>Back to my managed reclassifications</Link>
		</Box>
	)
}
