import React, {ReactNode} from 'react'
import {Link, useRouteMatch} from 'react-router-dom'
import {Box} from 'grommet'
import styled from 'styled-components'

const StyledMenuLink = styled(Link)`
	text-decoration: none;
	&:hover {
		background-color: #ccc;
	}
`

export function MenuLink({
	to,
	children,
	displayActiveIndicator = true,
	exact = true,
	overrideRouteMatch,
}: {
	to: string
	children: ReactNode
	displayActiveIndicator?: boolean
	overrideRouteMatch?: string
	exact?: boolean
}) {
	const match = useRouteMatch({
		path: overrideRouteMatch || to,
		exact: exact,
	})
	return (
		<StyledMenuLink to={to}>
			<Box
				border={match && displayActiveIndicator ? {side: 'left', size: 'small'} : undefined}
				pad={'xsmall'}
				direction={'row'}
				gap={'small'}
				alignSelf={'start'}
			>
				{children}
			</Box>
		</StyledMenuLink>
	)
}
